import React from 'react';
import { EnvironmentWarning } from './EnvironmentWarning'
import { NetworkInfoBar } from './NetworkInfoBar'

export const InfoBar = props =>
    <React.Fragment>
        <EnvironmentWarning />
        <NetworkInfoBar />
    </React.Fragment>

