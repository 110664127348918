import React from 'react'
import { ChatHelper, NewsHelper } from 'redux/helpers'
import { Button, Text } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { useLocation, useHistory } from 'navigation'
import { openURL } from 'lib/general'
import { routes } from 'navigation/index'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const ActionButton = ({ value, isCardAction, extraData, ...props }) => {
    const location = useLocation()
    const history = useHistory()
    const hasInternet = NetworkInfo.useHasInternet()
    const sendMessage = ChatHelper.useSendMessage()
    const setNewsItem = NewsHelper.useSetNewsItem()

    const buttonLabel = () => {
        let data
        if (value && value.isCustomEvent && value.data) data = value.data
        if (isCardAction && extraData && extraData.data) data = extraData.data
        if (data && data.type) {
            switch (data.type) {
                case 'screen':
                    return 'go to ' + data.location
                case 'newsEvent':
                    return 'open news item'
                default:
                    return ''
            }
        }
        if (value) {
            switch (value) {
                case 'Action.Submit':
                    return ''
                case 'Action.OpenUrl':
                    return extraData.url ? 'open ' + extraData.url : ''
                default:
                    return 'send ' + value + ' to Beacon'
            }
        }
        return ''
    }

    const handlePress = () => {
        if (value && value.isCustomEvent && value.data) return handleEvent(value.data, location, history, setNewsItem)
        if (isCardAction && extraData && extraData.data) return handleEvent(extraData.data, location, history, setNewsItem)
        if (value) {
            switch (value) {
                case 'Action.Submit':
                    return null
                case 'Action.OpenUrl':
                    return extraData.url ? openURL(extraData.url) : null
                default:
                    if (hasInternet) {
                        return sendMessage({ text: value }, true)
                    }
                    return null
            }
        }
        return null
    }

    const handleEvent = (data, location, history, setNewsItem) => {
        if (data && data.type) {
            switch (data.type) {
                case 'screen':
                    return history.push({ pathname: data.location, state: { from: location } })
                case 'newsEvent':
                    setNewsItem(data.body)
                    return history.push({ pathname: routes.news.default, state: { from: location } })
                default:
                    console.log('Unhandled Action Event')
                    return null
            }
        }
        return null
    }

    return (
        <Button
            accessibility={{ role: 'button', label: buttonLabel() }}
            handleAction={handlePress}
            padding={10}
            margin={2}
            marginTop={0}
            borderRadius={10}
            backgroundColor={props.color}
            opacity={props.opacity}
        >
            <Text
                color={props.textColor}
                nativeFontFamily={fonts.native.subHeading}
                fontSize={fonts.size.default}
                lineHeight={fonts.size.default * 1.5}
            >
                {props.title}
            </Text>
        </Button>
    )
}