export const routes = {
    login: '/',
    dashboard: '/dashboard',
    profile: '/profile',
    settings: {
        default: '/settings',
        accessibility: '/settings/accessibility',
        interests: '/settings/interests',
        notifications: '/settings/notifications',
        themes: '/settings/themes'
    },
    search: '/search',
    courses: '/courses',
    timetable: {
        default: '/timetable',
        weekView: '/timetable/week/:date',
        event: '/timetable/event/:eventId'
    },
    notifications: '/notifications',
    chat: {
        default: '/chat',
        suggestions: '/chat/suggestions'
    },
    news: {
        default: '/news',
        // id: '/news/:newsId'
    },
    webviews: {
        outlook: '/w/outlook',
        blackboard: '/w/blackboard',
        evision: '/w/evision',
        studentUnions: '/w/student-unions',
        covid19: '/w/covid',
        pcAvailability: '/w/pc-availability',
        termsOfUse: '/w/terms',
    },

    // External routes
    external: {
        outlook: 'https://outlook.office.com/mail',
        blackboard: 'https://blackboard.staffs.ac.uk',
        evision: 'https://evision.staffs.ac.uk/urd/sits.urd/run/siw_lgn',
        studentUnions: 'https://www.staffsunion.com',
        covid19: 'https://www.staffs.ac.uk/comms/coronavirus',
        pcAvailability: 'https://freepcfinder.staffs.ac.uk',
        togetherall: 'https://account.v2.togetherall.com/redirect/sso/azure?continue=https://v2.togetherall.com',
        termsOfUse: 'https://www.staffs.ac.uk/beacon-privacy-statement',
        iris: 'https://iris.staffs.ac.uk',
        myView: 'https://myview.staffs.ac.uk',
        workvivo: 'https://workvivo.staffs.ac.uk',
        skillSite: 'https://skillsite.staffs.ac.uk/auth/login/azuread-tenant-oauth2/?auth_entry=login&amp;next=%2F',
        appointmentBooking: 'https://bookings.staffs.ac.uk',
    }
}