import { submitAttendanceJob } from './submitAttendanceJob'

export {
    submitAttendanceJob
}

export const JobNames = {
    SUBMIT_ATTENDANCE_JOB: "SUBMIT_ATTENDANCE_JOB",
}

export const JobIndex = {
    [JobNames.SUBMIT_ATTENDANCE_JOB]: submitAttendanceJob,
}