import React from 'react'
import { Svg, Line, Polygon, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const CoursesIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'courses icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'courses icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Polygon scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} points="33.65,12 17.54,21.04 1.44,12 17.54,4.64        "/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} d="M28.84,14.96v8.96c0,3.58-5.06,6.49-11.3,6.49s-11.3-2.9-11.3-6.49v-8.96"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeMiterLimit:10}} x1="2.6" y1="13.29" x2="2.6" y2="21.02"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M4.39,21.88c0,0.99-1.79,3.87-1.79,3.87s-1.79-2.88-1.79-3.87c0-0.99,0.8-1.79,1.79-1.79S4.39,20.9,4.39,21.88
                z"/>
        </Svg>
    )
}