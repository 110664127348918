import React from 'react'
import { Svg, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const OnlineIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'online event icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'online event icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="24.6" cy="16.1" r="1.7"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M28.2,14.2c-0.2,0-0.4-0.1-0.6-0.2c-1.7-1.6-4.4-1.6-6.1,0c-0.4,0.3-0.9,0.3-1.2-0.1c-0.3-0.4-0.3-1,0.1-1.3
                c2.4-2.2,6-2.2,8.3,0c0.4,0.3,0.4,0.9,0.1,1.3C28.7,14.1,28.4,14.2,28.2,14.2z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M30.3,11.5c-0.2,0-0.4-0.1-0.6-0.2c-3-2.7-7.4-2.7-10.4,0c-0.4,0.3-0.9,0.3-1.2-0.1c-0.3-0.4-0.3-1,0.1-1.3
                c3.6-3.3,9-3.3,12.6,0c0.4,0.3,0.4,0.9,0.1,1.3C30.8,11.4,30.5,11.5,30.3,11.5z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M32.4,8.9c-0.2,0-0.4-0.1-0.6-0.2c-4.2-3.8-10.5-3.8-14.7,0C16.9,9,16.3,8.9,16,8.5c-0.3-0.4-0.3-1,0.1-1.3
                c4.8-4.4,12.1-4.4,16.9,0c0.4,0.3,0.4,0.9,0.1,1.3C32.9,8.8,32.7,8.9,32.4,8.9z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M31.7,11.8c-0.3,0.4-0.8,0.7-1.4,0.7c0.6,1.4,0.9,3,0.9,4.6H27c-0.2,0.4-0.4,0.7-0.7,1h5
                c-0.1,2.3-0.8,4.5-1.9,6.4H24c0.7-1.8,1.1-3.8,1.3-5.8c-0.2,0.1-0.5,0.1-0.7,0.1c-0.1,0-0.1,0-0.2,0c-0.2,2.1-0.6,4-1.3,5.8h-5.1
                v-6.4h4.9c-0.3-0.3-0.6-0.6-0.7-1h-4.3v-4.9c-0.2-0.1-0.3-0.2-0.4-0.4c-0.5-0.6-0.6-1.4-0.3-2c-0.1,0-0.3,0.1-0.5,0.1
                c-0.3,0-0.6-0.1-0.9-0.3h-3.5c1.1-2.2,2.6-4.2,4.7-5.9v1.7c0.3-0.2,0.7-0.4,1-0.6V3.7c0.3,0.2,0.5,0.4,0.7,0.7
                c0.3-0.1,0.6-0.3,0.9-0.4c0,0,0,0,0,0c0,0,0.1,0,0.1,0c1.3-0.5,2.7-0.8,4.2-0.9c-1.9-0.8-4-1.3-6.3-1.3C8.8,1.7,1.7,8.8,1.7,17.5
                s7.1,15.8,15.8,15.8s15.8-7.1,15.8-15.8c0-2.2-0.5-4.3-1.3-6.2C32,11.5,31.9,11.7,31.7,11.8z M15.2,3.9c-1.8,1.7-3.2,3.6-4.1,5.8
                h-5C8.2,6.7,11.4,4.5,15.2,3.9z M5.5,10.7h5.1c-0.7,2-1.2,4.1-1.2,6.4H3.7C3.8,14.8,4.4,12.6,5.5,10.7z M3.7,18.1h5.7
                c0.1,2.2,0.5,4.4,1.3,6.4H5.6C4.5,22.6,3.8,20.4,3.7,18.1z M6.3,25.5h4.9c1,2.1,2.3,4,4,5.6C11.5,30.5,8.4,28.4,6.3,25.5z
                    M16.9,31.2c-1.9-1.6-3.5-3.6-4.6-5.7h4.6V31.2z M16.9,24.5h-5c-0.9-2-1.4-4.2-1.5-6.4h6.5V24.5z M16.9,17.1h-6.5
                c0-2.3,0.5-4.4,1.3-6.4h5.2V17.1z M17.9,31.2v-5.7h4.6C21.4,27.7,19.9,29.6,17.9,31.2z M19.4,31.2c1.7-1.6,3.1-3.5,4.1-5.6h5.2
                C26.6,28.5,23.3,30.6,19.4,31.2z"/>
        </Svg>
    )
}