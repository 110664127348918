import React, { useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { SettingsHelper } from 'redux/helpers'

export const AnimatedPrimitive = ({ children, animations, initial, current, config, reset, delay = 0, ...props }) => {
    const accessibility = SettingsHelper.getAccessibilitySettings()
    const [{ scale, rotate, translateX, translateY, transform, ...springProps }, setAnimation] = useSpring(() => { return { from: animations[initial], to: animations[current], reset: reset, config: config, delay: delay, immediate: accessibility.disableAnimations } })

    let style = {...props}
    if(props.flex == 0) {
        style.flex = null
        style.flexShrink = 0
    }

    useEffect(() => {
        setAnimation(() => animations[current])
    }, [current, animations])

    return <animated.div style={{ ...style, ...springProps, transform: transform }}>{children}</animated.div>
}