import React, { useState, useEffect } from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Container, Text } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { ModuleYearView } from './ModuleYearView'
import { filterCourseData } from 'lib/courses/courseFilter'

export const CourseView = ({ data, ...props }) => {
    const colors = SettingsHelper.getColors()
    const [filteredData, setFilteredData] = useState([])
    const isCurrent = data.enrolStatus.i3Id = 21

    useEffect(() => {
        let newData = filterCourseData(data)
        setFilteredData(newData)
    }, [data])

    if (data) {
        return (
            <Container
                flex={0}
                width={'100%'}
                paddingBottom={20}
            >
                <Container
                    flex={0}
                    width={'100%'}
                    padding={5}
                    paddingBottom={10}
                    borderBottom={`2px solid ${colors.screenAltBg}`}
                    borderBottomWidth={2}
                    borderColor={colors.screenAltBg}
                >
                    <Text
                        color={isCurrent ? colors.screenText : colors.screenAltText}
                        nativeFontFamily={fonts.native.subHeading}
                        webFontFamily={fonts.web.heading}
                        fontWeight={fonts.weight.semiBold}
                        fontSize={fonts.size.large}
                    >
                        {data.course.fullTitle}
                    </Text>
                    <Text
                        color={colors.screenAltText}
                        nativeFontFamily={fonts.native.subHeading}
                        webFontFamily={fonts.web.heading}
                        fontWeight={fonts.weight.semiBold}
                    >
                        {data.enrolStatus.fullName}
                    </Text>
                </Container>
                {filteredData.map(year => <ModuleYearView data={year} key={year.year} />)}
            </Container>
        )
    }
    return null
}