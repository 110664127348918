import { useSelector, useDispatch } from 'react-redux'
import { getColors } from 'lib/config/ui-config'
import { SettingsActions } from './SettingsActions'
import { colorThemes } from 'lib/general/enums'

export class SettingsHelper {

    static getColors = () => {
        const theme = useSelector(state => state.settings.theme)
        return getColors(theme)
    }

    static getCurrentTheme = () => {
        const theme = useSelector(state => state.settings.theme)
        return theme
    }

    static getBackgroundColor = () => {
        const backgroundColor = useSelector(state => state.settings.backgroundColor)
        return backgroundColor
    }

    static getAccessibilitySettings = () => {
        const { theme, disableSwipeEvents, disableAnimations, disableInAppNotifications, largerDashboardTiles } = useSelector(state => state.settings)
        return { disableSwipeEvents, disableAnimations, disableInAppNotifications, isHighContrast: theme === colorThemes.highContrast, largerDashboardTiles }
    }

    static useSetAsyncBackgroundColor = () => {
        const dispatch = useDispatch()
        return (color, callbackFunc) => {
            Promise
            .resolve(dispatch(SettingsActions.setBackgroundColor(color)))
            .then(() => callbackFunc())
        }
    }

    static useSetQnaResults = () => {
        const dispatch = useDispatch()
        return results => {
            dispatch(SettingsActions.setQnaResults(results))
        }
    }
}

