import { useSelector, useDispatch } from 'react-redux'
import { PushNotification } from 'lib/primitives'
import { Platform } from 'lib/primitives'
import { NotificationActions, RedirectActions } from 'redux/actions'
import { notificationTypes } from 'lib/general/enums'
import moment from 'moment-timezone'
import { Namespaces } from '../../namespaces'

export class NotificationHelper {
  static useOnNotificationPressed = () => {
    const dispatch = useDispatch()
    const isNative = Platform.isNative()
    return async data => {
      if (isNative) {
        if (data.newsId) {
          // this.handleShowNewsModal(data.newsId) // TODO: change this old code to redirect to the news article screen
        }
        else if (data.type === notificationTypes.timetable) {
          dispatch(RedirectActions.addRedirectRoute('/timetable'))
        }
        else {
          console.warn('⚠️ Notifications: No handler defined. You should do something about it')
          console.log(data)
        }
      }
    }
  }

  static useOnNotificationForeground = () => {
    const isNative = Platform.isNative()
    return async notification => {
      if (isNative) {
        console.log('onNotificationForeground')
      }
    }
  }

  static useCreateNotificationsForTimetable = () => {
    const dispatch = useDispatch()
    const isNative = Platform.isNative()
    return timetable => {
      if (isNative) {
        let notifications = []
        let currentEventDate = null

        timetable.map(item => {
          const isSameDay = currentEventDate && moment(currentEventDate).isSame(item.eventStartDate, 'day')
          currentEventDate = item.eventStartDate
          const currentTimeInMiliSeconds = Date.now().toString()
          const notificationId  =`${currentTimeInMiliSeconds.substr(currentTimeInMiliSeconds.length - 8)}` //generate 8-bit int id

          if (!isSameDay && moment(item.eventStartDate).isAfter(moment().add(1, 'hour')) || isSameDay && moment(item.eventStartDate).isAfter(moment().add(15, 'minutes'))) {
            const notificationData = {
              title: item.description,
              message: `${moment(item.eventStartDate).format('HH:mm')} - ${moment(item.eventEndDate).format('HH:mm')} in ${item.locationDescription}`,
              date: isSameDay ? moment(item.eventStartDate).subtract(15, 'minutes').toDate() : moment(item.eventStartDate).subtract(1, 'hour').toDate(), //new Date(Date.now() + 0.2 * 1000),
              subtext: '📅Upcoming Event',
              type: notificationTypes.timetable,
              autoCancel: true,
              eventId: item.i3Id,
              autoCancel: true,
              subText: '📅Upcoming Event',
              vibrate: true,
              vibration: 300,
              playSound: true,
              soundName: 'default',
              eventId: item.scheduledEventId,
              id: notificationId,
              largeIcon: 'ic_launcher',
              smallIcon: 'ic_notification',
              color: 'green',
              userInfo : {
                id: notificationId //required for cancelling local notifications by id on iOS (See https://github.com/zo0r/react-native-push-notification/issues/570#issuecomment-337642922)
              },
              number: 0
            }

            PushNotification.localNotificationSchedule(notificationData)
            notifications.push(notificationData)
          }
        })
        dispatch(NotificationActions.addNotifications(notifications))
      }
    }
  }

  static useDeleteNotificationById = () => {
    const dispatch = useDispatch()
    const isNative = Platform.isNative()
    return id => {
      if (isNative) {
        dispatch(NotificationActions.removeNotification(id))
        PushNotification.cancelLocalNotifications({ id })
      }
    }
  }

  static useDeleteNotificationByType = () => {
    const dispatch = useDispatch()
    const isNative = Platform.isNative()
    const notifications = useSelector(state => state[Namespaces.NOTIFICATION].notifications)
    return type => {
      if (isNative) {
        const ids = notifications.filter(item => item.type == type).map(item => item.id)
        ids.map(id => PushNotification.cancelLocalNotifications({ id }))
        dispatch(NotificationActions.removeNotifications(ids))
      }
    }
  }

  static useDeleteAllNotifications = () => {
    const dispatch = useDispatch()
    const isNative = Platform.isNative()
    return () => {
      if (isNative) {
        dispatch(NotificationActions.removeAllNotifications())
        PushNotification.cancelAllLocalNotifications()
      }
    }
  }

  static useCreateScheduleNotification = () => {
    const dispatch = useDispatch()
    const isNative = Platform.isNative()
    return notificationData => {
      if (isNative) {
        let currentTimeInMiliSeconds = Date.now().toString()
        notificationData.id = `${currentTimeInMiliSeconds.substr(currentTimeInMiliSeconds.length - 8)}` //generate 8-bit int id
        dispatch(NotificationActions.addNotification(notificationData))
        PushNotification.localNotificationSchedule({
          ...notificationData,
          largeIcon: 'ic_launcher',
          smallIcon: 'ic_notification',
          color: 'green'
        })
      }
    }
  }
}
