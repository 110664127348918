import { useSelector, useDispatch } from 'react-redux'
import { RuntimeActions } from './'
import Screen from 'lib/screen/screen'
import { Platform } from 'lib/primitives'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export class RuntimeHelper {
    static getTileWidth = (width) => {
        const fullWidth = useSelector(state => state.runtime.width)
        const largerDashboardTiles = useSelector(state => state.settings.largerDashboardTiles)
        const screenSize = Screen.getScreenSize(width ? width : fullWidth)
        const { tileWidth } = Screen.getRawTileSize(screenSize, 4, 3, 2, 1.5, 1.2, width ? width : fullWidth, 12, largerDashboardTiles)
        return tileWidth
    }

    static getKeyboardVisible = () => {
        const keyboardVisible = useSelector(state => state.runtime.keyboardVisible)
        return keyboardVisible
    }

    static useSetScreenAnimating = () => {
        const dispatch = useDispatch()
        const isWeb = Platform.isWeb()
        return (isAnimating) => {
            dispatch(RuntimeActions.setScreenAnimating(isWeb ? false : isAnimating))
        }
    }

    static getScreenAnimating = () => {
        const screenAnimating = useSelector(state => state.runtime.screenAnimating)
        return screenAnimating
    }

    static useSetOnline = () => {
        const dispatch = useDispatch()
        return (online) => {
            dispatch(RuntimeActions.setOnline(online))
        }
    }

    static useCheckIfOnline = () => {
        const dispatch = useDispatch()
        const isOnline = useSelector(state => state.runtime.online)
        return async () => {
            try {
                let hasInternet = await NetworkInfo.hasInternetAsync()
                if (hasInternet) {
                    if (!isOnline) {
                        dispatch(RuntimeActions.setOnline(true))
                    }
                    return true
                } else {
                    if (isOnline) {
                        dispatch(RuntimeActions.setOnline(false))
                    }
                    return false
                }
            } catch (e) {
                if (isOnline) {
                    dispatch(RuntimeActions.setOnline(false))
                }
                return false
            }
        }
    }
}