import { Login } from './Login'
import { Dashboard } from './Dashboard'
import { Profile } from './Profile'
import { Courses } from './Courses'
import { Timetable } from './Timetable'
import { Notifications } from './Notifications'
import { Chat } from './Chat'
import { UpgradeRequired } from './UpgradeRequired'
import { Settings } from './Settings'
import { Search } from './Search'
import { News } from './News'
import { WebViewScreen } from './WebViewScreen'
import { NotFoundScreen } from './NotFoundScreen'

export {
  Login,
  Dashboard,
  Profile,
  Courses,
  Timetable,
  Notifications,
  Chat,
  UpgradeRequired,
  Settings,
  Search,
  News,
  WebViewScreen,
  NotFoundScreen
}