import React from 'react'
import { Container, Button, Text, Link } from 'primitives'
import { ChatUI, ChatInit } from 'components/chat'
import { SettingsHelper, ChatHelper } from 'redux/helpers'
import { Platform } from 'lib/primitives'
import { fonts } from 'lib/config/ui-config'
import { Icon, icons } from 'assets/MenuIcons'
import { useLocation, routes } from 'navigation'
import Screen from 'lib/screen/screen'

export const ChatWeb = props => {
    const isWeb = Platform.isWeb()
    const colors = SettingsHelper.getColors()
    const location = useLocation()
    const screenXS = Screen.isScreenXS()
    const isChatVisible = ChatHelper.getIsChatVisible()
    const setChatVisible = ChatHelper.useSetChatMenuVisibility()

    if (!(isWeb && location.pathname !== routes.login) || !isChatVisible || screenXS) return null

    return (
        <Container
            flex={0}
            width={400}
            zIndex={5}
            overflow={'hidden'}
            backgroundColor={colors.chatUserDefault}
        >
            <Container
                flex={0}
                margin={0}
                flexDirection={'row'}
                alignItems={'center'}
            >
                <Text
                    color={colors.menuIcon}
                    padding={10}
                    paddingLeft={20}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                    fontSize={fonts.size.large}
                >
                    Beacon Chat
                </Text>
                <Link to={routes.chat.suggestions} width={35} height={35} margin={5} marginLeft={'auto'}>
                    <Icon type={location.pathname === routes.chat.suggestions ? icons.suggestionsActive : icons.suggestions} color={colors.menuIcon} size={35} />
                </Link>
                <Button width={35} height={35} padding={0} margin={5} handleAction={setChatVisible} accessibility={{ role: 'button', label: 'close Beacon chat' }} >
                    <Icon type={icons.cross} color={colors.menuIcon} size={25} />
                </Button>
            </Container>
            <Container
                flex={1}
                position={'relative'}
                margin={10}
                marginTop={0}
            >
                <Container
                    flex={0}
                    position={'absolute'}
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    backgroundColor={colors.screenAltBg}
                    width={380}
                    borderRadius={10}
                    overflow={'hidden'}
                >
                    <ChatUI />
                    <ChatInit />
                </Container>
            </Container>
        </Container>
    )
}