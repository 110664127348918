import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { FetchHelper, SettingsHelper, UserHelper, NotificationHelper } from 'redux/helpers'
import { FetchRequest } from 'lib/general'
import { Namespaces } from 'redux/namespaces'
import { TimetableFetchKeys, SettingsFetchKeys } from 'redux/fetchKeys'

import { ScreenContainer, ReduxFetch, LoadingSpinner, LoadingData } from 'components/general'
import { Container, Animated, Text, Link } from 'primitives'
import { TimelineView } from 'components/timetable'
import { filterTimetableData, createDateUrls, getChunk } from 'lib/timetable/timetableFilter'
import { Icon, icons } from 'assets/MenuIcons'
import { useParams, useLocation, useHistory, routes } from 'navigation'
import { fonts } from 'lib/config/ui-config'
import { OfflineBar } from 'components/bars'
import { notificationTypes } from 'lib/general/enums'

export const TimetableWeek = () => {
    const colors = SettingsHelper.getColors()
    const { state: locationState } = useLocation()
    const history = useHistory()
    const isStudent = UserHelper.getIsStudent()
    const deleteNotifications = NotificationHelper.useDeleteNotificationByType()
    const createNotifications = NotificationHelper.useCreateNotificationsForTimetable()
    const notificationsData = useSelector(state => state[Namespaces.SETTINGS][SettingsFetchKeys.notifications])
    const { date } = useParams()
    const getTimetableForStudent = FetchRequest.useGetTimetableForStudent()
    const getTimetableForStaff = FetchRequest.useGetTimetableForStaff()


    if (!date) return history.push(routes.timetable.default)

    const dateInfo = createDateUrls(date)
    const chunkInfo = getChunk(dateInfo.currentWeek)

    if (isStudent === null) return <LoadingData backgroundColor={colors.screenBg} text={'Loading your personal timetable...'} />

    const handleNotifications = (data) => {
        let sendNotifications = true
        if (notificationsData && notificationsData.data) {
            const timetableSettings = notificationsData.data.find(item => item.name == 'Timetable')
            if (timetableSettings && timetableSettings.typeUsers && timetableSettings.typeUsers[0] && timetableSettings.typeUsers[0].hide == 1) {
                sendNotifications = false
            }
        }
        if (sendNotifications && moment(dateInfo.currentWeek).isSame(moment.utc().startOf('week'), 'day')) {
            deleteNotifications(notificationTypes.timetable)
            createNotifications(data)
        }
    }

    let fetchTimetable = () => { }
    if (isStudent) {
        fetchTimetable = FetchHelper.useFetch(Namespaces.TIMETABLE, TimetableFetchKeys[chunkInfo.chunk], () => getTimetableForStudent(chunkInfo.start, chunkInfo.end), handleNotifications)
    }
    else {
        fetchTimetable = FetchHelper.useFetch(Namespaces.TIMETABLE, TimetableFetchKeys[chunkInfo.chunk], () => getTimetableForStaff(chunkInfo.start, chunkInfo.end), handleNotifications)
    }

    return (
        <ScreenContainer>
            <OfflineBar />
            <Container
                flex={0}
                borderBottom={`2px solid ${colors.screenAltBg}`}
                borderColor={colors.screenAltBg}
                borderBottomWidth={2}
                flexDirection={'row'}
                justifyContent={'center'}
            >
                <Container
                    flex={1}
                    minHeight={0}
                    maxWidth={600}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Link to={`${routes.timetable.default}/week/${dateInfo.prevWeekUrl}`} extraState={{ timetableAnimation: 'left' }}>
                        <Container flex={0} margin={10} nativeTransform={[{ rotate: '180deg' }]} webTransform={['rotate(180deg']} >
                            <Icon type={icons.rightArrow} color={colors.screenAltText} size={30} />
                        </Container>
                    </Link>
                    <Container flex={1}>
                        <Text
                            color={colors.screenAltText}
                            nativeFontFamily={fonts.native.subHeading}
                            webFontFamily={fonts.web.heading}
                            fontWeight={fonts.weight.semiBold}
                            fontSize={fonts.size.large}
                            textAlign={'center'}
                        >
                            Week {dateInfo.currentWeek.format('w, DD MMMM YYYY')}
                        </Text>
                    </Container>
                    <Link to={`${routes.timetable.default}/week/${dateInfo.nextWeekUrl}`} extraState={{ timetableAnimation: 'right' }}>
                        <Container flex={0} margin={10}>
                            <Icon type={icons.rightArrow} color={colors.screenAltText} size={30} />
                        </Container>
                    </Link>
                </Container>
            </Container>
            <ReduxFetch
                debug={false}
                namespace={Namespaces.TIMETABLE}
                fetchKey={TimetableFetchKeys[chunkInfo.chunk]}
                fetchFunc={fetchTimetable}
                component={TimetableWeekDisplay}
                cacheDuration={1}
                extraData={{ dateInfo, locationState }}
                errorText={'Something went wrong refreshing your timetable. The most recent version is displayed below: '}
                displayRefresh={false}
                displayWhileLoading={true}
            />
        </ScreenContainer>
    )
}

const TimetableWeekDisplay = ({ data, extraData, fetchFunc, ...props }) => {
    const colors = SettingsHelper.getColors()
    const width = useSelector(state => state.runtime.width)
    const locationState = extraData.locationState
    const { currentWeek } = extraData.dateInfo
    const [filteredData, setFilteredData] = useState([])
    const [lastDateWithData, setLastDateWithData] = useState([])

    const SlideAnimation = {
        left: {
            translateX: -width,
        },
        center: {
            translateX: 0,
        },
        right: {
            translateX: width,
        }
    }

    useEffect(() => {
        const { filteredData: newData, lastDateWithData: newLastDate } = filterTimetableData(data.data, currentWeek)
        setFilteredData(newData)
        setLastDateWithData(newLastDate)
    }, [data.data])

    return (
        <Animated
            display={'flex'}
            flex={1}
            minHeight={0}
            animations={SlideAnimation}
            current={'center'}
            initial={locationState && locationState.timetableAnimation ? locationState.timetableAnimation : 'right'}
        >
            {data && data.isLoading && <LoadingSpinner size={40} color={colors.screenAltText} />}
            <TimelineView data={filteredData} lastFetchedAt={data.lastFetchedAt} lastDateWithData={lastDateWithData} fetchFunc={fetchFunc} />
        </Animated>
    )
}