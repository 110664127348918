import React from 'react'
import { Svg, Rect, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const TimetableIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'timetable icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'timetable icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M27.28,31.11H7.72c-2.41,0-4.38-1.97-4.38-4.38V10.82c0-2.41,1.97-4.38,4.38-4.38h19.55
                c2.41,0,4.38,1.97,4.38,4.38v15.91C31.66,29.14,29.69,31.11,27.28,31.11z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="8.68" y1="3.74" x2="8.68" y2="8.87"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="26.05" y1="3.74" x2="26.05" y2="8.87"/>
            <Rect scale={props.size/35} x="7.07" y="13.99" style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} width="20.6" height="13.08"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="13.82" y1="13.99" x2="13.82" y2="27.07"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="20.91" y1="13.99" x2="20.91" y2="27.07"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="7.07" y1="20.53" x2="27.66" y2="20.53"/>
        </Svg>
    )
}