import React, { useState, useEffect, Fragment } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Container, Text, Pre } from 'primitives'
import { StyledButton } from 'components/general'
import { SettingsHelper } from 'redux/helpers'
import { LoadingSpinner } from './'
import { Icon, icons } from 'assets/MenuIcons'

// This component makes it easier to manage the different states happening when making HTTP calls.
// 
// Props:
// - debug (optional):      shows the current state
// - namespace:             redux namespace where the data should be stored      
// - fetchKey:              key inside the above namespace where the data is stored    
// - fetchFunc:             function using useFetch to fetch the data
// - cacheDuration:         if the data was fetched more than [cacheDuration] days ago, it will be fetched again
// - component:             component where the data should be rendered if everything goes well. The state will be received inside props.data within that component
// 
// Example:
//     const fetchCourses = FetchHelper.useFetch(Namespaces.COURSES, CoursesFetchKeys.courses, () => FetchRequest.getCoursesAndModules(authToken))
//     (...)
//     <ReduxFetch
//         debug={true}
//         namespace={Namespaces.COURSES}
//         fetchKey={CoursesFetchKeys.courses}
//         fetchFunc={fetchCourses}
//         component={CoursesDisplay}
//     />
const defaultErrorText = 'An error occured, please try again later. If the problem persists please contact 3800@staffs.ac.uk.'

export const ReduxFetch = (props) => {
    if (!(props.namespace && props.fetchKey && props.fetchFunc && props.component)) {
        console.warn(`ReduxFetch needs the following props: ${props.namespace == null && 'namespace '}${props.fetchKey == null && 'fetchKey '}${props.fetchFunc == null && 'fetchFunc '}${props.component == null && 'component'}`)
        return null
        //throw "ReduxFetch not set up properly"
    }
    return <ReduxFetchLogic {...props} />
}

export const ReduxFetchLogic = ({ namespace, fetchKey, fetchFunc, errorText, cacheDuration = 3, displayWhileLoading = false, displayRefresh = true, ...props }) => {
    const colors = SettingsHelper.getColors()
    const data = useSelector(state => state[namespace][fetchKey])
    const [lastAttempt, setlastAttempt] = useState(moment())
    const [componentJustMounted, setComponentJustMounted] = useState(true)


    // If the data has not yet been fetched or if the data has not been refreshed in a while, do this now
    useEffect(() => {
        const notYetFetched = !data || (data && !data.attempted)
        const isStuckLoading = data && data.isLoading && !data.hasError && moment(data.lastAttemptedAt, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(moment().subtract(5, 'minutes'))
        const needsRefreshing = data && data.lastFetchedAt && !data.hasError && !data.isLoading && moment(data.lastFetchedAt, "YYYY-MM-DD HH:mm:ss").add(cacheDuration, 'days').isBefore(moment(), 'day')
        if (notYetFetched || isStuckLoading || needsRefreshing) {
            // To avoid spamming the refresh: we will only fetch if the component was just mounted or the last attempt from redux fetch was 5s ago
            const lastAttemptedALongTimeAgo = lastAttempt.isSameOrBefore(moment().subtract(5, 'seconds'))
            if (componentJustMounted || lastAttemptedALongTimeAgo) {
                setlastAttempt(moment())
                setComponentJustMounted(false)
                fetchFunc()
            }
        }
    }, [data])

    // Display a debugger to view the state
    const renderDebug = () => {
        if (props.debug && data) {
            return <Text><Pre>{JSON.stringify(data, null, 2)}</Pre></Text>
        }
        return null
    }

    // Deal with the possible states of the fetch request
    const renderContent = () => {
        if (data && data.isLoading) {
            if (displayWhileLoading && data.data) {
                return <props.component data={data} fetchFunc={fetchFunc} extraData={props.extraData} />
            }
            return <LoadingSpinner size={40} color={colors.screenAltText} />
        }
        else if (data && data.isForbidden) {
            return (
                <Container
                    flex={0}
                    width={'100%'}
                    maxWidth={600}
                    padding={5}
                    justifyContent={'center'}
                    alignItems={'center'}
                    borderBottom={`2px solid ${colors.screenAltBg}`}
                    borderBottomWidth={2}
                    borderColor={colors.screenAltBg}
                    marginBottom={5}
                >
                    <Container
                        flex={0}
                        flexDirection={'row'}
                        alignItems={'center'}
                        width={'100%'}
                        maxWidth={600}
                    >
                        <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
                        <Container flex={1} paddingLeft={5}>
                            <Text color={colors.screenAltText}>Sorry, you do not have access to this resource. If you believe this is in error please contact 3800@staffs.ac.uk.</Text>
                        </Container>
                    </Container>
                    {displayRefresh && <StyledButton title={'Refresh'} accessibility={{ role: 'button', label: 'refresh the page' }} handleAction={fetchFunc} bgColor={colors.actionBg} textColor={colors.actionText} />}
                </Container>
            )
        }
        else if (data && data.hasError) {
            return (
                <Fragment>
                    <Container
                        flex={0}
                        width={'100%'}
                        maxWidth={600}
                        padding={5}
                        justifyContent={'center'}
                        alignItems={'center'}
                        borderBottom={`2px solid ${colors.screenAltBg}`}
                        borderBottomWidth={2}
                        borderColor={colors.screenAltBg}
                    >
                        <Container
                            flex={0}
                            flexDirection={'row'}
                            alignItems={'center'}
                            width={'100%'}
                            maxWidth={600}
                        >
                            <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
                            <Container flex={1} paddingLeft={5}>
                                <Text color={colors.screenAltText}>{errorText ? errorText : defaultErrorText}</Text>
                            </Container>
                        </Container>
                        {displayRefresh && <StyledButton title={'Refresh'} accessibility={{ role: 'button', label: 'refresh the page' }} handleAction={fetchFunc} bgColor={colors.actionBg} textColor={colors.actionText} />}
                    </Container>
                    {data != null && data.data != null && <props.component data={data} fetchFunc={fetchFunc} extraData={props.extraData} />}
                </Fragment>
            )
        }
        else if (data && data.data) {
            return <props.component data={data} fetchFunc={fetchFunc} extraData={props.extraData} />
        }
        return (
            <Container
                flex={0}
                width={'100%'}
                maxWidth={600}
                padding={5}
                justifyContent={'center'}
                alignItems={'center'}
                borderBottom={`2px solid ${colors.screenAltBg}`}
                borderBottomWidth={2}
                borderColor={colors.screenAltBg}
                marginBottom={5}
            >
                <Container
                    flex={0}
                    flexDirection={'row'}
                    alignItems={'center'}
                    width={'100%'}
                    maxWidth={600}
                >
                    <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
                    <Container flex={1} paddingLeft={5}>
                        <Text color={colors.screenAltText}>An error occured, please contact beacon@staffs.ac.uk</Text>
                    </Container>
                </Container>
                <StyledButton title={'Refresh'} accessibility={{ role: 'button', label: 'refresh the page' }} handleAction={fetchFunc} bgColor={colors.actionBg} textColor={colors.actionText} />
            </Container>
        ) // should not happen
    }

    return (
        <React.Fragment>
            {renderDebug()}
            {renderContent()}
        </React.Fragment>
    )
}