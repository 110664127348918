import React, { useState } from 'react'
import moment from 'moment-timezone'
import { SettingsHelper } from 'redux/helpers'
import { ScreenContainer, LoadingData } from 'components/general'
import { Container, Text, Button, Link } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { Icon, icons } from 'assets/MenuIcons'
import { routes } from 'navigation'
import { UserHelper } from 'redux/helpers'
import { Environment } from 'lib/config/Environment'
import { OfflineBar } from 'components/bars'
import { DropdownButton, SlideInMenu } from 'components/general'
import { Platform } from 'lib/primitives'

export const Profile = props => {
  const colors = SettingsHelper.getColors()
  const getUser = UserHelper.useGetUser()
  const isStudent = UserHelper.getIsStudent()

  // Useful to debug API calls to Beacon
  const renderAuthTokenIfDev = async () => {
    const user = await getUser()
    //console.log(user ? user.token : null)
    if (Environment.isDev()) {
      return (
        <Container flex={0} wordWrap={'break-word'}>
          <Text color={colors.screenAltText}>Token:</Text>
          <Text color={colors.screenAltText}>{user ? user.token : ''}</Text>
        </Container>
      )
    }
    return null
  }

  if (isStudent === null) return <LoadingData backgroundColor={colors.screenBg} text={'Loading your personal profile...'} />

  return (
    <ScreenContainer viewType={'ScrollView'} alignItems={'center'}>
      <OfflineBar />
      <Container
        flex={0}
        padding={10}
        width={'100%'}
        maxWidth={600}
      >
        <DefaultInfo />
        {isStudent && <StudentInfo />}
        <ProfileOptions />
        {/* {renderAuthTokenIfDev()} */}
      </Container>
    </ScreenContainer>
  )
}

const DefaultInfo = props => {
  const colors = SettingsHelper.getColors()
  const userInfo = UserHelper.getUserInfo()

  if (!userInfo) return null

  return (
    <Container
      flex={0}
      alignItems={'center'}
      paddingBottom={10}
      borderBottom={`2px solid ${colors.screenAltBg}`}
      borderBottomWidth={2}
      borderColor={colors.screenAltBg}
    >
      <Container flex={0} alignItems={'center'}>
        <Icon type={icons.profile} color={colors.screenAltBg} size={100} />
      </Container>
      <Text
        color={colors.screenText}
        textAlign={'center'}
        nativeFontFamily={fonts.native.subHeading}
        webFontFamily={fonts.web.heading}
        fontWeight={fonts.weight.semiBold}
        fontSize={fonts.size.heading}
        lineHeight={fonts.size.heading * 1.5}
      >
        {userInfo && userInfo.firstName} {userInfo && userInfo.lastName}
      </Text>
      <Text
        color={colors.screenText}
        textAlign={'center'}
        nativeFontFamily={fonts.native.subHeading}
        webFontFamily={fonts.web.heading}
        fontWeight={fonts.weight.semiBold}
        fontSize={fonts.size.large}
        lineHeight={fonts.size.large * 1.5}
      >{userInfo && userInfo.username}</Text>
      <Text color={colors.screenText} textAlign={'center'}>{userInfo && userInfo.email}</Text>
    </Container>
  )
}

const addressTypes = {
  'CNTACT': 'Term time address',
  'HOME': 'Home address',
  'PLP': 'PLP address',
  'REF': 'Referee',
  'STUEMP': 'Work address',
  'CERT': 'Certificate',
  'AD_LCA': 'LCA address'
}

const StudentInfo = props => {
  const colors = SettingsHelper.getColors()
  const studentInfo = UserHelper.getStudentInfo()
  if (!studentInfo) return null
  return (
    <Container
      flex={0}
      paddingTop={5}
      paddingBottom={5}
      borderBottom={`2px solid ${colors.screenAltBg}`}
      borderBottomWidth={2}
      borderColor={colors.screenAltBg}
    >
      {studentInfo != null && studentInfo.personalEmail != null &&
        <Container flex={0} padding={5} flexDirection={'row'} flexWrap={'wrap'}>
          <Text color={colors.screenText} paddingRight={5}>Personal email:</Text>
          <Text color={colors.screenAltText}>{studentInfo.personalEmail}</Text>
        </Container>}
      {studentInfo != null && studentInfo.dateOfBirth != null &&
        <Container flex={0} padding={5} flexDirection={'row'} flexWrap={'wrap'}>
          <Text color={colors.screenText} paddingRight={5}>Date of birth:</Text>
          <Text color={colors.screenAltText}>{moment(studentInfo.dateOfBirth).format('DD MMMM YYYY')}</Text>
        </Container>}
      {studentInfo != null && studentInfo.studentAddress != null && studentInfo.studentAddress.length > 0 &&
        <Container flex={0}>
          {studentInfo.studentAddress.map((address, index) => <AddressDropdown address={address} colors={colors} key={address.addressLine1 + index} />)}
        </Container>}
      {studentInfo != null && studentInfo.emergencyContact != null && studentInfo.emergencyContact.length > 0 &&
        <Container flex={0}>
          <EmergencyContactDropdown contacts={studentInfo.emergencyContact} colors={colors} />
        </Container>}
    </Container>
  )
}

const AddressDropdown = ({ address, colors }) => {
  const [animation, setAnimation] = useState('closed')
  if (!address) return null
  if (address.status != 'C') return null
  return (
    <Container flex={0}>
      <Container flex={0} padding={5} flexDirection={'row'} alignItems={'center'}>
        <Button
          flex={1}
          handleAction={() => setAnimation(animation === 'closed' ? 'open' : 'closed')}
          accessibility={{ role: 'button', label: animation === 'closed' ? 'open dropdown menu' : 'close dropdown menu' }}
          padding={0}
        >
          <Text color={colors.screenText}>{addressTypes[address.type]}</Text>
        </Button>
        <DropdownButton current={animation} setAnimation={setAnimation} size={25} padding={5} />
      </Container>
      <SlideInMenu
        animation={animation}
        renderChildren={Address}
        colors={colors}
        data={address}
      />
    </Container>
  )
}

const Address = ({ data, colors }) => {
  const isWeb = Platform.isWeb()
  if (!data) return null
  return (
    <Container
      flex={0}
      width={isWeb ? '50%' : '100%'}
    >
      <Container
        flex={0}
        margin={5}
        padding={10}
        border={`2px solid ${colors.screenAltBg}`}
        borderWidth={2}
        borderColor={colors.screenAltBg}
        borderRadius={5}
      >
        {data.inResidence != null && data.roomNumber != null && <Text color={colors.screenAltText}>Room {data.roomNumber}</Text>}
        {data.addressLine1 != null && <Text color={colors.screenAltText}>{data.addressLine1}</Text>}
        {data.addressLine2 != null && <Text color={colors.screenAltText}>{data.addressLine2}</Text>}
        {data.addressLine3 != null && <Text color={colors.screenAltText}>{data.addressLine3}</Text>}
        {data.addressLine4 != null && <Text color={colors.screenAltText}>{data.addressLine4}</Text>}
        {data.addressLine5 != null && <Text color={colors.screenAltText}>{data.addressLine5}</Text>}
        {data.postalCode != null && <Text color={colors.screenAltText}>{data.postalCode}</Text>}
        <Container padding={5} flex={0} />
        {data.homeTelephone != null && <Text color={colors.screenAltText}>Home tel: {data.homeTelephone}</Text>}
        {data.mobileTelephone != null && <Text color={colors.screenAltText}>Mobile tel: {data.mobileTelephone}</Text>}
        {data.email != null && <Text color={colors.screenAltText}>Email: {data.email}</Text>}
      </Container>
    </Container>
  )
}

const EmergencyContactDropdown = ({ contacts, colors }) => {
  const [animation, setAnimation] = useState('closed')
  return (
    <Container flex={0}>
      <Container padding={5} flex={0} flexDirection={'row'} alignItems={'center'}>
        <Button
          flex={1}
          handleAction={() => setAnimation(animation === 'closed' ? 'open' : 'closed')}
          accessibility={{ role: 'button', label: animation === 'closed' ? 'open dropdown menu' : 'close dropdown menu' }}
          padding={0}
        >
          <Text color={colors.screenText}>Emergency contacts</Text>
        </Button>
        <DropdownButton current={animation} setAnimation={setAnimation} size={25} padding={5} />
      </Container>
      <SlideInMenu
        animation={animation}
        renderChildren={EmergencyContacts}
        colors={colors}
        data={contacts}
      />
    </Container>
  )
}

const EmergencyContacts = ({ data, colors }) => {
  const isWeb = Platform.isWeb()
  if (!data) return null
  return (
    <Container flex={0} width={'100%'} flexDirection={'row'} flexWrap={'wrap'}>
      {data.map((item, index) => {
        if (item == null) {
          return null
        }
        return (
          <Container
            flex={0}
            width={isWeb ? '50%' : '100%'}
            key={item.addressLine1 + index}
          >
            <Container
              flex={0}
              flexGrow={1}
              margin={5}
              padding={10}
              border={`2px solid ${colors.screenAltBg}`}
              borderWidth={2}
              borderColor={colors.screenAltBg}
              borderRadius={5}
            >
              <Text
                color={colors.screenText}
                nativeFontFamily={fonts.native.bold}
                webFontFamily={fonts.web.default}
                fontWeight={fonts.weight.bold}
              >
                {item.title && `${item.title}`}
                {item.forename1 && ` ${item.forename1}`}
                {item.forename2 && ` ${item.forename2}`}
                {item.forename3 && ` ${item.forename3}`}
                {item.surname && ` ${item.surname}`}
              </Text>
              {item.relationship != null && <Text color={colors.screenAltText}>{item.relationship}</Text>}
              <Container padding={5} flex={0} />
              {item.addressLine1 != null && <Text color={colors.screenAltText}>{item.addressLine1}</Text>}
              {item.addressLine2 != null && <Text color={colors.screenAltText}>{item.addressLine2}</Text>}
              {item.addressLine3 != null && <Text color={colors.screenAltText}>{item.addressLine3}</Text>}
              {item.addressLine4 != null && <Text color={colors.screenAltText}>{item.addressLine4}</Text>}
              {item.addressLine5 != null && <Text color={colors.screenAltText}>{item.addressLine5}</Text>}
              {item.postalCode != null && <Text color={colors.screenAltText}>{item.postalCode}</Text>}
              <Container padding={5} flex={0} />
              {item.homeTelephone != null && <Text color={colors.screenAltText}>Home tel: {item.homeTelephone}</Text>}
              {item.mobileTelephone != null && <Text color={colors.screenAltText}>Mobile tel: {item.mobileTelephone}</Text>}
              {item.email != null && <Text color={colors.screenAltText}>Email: {item.email}</Text>}
            </Container>
          </Container>)
      })}
    </Container>
  )
}

const ProfileOptions = props => {
  const colors = SettingsHelper.getColors()
  const handleLogOut = UserHelper.useHandleLogOut()
  return (
    <Container flex={0}>
      <Link
        to={routes.settings.default}
        flex={0}
        flexDirection={'row'}
        paddingTop={10}
        paddingBottom={10}
        alignItems={'center'}
        justifyContent={'flex-start'}
        textAlign={'left'}
        borderBottom={`2px solid ${colors.screenAltBg}`}
        borderBottomWidth={2}
        borderColor={colors.screenAltBg}
      >
        <Icon type={icons.settings} color={colors.screenAltText} size={35} />
        <Text
          color={colors.screenText}
          nativeFontFamily={fonts.native.subHeading}
          webFontFamily={fonts.web.heading}
          fontWeight={fonts.weight.semiBold}
          fontSize={fonts.size.large}
          paddingLeft={10}
        >
          Settings
        </Text>
      </Link>
      <Button
        accessibility={{ role: 'button', label: 'log out of Beacon' }}
        handleAction={handleLogOut}
        flex={0}
        flexDirection={'row'}
        padding={0}
        paddingTop={10}
        paddingBottom={10}
        alignItems={'center'}
        justifyContent={'flex-start'}
        textAlign={'left'}
        borderBottom={`2px solid ${colors.screenAltBg}`}
        borderBottomWidth={2}
        borderColor={colors.screenAltBg}
      >
        <Icon type={icons.logOut} color={colors.screenAltText} size={35} />
        <Text
          color={colors.screenText}
          nativeFontFamily={fonts.native.subHeading}
          webFontFamily={fonts.web.heading}
          fontWeight={fonts.weight.semiBold}
          fontSize={fonts.size.large}
          paddingLeft={10}
        >
          Log Out
        </Text>
      </Button>
    </Container>
  )
}