import React, { useEffect } from 'react'
import { Container } from 'primitives'
import { ChatBar } from 'components/bars'
import { ChatHelper, RuntimeHelper } from 'redux/helpers'

export const ScreenContainer = props => {
    const isChatVisible = ChatHelper.getIsChatVisible()
    const setIsAnimating = RuntimeHelper.useSetScreenAnimating()

    useEffect(() => {
        setIsAnimating(true)
    },[])

    return (
        <React.Fragment>
            <Container
                flex={1}
                flexGrow={1}
                flexShrink={1}
                minHeight={0}
                webOverflow={props.viewType == 'ScrollView' ? 'auto' : 'hidden'}
                nativeOverflow={'hidden'}
                alignItems={props.alignItems}
                viewType={props.viewType}
                backgroundColor={props.backgroundColor}
                onLayout={props.onLayout}
                onRefresh={props.onRefresh}
                flexDirection={props.flexDirection}
                useRef={props.useRef}
                isChat={isChatVisible}
            >
                {props.children}
            </Container>
            {!props.hideChatBar && <ChatBar />}
        </React.Fragment>
    )
}