import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { getStorage } from "redux/config/storage"; // Native: AsyncStorage | Web: localStorage
import { Namespaces } from 'redux/namespaces'

import { QueueReducer } from 'redux/helpers/queue/QueueReducer'

import { ExampleReducer } from 'redux/lib/example/ExampleReducer'
import { PlatformReducer } from 'redux/lib/platform/PlatformReducer'
import { UserReducer } from 'redux/lib/user/UserReducer'
import { InitialisationReducer } from 'redux/lib/initialisation/InitialisationReducer'
import { RuntimeReducer } from 'redux/lib/runtime/RuntimeReducer'
import { SettingsReducer } from 'redux/lib/settings/SettingsReducer'
import { CourseReducer } from 'redux/lib/courses/CourseReducer'
import { TimetableReducer } from 'redux/lib/timetable/TimetableReducer'
import { ChatReducer } from 'redux/lib/chat/ChatReducer'
import { NewsReducer } from 'redux/lib/news/NewsReducer'
import { NotificationReducer } from 'redux/lib/notification/NotificationReducer'
import { RedirectReducer } from 'redux/lib/redirect/RedirectReducer'



// REDUCERS:
const allReducers = combineReducers({
    [Namespaces.QUEUE]: QueueReducer, // Do not edit: set by package.json
    [Namespaces.PLATFORM]: PlatformReducer, // Do not edit: set by package.json
    [Namespaces.INITIALISATION]: InitialisationReducer, // Checks done when the app is opened
    [Namespaces.EXAMPLE]: ExampleReducer,
    [Namespaces.USER]: UserReducer, // User information and token (on web, some of the information is stored in an iframe)
    [Namespaces.RUNTIME]: RuntimeReducer,
    [Namespaces.SETTINGS]: SettingsReducer, // App settings such as color theme and accessibility
    [Namespaces.COURSES]: CourseReducer,
    [Namespaces.TIMETABLE]: TimetableReducer,
    [Namespaces.CHAT]: ChatReducer,
    [Namespaces.NEWS]: NewsReducer,
    [Namespaces.NOTIFICATION]: NotificationReducer,
    [Namespaces.REDIRECT]: RedirectReducer,
})

// If the persistance needs to be more granular:
// https://github.com/rt2zz/redux-persist#nested-persists)
const reducersToPersist = [
    Namespaces.QUEUE,
    Namespaces.EXAMPLE,
    Namespaces.USER,
    Namespaces.SETTINGS,
    Namespaces.COURSES,
    Namespaces.TIMETABLE,
    Namespaces.NOTIFICATION,
]

export const persistedAllReducers = persistReducer(
    {
        key: 'root',
        debug: true,
        storage: getStorage(),
        whitelist: reducersToPersist,
    },
    allReducers,
);
