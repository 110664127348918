import React from 'react';
import { SettingsHelper } from 'redux/helpers'
import { Environment } from 'lib/config/Environment'
import { fonts } from 'lib/config/ui-config'
import { Bar } from './Bar'

export const EnvironmentWarning = props => {
    const colors = SettingsHelper.getColors()

    if (Environment.isDev()) {
        return <Bar
            backgroundColor={colors.androidNavBg}
            textColor={colors.devEnv}
            textAlign={'center'}
            nativeFontFamily={fonts.native.bold}
            webFontFamily={fonts.web.default}
            fontWeight={fonts.weight.bold}
            padding={0}
            text="BEACON (DEV)"
        />
    } else if (Environment.isTest()) {
        return <Bar
            backgroundColor={colors.androidNavBg}
            textColor={colors.testEnv}
            textAlign={'center'}
            nativeFontFamily={fonts.native.bold}
            webFontFamily={fonts.web.default}
            fontWeight={fonts.weight.bold}
            padding={0}
            text="BEACON (TEST)"
        />
    }
    return null
}