import React from 'react';
import PropTypes from 'prop-types';
import { TextPrimitive } from './TextPrimitive'
import { fonts } from 'lib/config/ui-config'

export const Text = ({children, ...props}) => {
    return <TextPrimitive {...props}>{children}</TextPrimitive>
}

Text.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    textAlign: PropTypes.string,
    nativeFontFamily: PropTypes.string,
    webFontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.number,
    lineHeight: PropTypes.number,
};

Text.defaultProps = {
    children: "",
    color: "black",
    textAlign: 'left',
    nativeFontFamily: fonts.native.default,
    webFontFamily: fonts.web.default,
    fontSize: fonts.size.default,
    fontWeight: fonts.weight.semiBold,
    lineHeight: fonts.size.default * 1.5,
};
