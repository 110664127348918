import React from 'react'
import { Svg, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const FeedbackIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'feedback icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'feedback icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M30.85,10.07c0.73,1.42,1.13,2.97,1.13,4.61c0,3.05-1.4,5.83-3.7,7.93c-0.83,0.76,1.88,3.76-1.86,9.44
                c-2.39-5.72-3.12-6.43-4.32-6.1c-1.44,0.4-2.99,0.61-4.59,0.61c-8,0-14.48-5.32-14.48-11.88S9.5,2.8,17.5,2.8
                c2.74,0,5.31,0.63,7.49,1.71"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M29.28,2.88l-7.4,8.85c-0.05,0.03-0.09,0.08-0.11,0.14l-0.56,2.51l-0.15,0.67c-0.04,0.19,0.15,0.34,0.32,0.27
                l0.6-0.25l2.4-1.01c0.06-0.03,0.1-0.08,0.12-0.13l0.03,0l7.43-8.89l-0.04,0l0.06-1.15l-1.31-1.1l-1.37,0.07"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="9.76" y1="8.82" x2="19.5" y2="8.82"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="6.74" y1="13.15" x2="18.15" y2="13.15"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="7.62" y1="17.5" x2="26.67" y2="17.5"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="12.63" y1="21.6" x2="22.37" y2="21.6"/>
        </Svg>
    )
}