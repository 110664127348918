import React from 'react'
import { Svg, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const OnboardingIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'onboarding icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'onboarding icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M24.11,31.88H10.89c-1.59,0-2.88-1.3-2.88-2.88V6.01c0-1.59,1.3-2.88,2.88-2.88h13.21
                c1.59,0,2.88,1.3,2.88,2.88v22.99C26.99,30.58,25.69,31.88,24.11,31.88z"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M12.66,14.77c0.95-1.69,2.77-2.83,4.84-2.83c3.07,0,5.56,2.49,5.56,5.56s-2.49,5.56-5.56,5.56
                c-2.12,0-3.97-1.19-4.91-2.94"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="12.59" y1="20.12" x2="12.59" y2="22.9"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="12.59" y1="20.12" x2="15.22" y2="20.12"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M19.81,17.29L16.7,15.5c-0.27-0.15-0.6,0.04-0.6,0.35v2.77c0.39,0.23,0.68,0.6,0.8,1.04l2.9-1.68
                C20.08,17.83,20.08,17.45,19.81,17.29z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="12.27" y1="3.87" x2="22.73" y2="3.87"/>
        </Svg>
    )
}