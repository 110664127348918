import React from 'react'
import { Svg, Rect, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const PcAvailabilityIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'pc icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'pc icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Rect scale={props.size/35} x="14.26" y="25.95" style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} width="6.48" height="6.48"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.13,25.95H6.87c-1.83,0-3.32-1.49-3.32-3.32V6.35c0-1.83,1.49-3.32,3.32-3.32h21.27
                c1.83,0,3.32,1.49,3.32,3.32v16.28C31.46,24.45,29.96,25.95,28.13,25.95z"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M25.47,21.94H9.53c-1.14,0-2.08-0.93-2.08-2.08V9.11c0-1.14,0.93-2.08,2.08-2.08h15.94
                c1.14,0,2.08,0.93,2.08,2.08v10.75C27.55,21.01,26.61,21.94,25.47,21.94z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="4.64" y1="32.43" x2="30.36" y2="32.43"/>
        </Svg>
    )
}