// https://github.com/microsoft/BotFramework-DirectLineJS

import { DirectLine } from 'botframework-directlinejs';
const envConfig = require('lib/config/env-config').config

const BEACON = "Beacon"
const EVENT_NAMES = {
    GREETING: 'eventGreeting'
}

export default class Directline {

    // Use this to set the callback functions for directline
    static CALLBACK_KEYS = {
        RECEIVED_MESSAGE: 'RECEIVED_MESSAGE',
        EVENT_TYPING: 'EVENT_TYPING',
        RECEIVED_EVENT: 'RECEIVED_EVENT'
    }

    // Use this when you want to manually trigger events
    static EVENTS_KEYS = {
        GREETING: 'GREETING'
    }

    constructor(callbacks, user = null, greetUser = false) {
        if (envConfig.directline) {
            this.directline = new DirectLine(envConfig.directline)
            this.callbacks = callbacks
            this.listenToActivities()
            // If the user was provided, greet him as soon as the connection is set up
            if (user && greetUser) {
                this.sendEvent(user, Directline.EVENTS_KEYS.GREETING)
            }
        } else {
            console.error('❌ Could not find directline config')
        }
    }

    listenToActivities = () => {
        this.directline.activity$
            .filter(activity => !(activity.recipient && activity.recipient.name && activity.recipient.name && activity.recipient.name === BEACON)) // Ignore what we send
            .subscribe(
                activity => {
                    const CALLBACK_KEYS = this.constructor.CALLBACK_KEYS
                    const isTypeMessage = activity.type === 'message'
                    const isTypeEvent = activity.type === 'event'
                    const isTypeTyping = activity.type === 'typing'
                    const isFromBeacon = (activity.from && activity.from.name && activity.from.name && activity.from.name === BEACON)
                    let activityHandled = false

                    if (isTypeMessage && isFromBeacon) {
                        activityHandled = this.useCallback(CALLBACK_KEYS.RECEIVED_MESSAGE, activity)
                        console.log("💬 Received message from Beacon");

                    } else if (isTypeTyping && isFromBeacon) {
                        activityHandled = this.useCallback(CALLBACK_KEYS.EVENT_TYPING)

                    } else if (isTypeEvent && activity.name === "newAchivement") {
                        activityHandled = this.useCallback(CALLBACK_KEYS.RECEIVED_EVENT, activity)
                    }

                    if (!activityHandled) {
                        console.log("-------- ⚠️⚠️⚠️ Unhandled activity, you should do something about this ⚠️⚠️⚠️ ----------")
                        console.log(activity)
                        console.log("----------------------------------------------------------------------------")
                    }
                }
            );
    }

    postActivity = (activity) => {
        this.directline.postActivity(activity).subscribe(
            id => console.log("Posted activity, assigned ID ", id),
            error => console.error("❌ Error posting activity", error)
        );
    }

    sendMessage = (user, text, showInChat = true, value = {}) => {
        if (text && text.trim()) {
            text = text.trim();
            let activity = {
                type: 'message',
                text,
                value,
                from: user,
                textFormat: 'plain',
                timestamp: (new Date()).toISOString()
            }
            console.log('Sending message..');
            this.postActivity(activity)
        }
    }

    sendEvent = (user, eventName, value = {}) => {
        if (!EVENT_NAMES.hasOwnProperty(eventName)) {
            throw (`Invalid event name: ${eventName}`)
        }
        let activity = {
            type: 'event',
            name: EVENT_NAMES[eventName],
            value,
            from: user,
            textFormat: 'plain',
            timestamp: (new Date()).toISOString()
        }
        console.log('Sending event..');
        this.postActivity(activity)
    }

    // Returns false is cannot process the callback
    useCallback = (callbackName, args = null) => {
        let isSuccessful = false
        try {
            if (!this.constructor.CALLBACK_KEYS.hasOwnProperty(callbackName)) {
                throw ('Invalid callback name')
            }
            if (!this.callbacks[callbackName]) {
                throw ('Callback not defined')
            }
            this.callbacks[callbackName](args)
            isSuccessful = true
        } catch (e) {
            console.log(`❌ Error using directline callback: ${callbackName}`)
            console.error(e)
        }
        return isSuccessful
    }

}

