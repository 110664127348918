import { useSelector } from 'react-redux'
import { Platform } from 'lib/primitives'
import { PixelRatio } from './PixelRatio'

const sizes = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5
}

export const getDefaultSizes = (largerDashboardTiles = false) => {
  const isWeb = Platform.isWeb()
  if (isWeb) {
    return {
      xs: 4,
      sm: 3,
      md: 2,
      lg: 1.5,
      xl: 1.2
    }
  }
  const fontScale = PixelRatio.getFontScale()
  if (largerDashboardTiles || fontScale > 1.3) {
    return {
      xs: 6,
      sm: 4,
      md: 3,
      lg: 2,
      xl: 1.5
    }
  }
  else {
    return {
      xs: 4,
      sm: 3,
      md: 2,
      lg: 1.5,
      xl: 1.2
    }
  }
}

export default class Screen {

  static getScreenSize = (screenWidth) => {
    if (screenWidth < 576) {
      return sizes.xs
    }
    if (screenWidth >= 576 && screenWidth < 768) {
      return sizes.sm
    }
    if (screenWidth >= 768 && screenWidth < 992) {
      return sizes.md
    }
    if (screenWidth >= 992 && screenWidth < 1400) {
      return sizes.lg
    }
    if (screenWidth >= 1400) {
      return sizes.xl
    }
  }

  static isScreenXS = () => {
    const width = useSelector(state => state.runtime.width)
    return width < 576
  }

  static isScreenSM = () => {
    const width = useSelector(state => state.runtime.width)
    return width < 768
  }

  static toPercent = (num) => (num * 100) + '%'

  static getTileSize = (screenSize, xs, sm, md, lg, xl, parentWidth, parentSize, largerDashboardTiles = false) => {
    const defaultSizes = getDefaultSizes(largerDashboardTiles)
    switch (screenSize) {
      case sizes.xs:
        if (xs) {
          return { tileWidth: toPercent(xs / parentSize), tileHeight: toPercent(defaultSizes.xs / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: toPercent(defaultSizes.xs / 12) }
        }
      case sizes.sm:
        if (sm) {
          return { tileWidth: toPercent(sm / parentSize), tileHeight: toPercent(defaultSizes.sm / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: toPercent(defaultSizes.sm / 12) }
        }
      case sizes.md:
        if (md) {
          return { tileWidth: toPercent(md / parentSize), tileHeight: toPercent(defaultSizes.md / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: toPercent(defaultSizes.md / 12) }
        }
      case sizes.lg:
        if (lg) {
          return { tileWidth: toPercent(lg / parentSize), tileHeight: toPercent(defaultSizes.lg / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: toPercent(defaultSizes.lg / 12) }
        }
      case sizes.xl:
        if (xl) {
          return { tileWidth: toPercent(xl / parentSize), tileHeight: toPercent(defaultSizes.xl / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: toPercent(defaultSizes.xl / 12) }
        }
      default:
        return { tileWidth: parentWidth, tileHeight: toPercent(1 / 12) }
    }
  }

  static getRawTileSize = (screenSize, xs, sm, md, lg, xl, parentWidth, parentSize, largerDashboardTiles = false) => {
    const defaultSizes = getDefaultSizes(largerDashboardTiles)
    switch (screenSize) {
      case sizes.xs:
        if (xs) {
          return { tileWidth: parentWidth * (xs / parentSize), tileHeight: parentWidth * (defaultSizes.xs / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: parentWidth * (defaultSizes.xs / 12) }
        }
      case sizes.sm:
        if (sm) {
          return { tileWidth: parentWidth * (sm / parentSize), tileHeight: parentWidth * (defaultSizes.sm / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: parentWidth * (defaultSizes.sm / 12) }
        }
      case sizes.md:
        if (md) {
          return { tileWidth: parentWidth * (md / parentSize), tileHeight: parentWidth * (defaultSizes.md / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: parentWidth * (defaultSizes.md / 12) }
        }
      case sizes.lg:
        if (lg) {
          return { tileWidth: parentWidth * (lg / parentSize), tileHeight: parentWidth * (defaultSizes.lg / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: parentWidth * (defaultSizes.lg / 12) }
        }
      case sizes.xl:
        if (xl) {
          return { tileWidth: parentWidth * (xl / parentSize), tileHeight: parentWidth * (defaultSizes.xl / 12) }
        } else {
          return { tileWidth: parentWidth, tileHeight: parentWidth * (defaultSizes.xl / 12) }
        }
      default:
        return { tileWidth: parentWidth, tileHeight: parentWidth * (1 / 12) }
    }
  }
}

export const toPercent = (num) => (num * 100) + '%';