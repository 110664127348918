import React from 'react'
import { useSelector } from 'react-redux'
import { SettingsHelper, PlatformHelper } from 'redux/helpers'
import { Platform } from 'lib/primitives'
import { Container, Text, Image } from 'primitives'
import { ScreenContainer, StyledButton } from 'components/general'
import { fonts } from 'lib/config/ui-config'
import { ImageSrc } from 'assets/images'

export const UpgradeRequired = props => {
  //Global state
  const colors = SettingsHelper.getColors()
  const width = useSelector(state => state.runtime.width)
  const height = useSelector(state => state.runtime.height)
  const version = PlatformHelper.getVersion()
  const versionUpdateDetails = useSelector(state => state.runtime.versionUpdateDetails)

  const imageHeight = () => {
    return height * 0.5 > width ? width : height * 0.5
  }

  // Get additional information (if provided)
  let info = ''
  try {
    info = JSON.parse(versionUpdateDetails.text).content
  } catch (e) { console.log(e) }

  // Set updateUrl based on platform used
  let updateUrl = ''
  if (Platform.isNative()) {
    if (Platform.isAndroid()) {
      updateUrl = 'https://play.google.com/store/apps/details?id=uk.ac.staffs.beacon&hl=en'
    }
    if (Platform.isIOS()) {
      updateUrl = "https://apps.apple.com/gb/app/beacon-digital-coach/id1435526583"
    }
  }

  return (
    <ScreenContainer
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
      backgroundColor={colors.screenBg}
      hideChatBar={true}
    >
      <Container
        flex={0}
        justifyContent={'center'}
        alignItems={'center'}
        position={'absolute'}
        top={0}
        bottom={0}
        left={0}
        right={0}
        overflow={'hidden'}
      >
        <Image src={ImageSrc.loginBackground} alt={''} width={height > width ? height : width} height={height > width ? height : width} />
      </Container>
      <Container
        flex={0}
        position={'absolute'}
        top={0}
        bottom={0}
        left={0}
        right={0}
        viewType={'ScrollView'}
        webOverflow={'auto'}
      >
        <BeaconImage imageHeight={imageHeight} />
        <UpgradeText colors={colors} version={version} info={info} updateUrl={updateUrl} />
      </Container>
    </ScreenContainer>
  )
}

const BeaconImage = ({ imageHeight }) => {
  return (
    <Container
      flex={0}
      justifyContent={'center'}
      alignItems={'center'}
      marginBottom={-60}
      zIndex={5}
    >
      <Container flex={0} width={imageHeight() * (5 / 6)} height={imageHeight()}>
        <Image src={ImageSrc.beaconFullSize} alt={'Beacon mascot waving'} width={'100%'} height={'100%'} />
      </Container>
    </Container>
  )
}

const UpgradeText = ({ colors, version, info, updateUrl }) => {

  return (
    <Container
      flex={0}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Container
        flex={0}
        padding={20}
        paddingTop={50}
        margin={10}
        marginTop={0}
        borderRadius={10}
        alignItems={'center'}
        backgroundColor={colors.screenBg}
        border={`3px solid ${colors.screenAltBg}`}
        borderWidth={3}
        borderColor={colors.screenAltBg}
      >

        <Container
          flex={0}
          padding={5}
        >
          <Text
            color={colors.screenText}
            textAlign={'center'}
            nativeFontFamily={fonts.native.decorative}
            webFontFamily={fonts.web.heading}
            fontWeight={fonts.weight.medium}
            fontSize={fonts.size.heading}
            lineHeight={fonts.size.heading * 1.5}
          >
            {'Upgrade '}
            <Text
              color={colors.screenText}
              textAlign={'center'}
              nativeFontFamily={fonts.native.subHeading}
              webFontFamily={fonts.web.heading}
              fontWeight={fonts.weight.semiBold}
              fontSize={fonts.size.heading}
              lineHeight={fonts.size.heading * 1.5}
            >
              Required
          </Text>
          </Text>
        </Container>
        <Container
          flex={0}
          padding={5}
        >
          <Text
            color={colors.screenAltText}
            textAlign={'center'}
          >
            You are currently on version {version}.{'\n'}This version of Beacon is outdated.
          </Text>
        </Container>
        {info.length > 0 && <Container flex={0} padding={5}>
          <Text
            color={colors.screenText}
            textAlign={'center'}
          >
            {info}
          </Text>
        </Container>}
        <Container flex={0} paddingTop={10}>
          <StyledButton
            title={'Update Beacon'}
            accessibility={{ role: 'button', label: 'update Beacon via Apple App store or Google Play store' }}
            bgColor={colors.actionBg}
            textColor={colors.actionText}
            url={updateUrl}
            margin={0}
          />
        </Container>
      </Container>
    </Container>
  )
}