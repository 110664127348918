import React from 'react'
import { SettingsHelper, PlatformHelper, ChatHelper, UserHelper } from 'redux/helpers'
import { AuthenticatedRoute, routes } from 'navigation'
import { ScreenContainer, StyledButton, LoadingData } from 'components/general'
import { Container, Text, Link } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { Icon, icons } from 'assets/MenuIcons'
import { AccessibilityView, ColourThemeView, InterestsView, NotificationsView } from 'components/settings'
import { useHistory } from 'navigation'
import { DialogUtterances } from 'lib/chat/dialogUtterances'
import { Platform } from 'lib/primitives'
import { BackBar } from 'components/bars'
import { OfflineBar } from 'components/bars'

export const Settings = props => {
  return (
    <React.Fragment>
      <AuthenticatedRoute exact path={routes.settings.default} render={() => <SettingsScreen />} />
      <AuthenticatedRoute path={routes.settings.accessibility} render={() => <AccessibilityView />} />
      <AuthenticatedRoute path={routes.settings.interests} render={() => <InterestsView />} />
      <AuthenticatedRoute path={routes.settings.notifications} render={() => <NotificationsView />} />
      <AuthenticatedRoute path={routes.settings.themes} render={() => <ColourThemeView />} />
    </React.Fragment>
  )
}

const SettingsScreen = props => {
  const isStudent = UserHelper.getIsStudent()
  const colors = SettingsHelper.getColors()
  const version = PlatformHelper.getVersion()
  const isWeb = Platform.isWeb()
  const history = useHistory()
  const isChatVisible = ChatHelper.getIsChatVisible()
  const customDialog = DialogUtterances.useCustomDialog()

  if (isStudent === null) return <LoadingData backgroundColor={colors.screenBg} text={'Loading your personal settings...'}/>

  const sendFeedbackInChat = () => customDialog('Beacon feedback', history)

  const SettingsButton = ({ to, icon, text }) => {
    return (
      <Link
        to={to}
        flex={0}
        flexDirection={'row'}
        paddingTop={10}
        paddingBottom={10}
        alignItems={'center'}
        justifyContent={'flex-start'}
        textAlign={'left'}
        borderBottom={`2px solid ${colors.screenAltBg}`}
        borderBottomWidth={2}
        borderColor={colors.screenAltBg}
      >
        <Icon type={icon} color={colors.screenAltText} size={35} />
        <Text
          color={colors.screenText}
          nativeFontFamily={fonts.native.subHeading}
          webFontFamily={fonts.web.heading}
          fontWeight={fonts.weight.semiBold}
          fontSize={fonts.size.large}
          paddingLeft={10}
        >
          {text}
        </Text>
      </Link>
    )
  }

  return (
    <ScreenContainer alignItems={'center'}>
      <BackBar />
      <OfflineBar />
      <Container
        flex={1}
        flexGrow={1}
        width={'100%'}
        alignItems={'center'}
        position={'relative'}
        webOverflow={'auto'}
        nativeOverflow={'hidden'}
        viewType={'ScrollView'}
        isChat={isChatVisible}
      >
        <Container
          flex={0}
          padding={10}
          paddingTop={5}
          width={'100%'}
          maxWidth={600}
        >
          <SettingsButton to={routes.settings.accessibility} icon={icons.accessibility} text={'Accessibility'} />
          {!isWeb ? <SettingsButton to={routes.settings.notifications} icon={icons.notifications} text={'Notifications'} /> : null}
          {isStudent ? <SettingsButton to={routes.settings.interests} icon={icons.interests} text={'Interests'} /> : null}
          <SettingsButton to={routes.settings.themes} icon={icons.colorThemes} text={'Colour Themes'} />
          <Container flex={0} padding={15} alignItems={'center'}>
            <Text color={colors.screenAltText} textAlign={'center'}>App version {version}</Text>
          </Container>
          <Container flex={0} padding={15} alignItems={'center'}>
            <Text color={colors.screenAltText} textAlign={'center'}>
              If you have any suggestions on how I can be improved, I'd love to know!
          </Text>
            <StyledButton
              title={'Leave Feedback in Chat'}
              accessibility={{ role: 'button', label: 'leave feedback for Beacon in the chat' }}
              bgColor={colors.actionBg}
              handleAction={sendFeedbackInChat}
              textColor={colors.actionText}
            />
          </Container>
        </Container>
      </Container>
    </ScreenContainer>
  )
}