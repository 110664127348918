import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Container, Text } from 'primitives'

export const ModuleItem = ({ data, ...props }) => {
    const colors = SettingsHelper.getColors()
    if (data) {
        return (
            <Container flex={0} paddingTop={5} paddingBottom={5} width={'100%'}>
                <Text color={colors.screenText}>{data.moduleName}</Text>
                <Text color={colors.screenAltText}>{data.moduleCode}</Text>
                <Text color={colors.screenAltText}>{data.moduleCredits} credits</Text>
            </Container>
        )
    }
}