import React from 'react'
import { Svg, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const NotificationsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'notifications icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'notifications icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M5.62,25.7C12.56,23.9,4.44,8.15,17.5,6.83"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M29.38,25.7C22.44,23.9,30.56,8.15,17.5,6.83"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="5.62" y1="27.55" x2="29.38" y2="27.55"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M20.67,27.55c0,1.64-1.42,2.98-3.17,2.98s-3.17-1.33-3.17-2.98"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:3,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="17.5" y1="5.92" x2="17.5" y2="5"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="5.62" y1="25.7" x2="5.62" y2="27.55"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="29.38" y1="25.7" x2="29.38" y2="27.55"/>
        </Svg>
    )
}