import React from 'react'
import { Svg, Line, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const AppointmentBookingIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'Appointment booking icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'Appointment booking icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.1,30.8H6.9c-1.8,0-3.3-1.7-3.3-3.8V8C3.5,5.9,5,4.2,6.9,4.2h21.3c1.8,0,3.3,1.7,3.3,3.8v19
                C31.5,29.1,30,30.8,28.1,30.8z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinejoin:'round',strokeMiterlimit:10}} x1="3.6" y1="8" x2="31.5" y2="8"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="6.9" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="8.7" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.5" cy="6.3" r="0.7"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M25.46,23.95h-1.54v-1.54c0-0.31-0.25-0.56-0.56-0.56c-0.31,0-0.56,0.25-0.56,0.56v1.54h-1.54
                c-0.31,0-0.56,0.25-0.56,0.56c0,0.31,0.25,0.56,0.56,0.56h1.54v1.54c0,0.31,0.25,0.56,0.56,0.56c0.31,0,0.56-0.25,0.56-0.56v-1.54
                h1.54c0.31,0,0.56-0.25,0.56-0.56C26.02,24.2,25.77,23.95,25.46,23.95z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M23.36,20.49c-2.22,0-4.02,1.8-4.02,4.02s1.8,4.02,4.02,4.02c2.22,0,4.02-1.8,4.02-4.02
                S25.58,20.49,23.36,20.49z M23.36,27.7c-1.75,0-3.18-1.43-3.18-3.18c0-1.75,1.43-3.18,3.18-3.18c1.75,0,3.18,1.43,3.18,3.18
                C26.54,26.27,25.11,27.7,23.36,27.7z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M11.37,26.38c-1.17,0-2.12-0.95-2.12-2.12v-9.69c0-1.14,0.9-2.06,2.03-2.11v0.86c0,0.35,0.28,0.63,0.63,0.63
                s0.63-0.28,0.63-0.63v-0.87h9.44v0.87c0,0.35,0.28,0.63,0.63,0.63s0.63-0.28,0.63-0.63v-0.87h0.08c1.17,0,2.12,0.95,2.12,2.12v5.79
                c0.47,0.23,0.89,0.54,1.25,0.91v-6.69c0-1.86-1.51-3.37-3.37-3.37h-0.08v-1.03c0-0.35-0.28-0.63-0.63-0.63s-0.63,0.28-0.63,0.63
                v1.03h-9.44v-1.03c0-0.35-0.28-0.63-0.63-0.63s-0.63,0.28-0.63,0.63v1.04C9.46,11.25,8,12.74,8,14.57v9.69
                c0,1.86,1.51,3.37,3.37,3.37h8.54c-0.33-0.37-0.61-0.79-0.82-1.25H11.37z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M20.06,21.24v-0.12h0.13c0.83-0.77,1.94-1.25,3.16-1.25c0.29,0,0.58,0.03,0.86,0.08v-3.48
                c0-0.35-0.28-0.63-0.63-0.63H10.92c-0.35,0-0.63,0.28-0.63,0.63v8.05c0,0.35,0.28,0.63,0.63,0.63h7.84
                c-0.03-0.21-0.05-0.41-0.05-0.63c0-0.21,0.02-0.42,0.05-0.63H15.7v-2.77h3.11v2.44C19,22.66,19.44,21.86,20.06,21.24z M20.06,17.09
                h2.9v2.77h-2.9V17.09z M14.45,23.89h-2.9v-2.77h2.9V23.89z M14.45,19.86h-2.9v-2.77h2.9V19.86z M15.7,19.86v-2.77h3.11v2.77H15.7z"
                />
        </Svg>
    )
}