// No implementation on web
export default class FirebaseMessaging {

    constructor(user) { }

    unregister = async () => { }

    initialise = async () => { }

}

