import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { UserHelper, ChatHelper } from 'redux/helpers'
import { Namespaces } from 'redux/namespaces'

export const ChatInit = props => {
  // Grab data from state
  const directline = useSelector(state => state[Namespaces.CHAT].directline)
  const directlineIsReady = useSelector(state => state[Namespaces.CHAT].directlineIsReady)
  const getUser = UserHelper.useGetUser()

  // Set up hooks
  const setupDirectline = ChatHelper.useSetupDirectline()

  useEffect(() => {
    if (!directline) {
      getUser().then(user => {
        if (user) {
          setupDirectline(user)
        }
      })
    }
  }, [directlineIsReady])

  return null
}
