import { RedirectTypes } from 'redux/lib/redirect/RedirectTypes'

const initialState = {
  redirectRoute: null,
};

export const RedirectReducer = (state = initialState, action) => {
  switch (action.type) {
    case RedirectTypes.ADD_REDIRECT_ROUTE:
      return Object.assign({}, state, {
        redirectRoute: action.payload
      });

    case RedirectTypes.REDIRECT_COMPLETED:
      return Object.assign({}, state, {
        redirectRoute: null,
      });
    default:
      return state;
  }
};
