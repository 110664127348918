import React from 'react'
import { Svg, Rect, Line, Polyline, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const NewsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'news icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'news icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Polyline scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} points="27.37,24.17 27.37,4.47 3.89,4.47 3.89,26.87 "/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M3.89,10.63v16.24c0,2.01,1.65,3.66,3.66,3.66h19.9c2.01,0,3.66-1.65,3.66-3.66V10.63
                c0-2.01-1.65-3.66-3.66-3.66"/>
            <Rect scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x="8.59" y="9.35" width="5.89" height="5.89"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="18.6" y1="10.37" x2="23.26" y2="10.37"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="18.6" y1="14.97" x2="23.26" y2="14.97"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="8.59" y1="19.57" x2="23.26" y2="19.57"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="8.59" y1="24.17" x2="23.26" y2="24.17"/>
        </Svg>
    )
}