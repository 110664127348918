import React from 'react'
import { Svg, Line, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const SkillSiteIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'Skill Site icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'Skill Site icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.1,30.8H6.9c-1.8,0-3.3-1.7-3.3-3.8V8C3.5,5.9,5,4.2,6.9,4.2h21.3c1.8,0,3.3,1.7,3.3,3.8v19
                C31.5,29.1,30,30.8,28.1,30.8z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinejoin:'round',strokeMiterlimit:10}} x1="3.6" y1="8" x2="31.5" y2="8"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="6.9" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="8.7" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.5" cy="6.3" r="0.7"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M27.4,18.9"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M7.5,18.9"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M8.6,18.5c0.1-0.1,0.2-0.1,0.3-0.1c0.4,0,0.8,0,1.2,0c0.3,0,0.3,0,0.3,0.3c0,1.1,0.1,2.1,0.5,3.1
                c1.1,2.5,3,4,5.7,4.4c3.9,0.5,7.4-2.1,8-6c0.1-0.5,0.1-1,0.1-1.6c0-0.2,0.1-0.3,0.2-0.3c0.4,0,0.8,0,1.2,0c0.3,0,0.3,0,0.3,0.3
                c0.1,1.8-0.4,3.5-1.3,5C25,24,25,24.2,25,24.5c0.3,1.1,0.6,2.1,0.9,3.2c0,0.1,0,0.2,0.1,0.2c0,0.1,0,0.1-0.1,0.1
                c-0.1,0-0.2-0.1-0.2-0.1c-0.9-0.4-1.8-0.9-2.7-1.3c-0.2-0.1-0.3-0.1-0.5,0c-1.2,0.8-2.5,1.3-3.9,1.4c-3.1,0.3-5.7-0.7-7.7-3
                c-1.2-1.3-1.9-2.9-2.1-4.6c0-0.2,0-0.4-0.1-0.6C8.6,19.3,8.6,18.9,8.6,18.5z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M14.9,23.3c0-0.5,0-1.1,0-1.6c0-0.9,0.6-1.5,1.4-1.7c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.1-0.1-0.2
                c-1-0.8-1.1-2.2-0.2-3.1c0.8-0.8,2.2-0.8,2.9,0c0.8,0.9,0.7,2.2-0.2,3c-0.1,0.1-0.2,0.1-0.2,0.2c0,0.1,0.2,0.1,0.3,0.1
                c0.7,0.2,1.2,0.8,1.2,1.6c0,1.1,0,2.2,0,3.4c0,0.2-0.1,0.2-0.2,0.3c-0.9,0.3-1.9,0.5-2.9,0.4c-0.7,0-1.3-0.2-2-0.4
                c-0.2-0.1-0.3-0.2-0.3-0.4C14.9,24.4,14.9,23.9,14.9,23.3C14.9,23.3,14.9,23.3,14.9,23.3z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.5,12c-3.2,0-5.9,2-6.8,5c-0.1,0.4-0.1,0.4-0.5,0.4c-0.4,0-0.7,0-1.1,0c-0.3,0-0.3,0-0.2-0.3
                c0.2-0.9,0.5-1.7,1-2.5c1.5-2.4,3.6-3.9,6.4-4.3c4.9-0.7,8.9,2.5,9.9,6.7c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.2,0.2c-0.5,0-0.9,0-1.4,0
                c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.3-0.2-0.5-0.3-0.8c-1.1-2.5-2.9-3.9-5.6-4.4c-0.1,0-0.2,0-0.4-0.1C18,12,17.8,12,17.5,12z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M14.7,19.3c0,0.6-0.2,1.1-0.6,1.4c-0.2,0.1-0.2,0.1,0,0.2c0,0,0.1,0,0.1,0c0.2,0.1,0.3,0.2,0.2,0.4
                c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.9,0,1.9,0,2.8c0,0.3,0,0.3-0.2,0.1c-1.3-0.8-2.3-1.9-2.9-3.4c-0.1-0.3-0.1-0.3,0.1-0.4
                c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0,0.1-0.1,0-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.5-0.5-0.7-1.3-0.3-2c0.4-0.7,1.1-1,1.9-0.8
                C14.2,18,14.7,18.6,14.7,19.3z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M20.6,23.2c0-0.5,0-0.9,0-1.4c0-0.2,0-0.5-0.1-0.7c-0.1-0.2-0.1-0.3,0.2-0.3c0.1,0,0.1,0,0-0.1
                c-0.1-0.1-0.1-0.1-0.2-0.2c-0.7-0.6-0.7-1.7,0-2.3c0.7-0.7,1.7-0.6,2.4,0c0.6,0.7,0.6,1.7-0.1,2.3c0,0-0.1,0.1-0.1,0.1
                c-0.2,0.1-0.2,0.1,0.1,0.2c0.4,0.1,0.6,0.3,0.8,0.6c0.1,0.1,0.1,0.3,0,0.4c-0.6,1.3-1.5,2.3-2.7,3c-0.2,0.1-0.2,0.1-0.2-0.1
                C20.6,24.3,20.6,23.7,20.6,23.2z"/>
        </Svg>
    )
}