import React from 'react'
import { Svg, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const LogOutIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'log out icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'log out icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="15.5" y1="17.5" x2="31.17" y2="17.5"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="31.17" y1="17.5" x2="27.27" y2="13.6"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="31.17" y1="17.5" x2="27.27" y2="21.4"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M24.38,23.03v5.97c0,1.59-1.3,2.88-2.88,2.88H8.29c-1.59,0-2.88-1.3-2.88-2.88V6.01c0-1.59,1.3-2.88,2.88-2.88
                H21.5c1.59,0,2.88,1.3,2.88,2.88v6.17"/>
        </Svg>
    )
}