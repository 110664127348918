export const filterCourseData = (data) => {
    let newData = []
    let years = new Set()
    let periods = new Set()
    data.moduleEnrolments.map(mod => {
        years.add(mod.mavAcademicYear)
        periods.add(mod.mavPeriodId)
    })
    years = Array.from(years).sort().reverse()
    periods = Array.from(periods).sort().reverse()
    years.map(year => {
        let currentYearData = []
        periods.map(period => {
            const currentPeriodData = data.moduleEnrolments.filter(mod => mod.mavAcademicYear == year && mod.mavPeriodId == period)
            currentYearData.push({ period: period, data: currentPeriodData })
        })
        newData.push({ year: year, data: currentYearData })
    })
    return newData
}