import React from 'react'
import { Svg, Circle, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SpeechToTextIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'speech to text icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'speech to text icon'
    }

    return(
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="11.89" y1="26.67" x2="23.11" y2="26.67"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="17.5" y1="26.67" x2="17.5" y2="23.1"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M23.63,14.65v3.02c0,2.64-2.76,5.43-6.13,5.43h0c-3.37,0-6.13-2.79-6.13-5.43v-3.02"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M18.04,20.94h-1.08c-2.05,0-3.72-1.68-3.72-3.72v-6.98c0-2.05,1.68-3.72,3.72-3.72h1.08
                c2.05,0,3.72,1.68,3.72,3.72v6.98C21.76,19.27,20.09,20.94,18.04,20.94z"/>
        </Svg>
    )
}