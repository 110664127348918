import React from 'react';
import { Container, Text } from 'primitives'

export const Bar = props =>
    <Container
        backgroundColor={props.backgroundColor}
        padding={props.padding}
        paddingTop={props.paddingTop}
        paddingBottom={props.paddingBottom}
        width={props.width}
        maxWidth={props.maxWidth}
        flex={0}
        justifyContent={'center'}
    >
        <Text
            color={props.textColor}
            textAlign={props.textAlign}
            nativeFontFamily={props.nativeFontFamily}
            webFontFamily={props.webFontFamily}
            fontWeight={props.fontWeight}
            fontSize={props.fontSize}
        >{props.text}</Text>
    </Container >
