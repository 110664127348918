import React from 'react'
import { SettingsHelper, UserHelper, FetchHelper } from 'redux/helpers'
import { Platform } from 'lib/primitives'
import { Container, Text } from 'primitives'
import { FetchRequest } from 'lib/general/fetchRequest'
import { Namespaces } from 'redux/namespaces'
import { ScreenContainer, ReduxFetch, StyledButton, LoadingData } from 'components/general'
import { CourseView } from 'components/courses'
import { CoursesFetchKeys } from 'redux/fetchKeys'
import { useHistory, routes } from 'navigation'
import { OfflineBar } from 'components/bars'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const Courses = props => {
  const colors = SettingsHelper.getColors()
  const isStudent = UserHelper.getIsStudent()
  const hasInternet = NetworkInfo.useHasInternet()
  const history = useHistory()
  const getCoursesAndModules = FetchRequest.useGetCoursesAndModules()
  const fetchCourses = FetchHelper.useFetch(Namespaces.COURSES, CoursesFetchKeys.courses, getCoursesAndModules)

  if (isStudent === null) return <LoadingData backgroundColor={colors.screenBg} />
  if (!isStudent) history.push(routes.dashboard)

  return (
    <ScreenContainer viewType={'ScrollView'} onRefresh={hasInternet ? fetchCourses : null} alignItems={'center'}>
      <OfflineBar />
      <ReduxFetch
        debug={false}
        namespace={Namespaces.COURSES}
        fetchKey={CoursesFetchKeys.courses}
        fetchFunc={fetchCourses}
        component={CoursesDisplay}
      />
    </ScreenContainer>
  )
}

const CoursesDisplay = ({ data, ...props }) => {
  const colors = SettingsHelper.getColors()
  const isWeb = Platform.isWeb()
  const hasInternet = NetworkInfo.useHasInternet()
  return (
    <Container flex={0} padding={10} alignItems={'center'} width={'100%'}>
      {data != null && data.data != null && data.data.length < 1 &&
        <Text
          color={colors.screenAltText}
          padding={5}
        >
          You are currently not enrolled on any courses.
        </Text>}
      {data && data.data && data.data.length > 0 && data.data.map(course => {
        if (course && course.course && course.course.i3Id) {
          return <CourseView data={course} key={course.course.i3Id} />
        } else {
          return null
        }
      })}
      {isWeb && hasInternet ? <StyledButton title={'Refresh'} accessibility={{ role: 'button', label: 'refresh the page' }} handleAction={props.fetchFunc} bgColor={colors.actionBg} textColor={colors.actionText} /> : null}
    </Container>
  )
}

