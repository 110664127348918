import React from 'react'
import { Platform } from 'lib/primitives'
import { AnimatedTransition } from 'primitives'
import { useTransition } from 'lib/animation/springExports'
import { SettingsHelper } from 'redux/helpers'

export const SlideInMenu = ({ animation, renderChildren, containerStyle, ...extraProps }) => {
    const accessibility = SettingsHelper.getAccessibilitySettings()
    const isWeb = Platform.isWeb()
    const transitions = useTransition(animation === 'open', null, {
        from: isWeb ? { height: 0, opacity: 0, transform: `translateX(10px)` } : { height: 0, translateX: 10, opacity: 0 },
        enter: isWeb ? { height: 'auto', opacity: 1, transform: `translateX(0px)` } : { height: 'auto', translateX: 0, opacity: 1 },
        leave: isWeb ? { height: 0, opacity: 0, transform: `translateX(10px)` } : { height: 0, translateX: 10, opacity: 0 },
        immediate: accessibility.disableAnimations
    })

    return transitions.map(
        ({ item, key, props }) => {
            const { translateX } = props
            let transform = null
            if (!isWeb) {
                transform = [{ translateX: translateX }]
            }
            return (
                item && (
                    <AnimatedTransition native key={key} style={{ ...props, display: 'flex', transform: transform ? transform : props.transform, overflow: 'hidden', ...containerStyle }}>
                        {renderChildren(extraProps)}
                    </AnimatedTransition>
                )
            )
        }
    )
}