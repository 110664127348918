import { FetchTypes } from './FetchTypes';

export class FetchActions {

  static fetchAttempt = (namespace, key) => {
    return { type: `${namespace}_${FetchTypes.FETCH_ATTEMPT}_${key}`, payload: {} }
  }
  
  static fetchSuccess = (namespace, key, data) => {
    return { type: `${namespace}_${FetchTypes.FETCH_SUCCESS}_${key}`, payload: data }
  }
  
  static fetchFail = (namespace, key, error) => {
    return { type: `${namespace}_${FetchTypes.FETCH_FAIL}_${key}`, payload: error }
  }
  
  static fetchForbidden = (namespace, key) => {
    return { type: `${namespace}_${FetchTypes.FETCH_FORBIDDEN}_${key}`, payload: {} }
  }

  static fetchClear = (namespace, key) => {
    return { type: `${namespace}_${FetchTypes.FETCH_CLEAR}_${key}`, payload: {} }
  }

}
