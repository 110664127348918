import { NotificationTypes } from 'redux/lib/notification/NotificationTypes'
import { UserTypes } from 'redux/lib/user/UserTypes'
const initialState = {
  notifications: [],
  redirectRoute: null,
}

export const NotificationReducer = (state = initialState, action) => {
  let notifications
  switch (action.type) {
    case UserTypes.LOGOUT:
      return initialState

    case NotificationTypes.ADD_NOTIFICATION:
      console.log(action)
      return Object.assign({}, state, {
        notifications: [...state.notifications, action.payload],
      })

    case NotificationTypes.ADD_NOTIFICATIONS:
      console.log(action)
      return Object.assign({}, state, {
        notifications: [...state.notifications, ...action.payload],
      })

    case NotificationTypes.REMOVE_NOTIFICATION:
      notifications = state.notifications.filter(x => x.id !== action.payload);
      return Object.assign({}, state, {
        notifications: notifications,
      })

    case NotificationTypes.REMOVE_NOTIFICATIONS:
      notifications = state.notifications.filter(x => !action.payload.includes(x.id));
      return Object.assign({}, state, {
        notifications: notifications,
      })

    case NotificationTypes.REMOVE_ALL_NOTIFICATIONS:
      return Object.assign({}, state, {
        notifications: []
      })

    default:
      return state;
  }
}