import React from 'react'
import { Route, Redirect, routes } from './'
import { UserHelper } from 'redux/helpers'

export const LoginRoute = props => {
  const isLoggedIn = UserHelper.isLoggedIn()
  if (!isLoggedIn) {
    return <Route {...props} />
  }
  return (
    <Redirect to={{ pathname: routes.dashboard, state: { from: props.location } }} />
  )
}