import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general/ScreenContainer'
import { Container, Text, Link, Image } from 'primitives'
import { StyledButton } from 'components/general/StyledButton'
import { UserHelper } from 'redux/helpers'
import { fonts } from 'lib/config/ui-config'
import { ImageSrc } from 'assets/images'
import { useSelector } from 'react-redux'
import { routes } from 'navigation'

export const Login = props => {
  const colors = SettingsHelper.getColors()
  const width = useSelector(state => state.runtime.width)
  const height = useSelector(state => state.runtime.height)

  const imageHeight = () => {
    return height * 0.5 > width ? width : height * 0.5
  }

  return (
    <ScreenContainer
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
      backgroundColor={colors.screenBg}
      hideChatBar={true}
    >
      <Container
        flex={0}
        justifyContent={'center'}
        alignItems={'center'}
        position={'absolute'}
        top={0}
        bottom={0}
        left={0}
        right={0}
        overflow={'hidden'}
      >
        <Image src={ImageSrc.loginBackground} alt={''} width={height > width ? height : width} height={height > width ? height : width} />
      </Container>
      <Container
        flex={0}
        position={'absolute'}
        top={0}
        bottom={0}
        left={0}
        right={0}
        viewType={'ScrollView'}
        webOverflow={'auto'}
      >
        <BeaconImage imageHeight={imageHeight} />
        <LoginText colors={colors} />
      </Container>
    </ScreenContainer>
  )
}

const BeaconImage = ({ imageHeight }) => {
  return (
    <Container
      flex={0}
      justifyContent={'center'}
      alignItems={'center'}
      marginBottom={-60}
      zIndex={5}
    >
      <Container flex={0} width={imageHeight() * (5 / 6)} height={imageHeight()}>
        <Image src={ImageSrc.beaconFullSize} alt={'Beacon mascot waving'} width={'100%'} height={'100%'} />
      </Container>
    </Container>
  )
}

const LoginText = ({ colors }) => {
  const handleLogIn = UserHelper.useHandleLogIn()

  return (
    <Container
      flex={0}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Container
        flex={0}
        padding={20}
        paddingTop={50}
        margin={10}
        marginTop={0}
        borderRadius={10}
        alignItems={'center'}
        backgroundColor={colors.screenBg}
        border={`3px solid ${colors.screenAltBg}`}
        borderWidth={3}
        borderColor={colors.screenAltBg}
      >

        <Container
          flex={0}
          padding={5}
        >
          <Text
            color={colors.screenText}
            textAlign={'center'}
            nativeFontFamily={fonts.native.decorative}
            webFontFamily={fonts.web.heading}
            fontWeight={fonts.weight.medium}
            fontSize={fonts.size.heading}
            lineHeight={fonts.size.heading * 1.5}
          >
            {'Welcome to '}
            <Text
              color={colors.screenText}
              textAlign={'center'}
              nativeFontFamily={fonts.native.subHeading}
              webFontFamily={fonts.web.heading}
              fontWeight={fonts.weight.semiBold}
              fontSize={fonts.size.heading}
              lineHeight={fonts.size.heading * 1.5}
            >
              Beacon
          </Text>
          </Text>
        </Container>
        <Container
          flex={0}
          padding={5}
        >
          <Text
            color={colors.screenText}
            textAlign={'center'}
            nativeFontFamily={fonts.native.decorative}
            webFontFamily={fonts.web.heading}
            fontWeight={fonts.weight.medium}
            fontSize={fonts.size.large}
            lineHeight={fonts.size.large * 1.5}
          >
            Your Digital Coach.
          </Text>
        </Container>
        <StyledButton
          title={'Log In'}
          accessibility={{ role: 'button', label: 'log in to Beacon' }}
          bgColor={colors.actionBg}
          textColor={colors.actionText}
          handleAction={handleLogIn}
        />
        <Container
          flex={0}
          padding={5}
        >
          <Link to={'https://login.microsoftonline.com/common/oauth2/logout'} isExternal={true}>
            <Text
              color={colors.screenText}
              textAlign={'center'}
            >
              Looking to change account?{'\n'}Tap here to sign out of Office 365.
            </Text>
          </Link>
        </Container>
        <Container
          flex={0}
          padding={10}
        >
          {/* <Link to={routes.webviews.termsOfUse}> -----> need to fix back button before we can use this */}
          <Link to={routes.external.termsOfUse} isExternal={true}>
            <Text
              color={colors.screenAltText}
              textAlign={'center'}
              fontSize={fonts.size.small}
              lineHeight={fonts.size.small * 1.5}
            >
              By signing in, you agree to the Terms of Use.
            </Text>
          </Link>
        </Container>
      </Container>
    </Container>
  )
}

