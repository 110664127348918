import { Platform } from 'lib/primitives'

export const layoutDimensions = ({setHeight, setWidth, setDimensions, ref, event}) => {
    const isWeb = Platform.isWeb()
    if (isWeb && ref && ref.current) {
        if (setDimensions) {
            setDimensions({ height: ref.current.clientHeight, width: ref.current.clientWidth })
        }
        if (setHeight) {
            setHeight(ref.current.clientHeight)
        }
        if (setWidth) {
            setWidth(ref.current.clientWidth)
        }
    } else if (event && event.nativeEvent) {
        const { height, width } = event.nativeEvent.layout
        if (setDimensions) {
            setDimensions({ height: height, width: width })
        }
        if (setHeight) {
            setHeight(height)
        }
        if (setWidth) {
            setWidth(width)
        }
    }
}