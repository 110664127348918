import { useDispatch } from 'react-redux';
import { UserHelper } from 'redux/lib/user/UserHelper';
import { FetchActions } from './FetchActions';

export class FetchHelper {

  static useFetch = (namespace, key, fetchFunc, postProcessFunc = () => { }, logOutIfUnauthtorised = true) => {
    const dispatch = useDispatch()
    const handleLogOut = UserHelper.useHandleLogOut()
    return () => {
      dispatch(FetchActions.fetchAttempt(namespace, key))
      fetchFunc()
        .then(data => {
          if (data.error) {
            if (logOutIfUnauthtorised && data.resStatus === 401) {
              console.warn("The fetch request received a 401, logging out..")
              handleLogOut()
              return
            }
            if (data.resStatus === 403) {
              dispatch(FetchActions.fetchForbidden(namespace, key))
              return
            }
            dispatch(FetchActions.fetchFail(namespace, key, data))
            return
          } else {
            dispatch(FetchActions.fetchSuccess(namespace, key, data))
            postProcessFunc(data)
            return
          }
        })
        .catch(error => {
          console.log("error...")
          console.log("⚠️ Could not fetch data")
          console.log(error);
          dispatch(FetchActions.fetchFail(namespace, key, error))
        })
    }
  }

  static useFetchClear = () => {
    const dispatch = useDispatch()
    return (namespace, fetchKeys) => {
      if (!namespace || !fetchKeys) return null
      let keys = Object.keys(fetchKeys)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        dispatch(FetchActions.fetchClear(namespace, key))
      }
    }
  }

}
