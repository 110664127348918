import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const WarningActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'warning icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'warning icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M31.82,26.99L19.51,5.68c-0.42-0.73-1.17-1.16-2.01-1.16c0,0,0,0,0,0c-0.84,0-1.59,0.43-2.01,1.16L3.18,26.99
                c-0.42,0.73-0.42,1.6,0,2.32c0.42,0.73,1.17,1.16,2.01,1.16H29.8c0.84,0,1.59-0.43,2.01-1.16C32.24,28.59,32.24,27.72,31.82,26.99z
                M17.5,11.66c0.99,0,1.58,0.63,1.58,1.7c0,0.52-0.1,1.53-0.25,3.07c-0.07,0.68-0.14,1.45-0.22,2.31l-0.3,3.18h-1.62l-0.3-3.18
                c-0.08-0.86-0.16-1.64-0.22-2.31c-0.15-1.53-0.25-2.54-0.25-3.07C15.92,12.29,16.51,11.66,17.5,11.66z M17.5,26.35
                c-0.96,0-1.74-0.78-1.74-1.74c0-0.95,0.79-1.75,1.74-1.75c0.94,0,1.74,0.8,1.74,1.75C19.24,25.57,18.46,26.35,17.5,26.35z"/>
        </Svg>
    )
}