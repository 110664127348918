import React from 'react'

export const ContainerPrimitive = ({isChat, useRef, webOverflow, nativeOverflow, hideScrollBar, webTransform, nativeTransform, flexShrink, viewType, onRefresh, scrollEnabled, aspectRatio, children, ...props}) => {
    let style = {...props}
    if (webOverflow) style.overflow = webOverflow
    if (webTransform) style.transform = webTransform
    if (hideScrollBar) {
        style.overflowX = 'hidden'
    }
    if(props.flex == 0) {
        style.flex = null
        style.flexShrink = flexShrink ? flexShrink : 0
    }
    if(props.flex == 1) style.flex = '1 1 auto'
    if (viewType == 'ScrollView' && !isChat) style.paddingBottom = 40

    return (
        <div style={style} ref={useRef}>{children}</div>
    )
}