import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { StyledButton } from 'components/general'
import { SettingsHelper, TimetableHelper } from 'redux/helpers'
import { Icon, icons } from 'assets/MenuIcons'
import { TimetableUtils } from 'lib/timetable/TimetableUtils'
import { Alert } from 'lib/primitives'
import { GPS } from 'lib/general'
import { Container, Text, InputNumber } from 'primitives'
import { Namespaces } from 'redux/namespaces'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { useInterval } from 'lib/general/useInterval'

// The last digits of the attendance code acts as a checksum.
// To avoid wrong check in due to typos, we'll give 2 changes if the checksum is not valid
const MAX_WRONG_CODE_ATTEMPTS = 2

export const AttendanceCheckIn = ({ event, fetchFunc, ...props }) => {
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()
    const checkInAttendance = TimetableHelper.useCheckInAttendance()
    const attendanceStatus = useSelector(state => state[Namespaces.TIMETABLE].attendanceStatus)
    const [code, setCode] = useState()
    const [checkInBtnClicked, setCheckInBtnClicked] = useState(false)
    const [hasError, setHasError] = useState(event && event.attendanceStatus == 'ERROR')
    const [numberWrongCodesSubmitted, setNumberWrongCodesSubmitted] = useState(0)
    const isCurrent = moment().isSameOrBefore(event.eventEndDate)
    const checkAttendanceStatus = TimetableHelper.useCheckAttendanceStatus()
    const updateAttendanceStatus = TimetableHelper.useUpdateAttendanceStatus()

    useInterval(() => {
        if (hasInternet && Object.keys(attendanceStatus).length > 0) {
            checkAttendanceStatus(fetchFunc)
        }
    }, 30000)

    if (!(event && event.scheduledEventId) || moment().isSameOrBefore(moment(event.eventStartDate))) {
        return null
    }

    /* ============================== */
    /* =            LOGIC           = */
    /* ============================== */

    const handleSubmitCode = () => {
        let isValid = TimetableUtils.isAttendanceCodeValid(code)
        console.log(`Code submitted: ${code}`)
        if (!isValid) {
            console.log(`Code invalid (Wrong attempts: ${numberWrongCodesSubmitted}/${MAX_WRONG_CODE_ATTEMPTS})`)
            if (numberWrongCodesSubmitted < MAX_WRONG_CODE_ATTEMPTS) {
                setNumberWrongCodesSubmitted(numberWrongCodesSubmitted + 1)
                return Alert.confirm({
                    alertTitle: "Confirmation",
                    alertDescription: "That code looks incorrect, do you want proceed anyway?",
                    okBtnFunc: checkIn
                })
            }
        }
        return checkIn()
    }

    const checkIn = async () => {
        try {
            const gps = await GPS.getCurrentLatLong()
            console.log("-------------------------")
            console.log("Checking in...")
            console.log(`- Event ID: ${event.scheduledEventId}`)
            console.log(`- Code: ${code}`)
            console.log(`- GPS Coords:`)
            console.log(gps)
            await checkInAttendance(event.scheduledEventId, { code, gps })
            // Update attendance status
            updateAttendanceStatus({ [event.scheduledEventId]: { sent: false } })
        } catch (e) {
            console.log("An error occurred, could not submit the attendance")
            console.log(e)
            setHasError(true)
        }
    }

    /* ============================== */
    /* =       RENDER FUNCTIONS     = */
    /* ============================== */

    const renderSubmitCode = () => {
        if (code && code.length === 6) {
            return <StyledButton
                title={'Submit code'}
                accessibility={{ role: 'button', label: 'submit the attendance code' }}
                bgColor={colors.actionSuccess}
                textColor={colors.actionText}
                handleAction={handleSubmitCode}
                margin={0}
            />
        } else {
            return (
                <Container flex={0}>
                    <Text textAlign={'center'} color={colors.screenAltText}>Enter code</Text>
                </Container>
            )
        }
    }

    switch (event.attendanceStatus) {
        case 'SCORE_TOO_LOW':
        case 'ATTENDED': return (
            <AttendanceContainer justifyContent={'flex-start'} alignItems={'center'}>
                <Icon type={icons.successActive} color={colors.screenAltText} size={25} />
                <Text color={colors.screenAltText} paddingLeft={5}>Checked-in</Text>
            </AttendanceContainer>
        )
        case 'ABSENT': return (
            <AttendanceContainer justifyContent={'flex-start'} alignItems={'center'}>
                <Icon type={icons.cancelActive} color={colors.screenAltText} size={25} />
                <Text color={colors.screenAltText} paddingLeft={5}>Marked as absent</Text>
            </AttendanceContainer>
        )
        default:
            if (hasError || attendanceStatus && attendanceStatus[event.scheduledEventId] && attendanceStatus[event.scheduledEventId].hasError) {
                if (isCurrent) {
                    return (
                        <AttendanceContainer flexDirection={'column'}>
                            <Text color={colors.screenText} wordWrap={'break-word'} marginBottom={5} textAlign={'center'}>Sorry, there's been an issue checking you in to this event.</Text>
                            <StyledButton
                                title={'Try again'}
                                accessibility={{ role: 'button', label: 'attempt attendance check-in again' }}
                                bgColor={colors.actionSuccess}
                                textColor={colors.actionText}
                                handleAction={() => {
                                    setCheckInBtnClicked(false)
                                    setHasError(false)
                                }}
                                margin={0}
                            />
                        </AttendanceContainer>
                    )
                }
                return (
                    <AttendanceContainer flexDirection={'column'}>
                        <Text color={colors.screenAltText} wordWrap={'break-word'} marginBottom={5} textAlign={'center'}>Sorry, there's been an issue checking you in to this event.</Text>
                    </AttendanceContainer>
                )
            }

            if (attendanceStatus[event.scheduledEventId]) {
                return (
                    <AttendanceContainer justifyContent={'flex-start'} alignItems={'center'}>
                        <Icon type={icons.success} color={colors.screenAltText} size={25} />
                        <Text color={colors.screenAltText} paddingLeft={5}>Check-in processing...</Text>
                    </AttendanceContainer>
                )
            }

            if (isCurrent) {
                if (!checkInBtnClicked) {
                    return (
                        <AttendanceContainer>
                            <StyledButton
                                title={'Check-In'}
                                accessibility={{ role: 'button', label: 'start check-in to this event' }}
                                bgColor={colors.actionSuccess}
                                textColor={colors.actionText}
                                handleAction={() => setCheckInBtnClicked(true)}
                                margin={0}
                            />
                        </AttendanceContainer>
                    )
                }
                return (
                    <AttendanceContainer>
                        <Container flex={1} marginRight={5} justifyContent={'center'}>
                            <InputNumber
                                name={'enter code'}
                                maxDigits={6}
                                letterSpacing={6}
                                display={'flex'}
                                flex={0}
                                width={'100%'}
                                padding={5}
                                margin={0}
                                color={colors.screenText}
                                backgroundColor={colors.screenBg}
                                borderWidth={2}
                                borderColor={code && code.length === 6 ? colors.actionSuccess : colors.screenAltText}
                                value={code}
                                onEnter={handleSubmitCode}
                                onChange={text => setCode(text)}
                            />
                        </Container>
                        <Container flex={1} justifyContent={'center'}>
                            {renderSubmitCode()}
                        </Container>
                    </AttendanceContainer>
                )
            }

            return (
                <AttendanceContainer justifyContent={'flex-start'} alignItems={'center'}>
                    <Icon type={icons.cancel} color={colors.screenAltText} size={25} />
                    <Text color={colors.screenAltText} paddingLeft={5}>Did not check-in</Text>
                </AttendanceContainer>
            )
    }

}

const AttendanceContainer = ({ children, flexDirection = 'row', justifyContent = 'center' }) => {
    const colors = SettingsHelper.getColors()
    return (
        <Container
            flex={0}
            marginTop={5}
            paddingTop={10}
            flexDirection={flexDirection}
            borderTop={`2px solid ${colors.screenBorder}`}
            borderTopWidth={2}
            borderColor={colors.screenBorder}
            justifyContent={justifyContent}
        >
            {children}
        </Container>
    )
}

AttendanceCheckIn.propTypes = {
    event: PropTypes.object,
}

AttendanceCheckIn.defaultProps = {
    event: {}
}