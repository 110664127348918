import React from 'react'
import PropTypes from 'prop-types'
import { ButtonPrimitive } from './ButtonPrimitive'

export const Button = ({children, ...props}) => {
    return <ButtonPrimitive {...props} >{children}</ButtonPrimitive>
}

Button.propTypes = {
    url: PropTypes.string,
    handleAction: PropTypes.func,
    handleActionIn: PropTypes.func,
    handleActionOut: PropTypes.func,
    padding: PropTypes.number,
    margin: PropTypes.number,
    borderRadius: PropTypes.number,
    backgroundColor: PropTypes.string,
    display: PropTypes.oneOf(['flex', 'none']),
    flex: PropTypes.number,
    flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    flexWrap: PropTypes.oneOf(['wrap', 'nowrap']),
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
    alignSelf: PropTypes.string,
    activeOpacity: PropTypes.number,
};

Button.defaultProps = {
    url: "",
    handleAction: () => { console.log("Missing HandleAction") },
    flex: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0)',
    borderRadius: 0,
};