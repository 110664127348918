import { colorThemes } from 'lib/general/enums'

const colors = {
    suniRed: '#D70826',
    darkerRed: '#C10722',
    suniBerry: '#921C53',
    suniPink: '#CC1F4B',
    suniPurple: '#5C2C52',
    suniPurpleLight: '#BD75AD',
    suniRedTranslucent: 'rgba(215,8,38,0.5)',
    suniBerryTranslucent: 'rgba(146,28,83,0.5)',
    suniPinkTranslucent: 'rgba(204,31,75,0.5)',
    suniPurpleTranslucent: 'rgba(92,44,82, 0.5)',
    green: '#019433',
    devGreen: '#01AD92',
    testOrange: '#FA8E1A',
    white: '#FFFFFF',
    black: '#000000',
    darkGrey: '#222222',
    darkGrey2: '#333333',
    darkGrey3: '#191919',
    midGrey: '#88888E',
    midGrey2: '#66666C',
    lightGrey: '#E4E4E9',
    lightGrey2: '#D4D4D9',
    translucentBlack: 'rgba(0,0,0,0.2)',
    translucentBlackDarker: 'rgba(0,0,0,0.5)',
    translucentWhite: 'rgba(255,255,255,0.8)',
    transparent: 'rgba(0,0,0,0)',
}

export const getColors = (theme) => {
    switch (theme) {
        case colorThemes.basic:
            return {
                devEnv: colors.devGreen,        // Environment bar colors
                testEnv: colors.testOrange,
                appBg: colors.suniRed,          // Background to whole app, changing this changes colour behind the navigation bars

                menuIcon: colors.white,          // Text and icon color within navigation bars
                menuAltIcon: colors.midGrey,
                statusBar: 'light-content',     // NATIVE ONLY Changes status bar theme (time, battery, wifi)
                androidNavBg: colors.darkGrey,
                androidNav: false,        // ANDROID ONLY Changes navigation button theme, false == LIGHT
                loadingStatusBar: 'dark-content',

                screenBg: colors.white,         // App Screen/Page colors ------------
                screenAltBg: colors.lightGrey,
                screenText: colors.darkGrey,
                screenAltText: colors.midGrey2,
                screenBorder: colors.translucentBlack,
                screenFade: colors.translucentWhite,
                screenTitle: colors.translucentBlack,
                onboardingOverlay: colors.translucentBlackDarker,
                transparent: colors.transparent,

                actionBg: colors.suniRed,       // Button colors ---------------------
                actionText: colors.white,
                actionSuccess: colors.green,
                actionBgs: [colors.suniRed, colors.suniBerry, colors.suniPink, colors.suniPurple],
                actionLightBgs: [colors.suniRedTranslucent, colors.suniBerryTranslucent, colors.suniPinkTranslucent, colors.suniPurpleTranslucent],

                chatBotBg: colors.white,          // Chat ----------------------------
                chatBotText: colors.darkGrey,
                chatBotAltText: colors.midGrey2,
                chatBotTextAccent: colors.suniPurple,
                chatUserDefault: colors.suniPurple,
                chatUserText: colors.white,
                chatUserAltText: colors.translucentWhite,
            }
        case colorThemes.dark:
            return {
                devEnv: colors.devGreen,        // Environment bar colors
                testEnv: colors.testOrange,
                appBg: colors.darkerRed,          // Background to whole app, changing this changes colour behind the navigation bars

                menuIcon: colors.white,          // Text and icon color within navigation bars
                menuAltIcon: colors.lightGrey2,
                statusBar: 'light-content',     // NATIVE ONLY Changes status bar theme (time, battery, wifi)
                androidNavBg: colors.darkGrey2,
                androidNav: false,        // ANDROID ONLY Changes navigation button theme, false == LIGHT
                loadingStatusBar: 'dark-content',

                screenBg: colors.darkGrey,         // App Screen/Page colors ------------
                screenAltBg: colors.darkGrey3,
                screenText: colors.white,
                screenAltText: colors.lightGrey2,
                screenBorder: colors.translucentWhite,
                screenFade: colors.translucentBlack,
                screenTitle: colors.translucentBlack,
                onboardingOverlay: colors.translucentBlackDarker,
                transparent: colors.transparent,

                actionBg: colors.suniRed,       // Button colors ---------------------
                actionText: colors.white,
                actionSuccess: colors.green,
                actionBgs: [colors.suniRed, colors.suniBerry, colors.suniPink, colors.suniPurple],
                actionLightBgs: [colors.suniRedTranslucent, colors.suniBerryTranslucent, colors.suniPinkTranslucent, colors.suniPurpleTranslucent],

                chatBotBg: colors.darkGrey,          // Chat ----------------------------
                chatBotText: colors.white,
                chatBotAltText: colors.lightGrey2,
                chatBotTextAccent: colors.suniPurpleLight,
                chatUserDefault: colors.suniPurple,
                chatUserText: colors.white,
                chatUserAltText: colors.translucentWhite,
            }
        case colorThemes.highContrast:
            return {
                devEnv: colors.devGreen,        // Environment bar colors
                testEnv: colors.testOrange,
                appBg: colors.darkGrey,          // Background to whole app, changing this changes colour behind the navigation bars

                menuIcon: colors.white,          // Text and icon color within navigation bars
                menuAltIcon: colors.darkGrey,
                statusBar: 'light-content',     // NATIVE ONLY Changes status bar theme (time, battery, wifi)
                androidNavBg: colors.darkGrey,
                androidNav: false,        // ANDROID ONLY Changes navigation button theme, false == LIGHT
                loadingStatusBar: 'dark-content',

                screenBg: colors.white,         // App Screen/Page colors ------------
                screenAltBg: colors.lightGrey,
                screenText: colors.darkGrey,
                screenAltText: colors.darkGrey,
                screenBorder: colors.translucentBlack,
                screenFade: colors.translucentWhite,
                screenTitle: colors.translucentBlack,
                onboardingOverlay: colors.translucentBlackDarker,
                transparent: colors.transparent,

                actionBg: colors.darkGrey,       // Button colors ---------------------
                actionText: colors.white,
                actionSuccess: colors.darkGrey,
                actionBgs: [colors.darkGrey, colors.darkGrey, colors.darkGrey, colors.darkGrey],
                actionLightBgs: [colors.translucentBlack, colors.translucentBlack, colors.translucentBlack, colors.translucentBlack],

                chatBotBg: colors.white,          // Chat ----------------------------
                chatBotText: colors.darkGrey,
                chatBotAltText: colors.darkGrey,
                chatBotTextAccent: colors.darkGrey,
                chatUserDefault: colors.darkGrey,
                chatUserText: colors.white,
                chatUserAltText: colors.white,
            }
        default: return null
    }
}

export const fonts = {
    native: {
        default: 'OpenSans-SemiBold',
        light: 'OpenSans-Regular',
        bold: 'OpenSans-Bold',
        decorative: 'Montserrat-Medium',
        subHeading: 'Montserrat-SemiBold',
        heading: 'Montserrat-Bold'
    },
    web: {
        default: 'Open Sans',
        heading: 'Montserrat',
    },
    weight: {
        default: 400,
        medium: 500,
        semiBold: 600,
        bold: 700
    },
    size: {
        small: 12,
        dashboard: 12.5,
        default: 14,
        large: 16,
        subHeading: 18,
        heading: 22
    }
}

/*
export const colors = {
    basic: {
        name: 'Default - Light',
        actionButton: {
            bgColor: '#D70826',
            bgColors: ['#D70826', '#921C53', '#CC1F4B', '#5C2C52', '#333333'],
            textColor: '#FFFFFF',
        },
        botChat: {
            bgColor: '#FFFFFF',
            textColor: '#222222',
            dateTimeColor: '#898989',
            arrowNavigationColor: '#949494',
        },
        userChat: {
            bgColor: '#D70826',
            textColor: '#FFFFFF',
            dateTimeColor: '#F3B7C0'
        },
        input: {
            bgColor: '#FFFFFF',
            textColor: '#222222',
            placeholderColor: '#666666',
            borderColor: '#CCCCCC',
        },
        mainChat: {
            bgColor: '#F0F0F0',
            keyboard: 'light'
        },
        loginScreen: {
            bgColor: '#DDDDDD'
        },
        sideMenu: {
            bgColor: '#FFFFFF',
            iconColors: ['#666666', '#CC1F4B', '#5C2C52', '#D70826', '#921C53']
        },
        modals: {
            titleColors: ['#5C2C52', '#CC1F4B', '#921C53', '#222222', '#D70826'],
            subTitleColors: ['#5C2C52', '#CC1F4B', '#921C53', '#222222', '#D70826'],
            titleTextColor: '#FFFFFF',
            subTitleTextColor: '#FFFFFF',
            bgColor: '#FFFFFF',
            textColor: '#222222',
            altTextColor: '#898989',
            iconColors: ['#5C2C52', '#CC1F4B', '#921C53', '#D70826', '#666666'],
            borderColor: 'rgba(0,0,0,0.2)',
            suggestions: {
                bgColors: ['#5C2C52', '#921C53'],
                textColor: '#FFFFFF',
                alternateBgColor: '#CC1F4B',
                alternateTextColor: '#FFFFFF'
            },
            moodColors: ['#01947D', '#37606C', '#5C2C52', '#921C53', '#CC1F4B', 'rgba(0,0,0,0.2)'],
            linkColor: '#C10722',
            toggleColors: ['#2FA530', '#888888'],
            warningBgColor: 'rgba(255,255,255,0.7)',
            achievementInfoBgColor: 'rgba(255,255,255,0.9)',
            achievementTextColors: {
                [achievementLevels.special]: '#5C2C52',
                [achievementLevels.bronze]: '#C05B39',
                [achievementLevels.silver]: '#948582',
                [achievementLevels.gold]: '#CF8630',
                [achievementLevels.constellation]: '#242E47'
            },
            achievementConfettiColors: {
                [achievementLevels.special]: ['#D70826', '#5C2C52', '#921C53', '#CC1F4B', '#221F1F', '#C1C1C1'],
                [achievementLevels.bronze]: ['#EDAA6C', '#D66640', '#7C3848', '#9B4944', '#DD8956'],
                [achievementLevels.silver]: ['#E1CCC5', '#A59491', '#615773', '#716577', '#96858C'],
                [achievementLevels.gold]: ['#E0CB9E', '#E69536', '#AE673B', '#BD7539', '#E3B46F'],
                [achievementLevels.constellation]: ['#242E47', '#CFDBE1', '#3E4F7A', '#A0B8C4', '#6B92A9']
            },
            constellationBgColor: '#242E47',
            constellationTitleColor: '#FFFFFF',
            constellationTextColor: '#CFDBE1'
        },
        statusBar: {
            chat: 'dark-content',
            modal: 'light-content'
        }
    },
    dark: {
        name: 'Default - Dark',
        actionButton: {
            bgColor: '#D70826',
            bgColors: ['#F0092A', '#D22A78', '#DE2655', '#9A568B', '#707070'],
            textColor: '#FFFFFF',
        },
        botChat: {
            bgColor: '#222222',
            textColor: '#F0F0F0',
            dateTimeColor: '#707070',
            arrowNavigationColor: '#707070',
        },
        userChat: {
            bgColor: '#D70826',
            textColor: '#FFFFFF',
            dateTimeColor: '#F3B7C0'
        },
        input: {
            bgColor: '#222222',
            textColor: '#F0F0F0',
            placeholderColor: '#8A8A8A',
            borderColor: '#666666',
        },
        mainChat: {
            bgColor: '#111111',
            keyboard: 'dark'
        },
        loginScreen: {
            bgColor: '#333333'
        },
        sideMenu: {
            bgColor: '#222222',
            iconColors: ['#777777', '#DE2655', '#9A568B', '#F0092A', '#D22A78']
        },
        modals: {
            titleColors: ['#9A568B', '#DE2655', '#D22A78', '#666666', '#F0092A'],
            subTitleColors: ['#9A568B', '#DE2655', '#D22A78', '#666666', '#F0092A'],
            titleTextColor: '#FFFFFF',
            subTitleTextColor: '#FFFFFF',
            bgColor: '#111111',
            textColor: '#F0F0F0',
            altTextColor: '#707070',
            iconColors: ['#9A568B', '#DE2655', '#D22A78', '#F0092A', '#777777'],
            borderColor: 'rgba(255,255,255,0.2)',
            suggestions: {
                bgColors: ['#9A568B', '#D22A78'],
                textColor: '#FFFFFF',
                alternateBgColor: '#DE2655',
                alternateTextColor: '#FFFFFF'
            },
            moodColors: ['#01AD92', '#388395', '#9A568B', '#D22A78', '#DE2655', 'rgba(255,255,255,0.2)'],
            linkColor: '#F0092A',
            toggleColors: ['#2FA530', '#888888'],
            warningBgColor: 'rgba(34,34,34,0.7)',
            achievementInfoBgColor: 'rgba(34,34,34,0.9)',
            achievementTextColors: {
                [achievementLevels.special]: '#B780AC',
                [achievementLevels.bronze]: '#CE785B',
                [achievementLevels.silver]: '#958682',
                [achievementLevels.gold]: '#CA8C1C',
                [achievementLevels.constellation]: '#CFDBE1'
            },
            achievementConfettiColors: {
                [achievementLevels.special]: ['#D70826', '#5C2C52', '#921C53', '#CC1F4B', '#221F1F', '#C1C1C1'],
                [achievementLevels.bronze]: ['#EDAA6C', '#D66640', '#7C3848', '#9B4944', '#DD8956'],
                [achievementLevels.silver]: ['#E1CCC5', '#A59491', '#615773', '#716577', '#96858C'],
                [achievementLevels.gold]: ['#E0CB9E', '#E69536', '#AE673B', '#BD7539', '#E3B46F'],
                [achievementLevels.constellation]: ['#242E47', '#CFDBE1', '#3E4F7A', '#A0B8C4', '#6B92A9']
            },
            constellationBgColor: '#242E47',
            constellationTitleColor: '#FFFFFF',
            constellationTextColor: '#CFDBE1'
        },
        statusBar: {
            chat: 'light-content',
            modal: 'light-content'
        }
    },
    highContrast: {
        name: 'High Contrast',
        actionButton: {
            bgColor: '#222222',
            bgColors: ['#222222', '#222222', '#222222', '#222222', '#222222'],
            textColor: '#FFFFFF',
        },
        botChat: {
            bgColor: '#FFFFFF',
            textColor: '#222222',
            dateTimeColor: '#333333',
            arrowNavigationColor: '#888888',
        },
        userChat: {
            bgColor: '#FFFFFF',
            textColor: '#222222',
            dateTimeColor: '#333333'
        },
        input: {
            bgColor: '#FFFFFF',
            textColor: '#222222',
            placeholderColor: '#333333',
            borderColor: '#CCCCCC',
        },
        mainChat: {
            bgColor: '#F0F0F0',
            keyboard: 'light'
        },
        loginScreen: {
            bgColor: '#DDDDDD'
        },
        sideMenu: {
            bgColor: '#FFFFFF',
            iconColors: ['#222222', '#222222', '#222222', '#222222', '#222222']
        },
        modals: {
            titleColors: ['#222222', '#222222', '#222222', '#222222', '#222222'],
            subTitleColors: ['#999999', '#999999', '#999999', '#999999', '#999999'],
            titleTextColor: '#FFFFFF',
            subTitleTextColor: '#000000',
            bgColor: '#FFFFFF',
            textColor: '#222222',
            altTextColor: '#333333',
            iconColors: ['#222222', '#222222', '#222222', '#222222', '#222222'],
            borderColor: 'rgba(0,0,0,0.5)',
            suggestions: {
                bgColors: ['#999999'],
                textColor: '#000000',
                alternateBgColor: '#222222',
                alternateTextColor: '#FFFFFF'
            },
            moodColors: ['#01947D', '#37606C', '#5C2C52', '#921C53', '#CC1F4B', 'rgba(0,0,0,0.5)'],
            linkColor: '#A8061E',
            toggleColors: ['#222222', '#999999'],
            warningBgColor: 'rgba(255,255,255,0.7)',
            achievementInfoBgColor: 'rgba(255,255,255,0.9)',
            achievementTextColors: {
                [achievementLevels.special]: '#222222',
                [achievementLevels.bronze]: '#222222',
                [achievementLevels.silver]: '#222222',
                [achievementLevels.gold]: '#222222',
                [achievementLevels.constellation]: '#222222'
            },
            achievementConfettiColors: {
                [achievementLevels.special]: ['#D70826', '#5C2C52', '#921C53', '#CC1F4B', '#221F1F', '#C1C1C1'],
                [achievementLevels.bronze]: ['#EDAA6C', '#D66640', '#7C3848', '#9B4944', '#DD8956'],
                [achievementLevels.silver]: ['#E1CCC5', '#A59491', '#615773', '#716577', '#96858C'],
                [achievementLevels.gold]: ['#E0CB9E', '#E69536', '#AE673B', '#BD7539', '#E3B46F'],
                [achievementLevels.constellation]: ['#242E47', '#CFDBE1', '#3E4F7A', '#A0B8C4', '#6B92A9']
            },
            constellationBgColor: '#242E47',
            constellationTitleColor: '#FFFFFF',
            constellationTextColor: '#CFDBE1'
        },
        statusBar: {
            chat: 'dark-content',
            modal: 'light-content'
        }
    }
}
*/