import React from 'react';
import PropTypes from 'prop-types';
import { RowPrimitive } from './RowPrimitive'

export const Row = ({ children, ...props }) => {
    return <RowPrimitive {...props}>{children}</RowPrimitive>
}

Row.propTypes = {
    display: PropTypes.oneOf(['flex', 'none']),
    size: PropTypes.number,
    flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    flexWrap: PropTypes.oneOf(['wrap', 'nowrap']),
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
};

Row.defaultProps = {
    display: 'flex',
    size: 12,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
};
