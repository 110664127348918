import { NotificationTypes } from 'redux/lib/notification/NotificationTypes';

export class NotificationActions {
    static addNotification = notification => {
        return { type: NotificationTypes.ADD_NOTIFICATION, payload: notification }
    }
    static addNotifications = notifications => {
        return { type: NotificationTypes.ADD_NOTIFICATIONS, payload: notifications }
    }
    static removeNotification = notificationId => {
        return { type: NotificationTypes.REMOVE_NOTIFICATION, payload: notificationId }
    }
    static removeNotifications = notificationIds => {
        return { type: NotificationTypes.REMOVE_NOTIFICATIONS, payload: notificationIds }
    }
    static removeAllNotifications = () => {
        return { type: NotificationTypes.REMOVE_ALL_NOTIFICATIONS, payload: null }
    }
}
