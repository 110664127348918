import moment from 'moment-timezone'

export const filterTimetableData = (data, currentWeek) => {
    if (data) {
        let filteredData = []
        let lastDateWithData = currentWeek
        for (let i = 0; i < 7; i++) {
            let currentDate = currentWeek.clone().add(i, 'days')
            let matchedData = data.filter(item => moment(item.eventStartDate).isSame(currentDate, 'day'))
            if (matchedData.length || moment().isSame(currentDate, 'day') ) lastDateWithData = currentDate
            filteredData.push({ date: currentDate, data: matchedData })
        }
        return { filteredData, lastDateWithData }
    }
    return null
}

export const createDateUrls = (date) => {
    const dateInfo = date.split('-')
    const currentWeek = moment.utc(`${dateInfo[2]}-${dateInfo[1]}-1`, 'YYYY-W-d')
    const prevWeek = currentWeek.clone().subtract(1, 'week')
    const nextWeek = currentWeek.clone().add(1, 'week')
    const prevWeekUrl = `week-${prevWeek.week()}-${prevWeek.week() > dateInfo[1] ? parseInt(dateInfo[2]) - 1 : dateInfo[2]}`
    const nextWeekUrl = `week-${nextWeek.week()}-${nextWeek.week() < dateInfo[1] ? parseInt(dateInfo[2]) + 1 : dateInfo[2]}`
    return { currentWeek, prevWeekUrl, nextWeekUrl }
}

export const getChunk = (date) => {
    const currentWeek = date.week()
    const chunk = Math.floor((currentWeek - 1)/4) * 4 + 1
    const chunkStart = date.clone().week(chunk)
    // If looking at future timetable but happen to be at the border
    if (currentWeek == chunk && moment().add(4, 'weeks').week() == chunk) {
        return { chunk: `week${chunk - 4}`, start: chunkStart.clone().subtract(4, 'weeks').toISOString(), end: chunkStart.clone().add(1, 'week').toISOString() }
    }
    return { chunk: `week${chunk}`, start: chunkStart.toISOString(), end: chunkStart.clone().add(5, 'weeks').toISOString() }
}