import { useHistory } from './'
import { useSelector, useDispatch } from 'react-redux'
import { RedirectActions } from 'redux/actions';
import { UserHelper } from 'redux/helpers'
import { Namespaces } from 'redux/namespaces';

export const ReduxRedirect = props => {

    const history = useHistory()
    const isLoggedIn = UserHelper.isLoggedIn()
    const dispatch = useDispatch()
    const redirectRoute = useSelector(state => state[Namespaces.REDIRECT].redirectRoute);
    if (isLoggedIn && redirectRoute) {
        history.push(redirectRoute)
        dispatch(RedirectActions.redirectCompleted())
    }
    return null;

}


