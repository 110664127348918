import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { SwitchToggle } from 'components/general'
import { Container, Text, Button } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const SettingsToggle = ({ accessibility, isActive, isDisabled, isLoading, toggleFunc, text, description }) => {
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()

    const handleAction = () => {
        if (!isLoading && hasInternet && !isDisabled) {
            toggleFunc()
        }
    }

    return (
        <Button
            accessibility={accessibility}
            disable={!hasInternet}
            handleAction={handleAction}
            flex={0}
            flexDirection={'row'}
            alignItems={'center'}
            textAlign={'left'}
            padding={10}
            borderBottom={`2px solid ${colors.screenAltBg}`}
            borderBottomWidth={2}
            borderColor={colors.screenAltBg}
        >
            <Container paddingRight={5}>
                <Text
                    color={colors.screenText}
                    marginRight={'auto'}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                >
                    {text}
                </Text>
                {description != null &&
                    <Text color={colors.screenAltText}>
                        {description}
                    </Text>
                }
            </Container>
            <SwitchToggle
                active={isActive}
                toggleValue={toggleFunc}
                isLoading={isLoading}
                size={30}
            />
        </Button>
    )
}