import React from 'react'
import { Svg, Circle, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const StaffDirectoryIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'staff directory icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'staff directory icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M14.68,8.77c1.46,0,2.64,1.18,2.64,2.64c0,0.76-0.32,1.43-0.83,1.92c1.46,1,2.48,3.17,2.48,5.69H10.4
                c0-2.52,1.02-4.69,2.48-5.69c-0.51-0.48-0.83-1.16-0.83-1.92C12.05,9.95,13.23,8.77,14.68,8.77 M14.68,6.77
                c-2.56,0-4.64,2.08-4.64,4.64c0,0.55,0.1,1.09,0.29,1.6c-1.22,1.53-1.93,3.68-1.93,6.01c0,1.1,0.9,2,2,2h8.57c1.1,0,2-0.9,2-2
                c0-2.32-0.71-4.48-1.93-6.01c0.19-0.51,0.29-1.05,0.29-1.6C19.32,8.85,17.24,6.77,14.68,6.77L14.68,6.77z"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="14.66" cy="14.54" r="10.92"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:4,strokeLinecap:'round',strokeMiterLimit:10}} x1="23.4" y1="23.66" x2="30.41" y2="30.67"/>
        </Svg>
    )
}