import { RedirectTypes } from 'redux/lib/redirect/RedirectTypes'

export class RedirectActions {
    static addRedirectRoute = url => {
        return { type: RedirectTypes.ADD_REDIRECT_ROUTE, payload: url }
    }
    static redirectCompleted = () => {
        return { type: RedirectTypes.REDIRECT_COMPLETED, payload: {} }
    }


}
