import React from 'react';
import PropTypes from 'prop-types';
import { LinkPrimitive } from './LinkPrimitive'

export const Link = ({ children, ...props }) => {
    return <LinkPrimitive {...props}>{children}</LinkPrimitive>
}

Link.propTypes = {
    children: PropTypes.any.isRequired,
    to: PropTypes.string.isRequired,
    isExternal: PropTypes.bool,
    flex: PropTypes.number,
    flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    flexWrap: PropTypes.oneOf(['wrap', 'nowrap']),
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
    alignSelf: PropTypes.string,
    padding: PropTypes.number,
    paddingLeft: PropTypes.number,
    paddingRight: PropTypes.number,
    paddingTop: PropTypes.number,
    paddingBottom: PropTypes.number,
    activeOpacity: PropTypes.number,
};

Link.defaultProps = {
    to: "",
    isExternal: false,
    flex: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
};
