import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { SettingsHelper, UserHelper, ChatHelper } from 'redux/helpers'
import { FetchRequest, Keyboard, UpdateHelper } from 'lib/general'
import { features } from 'lib/general/enums'

import { ScreenContainer, LoadingSpinner, StyledButton, LoadingData } from 'components/general'
import { Container, Text, Button, InputText } from 'primitives'
import { Icon, icons } from 'assets/MenuIcons'
import { useParams, useHistory, routes } from 'navigation'
import { fonts } from 'lib/config/ui-config'
import { BackBar, OfflineBar } from 'components/bars'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { Platform } from 'lib/primitives'

const Statuses = {
    attended: 1,
    absent: 2,
    none: 3
}

export const TimetableEvent = () => {
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()
    const isWeb = Platform.isWeb()
    const history = useHistory()
    const { eventId } = useParams()
    const isStudent = UserHelper.getIsStudent()
    const isAttendanceEnabled = UserHelper.isFeatureEnabled(features.attendance)
    const isFeedbackEnabled = UserHelper.isFeatureEnabled(features.lectureFeedBack)
    const isChatVisible = ChatHelper.getIsChatVisible()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [updates, setUpdates] = useState({})
    const [showUpdate, setShowUpdate] = useState(false)
    const getAttendanceForEvent = FetchRequest.useGetAttendanceForEvent()
    const staffCheckInStudents = FetchRequest.useStaffCheckInStudents()

    const { updateItems, awaitingUpdate, awaitingRefresh } = UpdateHelper(data && data.attendees ? data.attendees : null, 'username', () => requestEventData())

    const requestEventData = () => {
        setError(false)
        setLoading(true)
        getAttendanceForEvent(eventId)
            .then(res => {
                if (res.error) {
                    setError(true)
                }
                else {
                    setData(res)
                }
                setLoading(false)
            })
            .catch(e => {
                console.log(e)
                setError(true)
                setLoading(false)
            })
    }

    // Fetch initial event data
    useEffect(() => {
        if (isStudent === null || !eventId || isStudent) {
            return null
        }
        else {
            return requestEventData()
        }
    }, [isStudent])

    if (isStudent === null) return <LoadingData backgroundColor={colors.screenBg} />
    if (!eventId || isStudent) return history.push(routes.timetable.default)

    // Post attendance updates and cache update info
    const submitChanges = () => {
        if (hasInternet && showUpdate) {
            const payload = {
                studentAttendances: Object.values(updates)
            }
            updateItems(Object.keys(updates), async () => {
                return await staffCheckInStudents(eventId, payload)
            })
            setUpdates({})
            setShowUpdate(false)
        }
    }

    return (
        <ScreenContainer alignItems={'center'}>
            <BackBar />
            <OfflineBar />
            {isWeb && isAttendanceEnabled && data != null && <EventDataOptions colors={colors} isWeb={isWeb} showUpdate={showUpdate} requestEventData={requestEventData} submitChanges={submitChanges} hasInternet={hasInternet} />}
            {loading && Object.values(awaitingRefresh).length == 0 && <LoadingSpinner size={40} color={colors.screenAltText} />}
            <Container
                flex={1}
                flexGrow={1}
                minHeight={0}
                width={'100%'}
                alignItems={'center'}
                position={'relative'}
                webOverflow={'auto'}
                nativeOverflow={'hidden'}
                viewType={'ScrollView'}
                isChat={isChatVisible}
                onRefresh={hasInternet ? requestEventData : null}
            >
                <Container
                    flex={0}
                    padding={10}
                    maxWidth={600}
                >
                    <EventContent
                        data={data}
                        error={error}
                        colors={colors}
                        updates={updates}
                        setUpdates={setUpdates}
                        setShowUpdate={setShowUpdate}
                        requestEventData={requestEventData}
                        awaitingUpdate={awaitingUpdate}
                        awaitingRefresh={awaitingRefresh}
                        isAttendanceEnabled={isAttendanceEnabled}
                        isFeedbackEnabled={isFeedbackEnabled}
                    />
                </Container>
            </Container>
            {!isWeb && isAttendanceEnabled && data != null && <EventDataOptions colors={colors} isWeb={isWeb} showUpdate={showUpdate} requestEventData={requestEventData} submitChanges={submitChanges} hasInternet={hasInternet} />}
        </ScreenContainer>
    )
}

const EventDataOptions = ({ colors, isWeb, showUpdate, requestEventData, submitChanges, hasInternet }) => {
    return (
        <Container
            flex={0}
            width={'100%'}
            maxWidth={600}
            alignItems={'center'}
            justifyContent={'center'}
            padding={5}
            opacity={!hasInternet ? 0.5 : null}
            flexDirection={'row'}
            borderTop={isWeb ? undefined : `2px solid ${colors.screenBorder}`}
            borderTopWidth={isWeb ? undefined : 2}
            borderColor={colors.screenBorder}
            borderBottom={isWeb ? `2px solid ${colors.screenBorder}` : undefined}
            borderBottomWidth={isWeb ? 2 : undefined}
        >
            <Container flex={1}>
                <StyledButton
                    title={'Refresh'}
                    accessibility={{ role: 'button', label: 'refresh page' }}
                    bgColor={colors.actionBg}
                    textColor={colors.actionText}
                    handleAction={requestEventData}
                    margin={0}
                />
            </Container>
            {!isWeb && <Container flex={0} flexShrink={1} width={30} />}
            <Container flex={1} opacity={showUpdate ? 1 : 0.5}>
                <StyledButton
                    title={'Submit'}
                    accessibility={{ role: 'button', label: 'submit attendance changes' }}
                    bgColor={colors.actionSuccess}
                    textColor={colors.actionText}
                    handleAction={submitChanges}
                    margin={0}
                />
            </Container>
        </Container>
    )
}

const EventContent = ({ data, error, colors, updates, setUpdates, setShowUpdate, requestEventData, awaitingUpdate, awaitingRefresh, isAttendanceEnabled, isFeedbackEnabled }) => {

    if (error) {
        return (
            <Container flex={0} width={'100%'}>
                <Container
                    flex={0}
                    padding={10}
                    alignItems={'center'}
                    justifyContent={'center'}
                    width={'100%'}
                    maxWidth={600}
                    flexDirection={'row'}
                >
                    <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
                    <Container flex={1} paddingLeft={5}>
                        <Text color={colors.screenAltText}>Sorry, something appears to have gone wrong.</Text>
                    </Container>
                </Container>
                <StyledButton
                    title={'Refresh'}
                    accessibility={{ role: 'button', label: 'refresh page' }}
                    bgColor={colors.actionBg}
                    textColor={colors.actionText}
                    handleAction={requestEventData}
                    margin={0}
                />
            </Container>
        )
    }

    if (!data) return null

    return (
        <Container flex={0}>
            <Container flex={0} >
                <Text
                    color={colors.screenText}
                    nativeFontFamily={fonts.native.heading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.bold}
                    textAlign={'center'}
                    padding={10}
                >
                    {data.description}
                </Text>
                <Text
                    color={colors.screenAltText}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                    textAlign={'center'}
                    padding={5}
                >
                    {moment(data.eventStartDate).format('DD MMMM YYYY, HH:mm')} - {moment(data.eventEndDate).format('HH:mm')}
                </Text>
                <Text
                    color={colors.screenAltText}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                    textAlign={'center'}
                    padding={5}
                >
                    {data.locationDescription}
                </Text>
            </Container>
            {isFeedbackEnabled && <FeedbackView
                data={data}
                colors={colors}
            />}
            {isAttendanceEnabled && <AttendanceView
                data={data}
                colors={colors}
                updates={updates}
                setUpdates={setUpdates}
                setShowUpdate={setShowUpdate}
                awaitingUpdate={awaitingUpdate}
                awaitingRefresh={awaitingRefresh}
            />}
        </Container>
    )
}

const FeedbackView = ({ data, colors }) => {
    if (!data || !data.averageEventRating) return null

    return (
        <Container
            flex={0}
            marginTop={5}
            paddingTop={5}
            borderTop={`2px solid ${colors.screenBorder}`}
            borderTopWidth={2}
            borderColor={colors.screenBorder}
        >
            <Container
                flex={0}
                flexDirection={'row'}
                flexWrap={'wrap'}
                justifyContent={'center'}
                paddingTop={10}
                paddingBottom={10}
            >
                <Container flex={0}>
                    <Text
                        color={colors.screenText}
                        nativeFontFamily={fonts.native.subHeading}
                        webFontFamily={fonts.web.heading}
                        fontWeight={fonts.weight.semiBold}
                        textAlign={'center'}
                        padding={5}
                    >
                        Average rating:
                </Text>
                </Container>
                <Container flex={0} flexDirection={'row'}>
                    <Icon type={data.averageEventRating >= 0.5 ? icons.star : icons.starEmpty} color={colors.screenAltText} size={30} />
                    <Icon type={data.averageEventRating >= 1.5 ? icons.star : icons.starEmpty} color={colors.screenAltText} size={30} />
                    <Icon type={data.averageEventRating >= 2.5 ? icons.star : icons.starEmpty} color={colors.screenAltText} size={30} />
                    <Icon type={data.averageEventRating >= 3.5 ? icons.star : icons.starEmpty} color={colors.screenAltText} size={30} />
                    <Icon type={data.averageEventRating >= 4.5 ? icons.star : icons.starEmpty} color={colors.screenAltText} size={30} />
                    <Text
                        color={colors.screenText}
                        nativeFontFamily={fonts.native.subHeading}
                        webFontFamily={fonts.web.heading}
                        fontWeight={fonts.weight.semiBold}
                        textAlign={'center'}
                        padding={5}
                    >
                        {data.averageEventRating.toFixed(1)}/5
                </Text>
                </Container>
            </Container>
        </Container>
    )
}

const AttendanceView = ({ data, colors, updates, setUpdates, setShowUpdate, awaitingUpdate, awaitingRefresh }) => {
    const [search, setSearch] = useState('')
    const [filterNotSubmitted, setFilterNotSubmitted] = useState(false)
    const [attendees, setAttendees] = useState(data.attendees)

    useEffect(() => {
        filterAttendees(search, filterNotSubmitted)
    }, [data.attendees, filterNotSubmitted])

    const filterAttendees = (text, nonSubmitted) => {
        if (data.attendees && text.length > 0) {
            let pattern = new RegExp(text.toLowerCase())
            let filteredSearch = data.attendees.filter(attendee => pattern.test(attendee.forename.toLowerCase()) || pattern.test(attendee.familyName.toLowerCase()) || pattern.test(attendee.username.toLowerCase()))
            if (nonSubmitted) {
                filteredSearch = filteredSearch.filter(attendee => attendee.attendanceStatus != 'SCORE_TOO_LOW' && attendee.attendanceStatus != 'ATTENDED' && attendee.attendanceStatus != 'ABSENT')
            }
            setAttendees(filteredSearch)
        }
        else if (nonSubmitted) {
            let filteredAttendees = data.attendees.filter(attendee => attendee.attendanceStatus != 'SCORE_TOO_LOW' && attendee.attendanceStatus != 'ATTENDED' && attendee.attendanceStatus != 'ABSENT')
            setAttendees(filteredAttendees)
        }
        else {
            setAttendees(data.attendees)
        }
    }

    return (
        <Container
            flex={0}
            marginTop={5}
            paddingTop={5}
            borderTop={`2px solid ${colors.screenBorder}`}
            borderTopWidth={2}
            borderColor={colors.screenBorder}
        >
            {data != null && data.attendanceCode != null &&
                <React.Fragment>
                    <Container flex={0} padding={5}>
                        <Text
                            color={colors.screenAltText}
                            textAlign={'center'}
                            padding={5}
                        >
                            Students can use the following code to confirm their attendance:
                </Text>
                    </Container>
                    <Container
                        flex={0}
                        border={`2px solid ${colors.actionSuccess}`}
                        borderColor={colors.actionSuccess}
                        borderWidth={2}
                    >
                        <Text
                            color={colors.screenText}
                            nativeFontFamily={fonts.native.subHeading}
                            webFontFamily={fonts.web.heading}
                            fontWeight={fonts.weight.semiBold}
                            fontSize={fonts.size.heading}
                            lineHeight={fonts.size.heading * 1.5}
                            textAlign={'center'}
                            padding={5}
                        >
                            {data.attendanceCode}
                        </Text>
                    </Container>
                </React.Fragment>}
            {data != null && data.attendees != null && data.attendees.length > 0 && <Container flex={0} padding={5}>
                <Text
                    color={colors.screenAltText}
                    textAlign={'center'}
                    padding={5}
                >
                    Attendance status can be manually updated below. Please note, it can take up to 5 minutes to reflect any changes made.
                </Text>
            </Container>}
            {data != null && data.attendees != null && data.attendees.length >= 8 && <SearchBar
                colors={colors}
                search={search}
                setSearch={setSearch}
                attendees={data.attendees}
                setAttendees={setAttendees}
                filterAttendees={filterAttendees}
                filterNotSubmitted={filterNotSubmitted}
                setFilterNotSubmitted={setFilterNotSubmitted}
            />}
            {attendees != null && attendees.map(attendee => <AttendeeStatus
                data={attendee}
                key={attendee.username}
                colors={colors}
                updates={updates}
                setUpdates={setUpdates}
                setShowUpdate={setShowUpdate}
                isLoading={awaitingUpdate[attendee.username] != null || awaitingRefresh[attendee.username] != null}
            />)}
        </Container>
    )
}

const SearchBar = ({ colors, search, setSearch, attendees, filterAttendees, filterNotSubmitted, setFilterNotSubmitted }) => {
    return (
        <Container
            flex={0}
            marginBottom={10}
            flexDirection={'row'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Container
                flex={1}
                padding={5}
                border={`2px solid ${colors.screenBorder}`}
                borderColor={colors.screenBorder}
                borderWidth={2}
                flexDirection={'row'}
            >
                <InputText
                    name={'search students'}
                    display={'flex'}
                    flex={1}
                    width={'100%'}
                    padding={5}
                    margin={0}
                    color={colors.screenText}
                    backgroundColor={colors.screenBg}
                    value={search}
                    placeholder={'Search students...'}
                    placeholderTextColor={colors.screenBorder}
                    onChange={text => {
                        setSearch(text)
                        filterAttendees(text, filterNotSubmitted)
                    }}
                />
                <Button
                    accessibility={{ role: 'button', label: 'remove search terms' }}
                    handleAction={() => {
                        setSearch('')
                        filterAttendees('', filterNotSubmitted)
                        Keyboard.dismiss()
                    }}
                    flex={0}
                    padding={5}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-start'}
                >
                    <Icon type={search ? icons.cancelActive : icons.search} color={search ? colors.screenAltText : colors.screenAltBg} size={25} />
                </Button>
            </Container>
            <Container flex={0} paddingLeft={5}>
                <StyledButton
                    title={filterNotSubmitted ? 'Show all' : 'Show not submitted'}
                    accessibility={{ role: 'button', label: 'toggle filter students that have not submitted attendance' }}
                    bgColor={colors.actionBg}
                    textColor={colors.actionText}
                    handleAction={() => setFilterNotSubmitted(!filterNotSubmitted)}
                    margin={0}
                />
            </Container>
        </Container>
    )
}

const AttendeeStatus = ({ data, colors, updates, setUpdates, setShowUpdate, isLoading }) => {
    const [initialStatus, setInitialStatus] = useState(getStatus())
    const [status, setStatus] = useState(getStatus())
    const [updated, setUpdated] = useState(false)

    useEffect(() => {
        setInitialStatus(getStatus())
        setStatus(getStatus())
    }, [data.attendanceStatus])

    useEffect(() => {
        if (isLoading) setUpdated(false)
    }, [isLoading])

    function getStatus() {
        switch (data.attendanceStatus) {
            case 'SCORE_TOO_LOW':
            case 'ATTENDED': return Statuses.attended
            case 'ABSENT': return Statuses.absent
            default: return Statuses.none
        }
    }

    const updateStatus = () => {
        if (isLoading) return null
        let newStatus = status + 1
        if (newStatus > Statuses.none || initialStatus != Statuses.none && newStatus > Statuses.absent) newStatus = Statuses.attended
        setStatus(newStatus)
        setUpdated(true)
        let newUpdates = updates
        switch (newStatus) {
            case Statuses.attended:
                if (initialStatus != Statuses.attended) {
                    newUpdates[data.username] = {
                        StudentUsername: data.username,
                        HasCheckedIn: true
                    }
                }
                else {
                    setUpdated(false)
                    delete newUpdates[data.username]
                }
                break
            case Statuses.absent:
                if (initialStatus != Statuses.absent) {
                    newUpdates[data.username] = {
                        StudentUsername: data.username,
                        HasCheckedIn: false
                    }
                }
                else {
                    setUpdated(false)
                    delete newUpdates[data.username]
                }
                break
            default:
                if (initialStatus == Statuses.none) {
                    setUpdated(false)
                }
                delete newUpdates[data.username]
                break
        }
        setUpdates(newUpdates)
        if (Object.keys(newUpdates).length > 0) {
            setShowUpdate(true)
        }
        else {
            setShowUpdate(false)
        }
    }

    return (
        <Button
            padding={0}
            handleAction={updateStatus}
            display={'flex'}
            accessibility={{ role: 'button', label: `update attendance for ${data.username}, current status: ${status == Statuses.attended ? 'attended' : status == Statuses.absent ? 'absent' : 'not submitted'}` }}
        >
            <Container
                flex={1}
                flexDirection={'row'}
                alignItems={'center'}
                borderRadius={5}
                border={`2px ${updated ? 'dashed' : 'solid'} ${status == Statuses.attended ? colors.actionSuccess : status == Statuses.absent ? colors.actionBg : colors.screenAltBg}`}
                borderWidth={2}
                borderColor={status == Statuses.attended ? colors.actionSuccess : status == Statuses.absent ? colors.actionBg : colors.screenAltBg}
                borderStyle={updated ? 'dashed' : 'solid'}
                padding={10}
                marginBottom={5}
                opacity={isLoading ? 0.5 : null}
            >
                <Container flex={1}>
                    <Text color={colors.screenText}>{data.forename} {data.familyName}</Text>
                    <Text color={colors.screenAltText}>{data.username}</Text>
                </Container>
                <Container flex={0} marginLeft={'auto'} flexDirection={'row'}>
                    <Text
                        color={colors.screenAltText}
                        paddingRight={5}
                        fontSize={fonts.size.small}>
                        {isLoading ? 'Updating...' : status == Statuses.attended ? 'Present' : status == Statuses.absent ? 'Absent' : 'Not Submitted'}
                    </Text>
                    <Icon
                        type={status == Statuses.attended ? icons.successActive : status == Statuses.absent ? icons.cancelActive : icons.success}
                        color={status == Statuses.attended ? colors.actionSuccess : status == Statuses.absent ? colors.actionBg : colors.screenAltBg}
                        size={25}
                    />
                </Container>
            </Container>
        </Button>
    )
}