import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const NotificationsActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'notifications icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'notifications icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M29.63,24.73c-2.12-0.55-2.41-2.89-2.71-6.66c-0.36-4.53-0.85-10.62-7.93-12.02C18.99,6,19,5.96,19,5.92V5
                c0-0.83-0.67-1.5-1.5-1.5C16.67,3.5,16,4.17,16,5v0.92c0,0.04,0.01,0.09,0.01,0.13c-7.07,1.4-7.57,7.5-7.93,12.02
                c-0.3,3.77-0.59,6.11-2.71,6.66c-0.44,0.11-0.75,0.51-0.75,0.97v1.86c0,0.55,0.45,1,1,1h23.75c0.55,0,1-0.45,1-1V25.7
                C30.38,25.24,30.07,24.84,29.63,24.73z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.5,31.53c1.54,0,2.87-0.81,3.6-1.99H13.9C14.63,30.72,15.96,31.53,17.5,31.53z"/>
        </Svg>
    )
}