import React from 'react'
import { Column } from 'primitives'
import { features } from 'lib/general/enums'
import { icons } from 'assets/MenuIcons'
import { routes } from 'navigation'
import { TimetableTile } from 'components/timetable'
import { LinkTile, ChatTile } from './'

export const TileConfig = [
    {
        title: 'Timetable',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileTimetableUpcomingEvents,
        component: ({ width, tileWidth }) => {
            return (
                <Column key={'Timetable'} xs={12} sm={12} md={6} lg={6} xl={4.8} width={width}>
                    <TimetableTile tileWidth={tileWidth} />
                </Column>
            )
        }
    },
    {
        title: 'Beacon Chat',
        visibility: ['web', 'student', 'staff'],
        restrictedFeature: features.tileBeaconChat,
        component: ({ width, colors, tileWidth, screenXS }) => {
            if (!screenXS) return null
            return (
                <Column key={'Beacon Chat'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Beacon Chat'}
                        icon={icons.suggestions}
                        tileWidth={tileWidth}
                        linkTo={routes.chat.default}
                        colors={colors}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Timetable',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileTimetable,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'Timetable'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Timetable'}
                        icon={icons.timetable}
                        tileWidth={tileWidth}
                        linkTo={routes.timetable.default}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Emails',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileEmail,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'Emails'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Emails'}
                        icon={icons.email}
                        tileWidth={tileWidth}
                        linkTo={routes.external.outlook}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'e:Vision',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileEVision,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'e:Vision'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'e:Vision'}
                        icon={icons.eVision}
                        tileWidth={tileWidth}
                        linkTo={routes.external.evision}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'News and Events',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileNewsEvents,
        component: ({ width, colors, tileWidth, history, hasInternet }) => {
            return (
                <Column key={'News and Events'} autoHeight={true} width={width}>
                    <ChatTile
                        title={'News and Events'}
                        message={'News and events'}
                        icon={icons.newsAndEvents}
                        tileWidth={tileWidth}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'COVID-19',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileCovid19,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'COVID-19'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'COVID-19'}
                        icon={icons.news}
                        tileWidth={tileWidth}
                        linkTo={routes.webviews.covid19}
                        colors={colors}
                        hasInternet={hasInternet}
                        isWebView={true}
                    />
                </Column>
            )
        }
    },
    {
        title: 'SU Events',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileSUEvents,
        component: ({ width, colors, tileWidth, history, hasInternet }) => {
            return (
                <Column key={'SU Events'} autoHeight={true} width={width}>
                    <ChatTile
                        title={`SU Events`}
                        message={`What Students' Union events are on in the next 7 days?`}
                        icon={icons.suEvents}
                        tileWidth={tileWidth}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Societies',
        visibility: ['native', 'web', 'student'],
        restrictedFeature: features.tileSUSocieties,
        component: ({ width, colors, tileWidth, history, hasInternet }) => {
            return (
                <Column key={'Societies'} autoHeight={true} width={width}>
                    <ChatTile
                        title={'Societies'}
                        message={'What societies can I join?'}
                        icon={icons.societies}
                        tileWidth={tileWidth}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: `Students' Union`,
        visibility: ['native', 'web', 'student'],
        restrictedFeature: features.tileSU,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={`Students' Union`} autoHeight={true} width={width}>
                    <LinkTile
                        title={`Students' Union`}
                        icon={icons.studentsUnion}
                        tileWidth={tileWidth}
                        linkTo={routes.webviews.studentUnions}
                        colors={colors}
                        hasInternet={hasInternet}
                        isWebView={true}
                    />
                </Column>
            )
        }
    },
    {
        title: `Blackboard`,
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileBlackboard,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={`Blackboard`} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Blackboard'}
                        icon={icons.blackboard}
                        tileWidth={tileWidth}
                        linkTo={routes.external.blackboard}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Skill Site',
        visibility: ['native', 'web', 'staff'],
        restrictedFeature: features.tileSkillSiteStaff,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'Skill Site'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Skill Site'}
                        icon={icons.skillSite}
                        tileWidth={tileWidth}
                        linkTo={routes.external.skillSite}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Skill Site',
        visibility: ['native', 'web', 'student'],
        restrictedFeature: features.tileSkillSiteStudent,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'Skill Site'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Skill Site'}
                        icon={icons.skillSite}
                        tileWidth={tileWidth}
                        linkTo={routes.external.skillSite}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'IRIS',
        visibility: ['native', 'web', 'staff'],
        restrictedFeature: features.tileIris,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'IRIS'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'IRIS'}
                        icon={icons.iris}
                        tileWidth={tileWidth}
                        linkTo={routes.external.iris}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'MyView',
        visibility: ['native', 'web', 'staff'],
        restrictedFeature: features.tileMyView,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'MyView'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'MyView'}
                        icon={icons.myView}
                        tileWidth={tileWidth}
                        linkTo={routes.external.myView}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Workvivo',
        visibility: ['native', 'web', 'staff'],
        restrictedFeature: features.tileWorkvivo,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'Workvivo'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Workvivo'}
                        icon={icons.workvivo}
                        tileWidth={tileWidth}
                        linkTo={routes.external.workvivo}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Togetherall',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileTogetherall,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'Togetherall'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Togetherall'}
                        icon={icons.togetherall}
                        tileWidth={tileWidth}
                        linkTo={routes.external.togetherall}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Appointment',
        visibility: ['native', 'web', 'student'],
        restrictedFeature: features.tileAppointment,
        component: ({ width, colors, tileWidth, hasInternet }) => {
            return (
                <Column key={'Appointment'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'Appointment'}
                        icon={icons.appointmentBooking}
                        tileWidth={tileWidth}
                        linkTo={routes.external.appointmentBooking}
                        isExternal={true}
                        colors={colors}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'PC Availability',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tilePCAvailability,
        component: ({ width, colors, tileWidth }) => {
            return (
                <Column key={'PC Availability'} autoHeight={true} width={width}>
                    <LinkTile
                        title={'PC Availability'}
                        icon={icons.pcAvailability}
                        tileWidth={tileWidth}
                        linkTo={routes.webviews.pcAvailability}
                        colors={colors}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Staff Directory',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileStaffDirectory,
        component: ({ width, colors, tileWidth, history, hasInternet }) => {
            return (
                <Column key={'Staff Directory'} autoHeight={true} width={width}>
                    <ChatTile
                        title={'Staff Directory'}
                        message={'Staff directory'}
                        icon={icons.staffDirectory}
                        tileWidth={tileWidth}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Room Search',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileRoomSearch,
        component: ({ width, colors, tileWidth, history, hasInternet }) => {
            return (
                <Column key={'Room Search'} autoHeight={true} width={width}>
                    <ChatTile
                        title={'Room Search'}
                        message={'Room search'}
                        icon={icons.roomLocation}
                        tileWidth={tileWidth}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Contacts',
        visibility: ['native', 'web', 'student'],
        restrictedFeature: features.tileContacts,
        component: ({ width, colors, tileWidth, history, hasInternet }) => {
            return (
                <Column key={'Contacts'} autoHeight={true} width={width}>
                    <ChatTile
                        title={'Contacts'}
                        message={'Who are my contacts?'}
                        icon={icons.contacts}
                        tileWidth={tileWidth}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
    {
        title: 'Feedback & Ideas',
        visibility: ['native', 'web', 'student', 'staff'],
        restrictedFeature: features.tileFeedback,
        component: ({ width, colors, tileWidth, history, hasInternet }) => {
            return (
                <Column key={'Feedback & Ideas'} autoHeight={true} width={width}>
                    <ChatTile
                        title={'Feedback & Ideas'}
                        message={'Beacon feedback'}
                        icon={icons.suggestions}
                        tileWidth={tileWidth}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                    />
                </Column>
            )
        }
    },
]