import React from 'react'
import { Svg, Circle, Line, Polyline, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const MoodIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'mood icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'mood icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M16.38,29.19H7.41c-2.24,0-4.07-1.83-4.07-4.07V10.32c0-2.24,1.83-4.07,4.07-4.07h18.18
                c2.24,0,4.07,1.83,4.07,4.07v6.28"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="8.3" y1="3.74" x2="8.3" y2="8.51"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="24.45" y1="3.74" x2="24.45" y2="8.51"/>
            <Polyline scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} points="14.52,25.43 6.81,25.43 6.81,13.27 25.96,13.27 25.96,14.52     "/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="13.08" y1="13.27" x2="13.08" y2="25.43"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="19.67" y1="13.27" x2="19.67" y2="15.34"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="6.81" y1="19.35" x2="15.5" y2="19.35"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="23.42" cy="23.42" r="8.9"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="19.96" cy="21.28" r="1.54"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="26.88" cy="21.28" r="1.54"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M27.51,25.15c0,2.02-1.83,3.65-4.09,3.65s-4.09-1.64-4.09-3.65"/>
        </Svg>
    )
}