import React from 'react'
import { useSelector } from 'react-redux'
import Screen from 'lib/screen/screen'
import { getDefaultSizes }from 'lib/screen/screen'

export const ColumnPrimitive = ({children, xs, sm, md, lg, xl, parentWidth, parentSize, width, ...props}) => {
    // Hooks:
    const fullWidth = useSelector(state => state.runtime.width)
    const largerDashboardTiles = useSelector(state => state.settings.largerDashboardTiles)
    // Consts:
    const sizes = getDefaultSizes(largerDashboardTiles)
    const screenSize = Screen.getScreenSize(width ? width : fullWidth)
    const { tileWidth, tileHeight } = Screen.getTileSize(screenSize, xs ? xs : sizes.xs, sm ? sm : sizes.sm, md ? md : sizes.md, lg ? lg : sizes.lg, xl ? xl : sizes.xl, parentWidth, parentSize, largerDashboardTiles)

    let style = props
    style.flex = null
    style.width = tileWidth
    style.position = 'relative'
    style.paddingTop = props.autoHeight ?  tileHeight : 0

    return <div style={style}>{children}</div>
}