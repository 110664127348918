import React from 'react'
import { Svg, Circle, Polygon, Line } from 'primitives'
import { Platform } from 'lib/primitives'

export const SocietiesIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: `societies icon`
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': `societies icon`
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="18.7" cy="10.3" r="1.4"/>
            <Polygon scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.2,strokeLinejoin:'round',strokeMiterLimit:10}} points="17.7,12.9 14.4,9.9 16,13.9 14.8,20.7 18.4,14.7 22.6,12.9 "/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} cx="17.5" cy="14.7" r="9"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinejoin:'round',strokeMiterLimit:10}} cx="17.5" cy="29.5" r="2.5"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeMiterLimit:10}} x1="17.5" y1="27" x2="17.5" y2="23.6"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinejoin:'round',strokeMiterLimit:10}} cx="4.7" cy="7.3" r="2.5"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeMiterLimit:10}} x1="6.8" y1="8.5" x2="9.7" y2="10.2"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinejoin:'round',strokeMiterLimit:10}} cx="30.3" cy="7.3" r="2.5"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeMiterLimit:10}} x1="28.2" y1="8.5" x2="25.3" y2="10.2"/>
        </Svg>
    )
}