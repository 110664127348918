import React from 'react'
import { Svg, Circle, Polygon } from 'primitives'
import { Platform } from 'lib/primitives'

export const AchievementsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'achievement icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'achievement icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterlimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Polygon scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="17.5,7.6 20.67,13.03 26.96,14.47 22.63,19.06 23.35,25.59 17.5,22.79 11.65,25.59 12.37,19.06 
                8.04,14.47 14.33,13.03 "/>
        </Svg>
    )
}