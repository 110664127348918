import { SettingsTypes } from './SettingsTypes'
import { UserTypes } from 'redux/lib/user/UserTypes'
import { colorThemes } from 'lib/general/enums'
import { getColors } from 'lib/config/ui-config'
import { FetchReducerHelper, FetchReducerHelperInitialState } from 'redux/helpers/fetch'
import { Namespaces } from 'redux/namespaces'

export class SettingsFetchKeys {
    static interests = "interests"
    static notifications = "notifications"
    static qna = "qna"
}

const initialState = {
    theme: colorThemes.basic,
    backgroundColor: getColors(colorThemes.basic).appBg,
    disableSwipeEvents: false,
    disableAnimations: false,
    disableInAppNotifications: false,
    largerDashboardTiles: false,
    timezone: 'Europe/London',
    qnaResults: [],
    [SettingsFetchKeys.interests]: FetchReducerHelperInitialState,
    [SettingsFetchKeys.notifications]: FetchReducerHelperInitialState,
    [SettingsFetchKeys.qna]: FetchReducerHelperInitialState
}

export const SettingsReducer = (state = initialState, action) => {
    let fetchState = FetchReducerHelper.switch(state, action, Namespaces.SETTINGS, SettingsFetchKeys)
    if (fetchState) return fetchState

    switch (action.type) {

        case UserTypes.LOGOUT:
            return initialState

        case SettingsTypes.SET_COLOR_THEME:
            return Object.assign({}, state, {
                theme: action.payload
            })

        case SettingsTypes.SET_BACKGROUND_COLOR:
            return Object.assign({}, state, {
                backgroundColor: action.payload
            })

        case SettingsTypes.SET_DISABLE_SWIPE_EVENTS:
            return Object.assign({}, state, {
                disableSwipeEvents: action.payload
            })

        case SettingsTypes.SET_DISABLE_ANIMATIONS:
            return Object.assign({}, state, {
                disableAnimations: action.payload
            })

        case SettingsTypes.SET_DISABLE_IN_APP_NOTIFICATIONS:
            return Object.assign({}, state, {
                disableInAppNotifications: action.payload
            })

        case SettingsTypes.SET_ENABLE_LARGER_TILES:
            return Object.assign({}, state, {
                largerDashboardTiles: action.payload
            })

        case SettingsTypes.SET_QNA_RESULTS:
            return Object.assign({}, state, {
                qnaResults: action.payload
            })

        default:
            return state
    }
}