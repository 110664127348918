import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const CancelActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'cancel icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'cancel icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.5,1.68c-8.72,0-15.82,7.1-15.82,15.82s7.1,15.82,15.82,15.82c8.72,0,15.82-7.1,15.82-15.82
                S26.22,1.68,17.5,1.68z M24.19,21.37c0.78,0.78,0.78,2.05,0,2.83c-0.39,0.39-0.9,0.59-1.41,0.59s-1.02-0.2-1.41-0.59l-3.87-3.87
                l-3.87,3.87c-0.39,0.39-0.9,0.59-1.41,0.59s-1.02-0.2-1.41-0.59c-0.78-0.78-0.78-2.05,0-2.83l3.87-3.87l-3.87-3.87
                c-0.78-0.78-0.78-2.05,0-2.83c0.78-0.78,2.05-0.78,2.83,0l3.87,3.87l3.87-3.87c0.78-0.78,2.05-0.78,2.83,0
                c0.78,0.78,0.78,2.05,0,2.83l-3.87,3.87L24.19,21.37z"/>
        </Svg>
    )
}