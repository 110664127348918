import React from 'react'
import { Switch, useLocation } from './'
import { animated, useTransition } from 'react-spring'
import { Container } from 'primitives'
import { SettingsHelper } from 'redux/helpers'

export const AnimatedSwitch = ({ routes, ...props }) => {
    // const location = useLocation()
    const colors = SettingsHelper.getColors()
    // const transitions = useTransition(location, location => location.pathname, { ...props })

    return (
        <Container
            flex={1}
            backgroundColor={colors.screenBg}
            position={'relative'}
        >
            <Container position={'absolute'} top={0} bottom={0} left={0} right={0}>
                <Switch>
                    {routes}
                </Switch>
            </Container>
        </Container>
    )

    // return (
    //     <Container
    //         flex={1}
    //         backgroundColor={colors.screenBg}
    //         position={'relative'}
    //     >
    //         {transitions.map(({ item, props, key }) => (
    //             <animated.div key={key} style={{ ...props, display: 'flex', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
    //                 <Switch location={item}>
    //                     {routes}
    //                 </Switch>
    //             </animated.div>
    //         ))}
    //     </Container>
    // )
}