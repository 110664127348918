import React, { useState, useEffect, useRef } from 'react'
import { Container, Text } from 'primitives'
import { SettingsHelper, ChatHelper } from 'redux/helpers'
import { MessageItem } from './'
import { useSelector } from 'react-redux'
import { Namespaces } from 'redux/namespaces'
import { layoutDimensions, scrollToEnd } from 'lib/general'
import { fonts } from 'lib/config/ui-config'
import { Platform } from 'lib/primitives'
import { KeyboardListener } from 'redux/listeners'


export const MessageContainer = React.memo((props) => {
    const isWeb = Platform.isWeb()
    const accessibility = SettingsHelper.getAccessibilitySettings()
    const colors = SettingsHelper.getColors()
    const messages = useSelector(state => state[Namespaces.CHAT].messages)
    const dimensions = useSelector(state => state[Namespaces.CHAT].chatDimensions)
    const setDimensions = ChatHelper.useSetChatDimensions()
    const containerRef = useRef(null)
    const messageRef = useRef(null)

    useEffect(() => { layoutDimensions({setDimensions, ref: containerRef}) }, [])
    useEffect(() => {
        scrollTimeout()
    }, [messages, containerRef])

    const scrollTimeout = () => {
        if (messages.length) {
            const id = setTimeout(() => scrollToEnd(messageRef, containerRef, accessibility.disableAnimations), 120)
            return () => clearTimeout(id)
        }
        return null
    }

    return (
        <Container
            flex={1}
            minHeight={0}
            onLayout={event => {
                if (dimensions.height == 0 || dimensions.width == 0) {
                    layoutDimensions({setDimensions, ref: containerRef, event})
                }
            }}
            renderToHardwareTextureAndroid={true}
            shouldRasterizeIOS={true}
        >
            <KeyboardListener
                onShow={() => scrollTimeout()}
                onHide={() => scrollTimeout()}
                useIOS={false}
            />
            <Container
                flex={1}
                minHeight={0}
                webOverflow={'auto'}
                useRef={containerRef}
                viewType={'ScrollView'}
                isChat={isWeb}
                paddingLeft={7}
                paddingRight={7}
                hideScrollBar={true}
            >
                <Container flex={0} height={dimensions.height} minHeight={dimensions.height} padding={10} paddingBottom={20} alignItems={'center'} justifyContent={'flex-end'}>
                    <Text
                        color={colors.screenAltText}
                        padding={5}
                        textAlign={'center'}
                        nativeFontFamily={fonts.native.subHeading}
                        webFontFamily={fonts.web.heading}
                        fontWeight={fonts.weight.semiBold}
                        fontSize={fonts.size.large}
                    >
                        Welcome to Beacon chat!
                        </Text>
                    <Text color={colors.screenAltText} padding={5} textAlign={'center'}>
                        Type any queries you might have into the box below, and Beacon will do its best to answer them.
                        </Text>
                </Container>
                {dimensions.width == 0 || dimensions.height == 0 ? null : messages.map((message, index) =>
                    <MessageItem
                        message={message}
                        latestMessage={messages[messages.length - 1]}
                        previousMessage={index > 0 ? messages[index - 1] : null}
                        nextMessage={index < messages.length - 1 ? messages[index + 1] : null}
                        width={isWeb ? dimensions.width - 10 : dimensions.width}
                        key={message._id}
                    />)}
                <IsTyping colors={colors} scrollTimeout={scrollTimeout} width={dimensions.width} height={dimensions.height}/>
                <Container flex={0} height={5} useRef={messageRef} />
            </Container>
        </Container>
    )
})

const IsTyping = ({ scrollTimeout, colors, width, height }) => {
    const isTyping = useSelector(state => state[Namespaces.CHAT].isTyping)

    useEffect(() => {
        if (isTyping) scrollTimeout()
    }, [isTyping])

    if (isTyping && width != 0 && height != 0) {
        return (
            <Container flex={0}>
                <Text color={colors.screenAltText} padding={5} textAlign={'left'} fontSize={fonts.size.small}>
                    Beacon is typing...
                    </Text>
            </Container>
        )
    }
    return null
}