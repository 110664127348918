import React from 'react'
import { Button, Text } from 'primitives'
import { fonts } from 'lib/config/ui-config'

export const StyledButton = props =>
    <Button
        borderRadius={15}
        padding={10}
        margin={props.margin != null ? props.margin : 10}
        flex={0}
        alignSelf={'center'}
        backgroundColor={props.bgColor}
        handleAction={props.handleAction}
        url={props.url}
        disable={props.disable}
        accessibility={props.accessibility}
    >
        <Text
            color={props.textColor}
            nativeFontFamily={fonts.native.subHeading}
            webFontFamily={fonts.web.heading}
            fontWeight={fonts.weight.semiBold}
        >
            {props.title}
        </Text>
    </Button >