import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general'
import { Container, Text } from 'primitives'
import { content, SuggestionsItem } from 'components/chat/suggestions'
import { BackBar } from 'components/bars'
import { OfflineBar } from 'components/bars'

export const Suggestions = props => {
  const colors = SettingsHelper.getColors()

  return (
    <ScreenContainer
      viewType={'ScrollView'}
      alignItems={'center'}
    >
      <BackBar />
      <OfflineBar/>
      <Container
        flex={0}
        padding={10}
        width={'100%'}
        maxWidth={600}
      >
        <Text color={colors.screenAltText} padding={5} textAlign={'center'}>
          Not sure what you can ask me?
          Tap on the examples below!
          </Text>
        <Text color={colors.screenAltText} padding={5} textAlign={'center'}>
          Remember, if you ever want to end a conversation, just say "stop" ✋
          </Text>
        {content.map(contentItem => <SuggestionsItem data={contentItem} key={contentItem.title} />)}
      </Container>
    </ScreenContainer>
  )
}