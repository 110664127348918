import { UserTypes } from 'redux/lib/user/UserTypes'
import { azureAD } from 'lib/auth/azureAD'

const initialState = {
    info: null,
    userInfo: null,
    studentInfo: null,
    showOnboarding: true,
    cannotLoadProfile: false
}
// {
//     loggingIn: true,
//     loggedIn: true,
//     authState: {
//         accessToken: null,
//         ...
//     },
//     info: {
//         id: null,
//         username: null,
//         email: null,
//         name: null,
//         token: null,
//         deviceId: null,
//         deviceOS: null,
//     }
// }


export const UserReducer = (state = initialState, action) => {
    switch (action.type) {

        case UserTypes.LOGIN_ATTEMPT:
            return Object.assign({}, state, {
                loggingIn: true
            })

        case UserTypes.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: true,
                authState: action.payload,
                info: action.payload.accessToken ? azureAD.getUserFromToken(action.payload.accessToken) : null
            })

        case UserTypes.LOGIN_FAILURE:
            return initialState

        case UserTypes.LOGOUT:
            return initialState

        case UserTypes.TOKEN_INVALID:
            return initialState

        case UserTypes.SET_USER_INFO:
            return Object.assign({}, state, {
                userInfo: action.payload,
                cannotLoadProfile: false
            })

        case UserTypes.SET_STUDENT_INFO:
            return Object.assign({}, state, {
                studentInfo: action.payload,
                cannotLoadProfile: false
            })

        case UserTypes.SET_SHOW_ONBOARDING:
            return Object.assign({}, state, {
                showOnboarding: action.payload
            })

        case UserTypes.CANNOT_LOAD_PROFILE:
            return Object.assign({}, state, {
                cannotLoadProfile: true
            })

        default:
            return state
    }
}