export class AlertPrimitive {

  static confirm = (
    alertTitle, // unused on web. we could add an 3rd party library if we really wanted it
    alertDescription,
    cancelBtnFunc,
    okBtnFunc,
  ) => {
    if (window.confirm(alertDescription)) {
      okBtnFunc()
    } else {
      cancelBtnFunc()
    }
  }

}


