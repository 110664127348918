import React from 'react'
import { Svg, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const SubMenuIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'menu icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'menu icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="17.5" cy="17.5" r="5"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="5" cy="17.5" r="5"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="30" cy="17.5" r="5"/>
        </Svg>
    )
}