import { QueueTypes } from './QueueTypes';

export class QueueActions {

  // queueItem = {
  //      id: [uuid]
  //      job_name: string
  //      job_params: {}
  //      max_fail_attempts: 5
  //      remove_after_date: [date]
  // }
  static queueAdd = (username, queueItem) => {
    return { type: QueueTypes.QUEUE_ADD, payload: { username, queueItem } }
  }

  static queueRemove = (username, jobId) => {
    return { type: QueueTypes.QUEUE_REMOVE, payload: { username, jobId } }
  }

  static queueIncreaseFailAttempsCount = (username, jobId) => {
    return { type: QueueTypes.QUEUE_INCREASE_FAIL_ATTEMPS_COUNT, payload: { username, jobId } }
  }

}
