import { useNavigatorOnline } from '@oieduardorabelo/use-navigator-online';

export class NetworkInfo {

    static getNetworkInfo = () => {
        return {}
    }

    static useHasInternet = () => {
        const { isOnline } = useNavigatorOnline()
        return isOnline
    }

    static hasInternetAsync = async () => {
        return window && window.navigator && window.navigator.onLine;
    }

}