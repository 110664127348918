import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const WarningIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'warning icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'warning icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} d="M16.35,6.18L4.05,27.49c-0.51,0.88,0.13,1.98,1.15,1.98H29.8c1.02,0,1.66-1.1,1.15-1.98L18.65,6.18
                C18.14,5.3,16.86,5.3,16.35,6.18z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M16.39,18.74l0.3,3.18h1.62l0.3-3.18c0.08-0.86,0.16-1.63,0.22-2.31c0.15-1.53,0.25-2.54,0.25-3.07
                    c0-1.06-0.59-1.7-1.58-1.7c-0.99,0-1.58,0.63-1.58,1.7c0,0.52,0.1,1.53,0.25,3.07C16.24,17.1,16.31,17.88,16.39,18.74z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.5,22.86c-0.94,0-1.74,0.8-1.74,1.75c0,0.96,0.78,1.74,1.74,1.74c0.96,0,1.74-0.78,1.74-1.74
                    C19.24,23.67,18.44,22.86,17.5,22.86z"/>
        </Svg>
    )
}