import React, { useState } from 'react'
import moment from 'moment-timezone'

import { SettingsHelper, UserHelper } from 'redux/helpers'
import { Container, Text, Link, Button } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { Icon, icons } from 'assets/MenuIcons'
import { getLectureLocationUrl } from 'lib/timetable/lectureLocation'
import { DropdownButton, SlideInMenu } from 'components/general'
import { Platform } from 'lib/primitives'
import { useHistory, routes } from 'navigation'

import { DialogUtterances } from 'lib/chat/dialogUtterances'
import { AttendanceCheckIn, LectureFeedback } from './'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { features } from 'lib/general/enums'

const areEqual = (prevProps, nextProps) => {
  return prevProps.isPast == nextProps.isPast && prevProps.data === nextProps.data
}

export const TimetableItem = React.memo(({ data, isPast, fetchFunc, ...props }) => {
  //Global state
  const isStudent = UserHelper.getIsStudent()
  const isAttendanceEnabled = UserHelper.isFeatureEnabled(features.attendance)
  const isFeedbackEnabled = UserHelper.isFeatureEnabled(features.lectureFeedBack)
  const isLecturerAbsentEnabled = UserHelper.isFeatureEnabled(features.lecturerAbsent)
  const isNative = Platform.isNative()
  const hasInternet = NetworkInfo.useHasInternet()
  const colors = SettingsHelper.getColors()
  //Hooks
  const history = useHistory()
  const incorrectEventDialog = DialogUtterances.useIncorrectEventDialog()
  const lecturerNotHereDialog = DialogUtterances.useLecturerNotHereDialog()
  //Local state
  const [animation, setAnimation] = useState('closed')
  //Other
  const locationUrl = getLectureLocationUrl(data)

  if (data && Object.keys(data).length > 1) {
    return (
      <React.Fragment>
        <Container
          flex={0}
          flexDirection={'row'}
        >
          <Container flex={1}>
            <Text color={isPast ? colors.screenAltText : colors.screenText} nativeFontFamily={fonts.native.bold} webFontFamily={fonts.web.default} fontWeight={fonts.weight.bold}>
              {data.description}
            </Text>
          </Container>
          <Container flex={0}>
            <Icon type={locationUrl ? icons.local : icons.online} color={isPast ? colors.screenAltText : locationUrl ? colors.actionBgs[1] : colors.actionBgs[2]} size={25} />
          </Container>
        </Container>
        <Container flex={0} flexGrow={isNative ? 0 : 1} flexDirection={'row'}>
          <Container flex={1} flexGrow={1}>
            <Text color={colors.screenAltText}>
              {props.isDashboard ? moment(data.eventStartDate).format('MMM DD ') : null}
              {moment(data.eventStartDate).format('HH:mm')} - {moment(data.eventEndDate).format('HH:mm')}
            </Text>
            <Text color={colors.screenAltText}>{data.locationDescription}</Text>
          </Container>
          {!(props.isDashboard && !isNative) ?
            <Container flex={0} marginRight={-7} marginTop={10} marginBottom={-10}>
              <DropdownButton current={animation} setAnimation={setAnimation} />
            </Container>
            :
            <Container flex={0} justifyContent={'flex-end'}>
              {isStudent ?
                <Link to={routes.timetable.default} flex={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} textAlign={'left'}>
                  <Text color={colors.screenText} paddingRight={5}>
                    View in timetable
                  </Text>
                  <Icon type={icons.rightArrow} color={colors.screenText} size={15} />
                </Link> :
                <Link to={`${routes.timetable.default}/event/${data.scheduledEventId}`} flex={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} textAlign={'left'}>
                  <Text color={colors.screenText} paddingRight={5}>
                    View more info
                  </Text>
                  <Icon type={icons.rightArrow} color={colors.screenText} size={15} />
                </Link>}
            </Container>
          }
        </Container>
        {!(props.isDashboard && !isNative) &&
          <React.Fragment>
            <SlideInMenu
              animation={animation}
              renderChildren={Menu}
              colors={colors}
              data={data}
              locationUrl={locationUrl}
              isNative={isNative}
              history={history}
              isStudent={isStudent}
              hasInternet={hasInternet}
              isAttendanceEnabled={isAttendanceEnabled}
              incorrectEventDialog={incorrectEventDialog}
              lecturerNotHereDialog={lecturerNotHereDialog}
              isLecturerAbsentEnabled={isLecturerAbsentEnabled}
            />
            {isStudent && isAttendanceEnabled && <AttendanceCheckIn event={data} fetchFunc={fetchFunc} />}
            {isStudent && isFeedbackEnabled && isPast && (isAttendanceEnabled && data.attendanceStatus === 'ATTENDED') && <LectureFeedback event={data} fetchFunc={fetchFunc} />}
            {!isStudent && isFeedbackEnabled && isPast && <FeedbackDisplay event={data} colors={colors}/>}
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
  return null
}, areEqual)

const FeedbackDisplay = ({ event, colors }) => {
  if (!event || !event.averageEventRating) return null

  return (
    <Container
      flex={0}
      marginTop={5}
      paddingTop={10}
      borderTop={`2px solid ${colors.screenBorder}`}
      borderTopWidth={2}
      borderColor={colors.screenBorder}
      flexDirection={'row'}
      alignItems={'center'}
    >
      <Icon type={icons.star} color={colors.screenAltText} size={25} />
      <Text color={colors.screenAltText} paddingLeft={5}>Average rating: {event.averageEventRating.toFixed(1)}/5</Text>
    </Container>
  )
}

const Menu = ({ colors, data, locationUrl, isNative, history, incorrectEventDialog, lecturerNotHereDialog, isStudent, hasInternet, isAttendanceEnabled, isLecturerAbsentEnabled }) => {
  return (
    <Container
      flex={0}
      width={'100%'}
      marginTop={5}
      paddingTop={5}
      borderTop={`2px solid ${colors.screenBorder}`}
      borderTopWidth={2}
      borderColor={colors.screenBorder}
    >
      {!isStudent && isAttendanceEnabled && <MoreInfo data={data} colors={colors} />}
      {locationUrl != null && <EventLocation locationUrl={locationUrl} colors={colors} />}
      {isStudent && <TeacherEmail data={data} colors={colors} />}
      {isStudent && <ReportPrompts data={data} colors={colors} isNative={isNative} history={history} isLecturerAbsentEnabled={isLecturerAbsentEnabled} incorrectEventDialog={incorrectEventDialog} lecturerNotHereDialog={lecturerNotHereDialog} hasInternet={hasInternet} />}
    </Container>
  )
}

const MoreInfo = ({ data, colors }) => {
  if (!data || !data.scheduledEventId) return null

  return (
    <Link to={`${routes.timetable.default}/event/${data.scheduledEventId}`} flex={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} textAlign={'left'}>
      <Icon type={icons.timetableActive} color={colors.screenAltText} size={25} />
      <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
        More Info
      </Text>
    </Link>
  )
}

const EventLocation = ({ locationUrl, colors }) => {
  return (
    <Link to={locationUrl} isExternal={true} flex={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} textAlign={'left'}>
      <Icon type={icons.locationActive} color={colors.screenAltText} size={25} />
      <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
        Show location
      </Text>
    </Link>
  )
}

const TeacherEmail = ({ data, colors }) => {
  if (data.teacherName && data.teacherEmail) {
    return (
      <Link to={'mailto:' + data.teacherEmail} isExternal={true} flex={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} textAlign={'left'}>
        <Icon type={icons.emailActive} color={colors.screenAltText} size={25} />
        <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
          Email {data.teacherName}
        </Text>
      </Link>
    )
  }
  return null
}

const ReportPrompts = ({ data, colors, history, incorrectEventDialog, lecturerNotHereDialog, isLecturerAbsentEnabled, hasInternet }) => {         
  const isCurrentLecture = moment().tz('Europe/London').isAfter(moment(data.eventStartDate).add(10, 'minutes')) && moment().tz('Europe/London').isBefore(moment(data.eventEndDate))
  const handleIncorrectInformationOnPress = () => incorrectEventDialog(moment(data.eventStartDate), history)
  const handleLecturerNotHereOnPress = () => lecturerNotHereDialog(history)

  return (
    <Container flex={0} opacity={!hasInternet ? 0.5 : null}>
      <Button accessibility={{ role: 'button', label: 'report incorrect event information in chat' }} handleAction={hasInternet ? handleIncorrectInformationOnPress : null} flex={0} padding={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} justifyContent={'flex-start'}>
        <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
        <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
          Report incorrect information
        </Text>
      </Button>
      {isLecturerAbsentEnabled && isCurrentLecture &&
        <Button accessibility={{ role: 'button', label: 'report lecturer not here in chat' }} handleAction={hasInternet ? handleLecturerNotHereOnPress : null} flex={0} padding={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} justifyContent={'flex-start'}>
          <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
          <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
            Report lecturer not here
          </Text>
        </Button>
      }
    </Container>
  )
}
