import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SettingsHelper, FetchHelper } from 'redux/helpers'
import { FetchRequest, Keyboard, useThrottle } from 'lib/general'
import { Namespaces } from 'redux/namespaces'
import { Container, InputText, Button } from "primitives"
import { Icon, icons } from 'assets/MenuIcons'
import { useHistory, useLocation, routes } from 'navigation'

export const SearchBar = ({ screenXS }) => {
    //Global state
    const colors = SettingsHelper.getColors()
    const qna = useSelector(state => state[Namespaces.SETTINGS].qna)
    //Hooks
    const history = useHistory()
    const location = useLocation()
    const setQnaResults = SettingsHelper.useSetQnaResults()
    //Local state
    const [regexQna, setRegexQna] = useState()
    const [search, setSearch] = useState('')
    //Other
    const currentSearch = useThrottle(search, 300)
    const fetchQna = FetchHelper.useFetch([Namespaces.SETTINGS], "qna", () => FetchRequest.getQnaQuestions())

    useEffect(() => {
        fetchQna()
    }, [])

    useEffect(() => {
        if (qna && qna.data) {
            let newRegexQna = qna.data.map(item => {
                return {
                    question: item.question,
                    filteredQuestion: item.question.toLowerCase().replace(/[^\w\s\']/g, ''),
                    kbname: item.kbname
                }
            })
            setRegexQna(newRegexQna)
            filterQna(currentSearch)
        }
    }, [qna])

    useEffect(() => {
        if (search != '' && location.pathname !== routes.search) {
            setSearch('')
        }
    }, [location])

    useEffect(() => {
        filterQna(currentSearch)
    }, [currentSearch])

    const filterQna = (text) => {
        if (regexQna && regexQna.length > 0 && text.length > 0) {
            let phrase = text.toLowerCase().replace(/[^\w\s\']/g, '')
            let words = Array.from(new Set(phrase.split(' '))).filter(item => item != '')

            //Returns any FAQ questions that match at least one word ordered by number of matches
            let filteredSearch = regexQna.map(item => {
                let matches = 0
                words.forEach(word => {
                    let wordRegex = new RegExp(word)
                    if (wordRegex.test(item.filteredQuestion)) matches++
                })
                return Object.assign({}, item, { matches: matches })
            })
                .filter(item => item.matches > 0 && item.matches > words.length - 1)
                .sort((a, b) => {
                    if (a.matches < b.matches) return 1
                    if (a.matches > b.matches) return -1
                    return 0
                })
                .map(item => {
                    return { question: item.question, kbname: item.kbname }
                })
            setQnaResults(filteredSearch.slice(0, Math.min(25, filteredSearch.length)))
        }
        else {
            setQnaResults([])
        }
    }

    return (
        <Container
            backgroundColor={colors.screenBg}
            borderRadius={20}
            margin={10}
            flexDirection={'row'}
            flexGrow={1}
            justifyContent={'flex-end'}
            width={screenXS ? 'calc(100% - 10px)' : null}
            maxWidth={600}
            overflow={'hidden'}
        >
            <InputText
                name={'search bar'}
                display={'flex'}
                flex={1}
                width={'100%'}
                padding={5}
                paddingLeft={10}
                margin={0}
                color={colors.screenText}
                backgroundColor={colors.screenBg}
                value={search}
                placeholder={'Search keywords...'}
                placeholderTextColor={colors.screenBorder}
                onChange={text => {
                    if (history.location.pathname !== routes.search) history.push(routes.search)
                    setSearch(text)
                }}
            />
            <Button
                accessibility={{ role: 'button', label: 'remove search terms' }}
                handleAction={() => {
                    if (search.length > 0) {
                        setSearch('')
                        Keyboard.dismiss()
                        history.goBack()
                    }
                }}
                flex={0}
                padding={5}
                paddingRight={search ? 5 : 8}
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'flex-start'}
            >
                <Icon type={search ? icons.cancelActive : icons.search} color={search ? colors.screenAltText : colors.menuAltIcon} size={30} />
            </Button>
        </Container>
    )
}