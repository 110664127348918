import { DeepLinking } from './DeepLinking'
import { Navigation } from './Navigation'
import { Router } from './Router'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import { LoginRoute } from './LoginRoute'
import { AnimatedSwitch } from './AnimatedSwitch'
import { useLocation, useHistory, useParams, BaseRouter, Route, Switch, Redirect } from './RouterImports'
import { RouteWrapper } from './RouteWrapper'
import { routes } from './routes'
import { webviewConfig } from './webviewConfig'
import {ReduxRedirect} from './ReduxRedirect'

export {
    DeepLinking,
    Navigation,
    BaseRouter,
    Router,
    Route,
    AuthenticatedRoute,
    LoginRoute,
    Switch,
    AnimatedSwitch,
    Redirect,
    RouteWrapper,
    useLocation,
    useHistory,
    useParams,
    routes,
    webviewConfig,
    ReduxRedirect
}