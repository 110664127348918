import { TimetableTypes } from 'redux/lib/timetable/TimetableTypes'
import { UserTypes } from 'redux/lib/user/UserTypes'
import { FetchReducerHelper, FetchReducerHelperInitialState } from 'redux/helpers/fetch'
import { Namespaces } from 'redux/namespaces'

export class TimetableFetchKeys {
    static week1 = "week1"
    static week5 = "week5"
    static week9 = "week9"
    static week13 = "week13"
    static week17 = "week17"
    static week21 = "week21"
    static week25 = "week25"
    static week29 = "week29"
    static week33 = "week33"
    static week37 = "week37"
    static week41 = "week41"
    static week45 = "week45"
    static week49 = "week49"
    static week53 = "week53"
}

const initialState = {
    [TimetableFetchKeys.week1]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week5]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week9]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week13]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week17]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week21]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week25]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week29]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week33]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week37]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week41]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week45]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week49]: FetchReducerHelperInitialState,
    [TimetableFetchKeys.week53]: FetchReducerHelperInitialState,
    attendanceStatus: {},
    staffAttendanceStatus: null
}

export const TimetableReducer = (state = initialState, action) => {
    let fetchState = FetchReducerHelper.switch(state, action, Namespaces.TIMETABLE, TimetableFetchKeys)
    if (fetchState) return fetchState
    switch (action.type) {

        case UserTypes.LOGOUT:
            return initialState

        case TimetableTypes.SET_ATTENDANCE_STATUS:
            return Object.assign({}, state, {
                attendanceStatus: action.payload
            })

        case TimetableTypes.ADD_ATTENDANCE_STATUS:
            const newStatus = Object.assign({}, state.attendanceStatus, action.payload)
            return Object.assign({}, state, {
                attendanceStatus: newStatus
            })

        case TimetableTypes.SET_STAFF_ATTENDANCE_STATUS:
            return Object.assign({}, state, {
                staffAttendanceStatus: action.payload
            })

        default:
            return state
    }
}