import React from 'react'
import { Container, Link, Text } from 'primitives'
import { generalConfig } from 'lib/config/generalConfig'
import { PlatformHelper } from 'redux/helpers'
import { SettingsHelper } from 'redux/helpers'

export const BottomBar = props => {
    const colors = SettingsHelper.getColors()
    const versionNumber = PlatformHelper.getVersion()
    return (
        <Container
            flex={0}
            padding={20}
            backgroundColor={colors.androidNavBg}
            flexDirection={'row'}
        >
            <Container flex={1} alignItems={'center'}>
                <Link to={generalConfig.termsUrl} isExternal={true} textAlign={'center'}>
                    <Text color={colors.menuIcon}>Terms</Text>
                </Link>
            </Container>
            <Container flex={1} alignItems={'center'}>
                <Link to={`mailto:${generalConfig.supportEmail}`} isExternal={true} textAlign={'center'}>
                    <Text color={colors.menuIcon} >{generalConfig.supportEmail}</Text>
                </Link>
            </Container>
            <Container flex={1} alignItems={'center'}>
                <Text textAlign={'center'} color={colors.menuIcon} >v{versionNumber}</Text>
            </Container>
        </Container>
    )
}
