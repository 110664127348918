import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { SettingsHelper, UserHelper } from 'redux/helpers'
import { Container, Text, Button } from 'primitives'
import { StyledButton, LoadingSpinner } from 'components/general'
import { Icon, icons } from 'assets/MenuIcons'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { FetchRequest } from 'lib/general'
import { Platform } from 'lib/primitives'
import Screen from 'lib/screen/screen'

export const LectureFeedback = ({ event, fetchFunc }) => {
    const colors = SettingsHelper.getColors()
    const isNative = Platform.isNative()
    const screenXS = Screen.isScreenXS()
    const hasInternet = NetworkInfo.useHasInternet()
    const [loading, setLoading] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [currentRating, setCurrentRating] = useState(0)
    const ratings = [1, 2, 3, 4, 5]
    const studentLectureRating = FetchRequest.useStudentLectureRating()

    useEffect(() => {
        if (event.rating && loading) {
            setLoading(false)
        }
    }, [event.rating])

    if (!(event && event.scheduledEventId) || moment().isSameOrBefore(moment(event.eventEndDate)) || !(event && event.rating) && moment().subtract(2, 'weeks').isSameOrAfter(moment(event.eventEndDate))) {
        return null
    }

    const handleSubmit = () => {
        if (hasInternet) {
            setLoading(true)
            const payload = {
                score: currentRating
            }
            studentLectureRating(event.scheduledEventId, payload)
                .then(res => {
                    if (res.error) {
                        setHasError(true)
                    } else {

                        fetchFunc()
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setHasError(true)
                })
        }
    }

    if (hasError) {
        return (
            <FeedbackContainer flexDirection={'column'}>
                <Text color={colors.screenText} wordWrap={'break-word'} marginBottom={5} textAlign={'center'}>Sorry, there's been an issue recording your rating for this event.</Text>
                <StyledButton
                    title={'Try again'}
                    accessibility={{ role: 'button', label: 'attempt event feedback again' }}
                    bgColor={colors.actionSuccess}
                    textColor={colors.actionText}
                    handleAction={() => {
                        setLoading(false)
                        setHasError(false)
                    }}
                    margin={0}
                />
            </FeedbackContainer>
        )
    }

    if (loading) {
        return (
            <FeedbackContainer alignItems={'center'}>
                <LoadingSpinner size={35} padding={5} color={colors.screenAltText} />
            </FeedbackContainer>
        )
    }

    if (event.rating != null) {
        return (
            <FeedbackContainer justifyContent={'flex-start'} alignItems={'center'}>
                <Icon type={icons.successActive} color={colors.screenAltText} size={25} />
                <Text color={colors.screenAltText} paddingLeft={5}>Rating recorded</Text>
            </FeedbackContainer>
        )
    }

    return (
        <FeedbackContainer flexDirection={'column'}>
            {(isNative || screenXS) && <Container flex={0}>
                <Text color={colors.screenAltText} paddingBottom={5} textAlign={'center'}>How would you rate this event?</Text>
            </Container>}
            <Container flex={0} flexDirection={'row'} justifyContent={'space-around'} alignItems={'center'}>
                {!isNative && !screenXS && <Container flex={0}>
                    <Text color={colors.screenAltText} paddingBottom={5} textAlign={'center'}>How would you rate this event?</Text>
                </Container>}
                <Container flexDirection={'row'} flex={0} marginRight={5} justifyContent={'center'}>
                    {ratings.map(rating => <FeedbackButton currentRating={currentRating} setCurrentRating={setCurrentRating} value={rating} colors={colors} key={rating} />)}
                </Container>
            </Container>
            {currentRating > 0 && <Container flex={0} flexDirection={'row'} justifyContent={'center'} opacity={!hasInternet ? 0.5 : null}>
                <StyledButton
                    title={'Cancel'}
                    accessibility={{ role: 'button', label: 'cancel rating' }}
                    bgColor={colors.actionBg}
                    textColor={colors.actionText}
                    handleAction={() => setCurrentRating(0)}
                    margin={5}
                />
                <StyledButton
                    title={'Submit'}
                    accessibility={{ role: 'button', label: 'submit the event rating' }}
                    bgColor={colors.actionSuccess}
                    textColor={colors.actionText}
                    handleAction={currentRating == 0 ? null : handleSubmit}
                    margin={5}
                />
            </Container>}
        </FeedbackContainer>
    )
}

const FeedbackContainer = ({ children, flexDirection = 'row', justifyContent = 'center' }) => {
    const hasInternet = NetworkInfo.useHasInternet()
    return (
        <Container
            flex={0}
            marginTop={5}
            paddingTop={5}
            flexDirection={flexDirection}
            justifyContent={justifyContent}
            opacity={!hasInternet ? 0.5 : null}
        >
            {children}
        </Container>
    )
}

const FeedbackButton = ({ currentRating, setCurrentRating, value, colors }) => {
    return (
        <Button handleAction={() => {
            if (currentRating == value) {
                setCurrentRating(0)
            }
            else {
                setCurrentRating(value)
            }
        }} padding={5} accessibility={{ role: 'button', label: 'rating: ' + value + ' stars' }}>
            <Icon type={currentRating >= value ? icons.star : icons.starEmpty} color={colors.screenAltText} size={30} />
        </Button>
    )
}