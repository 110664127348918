import React from 'react'
import { Svg, Circle, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const MoodTerribleIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'terrible mood icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'terrible mood icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="17.52" cy="17.5" r="14.81"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M14.32,13.94c0,1.41-1.15,2.56-2.56,2.56S9.2,15.36,9.2,13.94"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M25.83,13.94c0,1.41-1.15,2.56-2.56,2.56s-2.56-1.15-2.56-2.56"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M23.49,24.6c0,0-2.67-2.84-5.97-2.84s-5.97,2.84-5.97,2.84"/>
        </Svg>
    )
}