import { NewsTypes } from './NewsTypes'

const initialState = {
    newsItem: null,
}

export const NewsReducer = (state = initialState, action) => {
    switch (action.type) {

        case NewsTypes.SET_NEWS_ITEM:
            return Object.assign({}, state, {
                newsItem: action.payload,
            })

        default:
            return state
    }
}

