import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const EmailActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'email icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'email icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M32.52,15.34c0-0.4-0.11-0.77-0.31-1.09l-11.52,5.96l11.79,8.79c0.03-0.13,0.04-0.27,0.04-0.41V15.34z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M16.09,18.87l0.82-0.6c0.01-0.01,0.03-0.01,0.04-0.02c0.06-0.04,0.13-0.06,0.19-0.09
                c0.06-0.02,0.11-0.05,0.16-0.06c0.06-0.01,0.13-0.01,0.19-0.01c0.06,0,0.12,0,0.18,0.01c0.06,0.01,0.11,0.04,0.17,0.06
                c0.06,0.03,0.13,0.05,0.19,0.09c0.01,0.01,0.03,0.01,0.05,0.02l0.8,0.6l11.73-6.07V6.39c0-1.21-0.99-2.19-2.19-2.19H6.56
                c-1.21,0-2.19,0.99-2.19,2.19v6.42L16.09,18.87z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.49,20.33L3.62,30.44C3.91,30.6,4.24,30.7,4.6,30.7H30.4c0.28,0,0.55-0.06,0.8-0.16L17.49,20.33z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M2.79,14.24c-0.2,0.32-0.31,0.69-0.31,1.09v13.25c0,0.07,0.01,0.13,0.02,0.2l11.78-8.59L2.79,14.24z"/>
        </Svg>
    )
}