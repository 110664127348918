import React from 'react'
import { useSelector } from 'react-redux'
import { Text, Container } from 'primitives'
import { Icon, icons } from 'assets/MenuIcons'
import { fonts } from 'lib/config/ui-config'
import { Platform } from 'lib/primitives'
import { PixelRatio } from 'lib/screen/PixelRatio'

export const DefaultTileContent = ({ title, icon, colors, tileWidth, isExternal }) => {
  const isWeb = Platform.isWeb()
  const largerDashboardTiles = useSelector(state => state.settings.largerDashboardTiles)

  const getIconSize = () => {
    if (isWeb) return tileWidth / 2.3 - 10
    const fontScale = PixelRatio.getFontScale()
    if (fontScale > 2 || largerDashboardTiles) return tileWidth / 2 - 10
    if (fontScale > 1.7) return tileWidth / 1.6 - 10
    if (fontScale > 1.3) return tileWidth / 1.5 - 10
    if (fontScale > 1.23) return tileWidth / 2.8 - 10
    if (fontScale > 1.1) return tileWidth / 2.6 - 10
    return tileWidth / 2.3 - 10
  }

  return (
    <React.Fragment>
      {isExternal && <Container flex={0} position={'absolute'} top={0} right={0} paddingTop={isWeb ? 10 : 0} paddingRight={isWeb ? 10 : 0}>
        <Icon type={icons.externalLink} color={colors.screenAltText} size={18} />
      </Container>}
      <Container flex={0} paddingTop={5} justifyContent={'center'}>
        <Icon type={icon} color={colors.actionBg} size={getIconSize()} />
      </Container>
      <Container flex={1} justifyContent={'center'}>
        <Text
          color={colors.screenText}
          textAlign={'center'}
          fontSize={fonts.size.dashboard}
          flexShrink={1}
        >
          {title}
        </Text>
      </Container>
    </React.Fragment>
  )
}