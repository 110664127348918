import { ChatTypes } from './ChatTypes'

export class ChatActions {

    static setDirectline = directline => {
        return { type: ChatTypes.SET_DIRECTLINE, payload: directline }
    }

    static disconnectDirectline = () => {
        return { type: ChatTypes.DISCONNECT_DIRECTLINE, payload: {} }
    }

    static addMessage = message => {
        return { type: ChatTypes.ADD_MESSAGE, payload: message, meta: { throttle: 1000 } }
    }

    static beaconIsTyping = (isTyping = true) => {
        return { type: ChatTypes.BEACON_IS_TYPING, payload: isTyping }
    }

    static setChatMenuVisibility = isVisible => {
        return { type: ChatTypes.SET_CHAT_VISIBILITY, payload: isVisible }
    }

    static setChatDimensions = dimensions => {
        return { type: ChatTypes.SET_CHAT_DIMENSIONS, payload: dimensions }
    }

    static setSendGreeting = sendGreeting => {
        return { type: ChatTypes.SET_SEND_GREETING, payload: sendGreeting }
    }

}


