import { UserTypes } from 'redux/lib/user/UserTypes'
import { FetchReducerHelper, FetchReducerHelperInitialState } from 'redux/helpers/fetch'
import { Namespaces } from 'redux/namespaces'

export class CoursesFetchKeys {
    static courses = "courses"
    static other = "other"
}

const initialState = {
    [CoursesFetchKeys.courses]: FetchReducerHelperInitialState,
    [CoursesFetchKeys.other]: FetchReducerHelperInitialState,
}

export const CourseReducer = (state = initialState, action) => {
    let fetchState = FetchReducerHelper.switch(state, action, Namespaces.COURSES, CoursesFetchKeys)
    if (fetchState) return fetchState;

    switch (action.type) {

        case UserTypes.LOGOUT:
            return initialState

        default:
            return state
    }
}