import React, { useState, useEffect, useRef } from 'react'
import { SettingsHelper, UserHelper, RuntimeHelper, ChatHelper } from 'redux/helpers'
import { useHistory } from 'navigation'
import { Row } from 'primitives'
import { Platform } from 'lib/primitives'
import Screen from 'lib/screen/screen'
import { TileConfig } from 'components/dashboard'
import { ScreenContainer, LoadingData } from 'components/general'
import { layoutDimensions, useThrottle } from 'lib/general'
import { OfflineBar } from 'components/bars'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { StyledButton } from '../components/general/index'
import { FetchRequest } from 'lib/general'

export const Dashboard = props => {
  const isStudent = UserHelper.getIsStudent()
  const isWeb = Platform.isWeb()
  const hasInternet = NetworkInfo.useHasInternet()
  const screenXS = Screen.isScreenXS()
  const roles = [isStudent ? 'student' : 'staff', isWeb ? 'web' : 'native']
  const colors = SettingsHelper.getColors()
  const history = useHistory()
  const [currentWidth, setCurrentWidth] = useState(0)
  const width = useThrottle(currentWidth, 100)
  const containerRef = useRef(null)
  const openChat = ChatHelper.useSetChatMenuVisibility()
  const tileWidth = RuntimeHelper.getTileWidth(width)
  const isFeatureEnabled = UserHelper.useIsFeatureEnabled()

  // const getAuthTokenOLD = UserHelper.getAuthToken()
  // const getAuthToken = UserHelper.useGetAuthToken()
  // const getUserOLD = UserHelper.getUser()
  // const getUser = UserHelper.useGetUser()
  // const test = async () => {
  //   console.log("--------------------------------------")
  //   console.log("--------------------------------------")
  //   console.log("--------------------------------------")
  //   console.log("--------------------------------------")
  //   console.log("Testing auth token")
  //   console.log(`Date: ${new Date()}`)
  //   console.log("----------------- OLD ---------------------")
  //   console.log('Auth token OLD :')
  //   console.log(getAuthTokenOLD)
  //   console.log('Get User OLD :')
  //   console.log(getUserOLD)
  //   console.log('Fetch Request OLD')
  //   let test = await FetchRequest.getUserInfo(getAuthTokenOLD)
  //   console.log(test)
  //   console.log("------------------- NEW -------------------")
  //   console.log('Auth token:')
  //   let authToken = await getAuthToken()
  //   console.log(authToken)
  //   console.log('Get user:')
  //   let user = await getUser()
  //   console.log(user)
  //   console.log('Fetch Request')
  //   let test2 = await FetchRequest.getUserInfo(authToken)
  //   console.log(test2)
  // }

  //@ts-ignore
  useEffect(() => { isWeb && layoutDimensions({ setWidth: setCurrentWidth, ref: containerRef }) })
  //@ts-ignore
  useEffect(() => { screenXS && openChat(false) }, [screenXS])

  if (isStudent === null) return <LoadingData backgroundColor={colors.screenAltBg} text={'Loading your personalised dashboard...'} />

  return (
    <ScreenContainer
      viewType={'ScrollView'}
      backgroundColor={colors.screenAltBg}
      useRef={containerRef}
      onLayout={event => () => {
        if (width == 0) {
          layoutDimensions({ setWidth: setCurrentWidth, ref: containerRef, event })
        }
      }}
    >
      <OfflineBar />
      {/* <StyledButton
        title={'Test'}
        handleAction={test}
        bgColor={colors.actionBg}
        textColor={colors.actionText}
      /> */}
      <Row
        padding={5}
        paddingBottom={0}
      >
        {isWeb && width == 0 ? null : TileConfig.map((tile, index) => {
          // Show the tile if the user has the correct user roles
          if (roles.every(role => tile.visibility.includes(role))) {
            // and correct features enabled (for tiles that require a feature/beta access)
            if (!tile.restrictedFeature || (tile.restrictedFeature && isFeatureEnabled(tile.restrictedFeature))) {
              return (
                <React.Fragment key={index}>
                  {tile.component({ colors, width, tileWidth, history, screenXS, hasInternet })}
                </React.Fragment>
              )
            }
          }
          return null
        })}
      </Row>
    </ScreenContainer>
  )
}
