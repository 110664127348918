import React from 'react'
import { Svg, Rect, Path, Pattern, Defs } from 'primitives'
import { Platform } from 'lib/primitives'

export const BackgroundKnotIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'staffordshire knot repeating background'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'staffordshire knot repeating background'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Defs>
                <Pattern
                    id="KnotPattern"
                    patternUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width={100}
                    height={138}
                    viewBox={"0 0 0 0"}
                >
                    <Path style={{fill:props.color}} d="M87.52,17.53c-1.56-1.54-3.5-2.67-5.6-3.79c-1.74-0.93-3.59-1.72-5.43-2.21l0.11-9.18l-0.15,0l-7.81-0.01
                        c0,2.01,0.01,4.35,0,6.96C62.97,7.82,56.82,7,50.4,6.95v0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0l0,0
                        C43.96,6.97,37.8,7.76,32.11,9.2c0.01-2.61,0.03-4.95,0.04-6.96l-7.81-0.02l-0.15,0l0.07,9.18c-1.84,0.49-3.69,1.26-5.44,2.19
                        c-2.1,1.12-4.05,2.23-5.62,3.77C2.13,24.27-3.61,48.74,13.1,59.97c10.15,7.46,25.19,6.78,35.98-2.78c0.45-0.4,0.77-0.78,1.19-1.2
                        c0.41,0.42,0.73,0.8,1.18,1.21c10.74,9.61,25.78,10.37,35.97,2.96C104.18,49,98.57,24.51,87.52,17.53z M39.79,54.28
                        c-9.42,5.32-20.93,3.24-26.7-4.51c-6.87-9.06,0.05-21.55,0.05-21.55c1.84-2.7,4.35-5.06,7.47-6.83c1.64-0.93,3.37-1.7,5.09-2.19
                        c1.94,6.59,5.13,13.37,9.55,19.86c2.8,4.11,5.9,7.81,9.18,11.06l0.37,0.33C43.36,51.91,41.68,53.2,39.79,54.28z M50.29,46.31
                        c-7.09-7.15-16.41-21.27-16.81-28.78c5.15-1.3,10.86-2.01,16.86-2c0,0,0,0,0,0l0,0c0.01,0,0.01,0,0.02,0c0.01,0,0.01,0,0.02,0l0,0
                        c0,0,0,0,0,0c6.01,0.01,11.72,0.76,16.86,2.08C66.8,25.13,57.42,39.2,50.29,46.31z M87.48,49.94c-5.8,7.72-17.33,9.75-26.72,4.38
                        c-1.89-1.08-3.56-2.39-4.99-3.85l0.37-0.33c3.3-3.23,6.42-6.91,9.23-11.01c4.45-6.47,7.67-13.23,9.64-19.81
                        c1.72,0.5,3.45,1.28,5.08,2.21c3.11,1.78,5.61,4.16,7.44,6.86C87.53,28.4,94.39,40.92,87.48,49.94z"/>
                    <Path style={{fill:props.color}} d="M37.52,85.53c-1.56-1.54-3.5-2.67-5.6-3.79c-1.74-0.93-3.59-1.72-5.43-2.21l0.11-9.18l-0.15,0l-7.81-0.01
                        c0,2.01,0.01,4.35,0,6.96C12.97,75.82,6.82,75,0.4,74.95l0,0c-0.01,0-0.01,0-0.02,0c-0.01,0-0.01,0-0.02,0l0,0
                        c-6.42,0.02-12.57,0.81-18.26,2.25c0.01-2.61,0.03-4.95,0.04-6.96l-7.81-0.02l-0.15,0l0.07,9.18c-1.84,0.49-3.69,1.26-5.44,2.19
                        c-2.1,1.12-4.05,2.23-5.62,3.77c-11.08,6.93-16.81,31.39-0.1,42.63c10.15,7.46,25.19,6.78,35.98-2.78c0.45-0.4,0.77-0.78,1.19-1.2
                        c0.41,0.42,0.73,0.8,1.18,1.21c10.74,9.61,25.78,10.37,35.97,2.96C54.18,117,48.57,92.51,37.52,85.53z M-10.21,122.28
                        c-9.42,5.32-20.93,3.24-26.7-4.51c-6.87-9.06,0.05-21.55,0.05-21.55c1.84-2.7,4.35-5.06,7.47-6.83c1.64-0.93,3.37-1.7,5.09-2.19
                        c1.94,6.59,5.13,13.37,9.55,19.86c2.8,4.11,5.9,7.81,9.18,11.06l0.37,0.33C-6.64,119.91-8.32,121.2-10.21,122.28z M0.29,114.31
                        c-7.09-7.15-16.41-21.27-16.81-28.78c5.15-1.3,10.86-2.01,16.86-2c0,0,0,0,0,0l0,0c0.01,0,0.01,0,0.02,0s0.01,0,0.02,0l0,0
                        c0,0,0,0,0,0c6.01,0.01,11.72,0.76,16.86,2.08C16.8,93.13,7.42,107.2,0.29,114.31z M37.48,117.94c-5.8,7.72-17.33,9.75-26.72,4.38
                        c-1.89-1.08-3.56-2.39-4.99-3.85l0.37-0.33c3.3-3.23,6.42-6.91,9.23-11.01c4.45-6.47,7.67-13.23,9.64-19.81
                        c1.72,0.5,3.45,1.28,5.08,2.21c3.11,1.78,5.61,4.16,7.44,6.86C37.53,96.4,44.39,108.92,37.48,117.94z"/>
                    <Path style={{fill:props.color}} d="M137.52,85.53c-1.56-1.54-3.5-2.67-5.6-3.79c-1.74-0.93-3.59-1.72-5.43-2.21l0.11-9.18l-0.15,0l-7.81-0.01
                        c0,2.01,0.01,4.35,0,6.96c-5.68-1.47-11.83-2.29-18.25-2.34l0,0c-0.01,0-0.01,0-0.02,0s-0.01,0-0.02,0v0
                        c-6.42,0.02-12.57,0.81-18.26,2.25c0.01-2.61,0.03-4.95,0.04-6.96l-7.81-0.02l-0.15,0l0.07,9.18c-1.84,0.49-3.69,1.26-5.44,2.19
                        c-2.1,1.12-4.05,2.23-5.62,3.77c-11.08,6.93-16.81,31.39-0.1,42.63c10.15,7.46,25.19,6.78,35.98-2.78c0.45-0.4,0.77-0.78,1.19-1.2
                        c0.41,0.42,0.73,0.8,1.18,1.21c10.74,9.61,25.78,10.37,35.97,2.96C154.18,117,148.57,92.51,137.52,85.53z M89.79,122.28
                        c-9.42,5.32-20.93,3.24-26.7-4.51c-6.87-9.06,0.05-21.55,0.05-21.55c1.84-2.7,4.35-5.06,7.47-6.83c1.64-0.93,3.37-1.7,5.09-2.19
                        c1.94,6.59,5.13,13.37,9.55,19.86c2.8,4.11,5.9,7.81,9.18,11.06l0.37,0.33C93.36,119.91,91.68,121.2,89.79,122.28z M100.29,114.31
                        c-7.09-7.15-16.41-21.27-16.81-28.78c5.15-1.3,10.86-2.01,16.86-2c0,0,0,0,0,0v0c0.01,0,0.01,0,0.02,0s0.01,0,0.02,0l0,0
                        c0,0,0,0,0,0c6.01,0.01,11.72,0.76,16.86,2.08C116.8,93.13,107.42,107.2,100.29,114.31z M137.48,117.94
                        c-5.8,7.72-17.33,9.75-26.72,4.38c-1.89-1.08-3.56-2.39-4.99-3.85l0.37-0.33c3.3-3.23,6.42-6.91,9.23-11.01
                        c4.45-6.47,7.67-13.23,9.64-19.81c1.72,0.5,3.45,1.28,5.08,2.21c3.11,1.78,5.61,4.16,7.44,6.86
                        C137.53,96.4,144.39,108.92,137.48,117.94z"/>
                </Pattern>
            </Defs>
            <Rect fill="url(#KnotPattern)" width={props.size} height={props.size} x="0" y="0"/>
        </Svg>
    )
}