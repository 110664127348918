import React from 'react'
import { Container } from 'primitives'
import { AdaptiveCardCarousel } from '../attachments/AdaptiveCardCarousel'
import { ActionCarousel } from '../attachments/ActionCarousel'

export const MessageAttachments = ({ currentMessage, latestMessage, width, ...props }) => {
    const bubbleWidth = Math.min(width - (messageMargin * 3 + AvatarSize), 600)
    return (
        <Container flex={0}>
            {currentMessage.attachments && currentMessage.attachments.length > 0 ?
                <AdaptiveCardCarousel {...props} attachments={currentMessage.attachments} maxBubbleWidth={bubbleWidth} />
            : null}
            {currentMessage.actions && currentMessage.actions.length > 0 ?
                <ActionCarousel {...props} currentMessage={currentMessage} latestMessage={latestMessage} maxBubbleWidth={bubbleWidth} />
            : null}
        </Container>
    )
}

const AvatarSize = 36
const messageMargin = 7