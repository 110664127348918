import { routes } from 'navigation'
import { Platform } from 'lib/primitives'
import { ChatHelper } from 'redux/helpers'
import Screen from 'lib/screen/screen'

export class DialogUtterances {

    static useCustomDialog = () => {
        const isWeb = Platform.isWeb()
        const sendMessage = ChatHelper.useSendMessage()
        const screenXS = Screen.isScreenXS()
        const openChat = ChatHelper.useSetChatMenuVisibility()
        return (message, history) => {
            try {
                sendMessage({ text: message }, true)
                if (isWeb && !screenXS) {
                    openChat(true)
                }
                else {
                    history.push(routes.chat.default)
                }
            }
            catch (e) {
                console.error(e)
            }
        }
    }

    static useIncorrectEventDialog = () => {
        const isWeb = Platform.isWeb()
        const sendMessage = ChatHelper.useSendMessage()
        const screenXS = Screen.isScreenXS()
        const openChat = ChatHelper.useSetChatMenuVisibility()
        return (eventStartTimeMoment, history) => {
            try {
                // The date format is linked to the backend, if you change it, change the backend dialog too.
                let dialogTimeFormat = 'h:mm A [on] DD [of] MMMM, YYYY';
                let formattedTime = eventStartTimeMoment.format(dialogTimeFormat)
                let utterance = `The timetable information is incorrect for the lecture at ${formattedTime}`
                sendMessage({ text: utterance }, true)
                if (isWeb && !screenXS) {
                    openChat(true)
                }
                else {
                    history.push(routes.chat.default)
                }
            }
            catch (e) {
                console.error(e)
            }
        }
    }

    static useLecturerNotHereDialog = () => {
        const isWeb = Platform.isWeb()
        const sendMessage = ChatHelper.useSendMessage()
        const screenXS = Screen.isScreenXS()
        const openChat = ChatHelper.useSetChatMenuVisibility()
        return (history) => {
            try {
                let utterance = `My lecturer is not here`
                sendMessage({ text: utterance }, true)
                if (isWeb && !screenXS) {
                    openChat(true)
                }
                else {
                    history.push(routes.chat.default)
                }
            }
            catch (e) {
                console.error(e)
            }
        }
    }

    static useMissingEventDialog = () => {
        const isWeb = Platform.isWeb()
        const sendMessage = ChatHelper.useSendMessage()
        const screenXS = Screen.isScreenXS()
        const openChat = ChatHelper.useSetChatMenuVisibility()
        return (weekStartDate, history) => {
            try {
                let dialogDateFormat = 'DD [of] MMMM, YYYY'
                let formattedDate = weekStartDate.format(dialogDateFormat)
                let utterance = `My timetable is missing a lecture for the week beginning on ${formattedDate}`
                sendMessage({ text: utterance }, true)
                if (isWeb && !screenXS) {
                    openChat(true)
                }
                else {
                    history.push(routes.chat.default)
                }
            }
            catch (e) {
                console.error(e)
            }
        }
    }

}