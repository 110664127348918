import React, { useState } from 'react'
import { ArrowNavigation } from '../../general/ArrowNavigation'
import { Container, Animated } from 'primitives'
import { SettingsHelper } from 'redux/helpers'
import { AdaptiveCard } from './AdaptiveCard'

export const AdaptiveCardCarousel = ({ attachments, maxBubbleWidth, ...props }) => {
    const pageLength = attachments ? attachments.length : 0
    const [activePage, setActivePage] = useState(0)

    const scrollLeft = () => {
        setActivePage(Math.max(activePage - 1, 0))
    }

    const scrollRight = () => {
        setActivePage(Math.min(activePage + 1, pageLength - 1))
    }

    const scrollFastLeft = () => {
        setActivePage(Math.max(activePage - 10, 0))
    }

    const scrollFastRight = () => {
        setActivePage(Math.min(activePage + 10, pageLength - 1))
    }

    const renderAdaptiveCard = (attachment, index) => {
        return (
            <Container
                flex={0}
                padding={10}
                justifyContent={'flex-start'}
                alignItems={'center'}
                minWidth={maxBubbleWidth}
                maxWidth={maxBubbleWidth}
                renderToHardwareTextureAndroid={true}
                shouldRasterizeIOS={true}
                key={index}
            >
                <AdaptiveCard payload={attachment.content} />
            </Container>
        )
    }

    const CarouselAnimation = {
        default: {
            transform: `translateX(0px)`
        },
        animated: {
            transform: `translateX(${activePage * -maxBubbleWidth}px)`
        }
    }

    if (attachments && attachments.length == 1) {
        return (
            renderAdaptiveCard(attachments[0])
        )
    } else if (attachments && attachments.length > 1) {
        return (
            <Container flex={0}>
                <Container
                    flex={0}
                    minWidth={maxBubbleWidth}
                    maxWidth={maxBubbleWidth}
                    webOverflow={'hidden'}
                >
                    <Animated
                        display={'flex'}
                        flex={0}
                        flexDirection={'row'}
                        animations={CarouselAnimation}
                        current={'animated'}
                        initial={'default'}
                    >
                        {attachments.map((attachment, index) => renderAdaptiveCard(attachment, index))}
                    </Animated>
                </Container>
                <ArrowNavigation
                    activePage={activePage}
                    maxSize={maxBubbleWidth}
                    pageLength={attachments.length}
                    scrollLeft={scrollLeft}
                    scrollRight={scrollRight}
                    scrollFastLeft={scrollFastLeft}
                    scrollFastRight={scrollFastRight}
                />
            </Container>
        )
    }
    return null

}