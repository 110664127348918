import React from 'react';

export const TextPrimitive = ({nativeFontFamily, webFontFamily, fontSize, lineHeight, fontWeight, children, ...props}) => {
    const style = {...props}
    style.fontFamily = webFontFamily
    style.fontWeight = fontWeight
    style.fontSize = fontSize / 10 + 'rem'
    style.lineHeight = lineHeight / 10 + 'rem'
    return <span style={style}>{children}</span>
}


