import { FetchRequest } from 'lib/general'
import { TimetableActions } from 'redux/lib/timetable'
import moment from 'moment-timezone'

export const submitAttendanceJob = async (params, context) => {
    try {
        console.log("Submit attendance job:")
        console.log(params)
        await FetchRequest.studentCheckIn(context.user.token, params.eventId, params.payload)
            .then(res => {
                if (res.error) {
                    context.dispatch(TimetableActions.addAttendanceStatus({ [params.eventId]: { sent: true, hasError: true } }))
                    throw 'Could not send attendance data'
                }
                else {
                    console.log("Attendance sent. server response")
                    console.log(res)
                    context.dispatch(TimetableActions.addAttendanceStatus({ [params.eventId]: { sent: true, hasError: false, submittedAt: moment() } }))
                    return true
                }
            })
            .catch(e => {
                console.error(e)
                context.dispatch(TimetableActions.addAttendanceStatus({ [params.eventId]: { sent: true, hasError: true } }))
                throw 'Could not send attendance data'
            })
        return true
    } catch (e) {
        context.dispatch(TimetableActions.addAttendanceStatus({ [params.eventId]: { sent: false, hasError: true } }))
        throw 'Could not submit attendance'
    }
}