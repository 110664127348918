import React from 'react'
import moment from 'moment-timezone'
import { Container, Text, Animated } from 'primitives'
import { SettingsHelper } from 'redux/helpers'
import { MessageAttachments, CustomText, BeaconAvatar } from './'
import { fonts } from 'lib/config/ui-config'
import { Platform } from 'lib/primitives'

export const MessageItem = ({ message, latestMessage, previousMessage, nextMessage, width }) => {
    const colors = SettingsHelper.getColors()
    const isWeb = Platform.isWeb()

    const messageStatus = {
        userIsBeacon: message.user.name == 'Beacon',
        isCurrent: moment(message.createdAt).isSameOrAfter(moment().subtract(3, 'seconds')), //message._id == latestMessage._id &&
        isPreviousSameUser: previousMessage && message.user.name == previousMessage.user.name,
        isNextSameUser: nextMessage && message.user.name == nextMessage.user.name,
        isPreviousSameTimestamp: previousMessage && moment(message.createdAt).isSame(moment(previousMessage.createdAt), 'minute'),
        isNextSameTimestamp: nextMessage && moment(message.createdAt).isSame(moment(nextMessage.createdAt), 'minute')
    }

    return (
        <Container
            flex={0}
            marginTop={!previousMessage ? 10 : 0}
        >
            <MessageDate message={message} colors={colors} previousMessage={previousMessage} />
            <Container
                flex={0}
                marginBottom={5}
                flexDirection={'row'}
                alignItems={'flex-end'}
                marginRight={isWeb ? 10 : 0}
            >
                <Container flex={0} width={43}>
                    {messageStatus.userIsBeacon && !messageStatus.isNextSameUser && <BeaconAvatar />}
                </Container>
                <Container flex={1} minWidth={0}>
                    <MessageBubble message={message} colors={colors} messageStatus={messageStatus} latestMessage={latestMessage} width={width} />
                </Container>
            </Container>
            <MessageTimestamp message={message} colors={colors} messageStatus={messageStatus} />
        </Container >
    )
}

const BubbleAnimation = {
    start: {
        opacity: 0,
        translateY: 100,
        transform: `translateY(100px)`
    },
    end: {
        opacity: 1,
        translateY: 0,
        transform: `translateY(0px)`
    }
}

const MessageBubble = ({ message, latestMessage, colors, messageStatus, width }) => {
    if (messageStatus.isCurrent) {
        return (
            <Animated
                display={'flex'}
                flex={0}
                animations={BubbleAnimation}
                current={'end'}
                initial={'start'}
                config={{ tension: 210, friction: 20 }}
            >
                <MessageInner message={message} colors={colors} messageStatus={messageStatus} latestMessage={latestMessage} width={width} key={message._id}/>
            </Animated>
        )
    }

    return (
        <Container flex={0}>
            <MessageInner message={message} colors={colors} messageStatus={messageStatus} latestMessage={latestMessage} width={width} key={message._id}/>
        </Container>
    )
}

const areEqual = (prevProps, nextProps) => {
    return (prevProps.latestMessage._id != prevProps.message._id) == (nextProps.latestMessage._id != nextProps.message._id) && prevProps.colors == nextProps.colors
}

const MessageInner = React.memo(({ message, latestMessage, colors, messageStatus, width }) => {
    const renderText = () => {
        if (message && message.text) {
            return (
                <Container flex={0} marginLeft={5} marginRight={5} padding={5}>
                    <CustomText text={message.text} textColor={messageStatus.userIsBeacon ? colors.chatBotText : colors.chatUserText} />
                </Container>
            )
        }
        return null
    }
    return (
        <Container
            flex={0}
            flexShrink={1}
            maxWidth={600}
            borderTopLeftRadius={messageStatus.userIsBeacon && messageStatus.isPreviousSameUser && messageStatus.isPreviousSameTimestamp ? 3 : 10}
            borderBottomLeftRadius={messageStatus.userIsBeacon && messageStatus.isNextSameUser && messageStatus.isNextSameTimestamp ? 3 : 10}
            borderTopRightRadius={!messageStatus.userIsBeacon && messageStatus.isPreviousSameUser && messageStatus.isPreviousSameTimestamp ? 3 : 10}
            borderBottomRightRadius={!messageStatus.userIsBeacon && messageStatus.isNextSameUser && messageStatus.isNextSameTimestamp ? 3 : 10}
            backgroundColor={messageStatus.userIsBeacon ? colors.chatBotBg : colors.chatUserDefault}
            marginLeft={messageStatus.userIsBeacon ? 0 : 'auto'}
            marginRight={messageStatus.userIsBeacon ? 'auto' : 0}
        >
            {renderText()}
            <MessageAttachments currentMessage={message} latestMessage={latestMessage} width={width} />
        </Container>
    )
}, areEqual)

const MessageDate = ({ message, colors, previousMessage }) => {
    if (!previousMessage || !moment(message.createdAt).isSame(moment(previousMessage.createdAt), 'day')) {
        return (
            <Container
                flex={0}
                position={'relative'} zIndex={5}
                marginTop={10}
                marginBottom={10}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Container flex={1} height={3} marginRight={10} marginLeft={10} borderRadius={2} backgroundColor={colors.screenBorder} />
                <Text
                    color={colors.screenAltText}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                >
                    {moment(message.createdAt).format('dddd, DD MMMM')}
                </Text>
                <Container flex={1} height={3} marginRight={10} marginLeft={10} borderRadius={2} backgroundColor={colors.screenBorder} />
            </Container>
        )
    }
    return null
}

const MessageTimestamp = ({ message, colors, messageStatus }) => {
    if (!messageStatus.isNextSameUser || !messageStatus.isNextSameTimestamp) {
        return (
            < Container
                flex={0}
                marginLeft={messageStatus.userIsBeacon ? 43 : 'auto'}
                marginRight={messageStatus.userIsBeacon ? 'auto' : 0}
                paddingLeft={5}
                paddingRight={5}
                marginBottom={messageStatus.isNextSameUser ? 10 : 5}
            >
                <Text
                    color={colors.chatBotAltText}
                    fontSize={fonts.size.small}
                    lineHeight={fonts.size.small * 1.5}
                >
                    {`${message.user.name} - ${moment(message.createdAt).format('HH:mm')}`}
                </Text>
            </Container>
        )
    }
    return null
}