import React from 'react'
import PropTypes from 'prop-types'
import { AnimatedPrimitive } from './AnimatedPrimitive'

export const Animated = ({children, ...props}) => {
    return <AnimatedPrimitive {...props} >{children}</AnimatedPrimitive>
}

Animated.propTypes = {
    animations: PropTypes.object,
    initial: PropTypes.string,
    current: PropTypes.string,
    config: PropTypes.object,
    rasterize: PropTypes.bool,
};

Animated.defaultProps = {
    config: { mass: 0.5, tension: 210, friction: 12 },
    rasterize: true,
};