import React, { useState, useEffect, useRef } from 'react'

export function useThrottle(value, limit) {
    const [throttledValue, setThrottledValue] = useState(value)
    const lastRan = useRef(Date.now())

    useEffect(() => {
        const id = setTimeout(() => {
            if (Date.now() - lastRan.current >= limit) {
                setThrottledValue(value)
                lastRan.current = Date.now()
            }
        }, limit - (Date.now() - lastRan.current))
        return () => {
            clearTimeout(id)
        }
    }, [value, limit])

    return throttledValue
}

export function useDelayThrottle(value, limit) {
    const [throttledValue, setThrottledValue] = useState(value)
    const [throttleQueue, setThrottleQueue] = useState([])
    const lastRan = useRef(Date.now())

    useEffect(() => {
        const id = setTimeout(() => {
            if (throttleQueue.length) {
                handleQueue()
            }
        }, limit - (Date.now() - lastRan.current))
        return () => {
            clearTimeout(id)
        }
    }, [limit, value])

    const handleQueue = () => {
        let currentQueue = throttleQueue
        currentQueue.push(value)
        setThrottleQueue(currentQueue)
        handleQueue()
        if (Date.now() - lastRan.current >= limit) {
            if (currentQueue.length) {
                const nextValue = currentQueue.shift()
                console.log(nextValue)
                setThrottledValue(nextValue)
                setThrottleQueue(currentQueue)
                lastRan.current = Date.now()
            }
        }
    }

    return throttledValue
}