const buildingSearchTerms = {
  'Ashley': 'Staffordshire University Business Lounge',
  'Ashley2': 'Staffordshire University, Law School',
  'Ashley 2': 'Staffordshire University, Law School',
  'Blackheath': 'Staffordshire University, Law School',
  'Blackheath Lane': 'Blackheath Lane, Staffordshire University',
  'Beacon': 'Beacon Building, Staffordshire University',
  'Brindley': '53.008390,-2.175931',
  'Cadman': 'Cadman Building, Staffordshire University',
  'Cadman-Thompson': 'Cadman Building, Staffordshire University',
  'Cadman Games': '53.010139,-2.181953',
  'Coalport': '53.0077568,-2.1755889',
  'Crime Scene House': '53.012377,-2.171917',
  'Drama Studio One': '53.009611,-2.179131',
  'Dwight': '53.009987,-2.179625',
  'Flaxman': 'Flaxman Building, Staffordshire University, Flaxman Building, Stoke-on-Trent',
  'Henrion': 'Henrion Gallery, Stoke-On-Trent',
  'Learning Centre': 'Staffordshire University Shrewsbury Campus',
  'Leek Road': 'Staffordshire University Leek Road Campus',
  'Lichfield': 'Staffordshire Lichfield Campus',
  'Mellor': 'Mellor Building, Stoke-On-Trent',
  'Other Buildings Leek Road': '',
  'Performance Centre': '53.009837,-2.179101',
  'Science Centre': 'Staffordshire University Science Centre',
  'Shrewsbury': 'Staffordshire University Shrewsbury Campus',
  'Sir Stanley Mattews Sports Centre': 'Staffordshire University Sports Centre',
  'Stafford': 'Staffordshire University Blackheath Lane',
  'Trent': '53.008008,-2.176990'
}

export const getLectureLocationUrl = (data) => {
  if (data) {
    const { buildingName, buildingLatitude, buildingLongitude } = data
    const baseQueryUrl = 'https://www.google.com/maps/search/?api=1&query='
    let url = null
    let searchTerms = buildingSearchTerms[buildingName]
    if (searchTerms != null) {
      url = `${baseQueryUrl}${searchTerms}`
    } else if (buildingLatitude && buildingLongitude) {
      url = `${baseQueryUrl}${buildingLatitude},${buildingLongitude}`
    }
    return url
  }
  return null
}