import React from 'react'
import { Container, Link } from 'primitives'
import { DefaultTileContent } from './'

export const LinkTile = ({ colors, title, icon, tileWidth, linkTo, hasInternet, isExternal = false, isWebView = false }) => {
  return (
    <Container
      position={'absolute'}
      top={0}
      bottom={0}
      left={0}
      right={0}
      backgroundColor={colors.screenBg}
      margin={5}
      padding={10}
      borderRadius={10}
      opacity={!hasInternet && isWebView ? 0.5 : null}
    >
      <Link to={linkTo} isDisabled={!hasInternet && isWebView} isExternal={isExternal} flex={1} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}>
        <DefaultTileContent
          title={title}
          icon={icon}
          colors={colors}
          tileWidth={tileWidth}
          isExternal={isExternal}
        />
      </Link>
    </Container>
  )
}