import React, { useState, useEffect } from 'react'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const UpdateHelper = (data, id, fetchFunc) => {
    const hasInternet = NetworkInfo.useHasInternet()
    const [prevData, setPrevData] = useState(data)
    const [awaitingUpdate, setAwaitingUpdate] = useState({})
    const [awaitingRefresh, setAwaitingRefresh] = useState({})

    useEffect(() => {
        // Remove differences after data refresh
        if (prevData != null && data != null) {
            const difference = prevData.filter(item => !data.includes(item))
            if (difference.length > 0) {
                let newAwaitingRefresh = awaitingRefresh
                difference.map(item => {
                    delete newAwaitingRefresh[item[id]]
                })
                setAwaitingRefresh(newAwaitingRefresh)
            }
        }
        setPrevData(data)
    }, [data])

    const updateState = (id, state, setState, shouldAdd) => {
        let newState = state
        if (Array.isArray(id)) {
            id.map(item => {
                if (shouldAdd) {
                    newState[item] = true
                } else if (newState[item]) {
                    delete newState[item]
                }
            })
        }
        else {
            if (shouldAdd) {
                newState[id] = true
            } else if (newState[id]) {
                delete newState[id]
            }
        }
        setState(newState)
    }

    const updateItems = (id, updateFunc) => {
        //Update notification preference
        if (hasInternet) {
            //Add to awaiting update
            updateState(id, awaitingUpdate, setAwaitingUpdate, true)
            updateFunc().then(() => {
                // Add to awaiting refresh
                updateState(id, awaitingRefresh, setAwaitingRefresh, true)
                // Remove from awaiting update
                updateState(id, awaitingUpdate, setAwaitingUpdate, false)
                // Refresh data if no other items are updating
                if (Object.keys(awaitingUpdate).length == 0) {
                    fetchFunc()
                }
            })
                .catch(e => {
                    console.log(e)
                    // Remove from awaiting update
                    updateState(id, awaitingUpdate, setAwaitingUpdate, false)
                })
        }
    }

    return { updateItems, awaitingUpdate, awaitingRefresh }

}