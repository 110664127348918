import React from 'react'

export const ButtonPrimitive = ({ disable, accessibility, url, handleAction, handleActionIn, handleActionOut, children, activeOpacity, ...props}) => {
    let style = {...props}
    if(props.flex == 0) style.flex = null

    const handlePress = () => {
        if (disable) return null
        if (handleAction != null) {
            handleAction()
        }
        if (url) {
            window.open(url, '_blank')
        }
    }

    const handlePressIn = () => {
        if (disable) return null
        if (handleActionIn != null) {
            return handleActionIn()
        }
    }

    const handlePressOut = () => {
        if (disable) return null
        if (handleActionOut != null) {
            return handleActionOut()
        }
    }

    return (
        <button aria-required={true} aria-label={accessibility && accessibility.label} onClick={handlePress} onMouseDown={handlePressIn} onMouseUp={handlePressOut} style={style}>{children}</button>
    )

}