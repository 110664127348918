import React from 'react'
import { Svg, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const ContactsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'my contacts icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'my contacts icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinejoin:'round',strokeMiterLimit:10}} d="M27.39,31.11H7.61c-1.47,0-2.67-1.1-2.67-2.45V6.33c0-1.35,1.2-2.45,2.67-2.45h19.78c1.47,0,2.67,1.1,2.67,2.45
                v22.33C30.06,30.01,28.86,31.11,27.39,31.11z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="2.75" y1="17.19" x2="7.13" y2="17.19"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="2.75" y1="22.85" x2="7.13" y2="22.85"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="2.75" y1="11.53" x2="7.13" y2="11.53"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.5,10.9c1.83,0,3.32,1.49,3.32,3.32c0,0.96-0.41,1.81-1.06,2.42c1.85,1.26,3.14,3.99,3.14,7.17H12.1
                c0-3.18,1.29-5.92,3.14-7.17c-0.65-0.61-1.06-1.46-1.06-2.42C14.18,12.39,15.67,10.9,17.5,10.9 M17.5,8.9
                c-2.93,0-5.32,2.39-5.32,5.32c0,0.73,0.15,1.44,0.44,2.1c-1.59,1.84-2.52,4.55-2.52,7.49c0,1.1,0.9,2,2,2h10.8c1.1,0,2-0.9,2-2
                c0-2.94-0.94-5.64-2.52-7.49c0.29-0.66,0.44-1.38,0.44-2.1C22.82,11.29,20.43,8.9,17.5,8.9L17.5,8.9z"/>
        </Svg>
    )
}