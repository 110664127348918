import React from 'react'
import { Svg, Line, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const WorkvivoIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'WorkVivo icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'WorkVivo icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.1,30.8H6.9c-1.8,0-3.3-1.7-3.3-3.8V8C3.5,5.9,5,4.2,6.9,4.2h21.3c1.8,0,3.3,1.7,3.3,3.8v19
                C31.5,29.1,30,30.8,28.1,30.8z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinejoin:'round',strokeMiterlimit:10}} x1="3.6" y1="8" x2="31.5" y2="8"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="6.9" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="8.7" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.5" cy="6.3" r="0.7"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M27.4,18.9"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M7.5,18.9"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M13.7,11.9c0.6,0,1.3,0,1.9,0c0.3,0,0.4-0.1,0.6-0.3c0.2-0.3,0.4-0.4,0.8-0.4c1,0,2,0,3,0
                c0.3,0,0.4-0.1,0.6-0.3c0.2-0.3,0.4-0.4,0.7-0.4c2.2,0,4.3,0,6.5,0c0.2,0,0.4,0,0.6,0.2c0.1,0.2,0,0.4-0.1,0.6
                c-1.1,1.7-2.2,3.4-3.2,5.1c-0.8,1.3-1.6,2.6-2.5,3.9c-0.1,0.2-0.2,0.4,0,0.6c0.2,0.3,0.1,0.6,0.2,0.9c0.1,0.7,0.2,1.3,0.2,2
                c0.1,0.5-0.1,0.6-0.6,0.6c-0.3,0-0.4,0.1-0.3,0.4c0.1,0.5,0.2,1.1,0.3,1.6c0.1,0.3,0.1,0.7,0.2,1c0.1,0.3,0,0.5-0.2,0.6
                c-0.2,0.1-0.4,0-0.6-0.2c-0.5-0.6-1-1.3-1.4-1.9c0,0,0-0.1-0.1-0.1c-0.2-0.2-0.3-0.2-0.5,0c-0.3,0.4-0.5,0.7-0.8,1.1
                c-0.2,0.3-0.4,0.6-0.7,0.9c0,0.1-0.1,0.1-0.2,0.2C18,28,17.9,28.1,17.7,28c-0.2-0.1-0.3-0.2-0.3-0.4c0.1-0.4,0.1-0.7,0.2-1.1
                c0.1-0.5,0.2-1.1,0.3-1.6c0.1-0.4,0-0.5-0.4-0.5c-0.4,0-0.5-0.2-0.5-0.5c0.1-0.9,0.2-1.8,0.3-2.7c0-0.4,0.3-0.5,0.6-0.6
                c0.3-0.1,0.6-0.2,1-0.3c0.1,0,0.2,0,0.2-0.1c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.4-0.1-0.5-0.4-0.5-0.8
                c0-0.5,0-0.9,0-1.4c0-0.3,0.1-0.5,0.4-0.7c0.4-0.2,0.7-0.4,1.1-0.5c0.2-0.1,0.5-0.1,0.7,0c0.4,0.2,0.7,0.4,1.1,0.6
                c0.3,0.1,0.4,0.4,0.4,0.7c0,0.5,0,1,0,1.5c0,0.3-0.1,0.5-0.4,0.6c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.1,0.1-0.1,0.1
                c0,0.1,0.1,0.1,0.1,0.1c0.3,0.1,0.4,0.1,0.6-0.2c0.5-0.7,0.9-1.4,1.4-2.1c0.6-0.9,1.2-1.8,1.7-2.7c0.2-0.3,0.2-0.3-0.2-0.4
                c-0.9-0.2-1.7-0.3-2.6-0.5c-0.6-0.1-1.3-0.2-1.9-0.3c-0.4-0.1-0.4,0-0.6,0.3c-0.4,0.7-0.4,0.7-1.3,0.6c-0.8-0.1-1.5-0.3-2.3-0.5
                c-0.3-0.1-0.4,0-0.6,0.2c-0.3,0.5-0.5,0.5-1,0.2c-1-0.6-1.9-1.3-2.9-1.9c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.3-0.3-0.2-0.5
                c0.1-0.2,0.3-0.3,0.5-0.3C12.4,11.9,13.1,11.9,13.7,11.9C13.7,11.9,13.7,11.9,13.7,11.9z M24.3,11.3c-0.8,0-1.7,0-2.5,0
                c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0.4-0.5,0.8-0.7,1.2c-0.2,0.3-0.1,0.3,0.2,0.4c1.5,0.3,2.9,0.5,4.4,0.8c0.4,0.1,0.5,0.1,0.7-0.3
                c0.4-0.6,0.8-1.3,1.2-1.9c0.1-0.1,0.2-0.2,0.1-0.3c-0.1-0.1-0.2-0.1-0.4-0.1C25.9,11.3,25.1,11.3,24.3,11.3z M22.1,23.3
                c-0.1-0.5-0.1-1-0.2-1.4c0-0.1,0-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.3-0.3,0.5-0.5,0.8c-0.1,0.2-0.2,0.4-0.4,0.6
                c-0.2,0.2-0.4,0.2-0.6,0.1c-0.2-0.1-0.3-0.3-0.2-0.6c0-0.1,0.1-0.2,0.1-0.2c0.2-0.3,0.4-0.7,0.6-1c0.1-0.1,0.2-0.2,0-0.3
                c-0.3-0.2-0.6-0.4-0.9-0.3c-0.5,0.1-0.9,0.3-1.4,0.5c-0.2,0.1-0.3,0.2-0.3,0.4c-0.1,0.5-0.1,0.9-0.2,1.4c0,0.4,0,0.4,0.4,0.5
                c0.5,0,0.6,0.2,0.5,0.6c-0.1,0.4-0.1,0.7-0.2,1.1c0,0.1,0,0.2,0,0.2c0.1,0,0.1-0.1,0.2-0.1c0.2-0.3,0.4-0.6,0.6-0.8
                c0.1-0.2,0.3-0.3,0.5-0.2c0.2,0,0.3,0.1,0.4,0.3c0.2,0.3,0.4,0.5,0.6,0.8c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1
                c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.3,0.1-0.5,0.5-0.5c0.1,0,0.2,0,0.2,0C22,23.6,22.1,23.5,22.1,23.3z M18.4,14
                c0.2,0,0.3-0.1,0.4-0.2c0.3-0.5,0.6-0.9,0.9-1.4c0.2-0.3,0.1-0.4-0.2-0.4c-0.7,0-1.4,0-2.1,0c-0.2,0-0.3,0.1-0.4,0.2
                c-0.2,0.3-0.4,0.6-0.6,1c-0.1,0.2-0.1,0.3,0.1,0.3C17.1,13.7,17.7,13.9,18.4,14z M19,18.3c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.3,0.2,0.4
                c0.7,0.4,0.7,0.4,1.4,0.1c0,0,0,0,0,0c0.3-0.1,0.4-0.3,0.4-0.6c0-0.9,0.1-0.8-0.8-1.2c-0.3-0.1-1.1,0.2-1.3,0.4c0,0.1,0,0.1,0,0.2
                C19,18.1,19,18.2,19,18.3z M14.4,12.8c-0.3,0-0.6,0-0.8,0c-0.1,0-0.1,0-0.2,0.1c0,0.1,0,0.1,0.1,0.2c0.4,0.3,0.8,0.5,1.2,0.8
                c0.2,0.1,0.3,0.1,0.4-0.1c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.3-0.2-0.3C14.9,12.8,14.6,12.8,14.4,12.8z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M16.1,18c0,0.2,0,0.5,0,0.7c0,0.3-0.1,0.5-0.4,0.6c-0.3,0.1-0.5,0.3-0.8,0.4c-0.1,0-0.2,0.1-0.2,0.2
                c0,0.1,0.1,0.1,0.2,0.1c0.4,0.1,0.7,0.2,1.1,0.4c0.3,0.1,0.5,0.3,0.4,0.6c-0.1,0.2-0.3,0.3-0.7,0.2c-0.4-0.1-0.8-0.3-1.2-0.4
                c-0.1,0-0.3-0.1-0.4,0c-0.4,0.2-0.9,0.3-1.3,0.5c-0.2,0.1-0.3,0.2-0.3,0.5c0,0.5-0.1,0.9-0.2,1.4c0,0.3,0,0.4,0.3,0.4
                c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.2,0.3,0.5c0,0.3-0.1,0.7-0.2,1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.2-0.1
                c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.3,0.6-0.3,0.8,0c0.2,0.2,0.4,0.5,0.5,0.7c0,0.1,0.1,0.1,0.2,0.2
                c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.2c-0.1-0.3-0.1-0.6-0.2-0.9c0-0.1,0-0.2,0-0.3c0-0.2,0.1-0.4,0.3-0.5
                c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.1,0.4,0.1,0.7c0.2,0.9,0.3,1.7,0.5,2.6c0,0.1,0,0.3,0,0.4c0,0.2-0.1,0.3-0.2,0.4
                c-0.2,0.1-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.6-0.9-1.1-1.3-1.7c-0.2-0.3-0.3-0.3-0.5,0c-0.4,0.6-0.9,1.2-1.3,1.8
                c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.5,0.1c-0.2-0.1-0.3-0.3-0.2-0.5c0.1-0.3,0.1-0.6,0.2-1c0.1-0.6,0.2-1.2,0.3-1.8
                c0.1-0.4,0-0.4-0.3-0.4c-0.4,0-0.6-0.1-0.5-0.6c0.1-0.9,0.2-1.9,0.3-2.8c0-0.2,0.2-0.3,0.4-0.4c0.5-0.2,1-0.3,1.5-0.5
                c0.1,0,0.2,0,0.2-0.2c0-0.1-0.1-0.1-0.2-0.2c-0.3-0.2-0.6-0.3-0.9-0.4c-0.2-0.1-0.3-0.3-0.3-0.6c0-0.5,0-1.1,0-1.6
                c0-0.3,0.1-0.5,0.3-0.6c0.4-0.2,0.8-0.4,1.2-0.6c0.2-0.1,0.4-0.1,0.6,0c0.4,0.2,0.8,0.4,1.3,0.6c0.2,0.1,0.3,0.3,0.3,0.5
                C16.1,17.5,16.1,17.7,16.1,18C16.1,18,16.1,18,16.1,18z M13.2,18C13.2,18,13.2,18,13.2,18c0,0.2,0,0.3,0,0.5c0,0.2,0.9,0.7,1.1,0.6
                c0.3-0.1,0.5-0.3,0.8-0.4c0.1,0,0.1-0.1,0.1-0.2c0-0.3,0.1-0.6,0-0.9c0-0.2-0.9-0.7-1.1-0.6c-0.3,0.1-0.5,0.2-0.8,0.4
                c-0.1,0.1-0.2,0.2-0.2,0.3C13.2,17.7,13.2,17.8,13.2,18z"/>
        </Svg>
    )
}