import React from 'react'
import { Svg, Circle, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SettingsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'settings icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'settings icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} cx="17.5" cy="17.5" r="5.11"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M20.57,3.14l0.02,2.84c1.96,0.52,3.81,1.54,5.35,3.08l2.43-1.38"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M5.98,20.59L3.57,22c0.52,1.95,1.53,3.79,3.06,5.32l2.43-1.38"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M9.06,9.06L6.64,7.68C5.11,9.21,4.09,11.05,3.57,13l2.41,1.41"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M20.59,5.98l-0.02-2.84c-2.01-0.54-4.13-0.54-6.13,0l-0.02,2.84"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M6.64,7.68l2.43,1.38c1.54-1.54,3.39-2.56,5.35-3.08l0.02-2.84"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M3.57,22l2.41-1.41c-0.54-2.02-0.54-4.15,0-6.17L3.57,13"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M25.94,25.94l2.43,1.38c1.53-1.53,2.54-3.37,3.06-5.32l-2.41-1.41"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M31.43,13l-2.41,1.41c0.54,2.02,0.54,4.15,0,6.17L31.43,22"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M29.02,14.41L31.43,13c-0.52-1.95-1.53-3.79-3.06-5.32l-2.43,1.38"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M14.41,29.02l0.02,2.84c2.01,0.54,4.13,0.54,6.13,0l0.02-2.84"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.36,27.32l-2.43-1.38c-1.54,1.54-3.39,2.56-5.35,3.08l-0.02,2.84"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M14.43,31.86l-0.02-2.84c-1.96-0.52-3.81-1.54-5.35-3.08l-2.43,1.38"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M9.06,25.94c1.54,1.54,3.39,2.56,5.35,3.08"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M5.98,14.41c-0.54,2.02-0.54,4.15,0,6.17"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M20.59,29.02c1.96-0.52,3.81-1.54,5.35-3.08"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M25.94,9.06c-1.54-1.54-3.39-2.56-5.35-3.08"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M29.02,20.59c0.54-2.02,0.54-4.15,0-6.17"/>
        </Svg>
    )
}