import { ExampleTypes } from 'redux/lib/example/ExampleTypes'
import { Namespaces } from 'redux/namespaces'

const initialState = 0

export const ExampleReducer = (state = initialState, action) => {
    switch(action.type) {

        case ExampleTypes.ACTION_EXAMPLE:
            return state + action.payload
            
        default:
            return state
    }
}