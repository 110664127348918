import React from 'react'
import { Svg, Circle, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const ProfileIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'profile icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'profile icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} d="M10.44,24.88h14.13c0-4.16-1.68-7.74-4.09-9.38c0.84-0.79,1.38-1.91,1.38-3.16c0-2.4-1.95-4.35-4.35-4.35
                s-4.35,1.95-4.35,4.35c0,1.25,0.53,2.37,1.38,3.16C12.12,17.14,10.44,20.72,10.44,24.88z"/>
        </Svg>
    )
}