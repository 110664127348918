import { useSelector, useDispatch } from 'react-redux'
import { ChatActions } from 'redux/actions'
import Directline from 'lib/microsoft/directline'
import { Namespaces } from 'redux/namespaces'
import { Platform } from 'lib/primitives'
import Screen from 'lib/screen/screen'
import { BotMessageHelper } from 'lib/microsoft/BotMessageHelper'
import { UserHelper } from 'redux/helpers'
import BeaconIcon from 'assets/images/BeaconIcon.png'
import { Maths } from 'lib/general/Maths'
import moment from 'moment'

const HELLO_FALLBACK_GREETING = 'Hello!'

export class ChatHelper {

    static useHandleReceivedMessage = () => {
        const dispatch = useDispatch()
        const setBeaconIsTyping = ChatHelper.useSetBeaconIsTyping()
        return activity => {
            dispatch(ChatActions.addMessage(BotMessageHelper.convertBotActivityToGiftedMessage(activity)))
            dispatch(ChatActions.setSendGreeting(false))
            setBeaconIsTyping(false)
        }
    }

    static useSendMessageFromBot = () => {
        const handleReceivedMessage = ChatHelper.useHandleReceivedMessage()
        return async (text) => {
            let message = {
                _id: await Maths.uuid(),
                text: text,
                createdAt: new moment(),
                from: {
                    _id: 'BS-STG-1-BOT-1',
                    name: 'Beacon',
                    avatar: BeaconIcon,
                }
            }
            handleReceivedMessage(message)
        }
    }

    static useSendMessage = () => {
        const getUser = UserHelper.useGetUser()
        const userInfo = UserHelper.getUserInfo()
        const dispatch = useDispatch()
        const directline = useSelector(state => state[Namespaces.CHAT].directline)
        return async (giftedMessage, isButton) => {
            const user = await getUser()
            if (user && directline) {
                if (isButton) {
                    giftedMessage._id = await Maths.uuid()
                }
                giftedMessage.createdAt = new moment()
                giftedMessage.user = { _id: 1, name: userInfo && userInfo.firstName ? userInfo.firstName : 'You' }
                directline.sendMessage(user, giftedMessage.text)
                dispatch(ChatActions.addMessage(giftedMessage))
            }
        }
    }

    static useSetupDirectline = () => {
        const dispatch = useDispatch()
        const handleReceivedMessage = ChatHelper.useHandleReceivedMessage()
        const handleReceivedEvent = ChatHelper.useHandleDirectlineEvents()
        const handleBeaconIsTyping = () => dispatch(ChatActions.beaconIsTyping())
        const sendGreeting = useSelector(state => state[Namespaces.CHAT].sendGreeting)
        return (user = null) => {
            let directlineCallbacks = {}
            directlineCallbacks[Directline.CALLBACK_KEYS.RECEIVED_MESSAGE] = handleReceivedMessage
            directlineCallbacks[Directline.CALLBACK_KEYS.EVENT_TYPING] = handleBeaconIsTyping
            directlineCallbacks[Directline.CALLBACK_KEYS.RECEIVED_EVENT] = handleReceivedEvent
            let directline = new Directline(directlineCallbacks, user, sendGreeting)
            dispatch(ChatActions.setSendGreeting(false))
            dispatch(ChatActions.setDirectline(directline))
        }
    }

    static useSetBeaconIsTyping = () => {
        const dispatch = useDispatch()
        return async (isTyping) => {
            dispatch(ChatActions.beaconIsTyping(isTyping))
        }
    }

    static useDisconnectDirectline = () => {
        const dispatch = useDispatch()
        return () => dispatch(ChatActions.disconnectDirectline())
    }

    static useHandleDirectlineEvents = () => {
        return activity => {
            switch (activity.name) {
                case "newAchivement":
                    console.log('new achievement received')
                default:
                    console.log("-------- ⚠️⚠️⚠️ Unhandled event, you should do something about this ⚠️⚠️⚠️ ----------")
                    console.log(activity)
                    console.log("----------------------------------------------------------------------------")
            }
        }
    }

    // If isVisible is not provided, we will toggle the visibility
    static useSetChatMenuVisibility = () => {
        const dispatch = useDispatch()
        const isWeb = Platform.isWeb()
        const screenXS = Screen.isScreenXS()
        const isChatVisible = useSelector(state => state[Namespaces.CHAT].isChatVisible)
        return (isVisible = null) => {
            if (isVisible === null) {
                isVisible = !isChatVisible
            }
            isWeb && !screenXS ? dispatch(ChatActions.setChatMenuVisibility(isVisible)) : dispatch(ChatActions.setChatMenuVisibility(false))
        }
    }

    static getIsChatVisible = () => {
        const isChatVisible = useSelector(state => state[Namespaces.CHAT].isChatVisible)
        const screenXS = Screen.isScreenXS()
        return isChatVisible || screenXS
    }

    static useSetChatDimensions = () => {
        const dispatch = useDispatch()
        return (dimensions) => {
            dispatch(ChatActions.setChatDimensions(dimensions))
        }
    }

}

// TODO: convert this:
// handleNewEventAchievement = (activity) => {
//     if (activity.value != null && activity.value.achievementWithUserData != null) {
//       let currentPending = this.state.pendingAchievements
//       currentPending.push(activity.value.achievementWithUserData)
//       this.setState({ pendingAchievements: currentPending })
//       this.refreshData('achievement')
//     } else if (activity.value != null && activity.value.constellations.length > 0) {
//       let currentPending = this.state.pendingAchievements
//       activity.value.constellations.forEach(constellation => {
//         currentPending.push(constellation)
//       })
//       this.setState({ pendingAchievements: currentPending })
//       this.refreshData('achievement')
//     }
//   }