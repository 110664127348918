import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const HomeActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'home icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'home icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M32.83,17.79l-3.2-3.2l-0.71-0.71v-4.9c0-0.49-0.4-0.89-0.89-0.89h-1.82c-0.49,0-0.89,0.4-0.89,0.89v1.3
                l-7.12-7.12c-0.39-0.39-1.02-0.39-1.41,0L5.37,14.58l-3.2,3.2c-0.39,0.39-0.39,1.02,0,1.41c0.2,0.2,0.45,0.29,0.71,0.29
                s0.51-0.1,0.71-0.29l1.49-1.49v10.63c0,2.02,1.77,3.66,3.95,3.66h5.06c0.55,0,1-0.45,1-1v-8.36h4.83v8.36c0,0.55,0.45,1,1,1h5.06
                c2.18,0,3.95-1.64,3.95-3.66V17.71l1.49,1.49c0.2,0.2,0.45,0.29,0.71,0.29s0.51-0.1,0.71-0.29C33.22,18.81,33.22,18.18,32.83,17.79z
                "/>
        </Svg>
    )
}