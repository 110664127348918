import { UserTypes } from './UserTypes'

export class UserActions {

    static logInAttempt = () => {
        return { type: UserTypes.LOGIN_ATTEMPT, payload: {} }
    }

    static logInSuccess = authState => {
        return { type: UserTypes.LOGIN_SUCCESS, payload: authState }
    }

    static logInFailure = error => {
        return { type: UserTypes.LOGIN_FAILURE, payload: error }
    }

    static logOut = () => {
        return { type: UserTypes.LOGOUT, payload: {} }
    }

    static tokenInvalid = () => {
        return { type: UserTypes.TOKEN_INVALID, payload: {} }
    }

    static getUser = user => {
        return { type: UserTypes.GET_USER, payload: user }
    }

    static setUserInfo = info => {
        return { type: UserTypes.SET_USER_INFO, payload: info }
    }

    static setStudentInfo = info => {
        return { type: UserTypes.SET_STUDENT_INFO, payload: info }
    }

    static setShowOnboarding = show => {
        return { type: UserTypes.SET_SHOW_ONBOARDING, payload: show }
    }

    static cannotLoadProfile = () => {
        return { type: UserTypes.CANNOT_LOAD_PROFILE, payload: {} }
    }
}


