import React from 'react'
import { icons } from 'lib/general/enums'
import {
    HomeIcon,
    HomeActiveIcon,
    ProfileIcon,
    ProfileActiveIcon,
	SettingsIcon,
	SettingsActiveIcon,
    CoursesIcon,
    CoursesActiveIcon,
    TimetableIcon,
    TimetableActiveIcon,
    NotificationsIcon,
    NotificationsActiveIcon,

	SuggestionsIcon,
	SuggestionsActiveIcon,
    AchievementsIcon,
    MoodIcon,
    NewsIcon,
    EventIcon,
    NewsAndEventsIcon,
    SuEventsIcon,
	SocietiesIcon,
	StudentsUnionIcon,
    StaffDirectoryIcon,
	PcAvailabilityIcon,
	BlackboardIcon,
    TogetherallIcon,
	EVisionIcon,
	IrisIcon,
    MyViewIcon,
	WorkvivoIcon,
	SkillSiteIcon,
	AppointmentBookingIcon,
	RoomLocationIcon,

    LogOutIcon,
    SearchIcon,
    EmailIcon,
    EmailActiveIcon,
    ContactsIcon,
	LocationActiveIcon,
	ExternalLinkIcon,
	OnlineIcon,
    LocalIcon,

    SendMessageIcon,
    SpeechToTextIcon,
    ChatMenuIcon,

    AccessibilityIcon,
    InterestsIcon,
    FeedbackIcon,
    ColorThemesIcon,
    AppInfoIcon,
    OnboardingIcon,

    MoodFantasticIcon,
    MoodGoodIcon,
    MoodNeutralIcon,
    MoodBadIcon,
    MoodTerribleIcon,

    CrossIcon,
    RightArrowIcon,
    DoubleRightArrowIcon,
    DownArrowIcon,
	StarIcon,
	StarEmptyIcon,
    BackgroundKnotIcon,
    WarningIcon,
    WarningActiveIcon,
    RefreshIcon,
	SubMenuIcon,
	SuccessIcon,
    SuccessActiveIcon,
    CancelIcon,
	CancelActiveIcon,
	GradientIcon,
	SuniLogoIcon
} from './icons'

const Icon = props => {
	switch (props.type) {
		// ------------------------------------
		// ------------- Screens --------------
		// ------------------------------------
		case icons.home: return <HomeIcon {...props} />
		case icons.homeActive: return <HomeActiveIcon {...props} />
		case icons.profile: return <ProfileIcon {...props} />
		case icons.profileActive: return <ProfileActiveIcon {...props} />
		case icons.settings: return <SettingsIcon {...props} />
		case icons.settingsActive: return <SettingsActiveIcon {...props} />
		case icons.courses: return <CoursesIcon {...props} />
		case icons.coursesActive: return <CoursesActiveIcon {...props} />
		case icons.timetable: return <TimetableIcon {...props} />
		case icons.timetableActive: return <TimetableActiveIcon {...props} />
		case icons.notifications: return <NotificationsIcon {...props} />
		case icons.notificationsActive: return <NotificationsActiveIcon {...props} />
		// ------------------------------------
		// -------------- Tiles ---------------
		// ------------------------------------
		case icons.suggestions: return <SuggestionsIcon {...props} />
		case icons.suggestionsActive: return <SuggestionsActiveIcon {...props} />
		case icons.achievements: return <AchievementsIcon {...props} />
		case icons.mood: return <MoodIcon {...props} />
		case icons.news: return <NewsIcon {...props} />
		case icons.event: return <EventIcon {...props} />
		case icons.newsAndEvents: return <NewsAndEventsIcon {...props} />
		case icons.suEvents: return <SuEventsIcon {...props} />
		case icons.societies: return <SocietiesIcon {...props} />
		case icons.studentsUnion: return <StudentsUnionIcon {...props} />
		case icons.staffDirectory: return <StaffDirectoryIcon {...props} />
		case icons.pcAvailability: return <PcAvailabilityIcon {...props} />
		case icons.blackboard: return <BlackboardIcon {...props} />
		case icons.togetherall: return <TogetherallIcon {...props} />
		case icons.eVision: return <EVisionIcon {...props} />
		case icons.iris: return <IrisIcon {...props} />
		case icons.myView: return <MyViewIcon {...props} />
		case icons.workvivo: return <WorkvivoIcon {...props} />
		case icons.skillSite: return <SkillSiteIcon {...props} />
		case icons.appointmentBooking: return <AppointmentBookingIcon {...props} />
		case icons.roomLocation: return <RoomLocationIcon {...props}/>
		// ------------------------------------
		// -------------- Utils ---------------
		// ------------------------------------
		case icons.logOut: return <LogOutIcon {...props} />
		case icons.search: return <SearchIcon {...props} />
		case icons.email: return <EmailIcon {...props} />
		case icons.emailActive: return <EmailActiveIcon {...props} />
		case icons.contacts: return <ContactsIcon {...props} />
		case icons.locationActive: return <LocationActiveIcon {...props} />
		case icons.externalLink: return <ExternalLinkIcon {...props} />
		case icons.online: return <OnlineIcon {...props} />
		case icons.local: return <LocalIcon {...props} />
		// ------------------------------------
		// -------------- Chat ----------------
		// ------------------------------------
		case icons.sendMessage: return <SendMessageIcon {...props} />
		case icons.speechToText: return <SpeechToTextIcon {...props} />
		case icons.chatMenu: return <ChatMenuIcon {...props} />
		// ------------------------------------
		// ------------ Settings --------------
		// ------------------------------------
		case icons.accessibility: return <AccessibilityIcon {...props} />
		case icons.interests: return <InterestsIcon {...props} />
		case icons.feedback: return <FeedbackIcon {...props} />
		case icons.colorThemes: return <ColorThemesIcon {...props} />
		case icons.appInfo: return <AppInfoIcon {...props} />
		case icons.onboarding: return <OnboardingIcon {...props} />
		// ------------------------------------
		// --------------- Mood ---------------
		// ------------------------------------
		case icons.moodFantastic: return <MoodFantasticIcon {...props} />
		case icons.moodGood: return <MoodGoodIcon {...props} />
		case icons.moodNeutral: return <MoodNeutralIcon {...props} />
		case icons.moodBad: return <MoodBadIcon {...props} />
		case icons.moodTerrible: return <MoodTerribleIcon {...props} />
		// ------------------------------------
		// ------------- General --------------
		// ------------------------------------
		case icons.cross: return <CrossIcon {...props} />
		case icons.rightArrow: return <RightArrowIcon {...props} />
		case icons.doubleRightArrow: return <DoubleRightArrowIcon {...props} />
		case icons.downArrow: return <DownArrowIcon {...props} />
		case icons.star: return <StarIcon {...props} />
		case icons.starEmpty: return <StarEmptyIcon {...props}/>
		case icons.backgroundKnot: return <BackgroundKnotIcon {...props} />
		case icons.warning: return <WarningIcon {...props} />
		case icons.warningActive: return <WarningActiveIcon {...props} />
		case icons.refresh: return <RefreshIcon {...props} />
		case icons.subMenu: return <SubMenuIcon {...props} />
		case icons.success: return <SuccessIcon {...props} />
		case icons.successActive: return <SuccessActiveIcon {...props} />
		case icons.cancel: return <CancelIcon {...props} />
		case icons.cancelActive: return <CancelActiveIcon {...props} />
		case icons.gradient:  return <GradientIcon {...props} />
		case icons.suniLogo: return <SuniLogoIcon {...props} />

		default: return null
	}
}

export { Icon, icons }