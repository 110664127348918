import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const RefreshIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'refresh icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'refresh icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M32.16,13.28h-2.28c-1.76-5.15-6.63-8.87-12.38-8.87c-5.51,0-10.22,3.42-12.15,8.25
                c0.47-0.59,1.17-0.95,1.93-0.95c0.83,0,1.6,0.41,2.06,1.1l0,0.01c1.63-2.82,4.67-4.73,8.15-4.73c3.67,0,6.86,2.12,8.41,5.2h-2.63
                c-0.73,0-1.16,0.81-0.75,1.41l4.44,6.62c0.36,0.54,1.15,0.54,1.51,0l4.44-6.62C33.31,14.09,32.88,13.28,32.16,13.28z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M27.72,23.29L27.72,23.29c-0.83,0-1.6-0.41-2.06-1.1l0,0c-1.63,2.82-4.67,4.73-8.15,4.73
                c-3.67,0-6.86-2.12-8.41-5.2h2.63c0.73,0,1.16-0.81,0.75-1.41l-4.44-6.62c-0.36-0.54-1.15-0.54-1.51,0L2.09,20.3
                c-0.4,0.6,0.03,1.41,0.75,1.41h2.28c1.76,5.15,6.63,8.87,12.38,8.87c5.51,0,10.22-3.42,12.15-8.25
                C29.18,22.93,28.48,23.29,27.72,23.29z"/>
        </Svg>
    )
}