import React from 'react'
import { Svg, Circle, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const InterestsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'interests icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'interests icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterlimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M21.84,11.7c-2.39,0-4.33,1.94-4.33,4.33c0-2.39-1.94-4.33-4.33-4.33c-2.39,0-4.33,1.94-4.33,4.33
                c0,0-0.89,5.62,8.66,10.51c6.62-3.12,8.18-7.59,8.54-9.54c0.02-0.09,0.03-0.18,0.05-0.28c0.07-0.44,0.07-0.69,0.07-0.69
                C26.17,13.64,24.23,11.7,21.84,11.7z"/>
        </Svg>
    )
}