// ===================================================================================================
// == 					 																			==
// == 					 			ENVIRONEMENT CONFIG : PRODUCTION								==
// == 					 																			==
// == 				The file is copied from ./config/{environement}/env-config.js					==
// == 					 																			==
// == 				Do not edit the version in ./src/lib/config/env-config.js						==
// == 					 																			==
// == 		    To update the config, run: npm run pre_build:{development|test|production}			==
// == 					 																			==
// == 		    	   To get the config: import envConfig from 'lib/config/env-config'				==
// == 					 																			==
// =================================================================================================== 

exports.config = {
	environment: "PRODUCTION",
	bot: {
		// url: `https://bs-prod-1-bot-2.azurewebsites.net` // BEFORE SEPT 2020
		url: `https://bs-prod-1-bot-3.azurewebsites.net`	// AFTER SEPT 2020
	},
	directline: {
		domain: `https://directline.botframework.com/v3/directline`,
		webSocket: true,
		// secret: "miZ-wIJ9KCU.E06o2TbOxe8q5j_AxrU7d1lBISoR9OSIukx4TAfzH3E"  	// RG-PRD-1-BOT-2/BS-PRD-1-BOT-2 - BEFORE SEPT 2020
		secret: "cNu4eKkUnEI.JZsbqk6xFZGCVjsDKKIESOnVTqq-rO4adFoU_FYa_jI"  	// RG-PRD-1-BOT-2/BS-PRD-1-BOT-3 - AFTER SEPT 2020
	},
	appRegistration: {
		tenantId: '57af78f2-c87d-4466-b7bb-6b6cc99ed124',					// Staffordshire University
		clientId: '33ef8cc5-abf3-40b8-be93-4f064308db42'					// Beacon
	},
	urls: {																	// Will be used for deep linking
		auth: 'beacon-auth',
		native: 'beacon',
		webProtocol: 'https',
		web: 'beacon.staffs.ac.uk'
	},
	emails : {
		timetables: 'timetabling@staffs.ac.uk'
	}
}

// ===================================================================================================
// == 					 																			==
// == 					 			       DEPLOYMENT STRATEGY										==
// == 					 																			==
// == 				For every new release we make the app point to a different web app bot		    ==
// == 				            (Either bs-prod-1-bot-2 or bs-prod-1-bot-3)		    				==
// == 				   	this is done by changing bot.url and directline.secret above				==
// == 					 																			==
// ==             This allow us to deploy to a production environement without affecting 			==															==
// ==             the current production app. That web app bot will point to the resources on		==															==
// == 			  the -beta namespace on Kubernetes. Once the app is approved by Google Play 		== 																			==
// == 		      App Store then we will deploy the resources to the prod kubernetes cluster 	 	==																		==
// == 			  and make the "new" web app bot point to it		 								==											==
// == 					 																			==
// ===================================================================================================