import { Namespaces } from 'redux/namespaces'

export const UserTypes = {
    LOGIN_ATTEMPT: `${Namespaces.USER}_LOGIN_ATTEMPT`,
    LOGIN_SUCCESS: `${Namespaces.USER}_LOGIN_SUCCESS`,
    LOGIN_FAILURE: `${Namespaces.USER}_LOGIN_FAILURE`,
    LOGOUT: `${Namespaces.USER}_LOGOUT`,
    TOKEN_INVALID: `${Namespaces.USER}_TOKEN_INVALID`,
    SET_USER_INFO: `${Namespaces.USER}_SET_USER_INFO`,
    SET_STUDENT_INFO: `${Namespaces.USER}_SET_STUDENT_INFO`,
    SET_SHOW_ONBOARDING: `${Namespaces.USER}_SET_SHOW_ONBOARDING`,
    CANNOT_LOAD_PROFILE: `${Namespaces.USER}_CANNOT_LOAD_PROFILE`,
}