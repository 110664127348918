const envConfig = require('lib/config/env-config').config

export class Environment {
    static DEVELOPMENT = "DEVELOPMENT"
    static TEST = "TEST"
    static PRODUCTION = "PRODUCTION"

    static getEnv = () => envConfig.environment
    static isDev = () => envConfig.environment === Environment.DEVELOPMENT
    static isTest = () => envConfig.environment === Environment.TEST
    static isProd = () => envConfig.environment === Environment.PRODUCTION
}