import { AlertPrimitive } from './AlertPrimitive'

export class Alert {

    static confirm = ({
        alertTitle = "Confirmation",
        alertDescription = "Are you sure?",
        cancelBtnFunc = () => console.log("Cancel button pressed"),
        okBtnFunc = () => console.log("Ok button pressed"),
    } = {}) => {
        AlertPrimitive.confirm(
            alertTitle,
            alertDescription,
            cancelBtnFunc,
            okBtnFunc,
        )
    }




}
