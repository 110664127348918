// ------------------------------------
// ------------- Loading --------------
// ------------------------------------

export const loadingSteps = {
    notStarted: 1,
    checkedLocalStorage: 2,
    checkedVersion: 3,
    checkedToken: 4
}

export const loadingContent = {
    default: 1,
    noConnection: 2,
    versionCheck: 3,
    signIn: 4
}

// ------------------------------------
// -------------- Chat ----------------
// ------------------------------------

export const actionTypes = {
    menu: 1,
    modal: 2,
    message: 3,
    speech: 4,
    logout: 5
}

// ------------------------------------
// ------------- Modals ---------------
// ------------------------------------

export const modals = {
    settings: 1,
    suggestions: 2,
    achievements: 3,
    news: 4,
    mood: 5,
    notifications: 6,
    newAchievement: 7,
    newNotification: 8,
    warning: 9
}

export const settings = {
    accessibility: 1,
    interests: 2,
    feedback: 3,
    colorThemes: 4,
    appInfo: 5,
    signOut: 6
}

export const achievementTypes = {
    special: 1,
    progressive: 2,
    constellation: 3
}

export const achievementLevels = {
    special: 1,
    bronze: 2,
    silver: 3,
    gold: 4,
    constellation: 5,
    none: 6
}

export const achievementBackgrounds = {
    [achievementLevels.special]: '',
    [achievementLevels.bronze]: 'https://saprod1bot1.blob.core.windows.net/beacon-achievements/Achievement_Bronze.png',
    [achievementLevels.silver]: 'https://saprod1bot1.blob.core.windows.net/beacon-achievements/Achievement_Silver.png',
    [achievementLevels.gold]: 'https://saprod1bot1.blob.core.windows.net/beacon-achievements/Achievement_Gold.png',
    [achievementLevels.constellation]: 'https://saprod1bot1.blob.core.windows.net/beacon-achievements/Achievement_Constellation.png',
    [achievementLevels.none]: 'https://saprod1bot1.blob.core.windows.net/beacon-achievements/Achievement_Empty.png'
}

export const newsTypes = {
    news: 1,
    event: 2
}

export const notificationTypes = {
    timetable: 1,
    news: 2,
    event: 3,
    mood: 4
}

// ------------------------------------
// -------------- Icons ---------------
// ------------------------------------

export const icons = {
    // Navigation
    home: 1,
    homeActive: 2,
    profile: 3,
    profileActive: 4,
    settings: 5,
    settingsActive: 6,
    courses: 7,
    coursesActive: 8,
    timetable: 9,
    timetableActive: 10,
    notifications: 11,
    notificationsActive: 12,

    // Tiles
    suggestions: 101,
    suggestionsActive: 102,
    achievements: 103,
    mood: 104,
    news: 105,
    event: 106,
    newsAndEvents: 107,
    suEvents: 108,
    societies: 109,
    studentsUnion: 110,
    staffDirectory: 111,
    pcAvailability: 112,
    blackboard: 113,
    togetherall: 114,
    eVision: 115,
    iris: 116,
    myView: 117,
    workvivo: 118,
    skillSite: 119,
    appointmentBooking: 120,
    roomLocation: 121,

    // Utils
    logIn: 201,
    logOut: 202,
    search: 203,
    email: 204,
    emailActive: 205,
    contacts: 206,
    locationActive: 207,
    externalLink: 208,
    online: 209,
    local: 210,

    // Chat
    chatBubble: 301,
    sendMessage: 302,
    speechToText: 303,
    chatMenu: 304,

    // Settings
    accessibility: 401,
    interests: 402,
    feedback: 403,
    colorThemes: 404,
    appInfo: 405,
    onboarding: 406,

    // Mood
    moodFantastic: 501,
    moodGood: 502,
    moodNeutral: 503,
    moodBad: 504,
    moodTerrible: 505,

    // General
    cross: 601,
    rightArrow: 602,
    doubleRightArrow: 603,
    downArrow: 604,
    star: 605,
    starEmpty: 606,
    backgroundKnot: 607,
    warning: 608,
    warningActive: 609,
    refresh: 610,
    subMenu: 611,
    success: 612,
    successActive: 613,
    cancel: 614,
    cancelActive: 615,
    gradient: 616,
    suniLogo: 617
}

// ------------------------------------
// ---------- Colour Themes -----------
// ------------------------------------

export const colorThemes = {
    basic: 1,
    dark: 2,
    highContrast: 3
}

// ------------------------------------
// ------------- Features -------------
// ------------------------------------

export const features = {
    attendance: 'ATTENDANCE',
    lectureFeedBack: 'LECTURE_FEEDBACK',
    lecturerAbsent: 'LECTURER_ABSENT',
    mood: 'MOOD',
    // Tiles (Temporary to turn on/off tiles)
    tileNewsEvents: 'TILE_NEWS_EVENTS',
    tileRoomSearch: 'TILE_ROOM_SEARCH',
    tileTimetable: 'TILE_TIMETABLE',
    tileTimetableUpcomingEvents: 'TILE_TIMETABLE_UPCOMING_EVENTS',
    tileBeaconChat: 'TILE_BEACON_CHAT',
    tileEmail: 'TILE_EMAIL',
    tileEVision: 'TILE_EVISION',
    tileCovid19: 'TILE_COVID',
    tileSUEvents: 'TILE_SU_EVENTS',
    tileSUSocieties: 'TILE_SU_SOCIETIES',
    tileSU: 'TILE_SU',
    tileBlackboard: 'TILE_BLACKBOARD',
    tileSkillSiteStaff: 'TILE_SKILLSITE_STAFF',
    tileSkillSiteStudent: 'TILE_SKILLSITE_STUDENT',
    tileIris: 'TILE_IRIS',
    tileMyView: 'TILE_MYVIEW',
    tileWorkvivo: 'TILE_WORKVIVO',
    tileTogetherall: 'TILE_TOGETHERALL',
    tileAppointment: 'TILE_APPOINTMENT',
    tilePCAvailability: 'TILE_PC_AVAILABILITY',
    tileStaffDirectory: 'TILE_STAFF_DIRECTORY',
    tileContacts: 'TILE_CONTACTS',
    tileFeedback: 'TILE_FEEDBACK',
}

// ------------------------------------
// ------------ Animation -------------
// ------------------------------------

export const beaconAnimations = {
    none: 1,
    staticWaving: 2,
    waving: 3,
    wavingTransition: 4,
    thinking: 5,
    thinkingTransition: 6,
}

export const modalAnimations = {
    none: 1,
    hidden: 2,
    visible: 3,
    up: 4,
    down: 5,
    jump: 6,
    confetti: 7,
    star: 8
}

