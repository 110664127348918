import React from 'react'
import moment from 'moment'
import { TimetableWeek, TimetableEvent } from 'components/timetable'
import { AuthenticatedRoute, routes } from 'navigation'

export const Timetable = props => {
  return (
    <React.Fragment>
      <AuthenticatedRoute exact path={routes.timetable.default} redirect={{ to: `${routes.timetable.default}/week/week-${moment().week()}-${moment().year()}`, from: routes.timetable.default }}/>
      <AuthenticatedRoute path={routes.timetable.weekView} render={() => <TimetableWeek/>}/>
      <AuthenticatedRoute path={routes.timetable.event} render={() => <TimetableEvent/>}/>
    </React.Fragment>
  )
}