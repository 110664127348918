import { runWithAdal } from 'react-adal';
import { authContext } from 'lib/auth/adalConfig';

const DO_NOT_LOGIN = true;

runWithAdal(authContext, () => {
    // eslint-disable-next-line
    require('./indexApp.js');
}, DO_NOT_LOGIN);

// This index wrap is needed because ADAL use iframes for token silent refresh, 
// and we do not want to have duplicated ReactApp started on iframes too!