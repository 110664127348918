import React from 'react'
import { Svg, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const BlackboardIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'Blackboard icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'Blackboard icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.13,25.95H6.87c-1.83,0-3.32-1.49-3.32-3.32V6.35c0-1.83,1.49-3.32,3.32-3.32h21.27
                c1.83,0,3.32,1.49,3.32,3.32v16.28C31.46,24.45,29.96,25.95,28.13,25.95z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="11.54" y1="31.91" x2="17.5" y2="25.95"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="17.5" y1="25.95" x2="23.46" y2="31.91"/>
            <Path scale={props.size/35} style={{fillRule:'evenodd',clipRule:'evenodd',fill:props.color}} d="M8.94,10.9c0-2.6-1.98-1.57-1.98-2.46c0-0.34,0.21-0.27,0.34-0.27c0.07,0,0.21,0.07,1.09,0.07
                c1.64,0,3.22-0.07,4.79-0.07c4.65,0,4.65,2.8,4.65,3.22c0,1.23-0.68,2.26-2.05,2.67v0.07c2.33,0.55,3.01,1.57,3.01,3.28
                c0,2.53-1.71,4.1-4.79,4.1c-2.8,0-3.76-0.07-4.72-0.07c-0.48,0-0.96,0.07-1.37,0.07c-0.41,0-0.62,0-0.62-0.27
                c0-0.62,1.64-0.14,1.64-1.98L8.94,10.9L8.94,10.9z M11.27,12.95c0,0.89,0.21,1.03,1.3,1.09h1.09c1.44,0,1.98-0.75,1.98-2.12
                c0-1.78-1.37-2.94-3.08-2.94c-1.23,0-1.3,0.68-1.3,1.78V12.95L11.27,12.95z M11.27,18.42c0,1.78,0.48,2.33,2.33,2.33
                s2.8-1.23,2.8-3.08c0-1.09-0.55-2.8-3.97-2.8c-1.09,0-1.16,0.48-1.16,1.44L11.27,18.42L11.27,18.42z M19.75,10.01
                c0-1.37-1.16-0.55-1.16-1.23c0-0.48,0.89-0.21,2.67-1.3c0.14-0.07,0.21-0.14,0.34-0.14c0.21,0,0.14,0.41,0.14,0.55v7.46
                c0.75-0.68,1.57-1.3,2.53-1.3c2.19,0,3.01,1.92,3.01,3.22c0,2.87-1.64,4.38-3.97,4.38c-1.71,0-2.26-0.75-2.53-0.75
                c-0.34,0-0.55,0.75-0.96,0.75c-0.14,0-0.21-0.07-0.21-0.21c0-0.14,0.14-0.55,0.14-1.51L19.75,10.01L19.75,10.01z M21.73,19.04
                c0,1.57,1.44,1.92,1.98,1.92c1.57,0,1.92-1.51,1.92-2.74c0-1.44-0.68-2.8-2.46-2.8c-0.55,0-1.44,0.34-1.44,1.03V19.04L21.73,19.04z"
                />
            <Path scale={props.size/35} style={{fill:props.color}} d="M29.71,15.49h-1.5v-1h1.5V15.49z M27.71,15.49h-1.5v-1h1.5V15.49z M19.41,15.49h-1.5v-1h1.5V15.49z M8.71,15.49
                h-1.5v-1h1.5V15.49z M6.71,15.49h-1.5v-1h1.5V15.49z"/>
        </Svg>
    )
}