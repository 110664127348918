import { RuntimeTypes } from './RuntimeTypes'
import { getWidth, getHeight } from 'lib/screen/dimensions'

const initialState = {
    height: getHeight(),
    width: getWidth(),
    online: true,
    keyboardVisible: false,
    screenAnimating: false,
    versionUpToDate: true,
    versionUpdateDetails: {} // If needs upgrading: { upgradeNeeded: true, response: 'Current app version does not meet ...',  text: '...' }
}

export const RuntimeReducer = (state = initialState, action) => {
    switch (action.type) {

        case RuntimeTypes.SET_WINDOW_HEIGHT:
            return Object.assign({}, state, {
                height: action.payload
            })

        case RuntimeTypes.SET_WINDOW_WIDTH:
            return Object.assign({}, state, {
                width: action.payload
            })

        case RuntimeTypes.SET_ONLINE:
            return Object.assign({}, state, {
                online: action.payload
            })

        case RuntimeTypes.SET_KEYBOARD_VISIBLE:
            return Object.assign({}, state, {
                keyboardVisible: action.payload
            })

        case RuntimeTypes.SET_SCREEN_ANIMATING:
            return Object.assign({}, state, {
                screenAnimating: action.payload
            })

        case RuntimeTypes.SET_VERSION_CHECK:
            if (action.payload && action.payload.upgradeNeeded === true) {
                return Object.assign({}, state, {
                    versionUpToDate: false,
                    versionUpdateDetails: action.payload
                })
            } else {
                return Object.assign({}, state, {
                    versionUpToDate: true
                })
            }

        default:
            return state
    }
}