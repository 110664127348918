import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general/ScreenContainer'
import { Container, Text, Br } from 'primitives'
import { StyledButton } from 'components/general/StyledButton'
import { UserHelper } from 'redux/helpers'


import { WebView } from 'primitives'

export const WebViewScreen = props => {

  return (
    <WebView
      uri={props.uri}
      javaScriptEnabled={true}
      injectedJavascript={props.injectedJavascript || null}
    />
  )
}

