import React from 'react'
import { Svg, Path, Polygon, Rect } from 'primitives'
import { Platform } from 'lib/primitives'

export const SuniLogoIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'Staffordshire University logo'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'Staffordshire University logo'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '257.8 270.5'}`}>
            <Polygon scale={props.size/270.5} style={{fill:'#FFFFFF'}} points="0,0 0,218.9 51.8,270.5 257.8,270.5 257.8,0 "/>
            <Path scale={props.size/270.5} style={{fill:'#010101'}} d="M33.7,162.1c-1.5-1.4-3.5-2-5.6-2c-3.6,0-7.2,1.9-7.2,5.9c0,6.9,9.2,4.5,9.2,8.7c0,1.8-1.8,2.7-3.4,2.7
                c-1.5,0-3-0.7-3.8-2l-2.6,2.5c1.6,1.9,3.9,2.6,6.3,2.6c3.8,0,7.1-2,7.1-6.2c0-6.9-9.2-4.7-9.2-8.6c0-1.8,1.6-2.5,3.2-2.5
                c1.3,0,2.7,0.5,3.4,1.5L33.7,162.1z"/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="41.3,180 44.8,180 44.8,163.7 50.7,163.7 50.7,160.6 35.4,160.6 35.4,163.7 41.3,163.7    "/>
            <Path scale={props.size/270.5} style={{fill:'#010101',fillRule:'evenodd',clipRule:'evenodd'}} d="M48.4,180h3.9l1.8-4.4h8.4l1.8,4.4h3.9l-8.4-19.4h-3L48.4,180z M55.2,172.6l3-7.9l3,7.9H55.2z"/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="70.4,180 73.8,180 73.8,171.9 82.4,171.9 82.4,168.8 73.8,168.8 73.8,163.7 82.9,163.7 82.9,160.6 
                70.4,160.6  "/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="86.1,180 89.6,180 89.6,171.9 98.1,171.9 98.1,168.8 89.6,168.8 89.6,163.7 98.6,163.7 98.6,160.6 
                86.1,160.6  "/>
            <Path scale={props.size/270.5} style={{fill:'#010101',fillRule:'evenodd',clipRule:'evenodd'}} d="M100.8,170.4c0,6.1,4.3,10.1,10.2,10.1c6.1-0.1,10.3-4.2,10.3-10.2c0-6.2-4.3-10.3-10.3-10.2
                C105.1,160.1,100.8,164.1,100.8,170.4 M104.4,170.1c0-3.9,2.7-6.9,6.7-6.9c4,0,6.7,3.1,6.7,6.9c0,4.1-2.7,7.2-6.7,7.2
                C107.1,177.4,104.4,174.3,104.4,170.1"/>
            <Path scale={props.size/270.5} style={{fill:'#010101',fillRule:'evenodd',clipRule:'evenodd'}} d="M124.7,180h3.4v-8.2h2.5l4.5,8.2h4.2l-5.1-8.6c2.9-0.4,4.5-2.5,4.5-5.3c0-4.4-3.5-5.6-7.2-5.6h-6.7L124.7,180
                L124.7,180z M128.1,168.8v-5.3h3c1.8,0,3.9,0.3,3.9,2.6c0,2.5-2.3,2.7-4.2,2.7L128.1,168.8L128.1,168.8z"/>
            <Path scale={props.size/270.5} style={{fill:'#010101',fillRule:'evenodd',clipRule:'evenodd'}} d="M141.9,180h7.2c4.7,0,10.3-3.1,10.3-9.7c0-6.6-4.8-9.7-9.9-9.7h-7.6L141.9,180L141.9,180z M145.4,176.9v-13.1
                h3.1c4.2,0,7.3,1.9,7.3,6.6s-3.5,6.6-7.9,6.6L145.4,176.9L145.4,176.9z"/>
            <Path scale={props.size/270.5} style={{fill:'#010101'}} d="M174.8,162.1c-1.5-1.4-3.5-2-5.6-2c-3.6,0-7.1,1.9-7.1,5.9c0,6.9,9.2,4.5,9.2,8.7c0,1.8-1.8,2.7-3.4,2.7
                c-1.5,0-3-0.7-3.8-2l-2.6,2.5c1.6,1.9,3.9,2.6,6.4,2.6c3.8,0,7.1-2,7.1-6.2c0-6.9-9.2-4.7-9.2-8.6c0-1.8,1.6-2.5,3.2-2.5
                c1.4,0,2.7,0.5,3.4,1.5L174.8,162.1z"/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="178.5,180 181.9,180 181.9,171.4 191,171.4 191,180 194.4,180 194.4,160.6 191,160.6 191,168.3 
                181.9,168.3 181.9,160.6 178.5,160.6     "/>
            <Rect x="198.7" y="160.6" scale={props.size/270.5} style={{fill:'#010101'}} width="3.5" height="19.4"/>
            <Path scale={props.size/270.5} style={{fill:'#010101',fillRule:'evenodd',clipRule:'evenodd'}} d="M206.4,180h3.5v-8.2h2.5l4.5,8.2h4.2l-5.1-8.6c2.9-0.4,4.5-2.5,4.5-5.3c0-4.4-3.5-5.6-7.2-5.6h-6.7L206.4,180
                L206.4,180z M209.8,168.8v-5.3h3c1.8,0,3.9,0.3,3.9,2.6c0,2.5-2.3,2.7-4.2,2.7L209.8,168.8L209.8,168.8z"/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="223.7,180 237,180 237,176.9 227.1,176.9 227.1,171.6 236,171.6 236,168.5 227.1,168.5 227.1,163.7 
                236.5,163.7 236.5,160.6 223.7,160.6     "/>
            <Path scale={props.size/270.5} style={{fill:'#010101'}} d="M21.4,189.9v12.3c0,4.5,3.3,7.6,7.5,7.6c4.3,0,7.5-3.1,7.5-7.6v-12.3h-2.6v11.9c0,2.4-1.2,5.5-4.9,5.5
                c-3.7,0-4.9-3.1-4.9-5.5v-11.9H21.4z"/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="41.1,209.3 43.7,209.3 43.7,193.4 43.8,193.4 54.6,209.3 57.9,209.3 57.9,189.9 55.3,189.9 
                55.3,205.9 55.2,205.9 44.5,189.9 41.1,189.9     "/>
            <Rect x="62.9" y="189.9" scale={props.size/270.5} style={{fill:'#010101'}} width="2.6" height="19.4"/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="75.3,209.3 77.8,209.3 85.4,189.9 82.6,189.9 76.6,205.7 76.6,205.7 70.9,189.9 67.9,189.9    "/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="87.8,209.3 100.8,209.3 100.8,206.8 90.4,206.8 90.4,200.6 99.6,200.6 99.6,198.1 90.4,198.1 
                90.4,192.4 100.3,192.4 100.3,189.9 87.8,189.9   "/>
            <Path scale={props.size/270.5} style={{fill:'#010101',fillRule:'evenodd',clipRule:'evenodd'}} d="M104.5,209.3h2.6v-8.6h2.9l4.9,8.6h3.3l-5.5-8.9c2.9-0.3,4.8-2.7,4.8-5.1c0-2.4-1.3-5.4-6.2-5.4h-6.8
                L104.5,209.3L104.5,209.3z M107.1,192.2h3.4c2.1,0,4.2,0.5,4.2,3.1c0,2.6-2.1,3.1-4.2,3.1h-3.4V192.2z"/>
            <Path scale={props.size/270.5} style={{fill:'#010101'}} d="M119.7,207.1c1.3,1.8,3.7,2.7,6.2,2.7c3.7,0,6.7-2.3,6.7-6c0-7-9.6-4.1-9.6-9c0-1.2,0.9-3,3.8-3
                c1.5,0,2.8,0.7,3.4,1.7l2.1-1.9c-1.4-1.8-3.5-2.2-5.6-2.2c-4.7,0-6.6,3.1-6.6,5.4c0,7.6,9.6,4.8,9.6,9.3c0,2.1-2.1,3.2-3.9,3.2
                c-1.6,0-3.2-0.7-4.1-2.1L119.7,207.1z"/>
            <Rect x="136.5" y="189.9" scale={props.size/270.5} style={{fill:'#010101'}} width="2.6" height="19.4"/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="148.1,209.3 150.8,209.3 150.8,192.4 157,192.4 157,189.9 141.9,189.9 141.9,192.4 148.1,192.4    "/>
            <Polygon scale={props.size/270.5} style={{fill:'#010101'}} points="164.4,209.3 167,209.3 167,201 174.4,189.9 171.2,189.9 165.7,198.4 160.4,189.9 157,189.9 164.4,201 
                    "/>
            <Rect x="180.3" y="190.3" scale={props.size/270.5} style={{fill:'#D71F29'}} width="56.3" height="19.4"/>
            <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M208.5,134.1c-5.6-5.5-12.9-16.6-13.2-22.4c4-1,8.5-1.6,13.1-1.6c4.7,0,9.2,0.6,13.3,1.6
                C221.4,117.5,214.1,128.6,208.5,134.1"/>
            <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M203.9,137.8c-2.6-2.5-5-5.4-7.2-8.6c-3.5-5.1-6-10.3-7.5-15.5c-1.3,0.4-2.7,1-4,1.7
                c-7.8,4.4-10.8,13.8-6.7,20.9s13.8,9.2,21.7,4.7c1.5-0.8,2.8-1.9,3.9-3L203.9,137.8z"/>
            <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M213.2,137.9c2.6-2.6,5-5.5,7.1-8.7c3.4-5.1,5.9-10.4,7.4-15.6c1.3,0.4,2.7,1,3.9,1.7
                c7.8,4.5,10.8,13.9,6.6,21.1c-4.1,7.1-13.8,9.3-21.6,4.8c-1.5-0.9-2.8-1.9-3.9-3L213.2,137.9z"/>
            <Path scale={props.size/270.5} style={{fill:'#D71F29',fillRule:'evenodd',clipRule:'evenodd'}} d="M228.6,107.1v-6.6l-6.1,0v5.2h0c-4.3-1-8.9-1.6-13.7-1.6c-5.1,0-10,0.6-14.6,1.8c0-2,0-3.9,0-5.4l-6.1,0h-0.1
                l0.1,7.2c-1.4,0.4-2.9,1-4.2,1.7c-1.6,0.9-3.1,1.8-4.4,2.9v0.1V69.2l57.7-0.1l0,42.9c-1.2-1.2-2.8-2.1-4.4-3
                c-1.2-0.6-2.4-1.2-3.7-1.6c-0.1-0.1-0.3-0.1-0.4-0.1L228.6,107.1z"/>
            <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M237.3,146l-0.3,0.1c-7.9,5-19.2,4.2-27.3-2.7c-0.4-0.3-0.9-0.8-1.2-1.2c-0.3,0.3-0.6,0.7-1,1
                c-8.4,7.5-20.1,8-28,2.2l0,4.1h0v3.3h28.9c2.1,0,4.2,0,6.2,0h22.7v-3.3h0C237.3,149,237.3,147.9,237.3,146z"/>
        </Svg>
    )
    // return (
    //     <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '257.8 270.5'}`}>
    //         <Polygon scale={props.size/270.5} style={{fill:'#FFFFFF'}} points="0,0 0,218.9 51.8,270.5 257.8,270.5 257.8,0 "/>
    //         <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M208.5,134.1c-5.6-5.5-12.9-16.6-13.2-22.4c4-1,8.5-1.6,13.1-1.6c4.7,0,9.2,0.6,13.3,1.6
    //             C221.4,117.5,214.1,128.6,208.5,134.1"/>
    //         <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M203.9,137.8c-2.6-2.5-5-5.4-7.2-8.6c-3.5-5.1-6-10.3-7.5-15.5c-1.3,0.4-2.7,1-4,1.7
    //             c-7.8,4.4-10.8,13.8-6.7,20.9s13.8,9.2,21.7,4.7c1.5-0.8,2.8-1.9,3.9-3L203.9,137.8z"/>
    //         <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M213.2,137.9c2.6-2.6,5-5.5,7.1-8.7c3.4-5.1,5.9-10.4,7.4-15.6c1.3,0.4,2.7,1,3.9,1.7
    //             c7.8,4.5,10.8,13.9,6.6,21.1c-4.1,7.1-13.8,9.3-21.6,4.8c-1.5-0.9-2.8-1.9-3.9-3L213.2,137.9z"/>
    //         <Path scale={props.size/270.5} style={{fill:'#D71F29',fillRule:'evenodd',clipRule:'evenodd'}} d="M228.6,107.1v-6.6h-6.1v5.2l0,0c-4.3-1-8.9-1.6-13.7-1.6c-5.1,0-10,0.6-14.6,1.8c0-2,0-3.9,0-5.4h-6.1H188
    //             l0.1,7.2c-1.4,0.4-2.9,1-4.2,1.7c-1.6,0.9-3.1,1.8-4.4,2.9v0.1V69.2l57.7-0.1V112c-1.2-1.2-2.8-2.1-4.4-3c-1.2-0.6-2.4-1.2-3.7-1.6
    //             c-0.1-0.1-0.3-0.1-0.4-0.1L228.6,107.1z"/>
    //         <Path scale={props.size/270.5} style={{fill:'#D71F29'}} d="M237,146.1c-7.9,5-19.2,4.2-27.3-2.7c-0.4-0.3-0.9-0.8-1.2-1.2c-0.3,0.3-0.6,0.7-1,1c-8.4,7.5-20.1,8-28,2.2
    //             v4.1v3.3v56.9h57.8v-56.9v-3.3c0-0.5,0-1.6,0-3.5L237,146.1z"/>
    //     </Svg>
    // )
}