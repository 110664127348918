import React from 'react'
import { Svg, Circle, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SuccessIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'success icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'success icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinejoin:'round',strokeMiterlimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M25.52,11.97c-0.78-0.78-2.05-0.78-2.83,0l-6.82,6.82l-3.57-3.57c-0.78-0.78-2.05-0.78-2.83,0
                c-0.78,0.78-0.78,2.05,0,2.83l4.98,4.98c0.39,0.39,0.9,0.59,1.41,0.59s1.02-0.2,1.41-0.59l8.24-8.24
                C26.31,14.02,26.31,12.75,25.52,11.97z"/>
        </Svg>
    )
}