import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Container, Text } from 'primitives'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { Icon, icons } from 'assets/MenuIcons'

export const OfflineBar = props => {
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()

    if (hasInternet) return null

    return (
        <Container
            flex={0}
            width={'100%'}
            alignItems={'center'}
            borderBottom={`2px solid ${colors.screenAltBg}`}
            borderBottomWidth={2}
            borderColor={colors.screenAltBg}
        >
            <Container
                flex={0}
                padding={10}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
                maxWidth={600}
                flexDirection={'row'}
            >
                <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
                <Container flex={1} paddingLeft={5}>
                    <Text color={colors.screenAltText}>Some features are disabled in offline mode. Some data may be out of date.</Text>
                </Container>
            </Container>
        </Container>
    )
}