import { NewsTypes } from './NewsTypes'

export class NewsActions {

    static setNewsItem = item => {
        return { type: NewsTypes.SET_NEWS_ITEM, payload: item }
    }

}


