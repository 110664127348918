import { useSelector, useDispatch } from 'react-redux'
import { NewsActions } from 'redux/actions'

export class NewsHelper {

    static useSetNewsItem = () => {
        const dispatch = useDispatch()
        return (item) => {
            dispatch(NewsActions.setNewsItem(item))
        }
    }

    static getNewsItem = () => {
        const newsItem = useSelector(state => state.news.newsItem)
        return newsItem
    }
}