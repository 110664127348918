import React from 'react'
import { Svg, Line, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const IrisIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'IRIS icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'IRIS icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.1,30.8H6.9c-1.8,0-3.3-1.7-3.3-3.8V8C3.5,5.9,5,4.2,6.9,4.2h21.3c1.8,0,3.3,1.7,3.3,3.8v19
                C31.5,29.1,30,30.8,28.1,30.8z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinejoin:'round',strokeMiterlimit:10}} x1="3.6" y1="8" x2="31.5" y2="8"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="6.9" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="8.7" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.5" cy="6.3" r="0.7"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M27.4,18.9"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M7.5,18.9"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeMiterlimit:10}} cx="17.5" cy="19.3" r="8.1"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeMiterlimit:10}} x1="9.4" y1="19.3" x2="25.7" y2="19.3"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeMiterlimit:10}} x1="10.5" y1="15.2" x2="24.6" y2="15.2"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeMiterlimit:10}} x1="10.5" y1="23.4" x2="24.6" y2="23.4"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeMiterlimit:10}} x1="17.5" y1="11.1" x2="17.5" y2="27.4"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeMiterlimit:10}} d="M17.5,11.1c-5.5,4-5.5,12.2,0,16.3"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeMiterlimit:10}} d="M17.5,11.1c5.5,4,5.5,12.2,0,16.3"/>
        </Svg>
    )
}