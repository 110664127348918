import React from 'react'
import { Svg, Circle, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const ColorThemesIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'colour themes icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'colour themes icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:4,strokeLinecap:'round',strokeMiterLimit:10}} x1="30.13" y1="6.34" x2="23.84" y2="14.07"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M22.68,18.79c1.37-0.59,2-2.18,1.41-3.54c-0.59-1.37-2.18-2-3.54-1.41c-1.85,0.35-1.31,4.63-3.68,4.52
                C20.47,19.75,21.32,19.38,22.68,18.79z"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLineJoin:'round',strokeMiterLimit:10}} d="M30.85,12.07c0.73,1.42,1.13,2.97,1.13,4.61c0,3.05-2.97,1.18-6.21,4.37s0.74,5.84-0.68,6.9
                c-0.5,0.61-5.99,0.61-7.59,0.61c-8,0-14.48-5.32-14.48-11.88S9.5,4.8,17.5,4.8c2.74,0,5.31,0.63,7.49,1.71"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLineJoin:'round',strokeMiterLimit:10}} cx="8.31" cy="16.22" r="2.34"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLineJoin:'round',strokeMiterLimit:10}} cx="12.34" cy="11.2" r="2.34"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLineJoin:'round',strokeMiterLimit:10}} cx="18.66" cy="9.87" r="2.34"/>
        </Svg>
    )
}