import { InitialisationTypes } from 'redux/lib/initialisation/InitialisationTypes'
import { Namespaces } from 'redux/namespaces'

const initialState = {
    // tokenChecked: false,
    // versionChecked: false,
    // versionOutOfDate: false,
    // newVersionInfo: {},
    // internetConnectionChecked: false,
    // initialChecksCompleted: false
}

export const InitialisationReducer = (state = initialState, action) => {
    switch (action.type) {

        case InitialisationTypes.SET_TOKEN_CHECKED:
            return Object.assign({}, state, {
                tokenChecked: true
            })

        case InitialisationTypes.SET_INITIAL_CHECKS_COMPLETED:
            return Object.assign({}, state, {
                initialChecksCompleted: true
            })

        default:
            return state

    }
}