import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Animated, Button } from 'primitives'
import { Icon, icons } from 'assets/MenuIcons'

export const DropdownButton = ({ current, setAnimation, size = 40, padding = 10, ...props }) => {
    const colors = SettingsHelper.getColors()
    return (
        <Animated
            flex={0}
            height={size}
            alignSelf={'flex-end'}
            animations={ButtonAnimation}
            current={current}
            initial={'closed'}
        >
            <Button padding={padding} handleAction={() => setAnimation(current === 'closed' ? 'open' : 'closed')} accessibility={{ role: 'button', label: current === 'closed' ? 'open dropdown menu' : 'close dropdown menu' }}>
                <Icon type={icons.downArrow} color={colors.screenAltText} size={size - (padding * 2)} />
            </Button>
        </Animated>
    )
}

const ButtonAnimation = {
    closed: {
        rotate: '0deg',
        transform: `rotate(0deg)`
    },
    open: {
        rotate: '180deg',
        transform: `rotate(180deg)`
    }
}