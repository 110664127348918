import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const CoursesActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'courses icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'courses icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M34.06,11.09l-16.1-7.36c-0.26-0.12-0.57-0.12-0.83,0l-16.1,7.36c-0.34,0.16-0.57,0.49-0.58,0.87
                c-0.02,0.38,0.18,0.73,0.51,0.91l0.9,0.51v6.89c-0.61,0.28-1.04,0.9-1.04,1.62c0,0.99,1.79,3.87,1.79,3.87s1.79-2.88,1.79-3.87
                c0-0.72-0.43-1.33-1.04-1.62v-6.05l13.7,7.69c0.15,0.08,0.32,0.13,0.49,0.13s0.34-0.04,0.49-0.13l16.1-9.04
                c0.33-0.18,0.53-0.54,0.51-0.91C34.63,11.58,34.41,11.25,34.06,11.09z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M5.25,16.88v7.04c0,4.2,5.4,7.49,12.3,7.49c6.89,0,12.3-3.29,12.3-7.49v-7.03L17.5,23.66L5.25,16.88z"/>
        </Svg>
    )
}