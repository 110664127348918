import React from 'react'
import { Button } from 'primitives'
import { SettingsHelper, ChatHelper } from 'redux/helpers'
import { Icon, icons } from 'assets/MenuIcons'

export const SendButton = ({ text, setText, disable }) => {
    const colors = SettingsHelper.getColors()
    const sendMessage = ChatHelper.useSendMessage()

    const handleSendAction = () => {
        if (text && text.length > 0 && !disable) {
            sendMessage({ text: text }, true)
            setText('')
        }
    }

    return (
        <Button
            flex={0}
            padding={0}
            handleAction={handleSendAction}
            accessibility={{ role: 'button', label: 'send message to Beacon' }}
        >
            <Icon type={icons.sendMessage} color={colors.screenAltText} size={40} />
        </Button>
    )
}