import { AuthenticationContext, adalFetch, withAdalLogin, adalGetToken } from 'react-adal'
const envConfig = require('lib/config/env-config').config

// From the library:
export const adalConfig = {
  tenant: envConfig.appRegistration.tenantId,
  clientId: envConfig.appRegistration.clientId,
  extraQueryParameters: 'login_hint=<upn>', // for SSO with apps that don't use ADAL.js
  endpoints: {
    api: envConfig.appRegistration.clientId,
  },
  // cacheLocation: 'localStorage', // doesn't seem to work when this is set (log in page won't open when token expires)
  cacheLocation: 'localStorage', // localStorage is required for SSO: https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Sso
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  navigateToLoginRequestUrl: true, // we redirect to the dashboard any user that go to the log in page (that way we know when the callback was succesful)
  popUp: false,
}

// export const getAdalConfig = () => {
//   let config = adalConfig
//   config.redirectUri = window.location.origin
//   config.postLogoutRedirectUri = window.location.origin
//   return config
// }

export const authContext = new AuthenticationContext(adalConfig);
export const adalApiFetch = (fetch, url, options) => adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

// Added:
export const getToken = async () => await adalGetToken(authContext, adalConfig.clientId)
export const getCachedToken = () => authContext.getCachedToken(adalConfig.clientId)
export const getCachedUser = () => authContext.getCachedUser()
export const getCachedUserInfo = () => {
  // this return the same user object as on mobile
  try {
    const token = getCachedToken()
    const user = getCachedUser()
    if (!(user && token)){
      console.log("The user is signed out")
      return
    }
    let username = user.profile.upn.split('@')[0]
    return {
      id: username,
      username,
      email: user.profile.upn,
      name: user.profile.name,
      token,
      deviceId: null,
      deviceOS: "web",
    }
  } catch (error) {
    console.log("❌ Could not get user info")
    console.error(error)
  }
  return null
}