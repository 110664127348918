import { RuntimeTypes } from './RuntimeTypes'

export class RuntimeActions {

    static setWindowHeight = height => {
        return { type: RuntimeTypes.SET_WINDOW_HEIGHT, payload: height }
    }

    static setWindowWidth = width => {
        return { type: RuntimeTypes.SET_WINDOW_WIDTH, payload: width }
    }

    static setOnline = online => {
        return { type: RuntimeTypes.SET_ONLINE, payload: online }
    }

    static setKeyboardVisible = visible => {
        return { type: RuntimeTypes.SET_KEYBOARD_VISIBLE, payload: visible }
    }

    static setVersionChecked = (upgradeNeeded, res = '', text = '') => {
        return { type: RuntimeTypes.SET_VERSION_CHECK, payload: { upgradeNeeded, res, text } }
    }

    static setScreenAnimating = animating => {
        return { type: RuntimeTypes.SET_SCREEN_ANIMATING, payload: animating }
    }
}


