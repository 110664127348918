import React from 'react'

export const InputTextPrimitive = ({ name, value, onChangeText, placeholder, placeholderTextColor, onEnter, nativeFontFamily, webFontFamily, fontSize, lineHeight, fontWeight, children, ...props }) => {
    const style = { ...props }
    style.fontFamily = webFontFamily
    style.fontWeight = fontWeight
    style.fontSize = fontSize / 10 + 'rem'
    style.lineHeight = lineHeight / 10 + 'rem'

    const inputKeyPress = (event) => {
        if (event.key == 'Enter') {
            onEnter()
        }
    }

    return (
        <input
            autoComplete={'off'}
            type={'text'}
            name={name}
            title={name}
            aria-label={name}
            max={9999}
            style={{ ...style }}
            value={value}
            placeholder={placeholder}
            onChange={e => onChangeText(e.target.value)}
            onKeyPress={e => inputKeyPress(e)}
        />
    )
}