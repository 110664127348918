import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore } from "redux-persist";
import { persistedAllReducers } from './reducers'
import { Environment } from 'lib/config/Environment'
import thunk from 'redux-thunk'
import throttledMiddleware from './middleware/throttled'

const middlewares = [
    thunk
]

export const configureStore = props => {

    // DEFINE INITIAL STATE AND ENHANCERS
    let initialState = {}
    let enhancers = null
    let isWeb = props && props.platform && props.platform.platform && props.platform.platform === "web"

    if (props && props.platform) {
        initialState.platform = props.platform
    }

    // If Redux DevTools Chrome Extension for local debugging
    if (!Environment.isProd() && isWeb && window && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers = compose(
            applyMiddleware(...middlewares),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
    } else {
        enhancers = compose(
            applyMiddleware(...middlewares),
        )
    }

    // CREATE REDUX STORE
    const store = createStore(
        persistedAllReducers,
        initialState,
        enhancers
    )

    // if (isWeb) {
        console.log("🚀 Redux store initial state:")
        console.log(store.getState())
    // }


    // CREATE REDUX PERSISTOR
    const persistor = persistStore(store, null, () => {
        // if (isWeb) {
            console.log("🚀 Redux store restored state:")
            console.log(store.getState())
        // }
    })

    // RETURN STORE & PERSISTOR
    return { store, persistor }
}
