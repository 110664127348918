import React from 'react'
import { Route, Redirect, routes, useLocation } from './'
import { authContext, withAdalLoginApi, adalConfig } from 'lib/auth/adalConfig'
import { Container, Text, Br } from 'primitives'
import { ScreenContainer, StyledButton } from 'components/general'
import { UserHelper, SettingsHelper } from 'redux/helpers'
import { LoadingData } from 'components/general'

const Loading = props => <LoadingData text={'Authenticating...'} />
const Error = props => {
  const colors = SettingsHelper.getColors()
  const handleLogOut = UserHelper.useHandleLogOut()
  // console.warn(props.error)
  return (
    <ScreenContainer
      viewType={'ScrollView'}
    >
      <Br />
      <Text color={colors.screenText} textAlign="center">An error occurred with the authentication. </Text>
      <Br />
      <Text color={colors.screenText} textAlign="center">Try signing out to resolve the issue</Text>
      <Br />
      <Text color={colors.screenText} textAlign="center">Contact <a href='mailto:beacon@staffs.ac.uk' target='_blank'>beacon@staffs.ac.uk</a> if the problem persists</Text>
      <Br />
      <StyledButton
        title={'Log Out'}
        accessibility={{ role: 'button', label: 'log out of Beacon' }}
        bgColor={colors.actionBg}
        textColor={colors.actionText}
        handleAction={handleLogOut}
      />
    </ScreenContainer>
  )
}


// import React from 'react'
// import { SettingsHelper } from 'redux/helpers'
// import { ScreenContainer } from 'components/general/ScreenContainer'
// import { Container, Text, Br } from 'primitives'
// import { StyledButton } from 'components/general/StyledButton'
// import { UserHelper } from 'redux/helpers'

// export const Login = props => {
//   const user = UserHelper.getUser()
//   const handleLogIn = UserHelper.useHandleLogIn()
//   const colors = SettingsHelper.getColors()

//   return (
//     <ScreenContainer
//       viewType={'ScrollView'}
//     >
//       <Br />
//       <Text textAlign="center">Current User: {user && user.email ? user.email : 'logged out'}</Text>
//       <Br />
//       <StyledButton
//         title={'Log In'}
//         bgColor={'black'}
//         textColor={'white'}
//         handleAction={handleLogIn}
//       />
//     </ScreenContainer>
//   )
// }



export const AuthenticatedRoute = ({ render, redirect, ...props }) => {
  const location = useLocation()
  if (redirect && location.pathname != redirect.to && location.pathname == redirect.from) {
    return <Redirect to={{ pathname: redirect.to, state: { from: location } }} />
  }
  const ProtectedPage = withAdalLoginApi(render, () => <Loading />, (error) => <Error error={error} />)
  return (
    <Route {...props}>
      <ProtectedPage />
    </Route>
  )
}