import React, { useRef, useState, useEffect } from 'react'
import { ActionButton } from './ActionButton'
import { ArrowNavigation } from '../../general'
import { Container, Animated, Button } from 'primitives'
import { SettingsHelper } from 'redux/helpers'

export const ActionCarousel = ({ currentMessage, latestMessage, maxBubbleWidth, ...props }) => {
    const colors = SettingsHelper.getColors()
    const displayActions = !(latestMessage != null && latestMessage._id != currentMessage._id)
    const pageLength = currentMessage.actions != null ? Math.ceil(currentMessage.actions.length / maxButtons) : 0
    const [pages, setPages] = useState([])
    const [activePage, setActivePage] = useState(0)

    useEffect(() => {
        if (currentMessage && currentMessage.actions && currentMessage.actions.length > 0) {
            let newPages = []
            for (let i = 0; i < Math.ceil(currentMessage.actions.length / maxButtons); i++) {
                newPages.push(currentMessage.actions.slice(i * maxButtons, Math.min(i * maxButtons + maxButtons, currentMessage.actions.length)))
            }
            setPages(newPages)
        }
    }, [currentMessage])

    const scrollLeft = () => {
        setActivePage(Math.max(activePage - 1, 0))
    }

    const scrollRight = () => {
        setActivePage(Math.min(activePage + 1, pageLength - 1))
    }

    const renderActionPage = (page, index) => {
        return (
            <Container
                minWidth={maxBubbleWidth}
                maxWidth={maxBubbleWidth}
                paddingLeft={10}
                paddingRight={10}
                paddingBottom={10}
                key={index}
            >
                {page.map((action, index) =>
                    <ActionButton
                        title={action.title}
                        value={action.value}
                        color={colors.chatUserDefault} //colors.actionListBg[(index) % colors.actionListBg.length] (for varied colors)
                        opacity={index % 2 ? 0.9 : 1}
                        textColor={colors.actionText}
                        key={action.title}
                    />
                )}
                <Button flex={1} padding={0}><Container /></Button>
            </Container>
        )
    }

    const CarouselAnimation = {
        default: {
            transform: `translateX(0px)`
        },
        animated: {
            transform: `translateX(${activePage * -maxBubbleWidth}px)`
        }
    }

    if (displayActions && pages.length) {
        return (
            <Container flex={0}>
                <Container
                    flex={0}
                    minWidth={maxBubbleWidth}
                    maxWidth={maxBubbleWidth}
                    webOverflow={'hidden'}
                >
                    <Animated
                        display={'flex'}
                        flex={0}
                        flexDirection={'row'}
                        animations={CarouselAnimation}
                        current={'animated'}
                        initial={'default'}
                    >
                        {pages.map((page, index) => renderActionPage(page, index))}
                    </Animated>
                </Container>
                <ArrowNavigation
                    activePage={activePage}
                    maxSize={maxBubbleWidth}
                    pageLength={pageLength}
                    scrollLeft={scrollLeft}
                    scrollRight={scrollRight}
                />
            </Container>
        )
    }
    return null
}

const maxButtons = 5