import React from 'react'
import { Svg, Line, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const EVisionIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'e:Vision icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'e:Vision icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.1,30.8H6.9c-1.8,0-3.3-1.7-3.3-3.8V8C3.5,5.9,5,4.2,6.9,4.2h21.3c1.8,0,3.3,1.7,3.3,3.8v19
                C31.5,29.1,30,30.8,28.1,30.8z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinejoin:'round',strokeMiterlimit:10}} x1="3.6" y1="8" x2="31.5" y2="8"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="6.9" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="8.7" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.5" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinejoin:'round',strokeMiterlimit:10}} cx="17.5" cy="18.9" r="5.3"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M27.4,18.9"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M7.5,18.9"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.3,18.9c-5,8.1-16.7,8.1-21.6,0C11.6,10.8,23.3,10.8,28.3,18.9z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M20.3,19.4h-4.1c0.1,0.3,0.2,0.6,0.5,0.8s0.6,0.3,1,0.3c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4l0.8,0.9
                    c-0.5,0.6-1.2,0.9-2.2,0.9c-0.6,0-1.1-0.1-1.6-0.4c-0.5-0.2-0.8-0.6-1.1-1c-0.3-0.4-0.4-0.9-0.4-1.4c0-0.5,0.1-1,0.4-1.4
                    c0.3-0.4,0.6-0.8,1-1c0.4-0.2,0.9-0.4,1.5-0.4c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.8,0.6,1,1c0.2,0.4,0.4,0.9,0.4,1.5
                    C20.3,19,20.3,19.1,20.3,19.4z M16.7,17.6c-0.2,0.2-0.4,0.5-0.4,0.8h2.6c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.9-0.3
                    C17.2,17.3,16.9,17.4,16.7,17.6z"/>
        </Svg>
    )
}