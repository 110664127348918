import React, { useState } from 'react'
import { Container, InputText } from 'primitives'
import { SettingsHelper, ChatHelper } from 'redux/helpers'
import { SendButton } from './'
import { NetworkInfo } from 'lib/network/NetworkInfo'

const placeholderText = {
    type: 'Type a message...',
    speak: 'Listening...'
}

export const MessageInput = props => {
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()
    const sendMessage = ChatHelper.useSendMessage()
    const [text, setText] = useState()
    const [placeholder, setPlaceholder] = useState(placeholderText.type)

    const onEnter = () => {
        sendMessage({ text: text }, true)
        setText('')
    }

    return (
        <Container
            flex={0}
            flexDirection={'row'}
            alignItems={'center'}
            backgroundColor={colors.screenBg}
            borderTop={`2px solid ${colors.screenBorder}`}
            borderTopWidth={2}
            borderColor={colors.screenBorder}
            padding={5}
        >
            <Container flex={1} marginLeft={5} marginRight={5}>
                <InputText 
                    name={'message'}
                    display={'flex'}
                    flex={0}
                    width={'100%'}
                    padding={0}
                    margin={0}
                    color={colors.screenText}
                    backgroundColor={colors.screenBg}
                    value={text}
                    placeholder={placeholder}
                    placeholderTextColor={colors.screenBorder}
                    onChange={text => setText(text)}
                    onEnter={onEnter}
                />
            </Container>
            <Container flex={0} marginLeft={'auto'} marginRight={5} opacity={!hasInternet ? 0.5 : null}>
                <SendButton text={text} setText={setText} setPlaceholder={setPlaceholder} placeholderText={placeholderText} disable={!hasInternet}/>
            </Container>
        </Container>
    )
}