import BeaconIcon from 'assets/images/BeaconIcon.png'
import moment from 'moment'

export class BotMessageHelper {

    static convertBotActivityToGiftedMessage = botActivity => {
        let formattedText = botActivity.text || ''
        // URL encode all links
        formattedText = formattedText.replace(/\[(.*?)\] *\((.*?)( +".*?"){0,1}\)/ig, (match, text, url, title) => {
            if (!url.toLowerCase().startsWith('http') && !url.toLowerCase().startsWith('tel')) {
                url = 'https://' + url
            }
            return `[${text}](${url}${title === undefined ? '' : title})`
        })

        return {
            _id: botActivity.id,
            text: formattedText,
            summary: botActivity.summary,
            createdAt: new moment(),
            user: {
                _id: botActivity.from.id,
                name: botActivity.from.name,
                avatar: BeaconIcon,
            },
            actions: botActivity.suggestedActions != null && botActivity.suggestedActions.actions != null ? botActivity.suggestedActions.actions : [],
            attachments: botActivity.attachments
        }
    }
}