import { Platform } from 'lib/primitives'

export const scrollToEnd = (webRef, nativeRef, disableAnimations) => {
    const isWeb = Platform.isWeb()
    if (isWeb && webRef && webRef.current) {
        webRef.current.scrollIntoView({ behaviour: disableAnimations? 'auto' : 'smooth', block: 'end' })
    }
    else if (!isWeb && nativeRef && nativeRef.current) {
        nativeRef.current.scrollToEnd({ animated: !disableAnimations })
    }
}