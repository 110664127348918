import React from 'react'
import { Link } from "react-router-dom"
import { useLocation } from 'navigation'

export const LinkPrimitive = ({ children, to, isExternal, isDisabled, extraState, ...props }) => {
    const location = useLocation()
    let style = { ...props }
    if (props.flex == 0) style.flex = null

    if (isExternal) {
        return <a
            aria-required={true}
            role={'button'}
            aria-label={'opens ' + to}
            style={style}
            href={!isDisabled ? to : undefined}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    }
    return <Link
        aria-required={true}
        role={'button'}
        aria-label={'opens ' + to}
        style={style}
        to={{ pathname: to, state: { ...extraState, from: location } }}
        disabled={to === location.pathname || isDisabled ? true : false}
    >
        {children}
    </Link>
}