import React from 'react'
import { Container, Image } from 'primitives'
import { SettingsHelper } from 'redux/helpers'
import { CustomText } from '../components'
import { ActionButton } from './ActionButton'

export const AdaptiveCard = ({ payload }) => {
    return (
        <Container flex={0} width={'100%'} >
            <Container flex={0} width={'100%'} >
                {payload.body && payload.body.map(item => <AdaptiveCardItem data={item} key={JSON.stringify(item)} />)}
            </Container>
            <Container flex={0} width={'100%'} paddingTop={5}>
                {payload.actions && payload.actions.map((item, index) => <AdaptiveCardAction data={item} index={index} key={JSON.stringify(item)} />)}
            </Container>
        </Container>
    )
}

const AdaptiveCardAction = ({ data, index }) => {
    const colors = SettingsHelper.getColors()
    const { title, type, ...extraData } = data
    return (
        <ActionButton
            title={title}
            value={type}
            isCardAction={true}
            extraData={extraData}
            color={colors.chatUserDefault} //colors.actionListBg[(index) % colors.actionListBg.length] (for varied colors)
            opacity={index % 2 ? 0.9 : 1}
            textColor={colors.actionText}
            key={title}
        />
    )
}

const AdaptiveCardItem = ({ data }) => {
    const colors = SettingsHelper.getColors()
    //console.log(data)
    switch (data.type) {
        case 'Container':
            return (
                <Container flex={0}>
                    {data.items.map(item => <AdaptiveCardItem data={item} key={JSON.stringify(item)} />)}
                </Container>
            )
        case 'ColumnSet':
            return (
                <Container flex={0} flexDirection={'row'}>
                    {data.columns.map(item => <AdaptiveCardItem data={item} key={JSON.stringify(item)} />)}
                </Container>
            )
        case 'Column':
            return (
                <Container flex={1}>
                    {data.items.map(item => <AdaptiveCardItem data={item} key={JSON.stringify(item)} />)}
                </Container>
            )
        case 'TextBlock':
            return (
                <Container
                    flex={0}
                    borderTop={data.separator ? `2px solid ${colors.screenBorder}` : null}
                    borderTopWidth={data.separator ? 2 : 0}
                    borderColor={data.separator ? colors.screenBorder : null}
                >
                    <CustomText text={data.text} textColor={colors.chatBotText} textAlign={data.horizontalAlignment} title={data.size && data.size == 'large'} />
                </Container>
            )
        case 'Image':
            //console.log(data.url)
            return (
                <Image
                    src={{ uri: data.url }}
                    resizeMode={'center'}
                    height={200}
                    marginLeft={'auto'}
                    marginRight={'auto'}
                    width={'100%'}
                />
            )
        default: return null
    }
}