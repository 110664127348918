import React from 'react'
import { BaseRouter, DeepLinking, AnimatedSwitch, RouteWrapper, ReduxRedirect } from './'
import { TopBar, BottomBar, InfoBar, TitleBar } from 'components/bars'
import { Container } from 'primitives'
import { ChatWeb } from 'components/chat'

export const Router = ({ children }) => {
  return (
    <BaseRouter>
      <DeepLinking />
      <ReduxRedirect />
      <RouteWrapper>
        <InfoBar />
        <TopBar />
        <TitleBar />
        <Container flex={1} flexDirection={'row'}>
          <AnimatedSwitch
            from={{ opacity: 0 }}
            enter={{ opacity: 1 }}
            leave={{ opacity: 0 }}
            config={{ tension: 210, friction: 20 }}
            routes={children}
          />
          <ChatWeb/>
        </Container>
        <BottomBar />
      </RouteWrapper>
    </BaseRouter>
  )
}