import { useSelector, useDispatch } from 'react-redux';
import { UserHelper } from 'redux/lib/user/UserHelper';
import { QueueActions } from './QueueActions';
import { Maths } from 'lib/general/Maths'
import { Namespaces } from 'redux/namespaces'
import { NetworkInfo, useInternetReachable } from 'lib/network/NetworkInfo'
import { Platform } from 'lib/primitives'
import { JobIndex } from './job'
import moment from 'moment'

export class QueueHelper {

  static useAdd = () => {
    const dispatch = useDispatch()
    const getUser = UserHelper.useGetUser()
    const processQueue = QueueHelper.useProcessQueue();
    return async (jobName, jobParams = {}, maxFailAttempts = 5, removeAfterDate = null) => {
      try {
        const user = await getUser()
        if (!(user && user.username)) {
          console.log('No username found')
          return false
        }
        // Default expiration date: 1 month
        if (!removeAfterDate) {
          removeAfterDate = new moment().add(30, 'days')
        }
        let jobId = await Maths.uuid()
        let queueItem = {
          id: jobId,
          job_name: jobName,
          job_params: jobParams,
          max_fail_attempts: maxFailAttempts,
          remove_after_date: removeAfterDate,
        }
        console.log(`💼 Adding following queue item for username ${user.username}`)
        console.log(queueItem)
        // Add job to queue
        await dispatch(QueueActions.queueAdd(user.username, queueItem))
        console.log(`💼 Job id ${jobId} created successfully`)
        // Try processing the queue right away (including other jobs that might have been queue before)
        processQueue()
        return jobId
      } catch (e) {
        console.log(e)
        return false
      }
    }
  }

  static useRemoveJob = () => {
    const getUser = UserHelper.useGetUser()
    const dispatch = useDispatch()

    return async jobId => {
      const user = await getUser()
      if (!(user && user.username)) {
        console.log('💼 ❌ You can only remove jobs belonging to you, this user is not logged in')
        return
      }
      console.log(`💼 Removing job id ${jobId}`)
      dispatch(QueueActions.queueRemove(user.username, jobId))
    }
  }

  static useProcessQueue = () => {

    const getUser = UserHelper.useGetUser()
    const dispatch = useDispatch()
    const isNative = Platform.isNative()
    const queue = useSelector(state => state[Namespaces.QUEUE])

    return async () => {
      const user = await getUser()
      let now = new moment()
      console.log(`💼 Processing queue (${now.format('MM-DD-YYYY HH:mm:ss')})`)
      const hasInternet = await NetworkInfo.hasInternetAsync()
      if (!hasInternet) {
        console.log("💼 ⚠️ No internet found. we'll try to process the queue later")
        return
      }
      if (!(user && user.username && user.token)) {
        console.log('💼 ❌ The queue can only be processed for logged in users')
        return
      }
      try {
        // Find all the jobs in the queue for this user
        let jobs = queue.queue[user.username]
        let jobFunc = null
        let needToDeleteJob = false
        let isSuccess = false

        // Create a context that will be provided to all jobs
        let context = {
          user,
          isNative,
          dispatch
        }

        // Process the jobs
        if (jobs) {
          console.log(`💼 We found ${jobs.length} jobs to process:`)
          console.log(jobs)
          jobs.forEach(job => {
            needToDeleteJob = false
            jobFunc = JobIndex[job.job_name]
            try {
              jobFunc(job.job_params, context)
              isSuccess = true
              console.log(`💼 The job id ${job.id} was processed successfully ✅`)
            } catch (e) {
              console.log(e)
              console.log(`💼 The job id ${job.id} failed to be processed (Attempts: ${job.fail_attempts} / ${job.max_fail_attempts})`)
              dispatch(QueueActions.queueIncreaseFailAttempsCount(user.username, job.id))
            }

            // Need to delete the job if 
            // - The job was processed successfully
            // - The time is past expiration date
            // - The max attempt number has been reached
            needToDeleteJob = isSuccess
            needToDeleteJob = needToDeleteJob || moment().diff(job.remove_after_date, 'minutes') > 0
            if (job.fail_attempts) {
              needToDeleteJob = needToDeleteJob || job.fail_attempts >= job.max_fail_attempts
            }
            if (needToDeleteJob) {
              dispatch(QueueActions.queueRemove(user.username, job.id))
            }

          })

        } else {
          console.log(`💼 There are no job to process for this user`)
        }

      } catch (e) {
        console.log(e)
        console.error(`💼 ❌ An error occurred whilst processing the queue`)
      }

    }

  }


}
