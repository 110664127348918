import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SettingsActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'settings icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'settings icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M31.9,21.1l-1.7-1c0.1-0.3,0.1-0.7,0.2-1.1c0-0.1,0-0.2,0-0.3c0-0.4,0.1-0.8,0.1-1.2c0-1-0.1-1.8-0.2-2.6l1.8-1
                c0.4-0.2,0.6-0.7,0.5-1.1C31.8,10.5,30.7,8.5,29,7c-0.3-0.3-0.8-0.4-1.2-0.1l-1.8,1c-1.3-1.2-2.8-2.1-4.4-2.6V3.2
                c0-0.5-0.3-0.9-0.8-1c-2.2-0.5-4.4-0.5-6.6,0c-0.4,0.1-0.8,0.5-0.8,1v2C11.8,5.8,10.3,6.7,9,7.9l-1.9-1c0,0,0,0,0,0h0c0,0,0,0,0,0
                C6.8,6.7,6.6,6.7,6.4,6.7c-0.1,0-0.2,0-0.2,0.1c0,0,0,0,0,0C6,6.9,6,6.9,5.9,7c-1.6,1.6-2.7,3.5-3.2,5.7c0,0,0,0,0,0c0,0,0,0,0,0
                c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2
                c0,0,0,0,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2l1.7,1c-0.1,0.4-0.1,0.7-0.2,1.1c0,0.1,0,0.2,0,0.3c0,0.4-0.1,0.8-0.1,1.2
                c0,1,0.1,1.8,0.2,2.6l-1.7,1c-0.4,0.2-0.6,0.7-0.5,1.1C3.2,24.5,4.3,26.5,6,28c0.3,0.3,0.8,0.4,1.2,0.1l1.8-1
                c1.3,1.2,2.8,2.1,4.4,2.6v2.1c0,0.5,0.3,0.9,0.8,1c1.1,0.3,2.2,0.4,3.3,0.4c1.1,0,2.2-0.1,3.3-0.4c0.4-0.1,0.8-0.5,0.8-1v-2
                c1.7-0.5,3.2-1.4,4.5-2.6l1.7,1c0.4,0.2,0.9,0.2,1.2-0.2c1.4-1.4,2.5-3.2,3.2-5.3c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.2,0.1-0.3,0.1-0.5
                C32.4,21.7,32.2,21.3,31.9,21.1z M21.6,17.5c0,2.3-1.8,4.1-4.1,4.1s-4.1-1.8-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1S21.6,15.2,21.6,17.5z"/>
        </Svg>
    )
}