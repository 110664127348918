import { TimetableTypes } from 'redux/lib/timetable/TimetableTypes'

export class TimetableActions {
    static setAttendanceStatus = status => {
        return { type: TimetableTypes.SET_ATTENDANCE_STATUS, payload: status }
    }
    static addAttendanceStatus = status => {
        return { type: TimetableTypes.ADD_ATTENDANCE_STATUS, payload: status }
    }
    static setStaffAttendanceStatus = status => {
        return { type: TimetableTypes.SET_STAFF_ATTENDANCE_STATUS, payload: status }
    }
}
