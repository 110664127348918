import { Geolocation } from 'lib/primitives'

export class GPS {

   static getCurrentLatLong = async () => {
      let coords = null
      try {
         const position = await Geolocation.getCurrentPosition()
         if (position && position.coords) {
            coords = {
               latitude: position.coords.latitude,
               longitude: position.coords.longitude
            }
         }
      } catch (e) {
         console.log('Could not get GPS location')
         console.log(e)
      }
      return coords
   }

}