import React, { useState, useEffect } from 'react'
import { Container } from 'primitives'
import { useHistory, routes } from 'navigation'
import { Platform } from 'lib/primitives'
import { RuntimeHelper } from 'redux/helpers'
import {
  MessageContainer,
  MessageInput
} from './components'

export const ChatUI = props => {
  const history = useHistory()
  const isWeb = Platform.isWeb()
  const [delay, setDelay] = useState(true)
  const isAnimating = RuntimeHelper.getScreenAnimating()

  useEffect(() => {
    if (delay != isAnimating) {
      setDelay(isAnimating)
    }
  },[isAnimating])

  return (
    <Container flex={1} flexGrow={1} flexShrink={1} minHeight={0}>
      <Container flex={1} flexGrow={1} flexShrink={1} minHeight={0}>
        {delay || history.location.pathname !== routes.chat.default && !isWeb ? null :
          <MessageContainer />
        }
      </Container>
      <MessageInput />
    </Container>
  )

}