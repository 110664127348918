export const Namespaces = {
    QUEUE: "queue",
    PLATFORM: "platform",
    INITIALISATION: "initialisation",
    EXAMPLE: "example",
    USER: "user",
    CHAT: "chat",
    MESSAGES: "messages",
    RUNTIME: "runtime",
    SETTINGS: "settings",
    COURSES: "courses",
    TIMETABLE: "timetable",
    NEWS: "news",
    NOTIFICATION: "notification",
    REDIRECT: "redirect"
}