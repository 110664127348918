import React from 'react'
import { Svg, Circle, Line } from 'primitives'
import { Platform } from 'lib/primitives'

export const SearchIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'search icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'search icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:3,strokeMiterLimit:10}} cx="13.02" cy="12.9" r="9.29"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:5,strokeLinecap:'round',strokeMiterLimit:10}} x1="20.38" y1="20.64" x2="30.41" y2="30.67"/>
        </Svg>
    )
}