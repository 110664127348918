import React from 'react'
import { Svg, Circle, Rect, Line } from 'primitives'
import { Platform } from 'lib/primitives'

export const AccessibilityIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'accessibility icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'accessibility icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="17.5" cy="10.5" r="2.54"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="11.28" y1="15.01" x2="23.72" y2="15.01"/>
            <Rect scale={props.size/35} style={{fill:props.color}} x="15.5" y="15.14" width="4" height="7.18"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="17.5" y1="19.75" x2="14.29" y2="26.94"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="17.5" y1="19.75" x2="20.71" y2="26.94"/>
        </Svg>
    )
}