import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { fonts } from 'lib/config/ui-config'
import { Bar } from './Bar'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const NetworkInfoBar = props => {
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()

    if (!hasInternet) {
        return <Bar
            backgroundColor={colors.screenBg}
            textColor={colors.screenText}
            textAlign={'center'}
            padding={0}
            nativeFontFamily={fonts.native.bold}
            webFontFamily={fonts.web.default}
            fontWeight={fonts.weight.bold}
            text="OFFLINE MODE - NO INTERNET"
        />
    }
    return null
}