import React from 'react'
import { ScreenContainer } from 'components/general/ScreenContainer'
import { ChatUI, ChatInit } from 'components/chat'
import { SettingsHelper } from 'redux/helpers'
import { AuthenticatedRoute, useHistory, routes } from 'navigation'
import { Suggestions } from './Suggestions'
import { Platform } from 'lib/primitives'
import { OfflineBar } from 'components/bars'
import Screen from 'lib/screen/screen'
 
export const Chat = props => {
  return (
    <React.Fragment>
      <AuthenticatedRoute exact path={routes.chat.default} render={() => <ChatScreen />} />
      <AuthenticatedRoute path={routes.chat.suggestions} render={() => <Suggestions />} />
    </React.Fragment>
  )
}

export const ChatScreen = props => {
  const colors = SettingsHelper.getColors()
  const isWeb = Platform.isWeb()
  const screenXS = Screen.isScreenXS()
  const history = useHistory()

  if (isWeb && !screenXS) history.push(routes.chat.suggestions)
  
  return (
    <ScreenContainer
      backgroundColor={colors.screenAltBg}
    >
      <OfflineBar/>
      <ChatUI />
      <ChatInit />
    </ScreenContainer>
  )
}


