import React from 'react'
import { Svg, Rect, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const TimetableActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'timetable icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'timetable icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M8.68,9.87c-0.55,0-1-0.45-1-1V3.74c0-0.55,0.45-1,1-1s1,0.45,1,1v5.12C9.68,9.42,9.23,9.87,8.68,9.87z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M26.05,9.87c-0.55,0-1-0.45-1-1V3.74c0-0.55,0.45-1,1-1s1,0.45,1,1v5.12C27.05,9.42,26.61,9.87,26.05,9.87z"/>
            <Rect scale={props.size/35} style={{fill:props.color}} x="7.82" y="21.28" width="5.25" height="5.04"/>
            <Rect scale={props.size/35} style={{fill:props.color}} x="7.82" y="14.74" width="5.25" height="5.04"/>
            <Rect scale={props.size/35} style={{fill:props.color}} x="14.57" y="14.74" width="5.59" height="5.04"/>
            <Rect scale={props.size/35} style={{fill:props.color}} x="14.57" y="21.28" width="5.59" height="5.04"/>
            <Rect scale={props.size/35} style={{fill:props.color}} x="21.66" y="21.28" width="5.25" height="5.04"/>
            <Rect scale={props.size/35} style={{fill:props.color}} x="21.66" y="14.74" width="5.25" height="5.04"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M28.55,5.61v3.26c0,1.38-1.12,2.5-2.5,2.5s-2.5-1.12-2.5-2.5V5.44H11.18v3.43c0,1.38-1.12,2.5-2.5,2.5
                s-2.5-1.12-2.5-2.5V5.69c-2.21,0.67-3.83,2.7-3.83,5.13v15.91c0,2.97,2.41,5.38,5.38,5.38h19.55c2.97,0,5.38-2.41,5.38-5.38V10.82
                C32.66,8.3,30.9,6.19,28.55,5.61z M28.41,27.07c0,0.41-0.34,0.75-0.75,0.75H7.07c-0.41,0-0.75-0.34-0.75-0.75V13.99
                c0-0.41,0.34-0.75,0.75-0.75h20.6c0.41,0,0.75,0.34,0.75,0.75V27.07z"/>
        </Svg>
    )
}