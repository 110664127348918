import React from 'react';
import PropTypes from 'prop-types';
import { InputNumberPrimitive } from './InputNumberPrimitive'
import { fonts } from 'lib/config/ui-config'

export const InputNumber = ({ onChange, ...props }) => {

    const onChangeText = text => {
        // If the user emptied the input
        if(!text){
            onChange('')
        }
        // Ensure the user is only typing digits
        let digitsOnly = text.match(/^[0-9]+$/, "")
        if (!digitsOnly) {
            return
        }
        digitsOnly = digitsOnly.pop()
        if (digitsOnly.length > props.maxDigits) {
            digitsOnly = digitsOnly.substring(0, props.maxDigits)
        }
        onChange(digitsOnly)
    }
    return <InputNumberPrimitive {...props} onChangeText={onChangeText} />
}

InputNumber.propTypes = {
    color: PropTypes.string,
    value: PropTypes.string,
    maxDigits: PropTypes.number,
    onChange: PropTypes.func,
    height: PropTypes.number,
    width: PropTypes.string,
    borderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    borderRadius: PropTypes.number,
    borderStyle: PropTypes.string,
    nativeFontFamily: PropTypes.string,
    webFontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.number,
    lineHeight: PropTypes.number,
    textAlign: PropTypes.string,
    letterSpacing: PropTypes.number
}

InputNumber.defaultProps = {
    color: "black",
    value: '',
    maxDigits: 99999999999,
    onChange: () => console.log("onChange function missing"),
    nativeFontFamily: fonts.native.default,
    webFontFamily: fonts.web.default,
    fontSize: fonts.size.default,
    fontWeight: fonts.weight.semiBold,
    lineHeight: fonts.size.default * 1.5,
    textAlign:'center',
    letterSpacing: 1
}
