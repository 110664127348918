import React, { useState, useEffect } from 'react'
import { Container, Image, Link, Text, Button, Animated } from 'primitives'
import { SettingsHelper, UserHelper } from 'redux/helpers'
import { ImageSrc } from 'assets/images'
import { Icon, icons } from 'assets/MenuIcons'
import { ArrowNavigation, StyledButton } from 'components/general'
import { fonts } from 'lib/config/ui-config'
import { CustomText } from 'components/chat/components'

const OnboardingSteps = [
    {
        content: `![Beacon waving](https://saprod1bot1.blob.core.windows.net/beacon-general/BeaconVector.png)\nHi! I'm Beacon, your digital coach. Welcome to the new app!`,
        actions: null
    },
    {
        content: `![timetable icon](https://saprod1bot1.blob.core.windows.net/beacon-general/TimetableIcon.png)You can find your timetable by tapping the icon in the navigation bar below, or you can see your next event at the top of the home page.`,
        actions: {
            highlight: {
                timetable: true
            }
        }
    },
    {
        content: `![chat icon](https://saprod1bot1.blob.core.windows.net/beacon-general/SuggestionsIcon.png)If at any time you get stuck, have a question, or need advice, you can open the chat and talk to me by tapping my icon.\n\nI'm always happy to help!`,
        actions: null
    }
]

const PageAnimation = {
    start: {
        scale: 1,
        opacity: 0
    },
    end: {
        scale: 1,
        opacity: 1
    }
}

const BubbleAnimation = {
    start: {
        scale: 0.5,
        opacity: 0
    },
    end: {
        scale: 1,
        opacity: 1
    }
}

export const OnboardingOverlay = props => {
    //Global state
    const colors = SettingsHelper.getColors()
    //Hooks
    const setShowOnboarding = UserHelper.useSetOnboarding()
    //Local state
    const [pageNo, setPageNo] = useState(0)
    const [animation, setAnimation] = useState('end')

    useEffect(() => {
        if (animation !== 'start') return
        const id = setTimeout(() => setShowOnboarding(false), 350)
        return () => clearTimeout(id)
    },[animation])

    const endOnboarding = () => {
        setAnimation('start')
    }

    return (
        <Animated
            flex={0}
            animations={PageAnimation}
            current={animation}
            initial={'start'}
            config={{ tension: 210, friction: 30 }}
            position={'absolute'}
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex={50}
            backgroundColor={colors.onboardingOverlay}
        >
            <Container
                flex={1}
                flexGrow={1}
                viewType={'SafeAreaView'}
            >
                <Container flex={1}>
                    <SpeechBubble colors={colors} pages={OnboardingSteps} pageNo={pageNo} setPageNo={setPageNo} endOnboarding={endOnboarding} animation={animation}/>
                </Container>
                <BottomBar colors={colors} highlight={OnboardingSteps && OnboardingSteps[pageNo] && OnboardingSteps[pageNo].actions && OnboardingSteps[pageNo].actions.highlight} />
            </Container>
        </Animated>
    )
}

const SpeechBubble = ({ colors, pages, pageNo, setPageNo, endOnboarding, animation }) => {

    return (
        <Animated
            display={'flex'}
            flex={1}
            animations={BubbleAnimation}
            current={animation}
            initial={'start'}
            config={{ tension: 250, friction: 20 }}
            margin={30}
            justifyContent={'flex-end'}
        >
            <Container
                flex={0}
                backgroundColor={colors.screenBg}
                borderRadius={20}
                padding={10}
                alignItems={'center'}
            >
                {pageNo !== pages.length - 1 && <Button
                    flex={0}
                    padding={0}
                    width={'100%'}
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    handleAction={() => setPageNo(pages.length - 1)}
                >
                    <Text
                        color={colors.screenAltText}
                        fontSize={fonts.size.small}
                        lineHeight={fonts.size.small * 1.5}
                        paddingRight={5}
                    >
                        Skip all
                    </Text>
                    <Icon type={icons.rightArrow} color={colors.screenAltText} size={10} />
                </Button>}
                <Container flex={0} padding={10}>
                    <CustomText text={pages && pages[pageNo] && pages[pageNo].content} textColor={colors.screenText} textAlign={'center'} title={false} imageResizemode={'contain'} imageHeight={pageNo == 0 ? 200 : 75} />
                </Container>
                {pageNo === pages.length - 1 && <StyledButton
                    title={`Close`}
                    accessibility={{ role: 'button', label: 'end the onboarding' }}
                    bgColor={colors.actionBg}
                    textColor={colors.actionText}
                    handleAction={endOnboarding}
                    margin={0}
                />}
                <ArrowNavigation
                    activePage={pageNo}
                    maxSize={'100%'}
                    pageLength={pages.length}
                    scrollLeft={() => setPageNo(pageNo - 1)}
                    scrollRight={() => setPageNo(pageNo + 1)}
                />
            </Container>
            <Container flex={0} alignItems={'center'}>
                <Container
                    flex={0}
                    height={0}
                    width={0}
                    borderWidth={20}
                    borderBottomWidth={0}
                    borderColor={colors.transparent}
                    borderTopColor={colors.screenBg}
                />
            </Container>
        </Animated>
    )
}

const BottomBar = ({ colors, highlight }) => {
    return (
        <Container
            paddingLeft={10}
            paddingRight={10}
            flex={0}
            flexDirection={'row'}
            justifyContent={'space-around'}
            alignItems={'flex-start'}
        >
            <Container height={50}>
                <Container
                    flexDirection={'row'}
                    justifyContent={'space-around'}
                    alignItems={'center'}
                    padding={5}
                >
                    {highlight && highlight.dashboard && <Link activeOpacity={1.0}>
                        <Icon type={icons.home} color={colors.menuIcon} size={40} />
                    </Link>}
                    {highlight && highlight.profile && <Link activeOpacity={1.0}>
                        <Icon type={icons.profile} color={colors.menuIcon} size={40} />
                    </Link>}
                </Container>
            </Container>
            <Container
                flex={0}
                width={70}
                height={50}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Container
                    flex={0}
                    width={70}
                    height={70}
                    borderRadius={40}
                    borderWidth={5}
                    borderColor={colors.appBg}
                    overflow={'hidden'}
                    position={'absolute'}
                    top={-22}
                >
                    <Link activeOpacity={1.0}>
                        <Container
                            width={70}
                            height={70}
                            padding={5}
                            backgroundColor={'white'}
                            overflow={'hidden'}
                        >
                            <Image src={ImageSrc.beacon} alt={'Beacon mascot'} width={'100%'} height={'100%'} />
                        </Container>
                    </Link>
                </Container>
            </Container>
            <Container height={50}>
                <Container
                    flexDirection={'row'}
                    justifyContent={'space-around'}
                    alignItems={'center'}
                    padding={5}
                >
                    <Container flex={0} width={40} />
                    {highlight && highlight.timetable && <Link activeOpacity={1.0}>
                        <Icon type={icons.timetable} color={colors.menuIcon} size={40} />
                    </Link>}
                </Container>
            </Container>
        </Container>
    )
}