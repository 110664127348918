import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Animated, Container } from 'primitives'
import { LoadingSpinner } from './'

export const SwitchToggle = ({ active, toggleValue, isLoading, size, ...props }) => {
    const colors = SettingsHelper.getColors()
    const SwitchAnimation = {
        on: {
            translateX: size,
            transform: `translateX(${size}px)`
        },
        off: {
            translateX: 0,
            transform: `translateX(0px)`
        }
    }
    const SwitchColorAnimation = {
        on: {
            backgroundColor: colors.actionBg
        },
        off: {
            backgroundColor: colors.screenAltText
        }
    }

    if (isLoading) {
        return (
            <Container
                flex={0}
                height={size}
                width={size * 2}
                overflow={'hidden'}
            >
                <LoadingSpinner size={size} color={colors.screenAltText} padding={0}/>
            </Container>
        )
    }

    return (
        <Animated
            flex={0}
            height={size}
            width={size * 2}
            borderRadius={size / 2}
            animations={SwitchColorAnimation}
            current={active ? 'on' : 'off'}
            initial={'off'}
            style={props}
        >
            <Animated
                flex={0}
                height={size}
                width={size}
                animations={SwitchAnimation}
                current={active ? 'on' : 'off'}
                initial={'off'}
            >
                <Container
                    flex={0}
                    margin={5}
                    width={size - 10}
                    height={size - 10}
                    borderRadius={size / 2}
                    backgroundColor={colors.screenBg}
                />
            </Animated>
        </Animated>
    )
}