import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general'
import { Text } from 'primitives'
import { fonts } from 'lib/config/ui-config'

export const NotFoundScreen = props => {
    const colors = SettingsHelper.getColors()

    return (
        <ScreenContainer
            viewType={'ScrollView'}
            alignItems={'center'}
        >
            <Text
                color={colors.screenAltText}
                nativeFontFamily={fonts.native.subHeading}
                webFontFamily={fonts.web.heading}
                fontWeight={fonts.weight.semiBold}
                fontSize={fonts.size.large}
                padding={15}
                textAlign={'center'}
            >
                404 page not found.
            </Text>
        </ScreenContainer >
    )
}