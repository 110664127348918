import React from 'react'
import {
	Login,
	Dashboard,
	Profile,
	Settings,
	Search,
	Courses,
	Timetable,
	Notifications,
	Chat,
	News,
	WebViewScreen,
	NotFoundScreen
} from 'screens'
import { Router, Route, Redirect, AuthenticatedRoute, LoginRoute, routes, webviewConfig } from './'

// Keep "Home" as the last one of the list
// A <Switch> looks through its children <Route>s and renders the first one that matches the current URL.
export const Navigation = () => {
	return (
		<Router>
			<LoginRoute exact path={routes.login} render={() => <Login />} />
			<Route path={'/null'} render={() => <Redirect to={{ pathname: routes.login }} />} />
			<AuthenticatedRoute path={routes.dashboard} render={() => <Dashboard />} />
			<AuthenticatedRoute exact path={routes.profile} render={() => <Profile />} />
			<AuthenticatedRoute path={routes.settings.default} render={() => <Settings />} />
			<AuthenticatedRoute path={routes.search} render={() => <Search />} />
			<AuthenticatedRoute path={routes.courses} render={() => <Courses />} />
			<AuthenticatedRoute path={routes.timetable.default} render={() => <Timetable />} />
			{/* <AuthenticatedRoute path={routes.notifications} render={() => <Notifications />} /> TODO re-add notifications */}
			<AuthenticatedRoute path={routes.chat.default} render={() => <Chat />} />
			<AuthenticatedRoute path={routes.news.default} render={() => <News />} />
			{/* WebViews: */}
			<Route path={routes.webviews.termsOfUse} render={() => <WebViewScreen uri={routes.external.termsOfUse} />} />
			<AuthenticatedRoute path={routes.webviews.covid19} render={() => <WebViewScreen uri={routes.external.covid19} injectedJavascript={webviewConfig.covid19.injectedJavascript} />} />
			<AuthenticatedRoute path={routes.webviews.pcAvailability} render={() => <WebViewScreen uri={routes.external.pcAvailability} injectedJavascript={webviewConfig.pcAvailability.injectedJavascript} />} />
			<AuthenticatedRoute path={routes.webviews.evision} render={() => <WebViewScreen uri={routes.external.evision} />} />
			<AuthenticatedRoute path={routes.webviews.outlook} render={() => <WebViewScreen uri={routes.external.outlook} />} />
			<AuthenticatedRoute path={routes.webviews.studentUnions} render={() => <WebViewScreen uri={routes.external.studentUnions} />} />
			<AuthenticatedRoute path={routes.webviews.blackboard} render={() => <WebViewScreen uri={routes.external.blackboard} />} />
			<AuthenticatedRoute path={''} render={() => <NotFoundScreen />} />
		</Router>
	)
}
