import React from 'react';

export const ImagePrimitive = ({ src, alt, ...props }) => {
    return (
        <img
            src={src.uri ? src.uri : src}
            alt={alt}
            style={{...props}}
        />
    )
}


