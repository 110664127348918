import React, { useState } from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Container, Text, Button, Column } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { ModuleItem } from './ModuleItem'
import { DropdownButton, SlideInMenu } from 'components/general'
import { Platform } from 'lib/primitives'

export const ModuleYearView = ({ data, ...props }) => {
    const colors = SettingsHelper.getColors()
    const [animation, setAnimation] = useState('closed')

    if (data) {
        return (
            <React.Fragment>
                <Container
                    flex={0}
                    width={'100%'}
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    <Button
                        flex={1}
                        padding={0}
                        handleAction={() => setAnimation(animation === 'closed' ? 'open' : 'closed')}
                        accessibility={{ role: 'button', label: animation === 'closed' ? 'open dropdown menu' : 'close dropdown menu' }}
                    >
                        <Text
                            padding={10}
                            color={colors.screenAltText}
                            nativeFontFamily={fonts.native.subHeading}
                            webFontFamily={fonts.web.heading}
                            fontWeight={fonts.weight.semiBold}
                            fontSize={fonts.size.large}
                            marginRight={Platform.isNative() ? 'auto' : null}
                        >
                            {data.year}
                        </Text>
                    </Button>
                    <DropdownButton current={animation} setAnimation={setAnimation} />
                </Container>
                <SlideInMenu animation={animation} renderChildren={ModuleYear} data={data} colors={colors} />
            </React.Fragment>
        )
    }
    return null
}

const ModuleYear = ({ colors, data }) => {
    return (
        <React.Fragment>
            {data.data.map((period, index) => <ModuleYearPeriod key={index} period={period} colors={colors} hasMarginBottom={(index === data.data.length - 1 ? 0 : null)} />)}
        </React.Fragment>
    )
}

const ModuleYearPeriod = ({ colors, period, hasMarginBottom }) => {
    if (!(period && period.data && period.data.length > 0)) {
        return null
    }
    return (
        <Column xs={12} sm={6} md={4} lg={4} xl={3} key={period.period}>
            <Container
                borderBottom={`2px solid ${colors.screenAltBg}`}
                borderBottomWidth={2}
                borderColor={colors.screenAltBg}
                margin={15}
                marginTop={0}
                marginBottom={hasMarginBottom}
            >
                <Text color={colors.screenAltText}>{period.data[0].mavPeriodName}</Text>
                {period.data.map(mod => <ModuleItem data={mod} key={mod.moduleId + mod.isStudentModuleActive} />)}
            </Container>
        </Column>
    )

}