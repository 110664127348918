import React from 'react';
import PropTypes from 'prop-types';
import { InputTextPrimitive } from './InputTextPrimitive'
import { fonts } from 'lib/config/ui-config'

export const InputText = ({ onChange, ...props }) => {

    const onChangeText = text => {
        // If the user emptied the input
        if(!text){
            onChange('')
        }
        onChange(text)
    }
    return <InputTextPrimitive {...props} onChangeText={onChangeText} />
}

InputText.propTypes = {
    color: PropTypes.string,
    value: PropTypes.string,
    maxDigits: PropTypes.number,
    onChange: PropTypes.func,
    height: PropTypes.number,
    width: PropTypes.string,
    borderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    borderRadius: PropTypes.number,
    borderStyle: PropTypes.string,
    nativeFontFamily: PropTypes.string,
    webFontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.number,
    lineHeight: PropTypes.number,
    textAlign: PropTypes.string,
    letterSpacing: PropTypes.number
}

InputText.defaultProps = {
    color: "black",
    value: '',
    onChange: () => console.log("onChange function missing"),
    nativeFontFamily: fonts.native.default,
    webFontFamily: fonts.web.default,
    fontSize: fonts.size.default,
    fontWeight: fonts.weight.semiBold,
    lineHeight: fonts.size.default * 1.5,
    textAlign:'left',
    borderWidth: 0,
}
