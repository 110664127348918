import React from 'react'
import { Svg, Line, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const TogetherallIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'Togetherall icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'Togetherall icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.1,30.8H6.9c-1.8,0-3.3-1.7-3.3-3.8V8C3.5,5.9,5,4.2,6.9,4.2h21.3c1.8,0,3.3,1.7,3.3,3.8v19
                C31.5,29.1,30,30.8,28.1,30.8z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinejoin:'round',strokeMiterlimit:10}} x1="3.6" y1="8" x2="31.5" y2="8"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="6.9" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="8.7" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.5" cy="6.3" r="0.7"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinejoin:'round',strokeMiterlimit:10}} d="M13.7,16.3c-0.1-1.2,0.2-2.3,0.9-3.2c-0.9,0-3.1,0.2-4.7,1.6C8.6,15.8,8,17.5,8,19.5c0,2.8,1.2,4.7,2.2,5.8
                    c1.1,1.2,2.2,1.7,2.2,1.8c0.1,0,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1c0.2-0.1,0.3-0.3,0.3-0.5v-2.3c0.1-0.1,0.3-0.1,0.5-0.2
                    c1-0.5,2.3-1.1,3.4-1.9c-0.3-0.2-0.6-0.4-0.9-0.7C14.6,20,13.7,18.3,13.7,16.3z"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinejoin:'round',strokeMiterlimit:10}} d="M25.2,12.7c-1.8-1.8-4.3-2-5.4-2c0,0,0,0-0.1,0c-0.3,0-3.1,0.1-4.8,1.8c-0.2,0.2-0.3,0.3-0.4,0.5
                    c0.2,0,0.4,0,0.4,0c0.1,0,1.2,0.1,2.4,0.8c1.1,0.6,2.4,1.9,2.4,4.3c0,1.7-1.2,3-2.6,3.9c1.1,0.8,2.4,1.4,3.3,1.8
                    c0.2,0.1,0.4,0.2,0.6,0.3v2.4c0,0.2,0.1,0.4,0.3,0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,1.3-0.8,2.5-2.3
                    c1.1-1.4,2.5-3.7,2.5-6.9C27,15.7,26.4,14,25.2,12.7z"/>
        </Svg>
    )
}