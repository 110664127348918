import React from 'react';
import PropTypes from 'prop-types';
import { WebViewPrimitive } from './WebViewPrimitive'

export const WebView = props => {
    return <WebViewPrimitive {...props} />
}

WebView.propTypes = {
    uri: PropTypes.string.isRequired,
    javaScriptEnabled: PropTypes.bool,
    injectedJavascript: PropTypes.string,
};

WebView.defaultProps = {
    uri: "",
    javaScriptEnabled: true,
    injectedJavascript: ""
};
