import React from 'react'
import { Svg, Circle, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const MoodFantasticIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'fantastic mood icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'fantastic mood icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="17.52" cy="17.5" r="14.81"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M9.2,13.94c0-1.41,1.15-2.56,2.56-2.56s2.56,1.15,2.56,2.56"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M20.72,13.94c0-1.41,1.15-2.56,2.56-2.56s2.56,1.15,2.56,2.56"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M25.42,19.89c0,3.9-3.54,7.05-7.9,7.05s-7.9-3.16-7.9-7.05"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} x1="9.62" y1="19.89" x2="25.42" y2="19.89"/>
        </Svg>
    )
}