import React from 'react'
import moment from 'moment'
import { SettingsHelper } from 'redux/helpers'
import { Container } from 'primitives'
import { TimetableItem } from './TimetableItem'
import { Platform } from 'lib/primitives'
import { getLectureLocationUrl } from 'lib/timetable/lectureLocation'

export const TimelineWrapper = ({ data, fetchFunc }) => {
    const colors = SettingsHelper.getColors()
    const isPast = moment(data.eventEndDate).isBefore(moment())
    const locationUrl = getLectureLocationUrl(data)
    const isWeb = Platform.isWeb()

    return (
        <Container
            flex={0}
            position={'relative'}
            zIndex={5}
            width={'100%'}
            maxWidth={600}
            margin={5}
            padding={10}
            borderRadius={5}
            border={`2px solid ${isPast ? colors.screenAltBg : locationUrl ? colors.actionLightBgs[1] : colors.actionLightBgs[2]}`}
            borderWidth={2}
            borderColor={isPast ? colors.screenAltBg : locationUrl ? colors.actionLightBgs[1] : colors.actionLightBgs[2]}
            backgroundColor={isPast ? colors.screenAltBg : colors.screenBg}
        >
            <TimetableItem data={data} isPast={isPast} fetchFunc={fetchFunc}/>
        </Container>
    )
}