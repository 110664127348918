import { SettingsTypes } from './SettingsTypes'

export class SettingsActions {

    static resetSettings = () => {
        return { type: SettingsTypes.RESET_SETTINGS, payload: {} }
    }

    static setColorTheme = theme => {
        return { type: SettingsTypes.SET_COLOR_THEME, payload: theme }
    }

    static setBackgroundColor = color => {
        return { type: SettingsTypes.SET_BACKGROUND_COLOR, payload: color }
    }

    static setDisableSwipeEvents = disable => {
        return { type: SettingsTypes.SET_DISABLE_SWIPE_EVENTS, payload: disable }
    }

    static setDisableAnimations = disable => {
        return { type: SettingsTypes.SET_DISABLE_ANIMATIONS, payload: disable }
    }

    static setDisableInAppNotifications = disable => {
        return { type: SettingsTypes.SET_DISABLE_IN_APP_NOTIFICATIONS, payload: disable }
    }

    static setEnableLargerTiles = enable => {
        return { type: SettingsTypes.SET_ENABLE_LARGER_TILES, payload: enable }
    }

    static setQnaResults = results => {
        return { type: SettingsTypes.SET_QNA_RESULTS, payload: results }
    }

}


