import React from 'react'
import { Container, Button } from 'primitives'
import { DialogUtterances } from 'lib/chat/dialogUtterances'
import { DefaultTileContent } from './'

export const ChatTile = ({ colors, title, message, icon, tileWidth, history, hasInternet }) => {
  const customDialog = DialogUtterances.useCustomDialog()
  return (
    <Container
      position={'absolute'}
      top={0}
      bottom={0}
      left={0}
      right={0}
      backgroundColor={colors.screenBg}
      margin={5}
      padding={10}
      borderRadius={10}
      opacity={!hasInternet ? 0.5 : null}
    >
      <Button accessibility={{ role: 'button', label: 'send ' + message + ' to Beacon' }} handleAction={() => hasInternet ? customDialog(message, history) : null} flex={1} padding={0} flexDirection={'column'} alignItems={'center'} justifyContent={'flex-start'}>
        <DefaultTileContent
          title={title}
          icon={icon}
          colors={colors}
          tileWidth={tileWidth}
        />
      </Button>
    </Container>
  )
}