import React from 'react'
import { useSelector } from 'react-redux'
import { Container, Text, Button } from 'primitives'
import { Icon, icons } from 'assets/MenuIcons'
import { ScreenContainer, LoadingSpinner, StyledButton } from 'components/general'
import { SettingsHelper, UserHelper } from 'redux/helpers'
import { fonts } from 'lib/config/ui-config'
import { Namespaces } from 'redux/namespaces'

const defaultText = 'Loading...'

export const LoadingData = ({ text, backgroundColor }) => {
    const colors = SettingsHelper.getColors()
    const cannotLoadProfile = useSelector(state => state[Namespaces.USER].cannotLoadProfile)
    const handleLogOut = UserHelper.useHandleLogOut()

    const renderContent = () => {
        if (cannotLoadProfile) {
            return (
                <React.Fragment>
                    <Text
                        color={colors.screenAltText}
                        nativeFontFamily={fonts.native.subHeading}
                        webFontFamily={fonts.web.heading}
                        fontWeight={fonts.weight.semiBold}
                        fontSize={fonts.size.large}
                        padding={15}
                        paddingTop={25}
                        textAlign={'center'}
                    >
                        We could not load your profile. Please try again in a few minutes. If the problem persists please email beacon@staffs.ac.uk
                    </Text>
                    <StyledButton
                        title={'Log Out'}
                        accessibility={{ role: 'button', label: 'log out of Beacon' }}
                        bgColor={colors.actionBg}
                        textColor={colors.actionText}
                        handleAction={handleLogOut}
                    />
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
                <Text
                    color={colors.screenAltText}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                    fontSize={fonts.size.large}
                    padding={15}
                    paddingTop={25}
                    textAlign={'center'}
                >
                    {text ? text : defaultText}
                </Text>
                <LoadingSpinner size={40} color={colors.screenAltText} />
            </React.Fragment>
        )
    }


    return (
        <ScreenContainer
            backgroundColor={backgroundColor ? backgroundColor : colors.screenBg}
            hideChatBar={true}
            alignItems={'center'}
        >
            <Container
                flex={0}
                width={'100%'}
                maxWidth={600}
                alignItems={'center'}
            >
                {renderContent()}
            </Container>
        </ScreenContainer >
    )
}


