import React from 'react';
import PropTypes from 'prop-types';
import { ImagePrimitive } from './ImagePrimitive'

export const Image = props => {
    return <ImagePrimitive {...props}/>
}

Image.propTypes = {
    src: PropTypes.any.isRequired,
    resizeMode: PropTypes.oneOf(['cover', 'contain', 'stretch', 'repeat', 'center']),
    objectFit: PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    altText: PropTypes.string,
};

Image.defaultProps = {
    src: "",
    resizeMode: 'cover',
    objectFit: 'contain',
    altText: "",
};
