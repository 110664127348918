import React from 'react'
import { Svg, Polyline, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const HomeIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'home icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'home icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Polyline scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} points="2.87,18.49 17.5,3.87 32.13,18.49 "/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M6.07,15.44v12.9c0,1.46,1.33,2.66,2.95,2.66h5.06v-9.36h6.83v9.36h5.06c1.62,0,2.95-1.2,2.95-2.66v-12.9"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M28.04,14.87c0.49,0,0.89-0.4,0.89-0.89V8.98c0-0.49-0.4-0.89-0.89-0.89h-1.82c-0.49,0-0.89,0.4-0.89,0.89v2.58
                "/>
        </Svg>
    )
}