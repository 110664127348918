import React, { useState } from 'react'
import { Container, Text, Button } from 'primitives'
import { SettingsHelper } from 'redux/helpers'
import { fonts } from 'lib/config/ui-config'
import { DropdownButton, SlideInMenu } from 'components/general'
import { DialogUtterances } from 'lib/chat/dialogUtterances'
import { useHistory } from 'navigation'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const SuggestionsItem = ({ data }) => {
    const colors = SettingsHelper.getColors()
    const customDialog = DialogUtterances.useCustomDialog()
    const hasInternet = NetworkInfo.useHasInternet()
    const [animation, setAnimation] = useState('closed')
    const history = useHistory()

    return (
        <Container flex={0} opacity={!hasInternet ? 0.5 : null}>
            <Container
                flex={0}
                width={'100%'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
            >
                <Button
                    padding={0}
                    flex={1}
                    handleAction={() => setAnimation(animation === 'closed' ? 'open' : 'closed')}
                    accessibility={{ role: 'button', label: animation === 'closed' ? 'open dropdown menu' : 'close dropdown menu' }}
                >
                    <Text
                        paddingTop={10}
                        paddingBottom={10}
                        color={colors.screenAltText}
                        nativeFontFamily={fonts.native.subHeading}
                        webFontFamily={fonts.web.heading}
                        fontWeight={fonts.weight.semiBold}
                        fontSize={fonts.size.large}
                        marginRight={'auto'}
                    >
                        {data.title}
                    </Text>
                </Button>
                <DropdownButton current={animation} setAnimation={setAnimation} />
            </Container>
            <SlideInMenu
                animation={animation}
                renderChildren={SuggestionsContent}
                data={data.content}
                colors={colors}
                history={history}
                customDialog={customDialog}
                hasInternet={hasInternet}
            />
        </Container>
    )
}

const SuggestionsContent = ({ data, colors, history, customDialog, hasInternet }) => {
    return (
        <Container
            flex={0}
            width={'100%'}
            paddingLeft={data[0].title ? 15 : 0}
            borderTop={`2px solid ${colors.screenAltBg}`}
            borderTopWidth={2}
            borderColor={colors.screenAltBg}
        >
            {data[0].title ?
                data.map(contentItem => <SuggestionsItem data={contentItem} key={contentItem.title} />)
                :
                data.map(textItem => <SuggestionsText data={textItem} colors={colors} key={textItem} customDialog={customDialog} history={history} hasInternet={hasInternet} />)
            }
        </Container>
    )
}

const SuggestionsText = ({ data, colors, history, customDialog, hasInternet }) => {
    const handlePress = () => hasInternet ? customDialog(data, history) : null

    return (
        <Button accessibility={{ role: 'button', label: 'send ' + data + ' to Beacon' }} handleAction={handlePress} flex={0} flexDirection={'row'} marginTop={5} marginBottom={5} alignItems={'center'} textAlign={'left'}>
            <Text color={colors.screenText} paddingTop={5} paddingBottom={5}>
                {data}
            </Text>
        </Button>
    )
}