import { InitialisationTypes } from './InitialisationTypes'

export class InitialisationActions {

    static tokenChecked = () => {
        return { type: InitialisationTypes.SET_TOKEN_CHECKED, payload: true }
    }

    static initialChecksCompleted = () => {
        return { type: InitialisationTypes.SET_INITIAL_CHECKS_COMPLETED, payload: true }
    }

}
