import React from 'react'
import { useSelector } from 'react-redux'
import { Namespaces } from 'redux/namespaces'
import { SettingsHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general'
import { Container, Text, Button } from 'primitives'
import { BackBar } from 'components/bars'
import { OfflineBar } from 'components/bars'
import { DialogUtterances } from 'lib/chat/dialogUtterances'
import { useHistory, routes } from 'navigation'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { Icon, icons } from 'assets/MenuIcons'

export const Search = props => {
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()
    const history = useHistory()
    const customDialog = DialogUtterances.useCustomDialog()
    const qnaResults = useSelector(state => state[Namespaces.SETTINGS].qnaResults)

    return (
        <ScreenContainer
            viewType={'ScrollView'}
            alignItems={'center'}
        >
            <BackBar />
            <OfflineBar />
            <Container
                flex={0}
                padding={10}
                width={'100%'}
                maxWidth={600}
                opacity={!hasInternet ? 0.5 : null}
            >
                {history.location.pathname === routes.search && (!qnaResults || qnaResults.length == 0) &&
                    <React.Fragment>
                        <Text color={colors.screenAltText} padding={5} textAlign={'center'}>
                            No results.
                        </Text>
                        <Text color={colors.screenAltText} padding={5} textAlign={'center'}>
                            Can't find what you're looking for? Try using key words such as "Library" or "Wi-Fi"
                        </Text>
                    </React.Fragment>
                }
                {history.location.pathname === routes.search && qnaResults && qnaResults.map((item, index) => {
                    return <SearchItem
                        data={item}
                        colors={colors}
                        history={history}
                        hasInternet={hasInternet}
                        customDialog={customDialog}
                        key={index}
                    />
                })}
                {history.location.pathname === routes.search && (qnaResults && qnaResults.length == 25) &&
                    <React.Fragment>
                        <Text color={colors.screenAltText} padding={5} textAlign={'center'}>
                            Please note that results are limited to 25 results.
                        </Text>
                        <Text color={colors.screenAltText} padding={5} textAlign={'center'}>
                            If you cannot find what you're looking for, try typing more key words
                        </Text>
                    </React.Fragment>
                }
            </Container>
        </ScreenContainer>
    )
}

const SearchItem = ({ data, colors, customDialog, history, hasInternet }) => {
    return (
        <Button
            flex={0}
            margin={5}
            flexDirection={'row'}
            handleAction={() => hasInternet ? customDialog(data.question, history) : null}
        >
            <Container flex={1}>
                <Text color={colors.screenText} padding={5} textAlign={'left'}>
                    {data.question}
                </Text>
            </Container>
            <Container flex={0}>
                <Icon type={icons.rightArrow} color={colors.screenAltText} size={15} />
            </Container>
        </Button>
    )
}