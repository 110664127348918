import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container, Text } from 'primitives'
import { TimetableItem } from './TimetableItem'
import moment from 'moment'
import { FetchHelper, SettingsHelper, UserHelper, NotificationHelper } from 'redux/helpers'
import { FetchRequest } from 'lib/general'
import { Namespaces } from 'redux/namespaces'
import { TimetableFetchKeys, SettingsFetchKeys } from 'redux/fetchKeys'
import { ReduxFetch } from 'components/general'
import { getChunk } from 'lib/timetable/timetableFilter'
import { useInterval } from 'lib/general/useInterval'
import { notificationTypes } from 'lib/general/enums'

export const TimetableTile = props => {
    const colors = SettingsHelper.getColors()
    const currentWeek = moment.utc().startOf('week')
    const chunkInfo = getChunk(currentWeek)
    const deleteNotifications = NotificationHelper.useDeleteNotificationByType()
    const createNotifications = NotificationHelper.useCreateNotificationsForTimetable()
    const isStudent = UserHelper.getIsStudent()
    const notificationsData = useSelector(state => state[Namespaces.SETTINGS][SettingsFetchKeys.notifications])
    const getTimetableForStudent = FetchRequest.useGetTimetableForStudent()
    const getTimetableForStaff = FetchRequest.useGetTimetableForStaff()

    if (isStudent == null) return null

    const handleNotifications = (data) => {
        let sendNotifications = true
        if (notificationsData && notificationsData.data) {
            const timetableSettings = notificationsData.data.find(item => item.name == 'Timetable')
            if (timetableSettings && timetableSettings.typeUsers && timetableSettings.typeUsers[0] && timetableSettings.typeUsers[0].hide == 1) {
                sendNotifications = false
            }
        }
        deleteNotifications(notificationTypes.timetable)
        if (sendNotifications) {
            createNotifications(data)
        }
    }

    let fetchTimetable = () => { }
    if (isStudent) {
        fetchTimetable = FetchHelper.useFetch(Namespaces.TIMETABLE, TimetableFetchKeys[chunkInfo.chunk], () => getTimetableForStudent(chunkInfo.start, chunkInfo.end), handleNotifications)
    }
    else {
        fetchTimetable = FetchHelper.useFetch(Namespaces.TIMETABLE, TimetableFetchKeys[chunkInfo.chunk], () => getTimetableForStaff(chunkInfo.start, chunkInfo.end), handleNotifications)
    }

    return (
        <Container
            backgroundColor={colors.screenBg}
            margin={5}
            padding={10}
            borderRadius={10}
            minHeight={props.tileWidth - 10}
            justifyContent={'center'}
        >
            <ReduxFetch
                debug={false}
                namespace={Namespaces.TIMETABLE}
                fetchKey={TimetableFetchKeys[chunkInfo.chunk]}
                fetchFunc={fetchTimetable}
                cacheDuration={1}
                component={TimetableTileDisplay}
                errorText={'Something went wrong refreshing your timetable. The most recent version is displayed below: '}
            />
        </Container>
    )
}

const TimetableTileDisplay = ({ data, fetchFunc, ...props }) => {
    const colors = SettingsHelper.getColors()
    const [nextEvent, setNextEvent] = useState()

    useInterval(() => {
        if (data.data && nextEvent && moment().add(15, 'minutes').isAfter(moment(nextEvent.eventEndDate))) {
            const newNextEvent = data.data.find(item => moment().add(15, 'minutes').isBefore(moment(item.eventEndDate)))
            setNextEvent(newNextEvent)
        }
    }, 30000)

    useEffect(() => {
        if (data.data) {
            const newNextEvent = data.data.find(item => moment().add(15, 'minutes').isBefore(moment(item.eventEndDate)))
            setNextEvent(newNextEvent)
        }
    }, [data])

    if (nextEvent == null) return <Text color={colors.screenAltText} textAlign={'center'}>No upcoming events.</Text>
    return <TimetableItem data={nextEvent} fetchFunc={fetchFunc} isDashboard={true} />
}