import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const ProfileActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'profile icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'profile icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.5,1.68c-8.73,0-15.82,7.1-15.82,15.82c0,8.72,7.1,15.82,15.82,15.82c8.72,0,15.82-7.1,15.82-15.82
                C33.32,8.77,26.22,1.68,17.5,1.68z M13.15,12.34c0-2.4,1.95-4.35,4.35-4.35s4.35,1.95,4.35,4.35c0,1.25-0.53,2.37-1.38,3.16
                c2.42,1.64,4.09,5.22,4.09,9.38H10.44c0-4.16,1.68-7.74,4.09-9.38C13.69,14.7,13.15,13.59,13.15,12.34z"/>
        </Svg>
    )
}