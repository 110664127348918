export const content = [
    {
        title: `Popular`,
        content: [
            `I'd like to join a society`,
            `I need a new student card`,
            `Who is my personal tutor?`
        ]
    },
    {
        title: `Timetable`,
        content: [
            `When is my next lecture?`,
            `What lectures do I have today?`,
            `What lectures do I have on Thursday?`,
            `What lectures do I have next week?`,
            `What are the term dates?`,
            `When are my exams?`
        ]
    },
    {
        title: `PC Availability`,
        content: [
            `Are there free PCs?`,
            `Any PC available in Stafford?`,
            `Available computers in Cadman?`
        ]
    },
    {
        title: `Location`,
        content: [
            `How do I get to the Student Hub?`,
            `Where is the Ember Lounge?`,
            `Directions to Stafford campus`,
            `Where is S501?`,
            `H061 location?`
        ]
    },
    {
        title: `News & Events`,
        content: [
            `Show me news`,
            `Show me events`,
            `Personalised news`,
            `News about staffs uni`,
            `Events about technology`
        ]
    },
    {
        title: `Staff Directory`,
        content: [
            `Show me the staff directory`,
            `Who works in marketing?`,
            `Who is Elizabeth Barnes?`
        ]
    },
    {
        title: 'University Services',
        content: [
            {
                title: 'Academic Skills Support',
                content: [
                    `I need academic skills support`,
                    `What are the accessibility study resources?`,
                    `I'm struggling with maths`,
                    `I want to improve my academic writing`,
                    `I need help with referencing`
                ]
            },
            /* To be checked before approval
            {
                title: 'Accommodation',
                content: [
                    `How do I get post delivered?`,
                    `Will my belongings be insured?`,
                    `Where can I do my laundry?`,
                    `Will I need a TV license?`,
                    `Internet services in halls on campus`
                ]
            },
            */
            {
                title: `Careers`,
                content: [
                    `How do I write a CV?`,
                    `How do I prepare for an interview?`,
                    `What is MyCareer?`,
                    `I want to find a part-time job`,
                    `How can I find out about working freelance?`,
                    `I have a question about my placement`,
                    `I'd like to book a careers appointment`,
                    `How do I contact careers?`
                ]
            },
            {
                title: `Digital Services`,
                content: [
                    `How do I get wifi?`,
                    `What is my username?`,
                    `What is my university email address?`,
                    `How do I access my email?`,
                    `I need to reset my password`,
                    `Where are the gaming PCs?`,
                    `Where are the Engineering CAD PCs?`,
                    `Where are the open access Macs?`,
                    `Where are the printers?`,
                    `How much does printing and copying cost?`,
                    `How do I add printer credit`,
                    `How do I print from my own device?`,
                    `How do I scan a document?`,
                    `How do I use the lapsafe cabinet?`,
                    `How do I access Adobe software?`,
                    `What is MyStaffsUni?`,
                    `What is Blackboard?`,
                    `What is E:vision?`,
                    `What is LinkedIn Learning?`,
                    `What is TestFest?`,
                    `How do I contact Digital Services?`
                ]
            },
            {
                title: `International Student`,
                content: [
                    `Do I need to register with the police?`,
                    `I have lost my passport`,
                    `Where do I collect my Biometric Residence Permit from?`,
                    `I've lost my residence permit`,
                    `Where do I complete online enrolment?`,
                    `How do I complete face to face enrolment?`,
                    `What do I do if I want to change my course?`,
                    `What do I do if I need to be absent from the university?`
                ]
            },
            {
                title: `Library`,
                content: [
                    `Library opening hours`,
                    `How do I search for a library book?`,
                    `How long can I borrow a library book?`,
                    `How do I check what books I have on loan?`,
                    `How do I renew the loan on a book?`,
                    `How do I pay a recall charge?`,
                    `What are the reading lists for my course?`,
                    `Can you eat and drink in the library?`,
                    `How do I log into my library account?`,
                    `What are the headspace areas?`,
                    `Where can I bind my dissertation?`
                ]
            },
            {
                title: `Student Enabling`,
                content: [
                    `I need special arrangements for my exams`,
                    `What are the accessibility study resources?`,
                    `What is counselling?`,
                    `I'd like to see a counsellor`,
                    `What are the DSAs?`,
                    `What is a disability needs assessment?`,
                    `How can I arrange a disability needs assessment?`
                ]
            },
            {
                title: `Student Services`,
                content: [
                    `What is welcome week?`,
                    `How do I get my Student ID card?`,
                    `How do I obtain a replacement student ID card?`,
                    `How do I change my personal details?`,
                    `How do I check my unicard balance?`,
                    `How do I submit my assignment?`,
                    `How do I find out what modules I am enrolled on?`,
                    `What is my module about?`,
                    `Have I passed a module?`,
                    `When is graduation?`,
                    `What do you know about postgraduate courses?`,
                    `What do you know about apprenticeships?`,
                    `How do I change my course?`,
                    `How do I change a module?`,
                    `How do I get a parking permit?`,
                    `Who do I contact with a parking query?`
                ]
            },
            {
                title: `Students' Union`,
                content: [
                    `Students' Union opening times`,
                    `Students' Union events`,
                    `How do I start a new society?`,
                    `I'd like to join a society`,
                    `Who is my course rep?`,
                    `Who are the Students' Union officers?`,
                    `How can I get a student discount card?`,
                    `What is Gobble?`,
                    `When is Verve open?`,
                    `When is Squeeze Box open?`,
                    `Students' Union jobs`
                ]
            },
        ]
    },
    {
        title: `Recommendations`,
        content: [
            `I don't know what to do today`,
            `I need a coffee`,
            `Where can I go swimming?`,
            `Where is the gym?`,
            `Where can I go bowling?`,
            `Find me a takeaway`,
            `Where is the nearest chippy?`,
            `Where can I get vegan food?`,
            `Where can I get pizza?`
        ]
    },
    {
        title: `Fun`,
        content: [
            `Flip a coin`,
            `Roll a dice`,
            `Give me a high five`,
            `Give me a limerick`,
            `What's your favourite food?`
        ]
    }
]