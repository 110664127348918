const initialState = "unknown"

// state = {
//     platform: "...",
//     version: "..."
// }

// This reducer is initialised when the store is created
export const PlatformReducer = (state = initialState, action) => {
    return state
}