import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Container, Link, Button, Text } from "primitives"
import { useLocation, routes } from 'navigation'
import { UserHelper } from 'redux/helpers'
import { Icon, icons } from 'assets/MenuIcons'
import { SearchBar } from 'components/bars/SearchBar'
import { NotificationIndicator } from 'components/bars/NotificationIndicator'
import Screen from 'lib/screen/screen'
import { fonts } from 'lib/config/ui-config'

export const TopBar = props => {
    const screenXS = Screen.isScreenXS()
    const screenSM = Screen.isScreenSM()
    const isStudent = UserHelper.getIsStudent()
    const colors = SettingsHelper.getColors()
    const location = useLocation()
    const handleLogOut = UserHelper.useHandleLogOut()

    const renderLinks = () => {
        if (location.pathname !== routes.login) {
            return (
                <React.Fragment>
                    {!screenXS && !screenSM &&
                        <Link to={routes.dashboard}>
                            <Container
                                flex={0}
                                padding={5}
                                paddingRight={0}
                                alignSelf={'flex-start'}
                                flexDirection={'row'}
                                alignItems={'center'}
                                float={'left'}
                            >
                                <Container flex={0}>
                                    <Icon type={icons.suniLogo} size={50} />
                                </Container>
                                <Container flex={0} paddingLeft={5}>
                                    <Text
                                        color={colors.menuIcon}
                                        nativeFontFamily={fonts.native.heading}
                                        webFontFamily={fonts.web.heading}
                                        fontWeight={fonts.weight.semiBold}
                                        fontSize={fonts.size.heading}
                                    >
                                        Beacon
                                    </Text>
                                </Container>
                            </Container>
                        </Link>}
                    <Container
                        flex={1}
                        minWidth={screenXS ? '100%' : 0}
                        flexDirection={screenXS ? 'column' : 'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <SearchBar screenXS={screenXS} />
                        <Container
                            flex={0}
                            minWidth={screenXS ? '100%' : 0}
                            flexDirection={'row'}
                            justifyContent={screenXS ? 'space-around' : 'center'}
                            alignItems={'center'}
                            marginBottom={screenXS ? 5 : 0}
                        >
                            <Link to={routes.dashboard} paddingLeft={5} paddingRight={5}>
                                <Icon type={location.pathname === routes.dashboard ? icons.homeActive : icons.home} color={colors.menuIcon} size={40} />
                            </Link>
                            {/* <NotificationIndicator /> TODO re-add notifications */}
                            <Link to={routes.profile} paddingRight={5}>
                                <Icon type={location.pathname === routes.profile ? icons.profileActive : icons.profile} color={colors.menuIcon} size={40} />
                            </Link>
                            <Link to={routes.settings.default} paddingRight={5}>
                                <Icon type={location.pathname.startsWith(routes.settings.default) ? icons.settingsActive : icons.settings} color={colors.menuIcon} size={40} />
                            </Link>
                            {isStudent &&
                                <Link to={routes.courses} paddingLeft={3} paddingRight={5}>
                                    <Icon type={location.pathname.startsWith(routes.courses) ? icons.coursesActive : icons.courses} color={colors.menuIcon} size={40} />
                                </Link>
                            }
                            <Link to={routes.timetable.default} paddingRight={5}>
                                <Icon type={location.pathname.startsWith(routes.timetable.default) ? icons.timetableActive : icons.timetable} color={colors.menuIcon} size={40} />
                            </Link>
                            <Button handleAction={handleLogOut} padding={0} paddingRight={5} accessibility={{ role: 'button', label: 'log out of Beacon' }}>
                                <Icon type={icons.logOut} color={colors.menuIcon} size={37} />
                            </Button>
                        </Container>
                    </Container>
                </React.Fragment>
            )
        }
        return null
    }

    return (
        <Container
            flex={0}
            flexDirection={screenXS ? 'column' : 'row'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            {renderLinks()}
        </Container>
    )
}
