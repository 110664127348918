import React from 'react'
import { Svg, Circle, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const AppInfoIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'app info icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'app info icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterlimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.51,10.61c-0.33,0-0.62-0.1-0.86-0.31s-0.36-0.5-0.36-0.87c0-0.34,0.12-0.62,0.36-0.84s0.53-0.33,0.85-0.33
                c0.31,0,0.59,0.1,0.83,0.3s0.36,0.49,0.36,0.87c0,0.37-0.12,0.66-0.35,0.87S17.84,10.61,17.51,10.61z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="17.5" y1="14.04" x2="17.5" y2="26.85"/>
        </Svg>
    )
}