import { sleep } from 'lib/general/utils'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { UserHelper } from 'redux/helpers'

const config = require('lib/config/env-config').config

const HTTP_METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
}

export class FetchRequest {

    static useRequest = () => {
        const getAuthToken = UserHelper.useGetAuthToken()
        return async (method, address, body = {}, requiresAuthToken = true, attempts = 5, timer = 3000) => {
            let authToken = null
            if (requiresAuthToken) {
                authToken = await getAuthToken()
            }
            return await this.request(method, address, authToken, body, attempts, timer)
        }
    }

    static request = async (method, address, authToken, body = {}, attempts = 5, timer = 3000) => {
        const hasInternet = await NetworkInfo.hasInternetAsync()
        let logPrefix = `Request: ${method} ${config.bot.url}${address}`
        console.log(`Attempting ${logPrefix}`)

        if (!hasInternet) {
            // console.warn(`⚠️  ${logPrefix} No internet connection`)
            return {
                error: true,
                resStatus: 12163 // 12163 is Oracle HTTP API error code for 'Disconnected'
            }
        }

        try {
            let response
            let headers
            if (authToken != null) {
                headers = {
                    authorization: "Bearer " + authToken,
                    "Bearer": authToken,
                    "Content-Type": "application/json"
                }
            } else {
                headers = {
                    "Content-Type": "application/json"
                }
            }

            if (method === HTTP_METHODS.GET) {
                response = await fetch(config.bot.url + address, {
                    method: method,
                    headers: headers,
                })
            } else {
                response = await fetch(config.bot.url + address, {
                    method: method,
                    headers: headers,
                    body: JSON.stringify(body),
                })
            }

            if (response.ok) {
                return await response.json()
            } else {
                console.warn(`⚠️  ${logPrefix} received status code ${response.status}`)
                // If Forbidden or Unauthorized, don't try again
                if (response.status === 403 || response.status === 401) {
                    return {
                        error: true,
                        resStatus: response.status
                    }
                }
                attempts--
                if (attempts < 1) {
                    console.warn(`⚠️  ${logPrefix} Retry attempts maxed (See reponse below)`)
                    console.log(response)
                    return {
                        error: true,
                        resStatus: response.status
                    }
                }
                await sleep(timer)
                return await FetchRequest.request(method, address, authToken, body, attempts)
            }

        } catch (ex) {
            // console.warn(`⚠️  ${logPrefix} Unexpected error`)
            attempts--
            if (attempts < 1) {
                console.error(`⚠️  ${logPrefix} Retry attempts maxed (See error below)`)
                console.log(ex)
                return {
                    error: true,
                    resStatus: null
                }
            }
            await sleep(timer)
            return await FetchRequest.request(method, address, authToken, body, attempts)
        }
    }

    // ------------------------------------
    // -------------- Utils ---------------
    // ------------------------------------

    // checkIfTokenValid
    static useCheckIfTokenValid = () => {
        const req = this.useRequest()
        return async () => await req(HTTP_METHODS.GET, '/api/utils/check-token')
    }
    static checkIfTokenValid = async authToken => {
        return await FetchRequest.request(HTTP_METHODS.GET, '/api/utils/check-token', authToken)
    }

    // ------------------------------------
    // --------------- User ---------------
    // ------------------------------------

    // getUserInfo
    static useGetUserInfo = () => {
        const req = this.useRequest()
        return async () => await req(HTTP_METHODS.GET, '/api/users/my-info')
    }
    static getUserInfo = async authToken => {
        return await FetchRequest.request(HTTP_METHODS.GET, '/api/users/my-info', authToken)
    }

    // getStudentInfo
    static useGetStudentInfo = () => {
        const req = this.useRequest()
        return async () => await req(HTTP_METHODS.GET, '/api/users/my-info-details')
    }
    static getStudentInfo = async authToken => {
        return await FetchRequest.request(HTTP_METHODS.GET, '/api/users/my-info-details', authToken)
    }

    // ------------------------------------
    // ---------- Achievements ------------
    // ------------------------------------

    static getAchievements = async authToken => {
        return await FetchRequest.request(HTTP_METHODS.GET, '/api/achievements/my-achievements', authToken)
    }

    // ------------------------------------
    // ------------ Interests -------------
    // ------------------------------------

    // getInterestCategories
    static useGetInterestCategories = () => {
        const req = this.useRequest()
        return async () => await req(HTTP_METHODS.GET, '/api/news-and-events/all-categories-with-user-interests')
    }
    static getInterestCategories = async authToken => {
        return await FetchRequest.request(HTTP_METHODS.GET, '/api/news-and-events/all-categories-with-user-interests', authToken)
    }

    // toggleInterestCategory
    static useToggleInterestCategory = () => {
        const req = this.useRequest()
        return async categoryId => await req(HTTP_METHODS.POST, '/api/news-and-events/toggle-user-interest-category', { 'categoryId': categoryId })
    }
    static toggleInterestCategory = async (authToken, categoryId) => {
        return await FetchRequest.request(HTTP_METHODS.POST, '/api/news-and-events/toggle-user-interest-category', authToken, { 'categoryId': categoryId })
    }

    // increaseInterestScore
    static useIncreaseInterestScore = () => {
        const req = this.useRequest()
        return async categoryId => await req(HTTP_METHODS.POST, '/api/news-and-events/increase-user-interest-score', { 'categoryId': categoryId })
    }
    static increaseInterestScore = async (authToken, categoryId) => {
        return await FetchRequest.request(HTTP_METHODS.POST, '/api/news-and-events/increase-user-interest-score', authToken, { 'categoryId': categoryId })
    }

    // decreaseInterestScore
    static useDecreaseInterestScore = () => {
        const req = this.useRequest()
        return async categoryId => await req(HTTP_METHODS.POST, '/api/news-and-events/decrease-user-interest-score', { 'categoryId': categoryId })
    }
    static decreaseInterestScore = async (authToken, categoryId) => {
        return await FetchRequest.request(HTTP_METHODS.POST, '/api/news-and-events/decrease-user-interest-score', authToken, { 'categoryId': categoryId })
    }

    // ------------------------------------
    // --------- News and Events ----------
    // ------------------------------------

    // getNewsById
    static useGetNewsById = () => {
        const req = this.useRequest()
        return async newsId => await req(HTTP_METHODS.GET, `/api/news-and-events/news-by-id/${newsId}`)
    }
    static getNewsById = async newsId => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/news-and-events/news-by-id/${newsId}`)
    }

    // recordNewsAsRead
    static useRecordNewsAsRead = () => {
        const req = this.useRequest()
        return async newsId => await req(HTTP_METHODS.POST, '/api/news-and-events/record-news-read', { 'newsId': newsId, 'sourceName': 'Beacon', 'score': '1' })
    }
    static recordNewsAsRead = async (authToken, newsId) => {
        return await FetchRequest.request(HTTP_METHODS.POST, '/api/news-and-events/record-news-read', authToken, { 'newsId': newsId, 'sourceName': 'Beacon', 'score': '1' })
    }

    // increaseInterestScoreForNews
    static increaseInterestScoreForNews = async (authToken, newsData) => {
        if (authToken && newsData.newsItemInterestCategories && newsData.newsItemInterestCategories.length > 0) {
            newsData.newsItemInterestCategories.forEach(category => {
                FetchRequest.increaseInterestScore = (authToken, category.interestCategoryId)
            })
        }
    }

    // ------------------------------------
    // ---------- Notifications -----------
    // ------------------------------------

    // registerForPushNotifications
    // payload = {
    //     'deviceId': ,
    //     'username':,
    //     'registrationToken':,
    //     'registrationType':,
    //     'deviceOS':,
    // }
    static registerForPushNotifications = async (authToken, payload) => {
        return await FetchRequest.request(HTTP_METHODS.POST, '/api/push-notifications', authToken, payload)
    }

    // markNotificationAsViewed
    static markNotificationAsViewed = async (authToken, pushQueueId) => {
        return await FetchRequest.request(HTTP_METHODS.POST, '/api/push-queue/mark-as-viewed', authToken, { id: pushQueueId })
    }

    // getNotificationSettingsForUsername
    static useGetNotificationSettingsForUsername = () => {
        const req = this.useRequest()
        return async () => await req(HTTP_METHODS.GET, '/api/notifications/notification-settings')
    }
    static getNotificationSettingsForUsername = async (authToken) => {
        return await FetchRequest.request(HTTP_METHODS.GET, '/api/notifications/notification-settings', authToken)
    }

    // updateNotificationPreferences
    static useUpdateNotificationPreferences = () => {
        const req = this.useRequest()
        return async (typeId, hide) => await req(HTTP_METHODS.POST, '/api/notifications/type-user', { typeId: typeId, hide: hide })
    }
    static updateNotificationPreferences = async (authToken, typeId, hide) => {
        return await FetchRequest.request(HTTP_METHODS.POST, '/api/notifications/type-user', authToken, { typeId: typeId, hide: hide })
    }

    // getNotificationTypes
    static getNotificationTypes = async (authToken) => {
        return await FetchRequest.request(HTTP_METHODS.GET, '/api/notifications/push-content-types', authToken)
    }

    // ------------------------------------
    // ------------- Ratings --------------
    // ------------------------------------

    // getRating
    static getRating = async (authToken, startDate, endDate, type) => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/ratings/${startDate}/${endDate}/${type}`, authToken)
    }

    // ------------------------------------
    // ------------ Timetable -------------
    // ------------------------------------

    // getTimetable
    static getTimetable = async (authToken, startDate, endDate) => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/timetable/${startDate}/${endDate}`, authToken)
    }

    // getTimetableForStudent
    static useGetTimetableForStudent = () => {
        const req = this.useRequest()
        return async (startDate, endDate) => await req(HTTP_METHODS.GET, `/api/timetable/student/${startDate}/${endDate}`)
    }
    static getTimetableForStudent = async (authToken, startDate, endDate) => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/timetable/student/${startDate}/${endDate}`, authToken)
    }

    // getNextEventForStudent
    static getNextEventForStudent = async (authToken) => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/timetable/student/next-event`, authToken)
    }

    // studentCheckIn
    static useStudentCheckIn = () => {
        const req = this.useRequest()
        return async (eventId, payload) => await req(HTTP_METHODS.POST, `/api/timetable/lecture/${eventId}/student-check-in`, payload)
    }
    static studentCheckIn = async (authToken, eventId, payload) => {
        return await FetchRequest.request(HTTP_METHODS.POST, `/api/timetable/lecture/${eventId}/student-check-in`, authToken, payload)
    }

    // getAttendanceForEvent
    static useGetAttendanceForEvent = () => {
        const req = this.useRequest()
        return async eventId => await req(HTTP_METHODS.GET, `/api/timetable/staff/event/${eventId}?includeAttendanceData=true`)
    }
    static getAttendanceForEvent = async (authToken, eventId) => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/timetable/staff/event/${eventId}?includeAttendanceData=true`, authToken)
    }

    // staffCheckInStudents
    static useStaffCheckInStudents = () => {
        const req = this.useRequest()
        return async (eventId, payload) => await req(HTTP_METHODS.POST, `/api/timetable/lecture/${eventId}/lecturer-check-in`, payload)
    }
    static staffCheckInStudents = async (authToken, eventId, payload) => {
        return await FetchRequest.request(HTTP_METHODS.POST, `/api/timetable/lecture/${eventId}/lecturer-check-in`, authToken, payload)
    }

    // studentLectureRating
    static useStudentLectureRating = () => {
        const req = this.useRequest()
        return async (eventId, payload) => await req(HTTP_METHODS.POST, `/api/hex/lecture/${eventId}`, payload)
    }
    static studentLectureRating = async (authToken, eventId, payload) => {
        return await FetchRequest.request(HTTP_METHODS.POST, `/api/hex/lecture/${eventId}`, authToken, payload)
    }

    // getTimetableForStaff
    static useGetTimetableForStaff = () => {
        const req = this.useRequest()
        return async (startDate, endDate) => await req(HTTP_METHODS.GET, `/api/timetable/staff/${startDate}/${endDate}`)
    }
    static getTimetableForStaff = async (authToken, startDate, endDate) => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/timetable/staff/${startDate}/${endDate}`, authToken)
    }

    // ------------------------------------
    // ------------- Version --------------
    // ------------------------------------

    static checkVersion = async version => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/version-check/${version}`)
    }

    // ------------------------------------
    // --------------- QnA ----------------
    // ------------------------------------

    static getQnaQuestions = async () => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/users/qna/all-questions`)
    }

    // --------------------------------------------------
    // ------------- Courses and Modules ----------------
    // --------------------------------------------------
    // getCoursesAndModules
    static useGetCoursesAndModules = () => {
        const req = this.useRequest()
        return async () => await req(HTTP_METHODS.GET, `/api/student/courses`)
    }
    static getCoursesAndModules = async (authToken) => {
        return await FetchRequest.request(HTTP_METHODS.GET, `/api/student/courses`, authToken)
    }

}