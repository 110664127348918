export class TimetableUtils {
    
    static isAttendanceCodeValid = code => {
        // Rule 1: should be 6 digits long
        if (!(code && code.length === 6)) {
            return false
        }
        // Rule 2: is should be a integer
        let intCode = null
        try {
            intCode = parseInt(code)
        } catch (e) { }
        if (!intCode) {
            return false
        }
        if (!Number.isInteger(intCode)) {
            return false
        }
        // Rule 3: the last digit should be the sum of the others
        let i = 0
        let sum = 0
        let value = code.substring(0, 5)
        let length = value.length
        try {
            while (length > 1) {
                sum = 0
                for (i = 0; i < value.length; i++) {
                    sum += parseInt(value[i])
                }
                value = sum.toString()
                length = value.length
            }
        } catch (e) {
            return false
        }
        return sum == code.substring(5, 6)
    }

}
