

import { useSelector, useDispatch } from 'react-redux'
import { getCachedToken, getCachedUserInfo } from 'lib/auth/adalConfig'
import { azureAD } from 'lib/auth/azureAD'
import { UserActions } from 'redux/actions'
import { UserHelper } from 'redux/helpers'
import { authContext } from 'lib/auth/adalConfig'
import { FetchRequest } from 'lib/general'
import { Platform, Geolocation } from 'lib/primitives'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { QueueHelper, JobNames } from 'redux/helpers'
import { TimetableActions } from './'
import moment from 'moment-timezone'

// import Geolocation from "@react-native-community/geolocation";
export class TimetableHelper {

    static useCheckInAttendance = () => {
        const getUser = UserHelper.useGetUser()
        const addToQueue = QueueHelper.useAdd()

        return async (eventId, payload) => {
            const user = await getUser()
            console.log('-------------------------------------')
            console.log(payload)
            if (!(eventId && payload && payload.code)) {
                console.log('Cannot check in (code or event ID missing)')
                return
            }
            if (user) {
                payload.deviceId = user.deviceId
                payload.deviceOS = user.deviceOS
            }
            // return false if cannot add to queue
            let jobId = await addToQueue(JobNames.SUBMIT_ATTENDANCE_JOB, { eventId, payload })
            return jobId
        }
    }

    static useUpdateAttendanceStatus = () => {
        const dispatch = useDispatch()
        const attendanceStatus = useSelector(state => state.timetable.attendanceStatus)
        return (update) => {
            let newStatus = Object.assign({}, attendanceStatus, update)
            dispatch(TimetableActions.setAttendanceStatus(newStatus))
        }
    }

    static useCheckAttendanceStatus = () => {
        const dispatch = useDispatch()
        const attendanceStatus = useSelector(state => state.timetable.attendanceStatus)
        return (fetchFunc) => {
            let newStatus = attendanceStatus
            let updated = false
            Object.keys(attendanceStatus).map(id => {
                if (attendanceStatus[id] && attendanceStatus[id].submittedAt && moment(attendanceStatus[id].submittedAt).isSameOrBefore(moment().subtract(5, 'minutes'))) {
                    updated = true
                    delete newStatus[id]
                }
            })
            dispatch(TimetableActions.setAttendanceStatus(newStatus))
            if (updated && fetchFunc) fetchFunc()
        }
    }

}

