import { Namespaces } from 'redux/namespaces'
import { QueueTypes } from './QueueTypes'
import moment from 'moment'

// export class CoursesQueueKeys {
//     static courses = "courses"
//     static other = "other"
// }

// Queue item


const initialState = {
    queue: {
        // username: [
        //  {  
        //     id: [uuid]
        //     job_name: string
        //     job_params: {}
        //     created_at: [date]
        //     fail_attempts: 0
        //     max_fail_attempts: 5
        //     last_attempted_at: [date]
        //     remove_after_date: [date]
        //  }
        // ]
    },
}

export const QueueReducer = (state = initialState, action) => {

    let newState = Object.assign({}, state)
    let username = null
    let queueItem = null
    let jobId = null
    let index = null
    let job = null

    switch (action.type) {

        case QueueTypes.QUEUE_ADD:
            username = action.payload.username
            queueItem = action.payload.queueItem
            if (!newState.queue) {
                newState.queue = {}
            }
            if (!newState.queue[username]) {
                newState.queue[username] = []
            }
            queueItem.created_at = new moment()
            queueItem.fail_attempts = 0
            newState.queue[username].push(queueItem)
            return newState

        case QueueTypes.QUEUE_REMOVE:
            username = action.payload.username
            jobId = action.payload.jobId
            if (newState.queue && newState.queue[username]) {
                newState.queue[username] = newState.queue[username].filter(job => job.id !== jobId)
            }
            return newState

        case QueueTypes.QUEUE_INCREASE_FAIL_ATTEMPS_COUNT:
            username = action.payload.username
            jobId = action.payload.jobId
            if (newState.queue && newState.queue[username]) {
                index = newState.queue[username].findIndex(job => job.id === jobId)
                job = newState.queue[username][index]
                newState.queue[username][index] = {
                    ...job,
                    fail_attempts: job.fail_attempts ? job.fail_attempts + 1 : 1,
                    last_attempted_at: new moment(),
                }
            }
            return newState

        default:
            return state
    }
}
