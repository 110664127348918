import { FetchRequest } from 'lib/general'

export class TokenHelper {

    static useIsTokenValid = () => {
        const checkIfTokenValid = FetchRequest.useCheckIfTokenValid()
        return async () => {
            let res = await checkIfTokenValid()
            if (res && (!res.error || res.error && res.resStatus == 12163)) {
                return true
            }
            return false
        }
    }

    static isTokenValid = async token => {
        let res = await FetchRequest.checkIfTokenValid(token)
        if (res && (!res.error || res.error && res.resStatus == 12163)) {
            return true
        }
        return false
    }

}