import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const StarIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'star icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'star icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M18.34,2.03l4.5,7.94c0.15,0.46,0.58,0.78,1.07,0.78l9.11,1.95c0.84,0,1.19,1.07,0.51,1.57l-6.33,6.61
            c-0.4,0.29-0.56,0.8-0.41,1.26l1.13,9.39c0.26,0.8-0.65,1.46-1.33,0.97l-8.41-4.1c-0.4-0.29-0.93-0.29-1.33,0l-8.41,4.1
            c-0.68,0.49-1.59-0.17-1.33-0.97l1.13-9.39c0.15-0.46-0.01-0.97-0.41-1.26L1.5,14.27c-0.68-0.49-0.33-1.57,0.51-1.57l9.11-1.95
            c0.49,0,0.92-0.31,1.07-0.78l4.5-7.94C16.95,1.23,18.08,1.23,18.34,2.03z"/>
        </Svg>
    )
}