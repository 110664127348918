import React from 'react'
import moment from 'moment'
import { SettingsHelper, ChatHelper, NewsHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general/ScreenContainer'
import { Container, Text, Button, Link, Image } from 'primitives'
import { AuthenticatedRoute, routes } from 'navigation'
import { BackBar } from 'components/bars'
import { HTMLContent } from 'components/general'
import { fonts } from 'lib/config/ui-config'
var base64 = require('base-64')
var utf8 = require('utf8')

export const News = props => {
    // Alternate routes TODO for future development; Fetching news by id when linked from push notifications
    return (
        <React.Fragment>
            <AuthenticatedRoute exact path={routes.news.default} render={() => <NewsView />} />
            {/* <AuthenticatedRoute path={routes.news.id} render={() => <NewsView />} /> */}
        </React.Fragment>
    )
}

export const NewsView = props => {
    const colors = SettingsHelper.getColors()
    const isChatVisible = ChatHelper.getIsChatVisible()
    const newsItem = NewsHelper.getNewsItem()

    if (newsItem == null) return null

    const isNews = newsItem.newsItemTypeId == 1
    const newsText = utf8.decode(base64.decode(newsItem.fullText)).replace(/<p>&nbsp;<\/p>|<p><br><\/p>|<br \/>|<hr \/>|<em>|<\/em>|\n/g, '')

    return (
        <ScreenContainer alignItems={'center'}>
            <BackBar />
            <Container
                flex={1}
                flexGrow={1}
                minHeight={0}
                width={'100%'}
                alignItems={'center'}
                position={'relative'}
                webOverflow={'auto'}
                nativeOverflow={'hidden'}
                viewType={'ScrollView'}
                isChat={isChatVisible}
            >
                <Container
                    flex={0}
                    padding={15}
                    width={'100%'}
                    maxWidth={600}
                >
                    <Container flex={0} paddingTop={10} paddingBottom={10}>
                        <Text
                            color={colors.screenText}
                            nativeFontFamily={fonts.native.subHeading}
                            webFontFamily={fonts.web.heading}
                            fontWeight={fonts.weight.semiBold}
                            fontSize={fonts.size.heading}
                            textAlign={'center'}
                        >
                            {newsItem.name}
                        </Text>
                    </Container>
                    {displayExtraInfo(colors, isNews, newsItem)}
                    <Image
                        src={{ uri: newsItem.mainImageUrl }}
                        resizeMode={'center'}
                        height={200}
                        marginLeft={'auto'}
                        marginRight={'auto'}
                        width={'100%'}
                    />
                    <Container flex={0} paddingTop={10} paddingBottom={20}>
                        <HTMLContent
                            content={newsText}
                            origin={newsItem.origin != null ? newsItem.origin : 'staffsUniversity'}
                            color={colors.screenText}
                            fontFamily={fonts.web.default}
                            fontWeight={fonts.weight.semiBold}
                            fontSize={fonts.size.default}
                        />
                    </Container>
                </Container>
            </Container>
        </ScreenContainer>
    )
}

const displayExtraInfo = (colors, isNews, data) => {
    if (isNews && data && data.dateCreated) {
        return (
            <Container flex={0} paddingBottom={10}>
                <Text color={colors.screenAltText} >
                    Published {moment(data.dateCreated).format('llll')}
                </Text>
            </Container>
        )
    }
    if (!isNews && data) {
        let dateString = null
        if (data.startDate && data.endDate) {
            if (data.allDay) {
                dateString = `${moment(data.startDate).format('DD MMMM YYYY')}, All day`
            } else if (moment(data.startDate).isSame(moment(data.endDate), 'day')) {
                dateString = `${moment(data.startDate).format('DD MMMM YYYY')}, ${moment(data.startDate).format('HH:mma')} - ${moment(data.endDate).format('HH:mma')}`
            }
            else {
                dateString = `From ${moment(data.startDate).format('DD MMMM YYYY, HH:MMa')}, \nTo ${moment(data.endDate).format('DD MMMM YYYY, HH:MMa')}`
            }
        }
        return (
            <Container flex={0} paddingBottom={10}>
                <Text color={colors.screenAltText}>
                    {dateString}
                </Text>
                {data.location != null &&
                    <Text color={colors.screenAltText}>
                        {data.location}
                    </Text>
                }
            </Container>
        )
    }
    return null
}