export class Geolocation {

  static getCurrentPosition = () => {
    if (!navigator.geolocation) {
      console.log("This browser does not support geolocation")
      return null
    }
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })
  }

}
