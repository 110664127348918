import React from 'react'
import { Svg, Rect, Line, Polyline, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const NewsAndEventsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'news and events icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'news and events icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Polyline scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} points="25.45,15.8 25.45,3.47 3.89,3.47 3.89,24.04 "/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M28.89,15.75V9.12c0-1.85-1.51-3.36-3.36-3.36"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} d="M3.89,9.12v14.92c0,1.85,1.51,3.36,3.36,3.36h4.05"/>
            <Rect scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x="7.49" y="7.21" width="4.51" height="4.51"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="7.49" y1="15.27" x2="11.05" y2="15.27"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="7.49" y1="18.79" x2="8.74" y2="18.79"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M28.51,17.03c0,1.56,1,2.83,2.24,2.83v1.06c-1.24,0-2.24,1.27-2.24,2.83c0,1.56,1,2.83,2.24,2.83v1.06
                c-1.24,0-2.24,1.27-2.24,2.83H15.16c0-1.56-1-2.83-2.24-2.83v-1.06c1.24,0,2.24-1.27,2.24-2.83c0-1.56-1-2.83-2.24-2.83v-1.06
                c1.24,0,2.24-1.27,2.24-2.83H28.51 M28.51,15.03H15.16c-1.1,0-2,0.9-2,2c0,0.47-0.2,0.76-0.29,0.83c-1.08,0.03-1.95,0.91-1.95,2
                v1.06c0,1.09,0.87,1.97,1.95,2c0.09,0.07,0.29,0.35,0.29,0.83c0,0.47-0.2,0.76-0.29,0.83c-1.08,0.03-1.95,0.91-1.95,2v1.06
                c0,1.09,0.87,1.97,1.95,2c0.09,0.07,0.29,0.35,0.29,0.83c0,1.1,0.9,2,2,2h13.36c1.1,0,2-0.9,2-2c0-0.47,0.2-0.76,0.29-0.83
                c1.08-0.03,1.95-0.91,1.95-2v-1.06c0-1.09-0.87-1.97-1.95-2c-0.09-0.07-0.29-0.35-0.29-0.83c0-0.47,0.2-0.76,0.29-0.83
                c1.08-0.03,1.95-0.91,1.95-2v-1.06c0-1.09-0.87-1.97-1.95-2c-0.09-0.07-0.29-0.35-0.29-0.83C30.51,15.92,29.62,15.03,28.51,15.03
                L28.51,15.03z"/>
            <Rect scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinejoin:'round',strokeMiterLimit:10}} x="17.01" y="19.69" width="9.23" height="8.11"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="7.49" y1="22.27" x2="8.74" y2="22.27"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="15.72" y1="7.7" x2="21.75" y2="7.7"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} x1="15.72" y1="11.23" x2="21.83" y2="11.23"/>
        </Svg>
    )
}