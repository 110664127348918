import React from 'react'
import { renderToString } from 'react-dom/server'
import { SettingsHelper } from 'redux/helpers'
import { Icon, icons } from 'assets/MenuIcons'
import { fonts } from 'lib/config/ui-config'

export const HTMLContent = ({ content, origin, ...props }) => {
    const colors = SettingsHelper.getColors()
    const htmlIcon = renderToString(<Icon type={icons.externalLink} color={colors.screenAltText} size={fonts.size.default} />)
    const contentWithIcon = content.replace(/<\/a/g, htmlIcon + '</a')

    return <div style={props} dangerouslySetInnerHTML={{__html: contentWithIcon}}/>
}