import { useSelector } from 'react-redux'

export class PlatformHelper {

    // DEPRECATED. Use this instead:
    // import { Platform } from 'lib/primitives'

    // static isWeb = () => {
    //     const platform = useSelector(state => state.platform)
    //     return platform && platform.platform && platform.platform === "web"
    // }

    // static isNative = () => {
    //     const platform = useSelector(state => state.platform)
    //     return platform && platform.platform && platform.platform === "native"
    // }

    static getVersion = () => {
        const platform = useSelector(state => state.platform)
        return (platform && platform.version) ? platform.version : ""
    }

}