import { Namespaces } from 'redux/namespaces'

export const ChatTypes = {
    SET_DIRECTLINE: `${Namespaces.CHAT}_SET_DIRECTLINE`,
    DISCONNECT_DIRECTLINE: `${Namespaces.CHAT}_DISCONNECT_DIRECTLINE`,
    BEACON_IS_TYPING: `${Namespaces.CHAT}_BEACON_IS_TYPING`,
    SET_CHAT_VISIBILITY: `${Namespaces.CHAT}_SET_CHAT_VISIBILITY`,
    SET_CHAT_DIMENSIONS: `${Namespaces.CHAT}_SET_CHAT_DIMENSIONS`,
    SET_SEND_GREETING: `${Namespaces.CHAT}_SET_SEND_GREETING`,
    ADD_MESSAGE: `${Namespaces.CHAT}_ADD_MESSAGE`
}