import moment from 'moment'

import { FetchTypes } from './FetchTypes'

export const FetchReducerHelperInitialState = {
    isLoading: false,
    attempted: false,
    lastAttemptedAt: null,
    hasError: false,
    isForbidden: false,
    error: {},
    lastFetchedAt: null,
    data: null
}

export class FetchReducerHelper {

    // Examples : FetchReducerHelper.switch(state, action, Namespaces.COURSES, CoursesFetchKeys)
    static switch = (state, action, namespace, fetchKeys) => {
        let keys = Object.keys(fetchKeys)
        let newState = null
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i]
            switch (action.type) {

                case `${namespace}_${FetchTypes.FETCH_ATTEMPT}_${key}`:
                    newState = Object.assign({}, state)
                    newState[key] = Object.assign({}, FetchReducerHelperInitialState, state[key], {
                        attempted: true,
                        lastAttemptedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                        isLoading: true
                    })
                    return newState

                case `${namespace}_${FetchTypes.FETCH_FORBIDDEN}_${key}`:
                    newState = Object.assign({}, state)
                    newState[key] = Object.assign({}, FetchReducerHelperInitialState, state[key], {
                        attempted: true,
                        hasError: true,
                        error: action.payload,
                        isLoading: false,
                        isForbidden: true,
                        data: null // Ensure the data is removed if the access is now denied
                    })
                    return newState

                case `${namespace}_${FetchTypes.FETCH_FAIL}_${key}`:
                    newState = Object.assign({}, state)
                    newState[key] = Object.assign({}, FetchReducerHelperInitialState, state[key], {
                        attempted: true,
                        hasError: true,
                        isLoading: false,
                        error: action.payload,
                    })
                    return newState

                case `${namespace}_${FetchTypes.FETCH_SUCCESS}_${key}`:
                    newState = Object.assign({}, state)
                    newState[key] = Object.assign({}, FetchReducerHelperInitialState, state[key], {
                        attempted: true,
                        hasError: false,
                        isLoading: false,
                        error: {},
                        lastFetchedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                        data: action.payload,
                    })
                    return newState

                case `${namespace}_${FetchTypes.FETCH_CLEAR}_${key}`:
                    newState = Object.assign({}, state)
                    newState[key] = Object.assign({}, state[key], FetchReducerHelperInitialState)
                    return newState
            }
        }
        return null
    }
}