import React from 'react';
import PropTypes from 'prop-types';
import { ContainerPrimitive } from './ContainerPrimitive'

export const Container = ({ children, ...props }) => {
    return <ContainerPrimitive {...props}>{children}</ContainerPrimitive>
}

Container.propTypes = {
    // Common to web and native
    children: PropTypes.any,
    display: PropTypes.oneOf(['flex', 'none', 'block']),
    flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    flexWrap: PropTypes.oneOf(['wrap', 'nowrap']),
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
    alignSelf: PropTypes.oneOf(['auto', 'flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
    flex: PropTypes.number,
    flexGrow: PropTypes.number,
    flexShrink: PropTypes.number,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    position: PropTypes.oneOf(['absolute', 'relative']),
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    zIndex: PropTypes.number,
    padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    paddingTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    paddingBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    paddingLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    paddingRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderColor: PropTypes.string,
    borderTopColor: PropTypes.string,
    borderBottomColor: PropTypes.string,
    borderLeftColor: PropTypes.string,
    borderRightColor: PropTypes.string,
    borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderTopWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderBottomWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderLeftWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderRightWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // Platform Specific
    webOverflow: PropTypes.oneOf(['visible', 'hidden', 'scroll', 'auto']), // Web ONLY
    nativeOverflow: PropTypes.oneOf(['visible', 'hidden', 'scroll']), // Native ONLY
    webTransform: PropTypes.array,
    nativeTransform: PropTypes.array,
    viewType: PropTypes.oneOf(['View', 'SafeAreaView', 'ScrollView', 'KeyboardAvoidingView']), // Native ONLY
    scrollEnabled: PropTypes.bool, // Native ONLY
    aspectRatio: PropTypes.number, // Native ONLY
};

Container.defaultProps = {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: 1,
    viewType: 'View',
};
