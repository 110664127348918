import React from 'react'
import { Svg, Circle, Rect, Polygon, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SuEventsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: `students' union events icon`
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': `students' union events icon`
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M28.18,6.76c0,2.5,1.6,4.52,3.58,4.52v1.69c-1.98,0-3.58,2.02-3.58,4.52c0,2.5,1.6,4.52,3.58,4.52v1.69
                c-1.98,0-3.58,2.02-3.58,4.52H6.82c0-2.5-1.6-4.52-3.58-4.52v-1.69c1.98,0,3.58-2.02,3.58-4.52c0-2.5-1.6-4.52-3.58-4.52v-1.69
                c1.98,0,3.58-2.02,3.58-4.52H28.18 M28.18,4.76H6.82c-1.1,0-2,0.9-2,2c0,1.44-0.84,2.52-1.58,2.52c-1.1,0-2,0.9-2,2v1.69
                c0,1.1,0.9,2,2,2c0.75,0,1.58,1.08,1.58,2.52c0,1.44-0.84,2.52-1.58,2.52c-1.1,0-2,0.9-2,2v1.69c0,1.1,0.9,2,2,2
                c0.75,0,1.58,1.08,1.58,2.52c0,1.1,0.9,2,2,2h21.36c1.1,0,2-0.9,2-2c0-1.44,0.84-2.52,1.58-2.52c1.1,0,2-0.9,2-2v-1.69
                c0-1.1-0.9-2-2-2c-0.75,0-1.58-1.08-1.58-2.52c0-1.44,0.84-2.52,1.58-2.52c1.1,0,2-0.9,2-2v-1.69c0-1.1-0.9-2-2-2
                c-0.75,0-1.58-1.08-1.58-2.52C30.18,5.66,29.29,4.76,28.18,4.76L28.18,4.76z"/>
            <Rect scale={props.size/35} x="9.27" y="10.27" style={{fill:'none',stroke:props.color,strokeWidth:1.7,strokeLinejoin:'round',strokeMiterlimit:10}} width="16.46" height="14.46"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="18.15" cy="13.16" r="1.25"/>
            <Polygon scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.2,strokeLinejoin:'round',strokeMiterlimit:10}} points="17.26,15.46 14.29,12.81 15.7,16.39 14.7,22.47 17.93,17.07 21.7,15.5 "/>
        </Svg>
    )
}