import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'primitives'
import { AppBackground } from 'components/general/AppBackground'
import { Platform } from 'lib/primitives'
import { Namespaces } from 'redux/namespaces'
import { KeyboardListener } from 'redux/listeners'
import { OnboardingOverlay } from 'components/onboarding'
import { useLocation, routes } from 'navigation'

export const RouteWrapper = props => {
  const isWeb = Platform.isWeb()
  const isIOS = Platform.isIOS()
  const isChatVisible = useSelector(state => state[Namespaces.CHAT].isChatVisible)
  const showOnboarding = useSelector(state => state[Namespaces.USER].showOnboarding)
  const location = useLocation()
  return (
    <Container
      minWidth={isWeb ? isChatVisible ? 860 : 460 : 0}
      position={'absolute'}
      top={0}
      bottom={0}
      left={0}
      right={0}
    >
      <AppBackground />
      <Container
        position={'absolute'}
        top={0}
        bottom={0}
        left={0}
        right={0}
      >
        <Container
          flex={1}
          flexGrow={1}
          viewType={'SafeAreaView'}
          minWidth={isWeb ? isChatVisible ? 860 : 460 : 0}
        >
          {props.children}
        </Container>
        {isIOS && <Spacer />}
        {!isWeb && showOnboarding && location.pathname !== routes.login && <OnboardingOverlay/>}
      </Container>
    </Container>
  )
}

const Spacer = () => {
  const [spacerHeight, setSpacerHeight] = useState(0)

  return (
    <React.Fragment>
      <Container flex={0} height={spacerHeight} />
      <KeyboardListener
        onShow={(e) => setSpacerHeight(e.endCoordinates.height)}
        onHide={() => setSpacerHeight(0)}
      />
    </React.Fragment>
  )
}