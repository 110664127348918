import React, { useEffect, useState } from 'react'
import { Animated } from 'primitives'
import { useLocation, routes } from 'navigation'
import { setStatusBarTheme } from 'lib/screen/statusBar'
import { SettingsHelper } from 'redux/helpers'
import * as easings from 'd3-ease'

export const AppBackground = props => {
  const colors = SettingsHelper.getColors()
  const backgroundColor = SettingsHelper.getBackgroundColor()
  const location = useLocation()

  const [animation, setAnimation] = useState({ color: { backgroundColor: backgroundColor } })
  const setAsyncBackgroundColor = SettingsHelper.useSetAsyncBackgroundColor()

  useEffect(() => {
    let statusBar, appBg
    switch (location.pathname) {
      case routes.login:
        statusBar = colors.loadingStatusBar
        appBg = colors.screenBg
        break;
      case routes.chat.default:
      case routes.chat.suggestions:
        statusBar = colors.statusBar
        appBg = colors.chatUserDefault
        break;
      default:
        statusBar = colors.statusBar
        appBg = colors.appBg
    }

    if (backgroundColor != appBg) {
      setAsyncBackgroundColor(appBg,
        () => {
          setStatusBarTheme(statusBar, appBg, colors.androidNavBg, colors.androidNav)
          setAnimation({ color: { backgroundColor: appBg } })
        })
    }
  }, [location, backgroundColor, colors])

  return (
    <Animated
      position={'absolute'}
      top={0}
      bottom={0}
      left={0}
      right={0}
      animations={animation}
      initial={'color'}
      current={'color'}
      config={config}
      rasterize={false}
    />
  )
}

const config = { duration: 300, easing: easings.easeQuadInOut }