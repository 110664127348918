import React from 'react'
import { Svg, Line } from 'primitives'
import { Platform } from 'lib/primitives'

export const DownArrowIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'down arrow icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'down arrow icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:4,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="5" y1="10" x2="17.5" y2="25"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:4,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} x1="30" y1="10" x2="17.5" y2="25"/>
        </Svg>
    )
}