import React, { useState, useEffect } from 'react'
import { Icon, icons } from 'assets/MenuIcons'
import { SettingsHelper } from 'redux/helpers'
import { Container, Button, Text } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { Platform } from 'lib/primitives'

export const ArrowNavigation = props => {
    const colors = SettingsHelper.getColors()
    const isNative = Platform.isNative()
    const [delay, setDelay] = useState(false)

    useEffect(() => {
        if (delay) {
            let id = setTimeout(() => setDelay(false), 500)
            return () => clearTimeout(id)
        }
    }, [delay])

    const handlePressLeft = () => {
        if (!delay && props.scrollLeft != null) {
            props.scrollLeft()
            setDelay(true)
        }
    }

    const handlePressRight = () => {
        if (!delay && props.scrollRight != null) {
            props.scrollRight()
            setDelay(true)
        }
    }

    const handlePressFastLeft = () => {
        if (!delay && props.scrollFastLeft != null) {
            props.scrollFastLeft()
            setDelay(true)
        }
    }

    const handlePressFastRight = () => {
        if (!delay && props.scrollFastRight != null) {
            props.scrollFastRight()
            setDelay(true)
        }
    }

    const renderLocation = () => {
        // Render page location as current page number / total page number
        if (props.pageLength > 10) {
            return (
                <Container
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingTop={padding}
                    paddingBottom={padding}
                >
                    <Text
                        nativeFontFamily={fonts.native.subHeading}
                        color={colors.chatBotAltText}
                    >
                        {props.activePage + 1}/{props.pageLength}
                    </Text>
                </Container>
            )
        }
        // Render page location as circles
        else {
            let circles = []
            for (let i = 0; i < props.pageLength; i++) {
                let color = props.activePage == i ? colors.chatBotText : colors.chatBotAltText
                circles.push(<Container flex={0} width={10} height={10} margin={3} borderRadius={5} backgroundColor={color} key={i} />)
            }
            return (
                <Container
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingTop={padding}
                    paddingBottom={padding}
                >
                    {circles}
                </Container>
            )
        }
    }

    const renderLeftArrow = () => {
        return (
            <Container
                flex={0}
                marginRight={'auto'}
                flexDirection={'row'}
            >
                <Button
                    handleAction={handlePressLeft}
                    transform={isNative ? [{ rotate: '180deg' }] : `rotate(180deg)`}
                    padding={padding}
                    paddingLeft={0}
                    accessibility={{ role: 'button', label: 'go back by one' }}
                >
                    <Icon type={icons.rightArrow} color={colors.chatBotText} size={arrowSize} />
                </Button>
                {props.pageLength > 10
                    ? <Button
                        handleAction={handlePressFastLeft}
                        transform={isNative ? [{ rotate: '180deg' }] : `rotate(180deg)`}
                        padding={padding}
                        paddingLeft={0}
                        accessibility={{ role: 'button', label: 'go back by ten' }}
                    >
                        <Icon type={icons.doubleRightArrow} color={colors.chatBotText} size={arrowSize} />
                    </Button>
                    : null
                }
            </Container>
        )
    }

    const renderRightArrow = () => {
        return (
            <Container
                flex={0}
                marginRight={'auto'}
                flexDirection={'row'}
            >
                {props.pageLength > 10
                    ? <Button
                        handleAction={handlePressFastRight}
                        padding={padding}
                        paddingLeft={0}
                        accessibility={{ role: 'button', label: 'go forward by ten' }}
                    >
                        <Icon type={icons.doubleRightArrow} color={colors.chatBotText} size={arrowSize} />
                    </Button>
                    : null
                }
                <Button
                    handleAction={handlePressRight}
                    padding={padding}
                    paddingLeft={0}
                    accessibility={{ role: 'button', label: 'go forward by one' }}
                >
                    <Icon type={icons.rightArrow} color={colors.chatBotText} size={arrowSize} />
                </Button>
            </Container>
        )
    }

    if (props.pageLength <= 1) return null
    if (props.activePage == 0) {
        return (
            <Container
                flex={0}
                flexDirection={'row'}
                paddingLeft={props.pageLength > 10 ? (arrowSize + padding) * 2 : arrowSize + padding}
                width={props.maxSize}
                justifyContent={'center'}
                alignContent={'center'}
            >
                {renderLocation()}
                {renderRightArrow()}
            </Container>
        )
    }
    else if (props.activePage == props.pageLength - 1) {
        return (
            <Container
                flex={0}
                flexDirection={'row'}
                paddingRight={props.pageLength > 10 ? (arrowSize + padding) * 2 : arrowSize + padding}
                width={props.maxSize}
                justifyContent={'center'}
                alignContent={'center'}
            >
                {renderLeftArrow()}
                {renderLocation()}
            </Container>
        )
    }
    else {
        return (
            <Container
                flex={0}
                flexDirection={'row'}
                width={props.maxSize}
                justifyContent={'center'}
                alignContent={'center'}
            >
                {renderLeftArrow()}
                {renderLocation()}
                {renderRightArrow()}
            </Container>
        )
    }

}

const arrowSize = 26
const padding = 10