import React from 'react'
import { Svg, Line, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const EmailIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'email icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'email icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} d="M30.15,14.18c0.79,0,1.43,0.65,1.43,1.43V28.3c0,0.79-0.65,1.43-1.43,1.43H4.85c-0.79,0-1.43-0.65-1.43-1.43
                V15.62c0-0.79,0.65-1.43,1.43-1.43"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} d="M5.32,14.42V6.61c0-0.79,0.65-1.43,1.43-1.43h21.5c0.79,0,1.43,0.65,1.43,1.43v7.81"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} x1="30.15" y1="14.18" x2="18.8" y2="20.05"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} x1="16.18" y1="20.04" x2="4.85" y2="14.18"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} x1="3.71" y1="29.14" x2="17.5" y2="19.58"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} x1="31.19" y1="29.28" x2="17.5" y2="19.58"/>
        </Svg>
    )
}