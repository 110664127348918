import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { ScreenContainer, StyledButton, ReduxFetch, LoadingSpinner } from 'components/general'
import { Container, Text } from 'primitives'
import { SettingsHelper, UserHelper, FetchHelper, ChatHelper, NotificationHelper } from 'redux/helpers'
import { BackBar, OfflineBar } from 'components/bars'
import { SettingsToggle } from './'
import { FetchRequest, UpdateHelper } from 'lib/general'
import { Namespaces } from 'redux/namespaces'
import { Platform } from 'lib/primitives'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { getChunk } from 'lib/timetable/timetableFilter'
import { TimetableFetchKeys } from 'redux/fetchKeys'
import { notificationTypes } from 'lib/general/enums'

export const NotificationsView = props => {
    const currentWeek = moment.utc().startOf('week')
    const chunkInfo = getChunk(currentWeek)
    const deleteNotifications = NotificationHelper.useDeleteNotificationByType()
    const createTimetableNotifications = NotificationHelper.useCreateNotificationsForTimetable()
    const timetableData = useSelector(state => state[Namespaces.TIMETABLE][TimetableFetchKeys[chunkInfo.chunk]])
    const getNotificationSettingsForUsername = FetchRequest.useGetNotificationSettingsForUsername()

    const handleNotifications = (data) => {
        let sendNotifications = true
        if (data) {
            const timetableSettings = data.find(item => item.name == 'Timetable')
            if (timetableSettings && timetableSettings.typeUsers && timetableSettings.typeUsers[0] && timetableSettings.typeUsers[0].hide == 1) {
                sendNotifications = false
            }
        }
        deleteNotifications(notificationTypes.timetable)
        if (sendNotifications && timetableData && timetableData.data) {
            createTimetableNotifications(timetableData.data)
        }
    }
    const fetchNotificationPrefs = FetchHelper.useFetch([Namespaces.SETTINGS], "notifications", getNotificationSettingsForUsername, handleNotifications)

    return (
        <ScreenContainer alignItems={'center'}>
            <BackBar />
            <OfflineBar />
            <Container
                flex={1}
                flexGrow={1}
                width={'100%'}
                minHeight={0}
            >
                <ReduxFetch
                    debug={false}
                    namespace={Namespaces.SETTINGS}
                    fetchKey={"notifications"}
                    fetchFunc={fetchNotificationPrefs}
                    cacheDuration={1}
                    component={NotificationPrefDisplay}
                    displayWhileLoading={true}
                />
            </Container>
        </ScreenContainer>
    )
}

const NotificationPrefDisplay = ({ data, fetchFunc }) => {
    const colors = SettingsHelper.getColors()
    const isWeb = Platform.isWeb()
    const hasInternet = NetworkInfo.useHasInternet()
    const isChatVisible = ChatHelper.getIsChatVisible()
    const fetchUpdateNotificationPreferences = FetchRequest.useUpdateNotificationPreferences()

    const updateNotificationPreferences = async (id, hidden) => {
        return await fetchUpdateNotificationPreferences(id, hidden)
    }
    const { updateItems, awaitingUpdate, awaitingRefresh } = UpdateHelper(data.data, 'id', fetchFunc)

    const handleAction = () => {
        if (hasInternet) {
            fetchFunc()
        }
    }

    return (
        <React.Fragment>
            {data.isLoading && Object.values(awaitingRefresh).length == 0 && <LoadingSpinner size={40} color={colors.screenAltText} />}
            <Container
                flex={1}
                flexGrow={1}
                minHeight={0}
                opacity={!hasInternet ? 0.5 : null}
                alignItems={'center'}
                position={'relative'}
                webOverflow={'auto'}
                nativeOverflow={'hidden'}
                viewType={'ScrollView'}
                onRefresh={handleAction}
                isChat={isChatVisible}
            >
                <Container
                    flex={0}
                    padding={10}
                    paddingTop={0}
                    width={'100%'}
                    maxWidth={600}
                >
                    <Container
                        flex={0}
                        padding={10}
                        borderBottom={`2px solid ${colors.screenAltBg}`}
                        borderBottomWidth={2}
                        borderColor={colors.screenAltBg}
                    >
                        <Text color={colors.screenAltText}>
                            Choose which notifcations you'd like to receive.
                    </Text>
                    </Container>
                    {data.data && data.data.map(item => <NotificationItem
                        data={item}
                        isWeb={isWeb}
                        hasInternet={hasInternet}
                        updateItems={updateItems}
                        updateNotificationPreferences={updateNotificationPreferences}
                        isLoading={awaitingUpdate[item.id] != null || awaitingRefresh[item.id] != null}
                        key={item.id}
                    />
                    )}
                    {isWeb && hasInternet ? <StyledButton
                        title={'Refresh'}
                        accessibility={{ role: 'button', label: 'refresh the page' }}
                        handleAction={handleAction}
                        bgColor={colors.actionBg}
                        textColor={colors.actionText}
                    /> : null}
                </Container>
            </Container>
        </React.Fragment>
    )
}

const NotificationItem = ({ data, isWeb, hasInternet, updateItems, updateNotificationPreferences, isLoading }) => {
    const [loading, setLoading] = useState(isLoading)
    const active = data && data.typeUsers && data.typeUsers[0] && data.typeUsers[0].hide == 1 ? false : true

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    if (!data) return null

    //if (isWeb && (data.name == 'Timetable' || data.name == 'Mood')) return null // Hide timetable and mood notifications as they are local to mobile users only
    if (isWeb || (data.name != 'Timetable')) return null // TODO re-add notifications
    return (
        <SettingsToggle
            accessibility={{ role: 'button', label: 'toggle ' + data.name + ' notifications' + active ? 'enabled' : 'disabled' }}
            isActive={active}
            isLoading={loading}
            toggleFunc={() => {
                if (hasInternet) {
                    setLoading(true)
                    updateItems(data.id, async () => await updateNotificationPreferences(data.id, active ? 1 : 0))
                }
            }}
            text={data.name}
            key={data.name}
        />
    )
}