import React from 'react'
import PropTypes from 'prop-types'
import { ColumnPrimitive } from './ColumnPrimitive'

export const Column = ({children, ...props}) => {
    return <ColumnPrimitive {...props}>{children}</ColumnPrimitive>
}

Column.propTypes = {
    parentSize: PropTypes.number,
    parentWidth: PropTypes.number,
    flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    flexWrap: PropTypes.oneOf(['wrap', 'nowrap']),
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'stretch', 'baseline']),
    overflow: PropTypes.oneOf(['visible', 'hidden', 'scroll']),
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
};

Column.defaultProps = {
    parentSize: 12,
    parentWidth: 0,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
};