import { ChatTypes } from './ChatTypes'
import { UserTypes } from 'redux/lib/user/UserTypes'

const initialState = {
    directline: null,
    directlineIsReady: false,
    isTyping: false,
    isChatVisible: false,
    sendGreeting: true,
    messages: [],
    chatDimensions: { height: 0, width: 0 }
}

const HELLO_FALLBACK_GREETING = 'Hello!'

const messageLimit = 20

export const ChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserTypes.LOGOUT:
            return initialState

        case ChatTypes.ADD_MESSAGE:
            let newState = Object.assign({}, state)
            let addMessage = true
            let activity = action.payload
            // Do not display greeting if that isn't the first message in the chat.
            if (activity && activity.summary === HELLO_FALLBACK_GREETING && newState && newState.messages && newState.messages.length > 0) {
                addMessage = false
            }
            if (addMessage === true) {
                newState.messages = [...state.messages, activity]
                if (newState.messages.length > messageLimit) {
                    newState.messages = newState.messages.slice(newState.messages.length - messageLimit, newState.messages.length)
                }
            }
            // If we have more than 3 messages in the chat, there is no point greeting the user
            if (newState.messages.length > 0) {
                newState.sendGreeting = false
            }
            return newState

        case ChatTypes.SET_DIRECTLINE:
            return Object.assign({}, state, {
                directline: action.payload,
                directlineIsReady: true,
            })

        case ChatTypes.DISCONNECT_DIRECTLINE:
            return Object.assign({}, state, {
                directline: null,
                directlineIsReady: false,
            })

        case ChatTypes.BEACON_IS_TYPING:
            return Object.assign({}, state, {
                isTyping: action.payload
            })

        case ChatTypes.SET_CHAT_VISIBILITY:
            return Object.assign({}, state, {
                isChatVisible: action.payload,
            })

        case ChatTypes.SET_CHAT_DIMENSIONS:
            return Object.assign({}, state, {
                chatDimensions: action.payload,
            })

        case ChatTypes.SET_SEND_GREETING:
            return Object.assign({}, state, {
                sendGreeting: action.payload,
            })

        default:
            return state
    }
}

