import React from 'react'
import { Container, Image } from 'primitives'
import { ImageSrc } from 'assets/images'

export const BeaconAvatar = props => {
    return (
        <Container flex={0} marginRight={7} width={36} height={36} borderRadius={18} overflow={'hidden'}>
            <Image src={ImageSrc.beacon} alt={'Beacon mascot'} width={'100%'} height={'100%'} />
        </Container>
    )
}