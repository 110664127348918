import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { useLocation, routes } from 'navigation'
import { Bar } from 'components/bars/Bar'
import { fonts } from 'lib/config/ui-config'
import { AnimatedTransition } from 'primitives'
import { useTransition } from 'lib/animation/springExports'
import { Platform } from 'lib/primitives'

export const TitleBar = props => {
    const colors = SettingsHelper.getColors()
    const isWeb = Platform.isWeb()
    const accessibility = SettingsHelper.getAccessibilitySettings()
    const location = useLocation()
    const display = !([routes.login, routes.dashboard, routes.chat.default].includes(location.pathname))
    const transitions = useTransition(display, null, {
        from: { height: 0, opacity: 0 },
        enter: { height: fonts.size.large * 1.5 + 15, opacity: 1 },
        leave: { height: 0, opacity: 0 },
        immediate: accessibility.disableAnimations
    })

    const text = () => {
        switch (location.pathname) {
            case routes.profile: return 'Profile'
            case routes.settings.default: return 'Settings'
            case routes.settings.accessibility: return 'Settings - Accessibility'
            case routes.settings.interests: return 'Settings - Interests'
            case routes.settings.notifications: return 'Settings - Notifications'
            case routes.settings.themes: return 'Settings - Colour Themes'
            case routes.courses: return 'Courses'
            case routes.notifications: return 'Notifications'
            case routes.search: return 'Search'
            case routes.chat.suggestions: return 'Suggestions'

            case routes.webviews.outlook: return 'Outlook'
            case routes.webviews.blackboard: return 'Blackboard'
            case routes.webviews.evision: return 'E-Vision'
            case routes.webviews.studentUnions: return `Students' Union`
            case routes.webviews.covid19: return 'COVID-19'
            case routes.webviews.pcAvailability: return 'PC Availability'

        }
        if (location.pathname.startsWith('/timetable/event')) return 'Timetable - Event'
        if (location.pathname.startsWith('/timetable')) return 'Timetable'
        if (location.pathname.startsWith(routes.news.default)) return 'News and Events'
        return null
    }

    return transitions.map(({ item, key, props }) =>
        item &&
        <AnimatedTransition native key={key} style={{ ...props, display: 'flex', overflow: 'hidden', justifyContent: 'center' }}>
            <Bar
                backgroundColor={isWeb ? colors.screenTitle : 'rgba(0,0,0,0)'}
                textColor={colors.menuIcon}
                textAlign={'left'}
                nativeFontFamily={fonts.native.subHeading}
                webFontFamily={fonts.web.heading}
                fontWeight={fonts.weight.semiBold}
                fontSize={fonts.size.large}
                padding={10}
                paddingTop={isWeb ? 10 : 5}
                paddingBottom={10}
                text={text()}
                width={'100%'}
            />
        </AnimatedTransition>)
}