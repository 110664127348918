import React from 'react'
import { Svg, Line, Path, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const MyViewIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'MyView icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'MyView icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M28.1,30.8H6.9c-1.8,0-3.3-1.7-3.3-3.8V8C3.5,5.9,5,4.2,6.9,4.2h21.3c1.8,0,3.3,1.7,3.3,3.8v19
                C31.5,29.1,30,30.8,28.1,30.8z"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinejoin:'round',strokeMiterlimit:10}} x1="3.6" y1="8" x2="31.5" y2="8"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="6.9" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="8.7" cy="6.3" r="0.7"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.5" cy="6.3" r="0.7"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M27.4,18.9"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M7.5,18.9"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M27.3,22.4c-3.2-2.3-6.4-4.5-10.6-3.5c-1.5,0.4-2.6,1.5-3.8,2.4c-1,0.8-2.1,1.5-3.3,1.8c-0.5,0.1-1,0.1-1.4-0.4
                c-0.3-0.5-0.2-1,0.1-1.4c0.8-1.1,2-1.7,3.2-2.3c3.2-1.7,6.6-2.4,10.1-1c2.3,0.9,4.1,2.4,5.7,4.2C27.3,22.3,27.2,22.3,27.3,22.4
                L27.3,22.4z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M10,15c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2c0,1.2-1,2.2-2.2,2.2C11,17.2,10,16.2,10,15z"/>
        </Svg>
    )
}