import React, { useState } from 'react'
import moment from 'moment'
import { SettingsHelper, UserHelper } from 'redux/helpers'
import { Container, Text, Button, Link } from 'primitives'
import { Icon, icons } from 'assets/MenuIcons'
import { fonts } from 'lib/config/ui-config'
import { SubMenuButton, SlideInMenu } from 'components/general'
import { DialogUtterances } from 'lib/chat/dialogUtterances'
import { useHistory } from 'navigation'
import { NetworkInfo } from 'lib/network/NetworkInfo'
import { routes } from 'navigation/index'
import { Platform } from 'lib/primitives'

export const TimetableOptions = ({ fetchFunc, weekDate, lastFetchedAt }) => {
    //Global state
    const colors = SettingsHelper.getColors()
    const hasInternet = NetworkInfo.useHasInternet()
    const isStudent = UserHelper.getIsStudent()
    const isWeb = Platform.isWeb()
    //Hooks
    const history = useHistory()
    const missingEventDialog = DialogUtterances.useMissingEventDialog()
    //Local state
    const [animation, setAnimation] = useState('closed')
    //Other
    const handleMissingEvent = () => missingEventDialog(weekDate, history)

    return (
        <Container
            flex={0}
            width={'100%'}
            maxWidth={600}
            zIndex={5}
            padding={5}
            paddingBottom={0}
            backgroundColor={colors.screenBg}
        >
            <Container flex={0} flexDirection={'row'} alignItems={'center'}>
                <LastRefresh lastFetchedAt={lastFetchedAt} colors={colors} />
                <Container flex={0} marginLeft={'auto'}>
                    <SubMenuButton current={animation} setAnimation={setAnimation} />
                </Container>
            </Container>
            <SlideInMenu
                animation={animation}
                renderChildren={OptionsMenu}
                colors={colors}
                fetchFunc={fetchFunc}
                missingEvent={handleMissingEvent}
                containerStyle={{ flexDirection: 'column' }}
                isStudent={isStudent}
                hasInternet={hasInternet}
                isWeb={isWeb}
            />
        </Container>
    )
}

const LastRefresh = ({ lastFetchedAt, colors }) => {
    if (!lastFetchedAt) return null
    if (moment().isSame(lastFetchedAt, 'day')) {
        return <Text padding={5} color={colors.screenAltText} textAlign={'center'} fontSize={fonts.size.small}>Last refreshed: Today, {moment(lastFetchedAt).format('HH:mm')}</Text>
    }
    if (moment().subtract(1, 'day').isSame(lastFetchedAt, 'day')) {
        return <Text padding={5} color={colors.screenAltText} textAlign={'center'} fontSize={fonts.size.small}>Last refreshed: Yesterday, {moment(lastFetchedAt).format('HH:mm')}</Text>
    }
    return <Text padding={5} color={colors.screenAltText} textAlign={'center'} fontSize={fonts.size.small}>Last refreshed: {moment(lastFetchedAt).format('DD MMMM YYYY')}, {moment(lastFetchedAt).format('HH:mm')}</Text>
}

const OptionsMenu = ({ colors, fetchFunc, missingEvent, isStudent, hasInternet, isWeb }) => {
    return (
        <Container
            flex={0}
            opacity={!hasInternet ? 0.5 : null}
            borderTop={`2px solid ${colors.screenAltBg}`}
            borderTopWidth={2}
            borderColor={colors.screenAltBg}
        >
            <Button accessibility={{ role: 'button', label: 'refresh page' }} handleAction={hasInternet ? fetchFunc : null} flex={0} flexDirection={'row'} margin={5} alignItems={'center'} justifyContent={'flex-start'}>
                <Icon type={icons.refresh} color={colors.screenAltText} size={25} />
                <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
                    Refresh timetable
                </Text>
            </Button>
            {!isWeb && <Link to={routes.settings.notifications} flex={0} flexDirection={'row'} margin={5} alignItems={'center'} justifyContent={'flex-start'}>
                <Icon type={icons.notificationsActive} color={colors.screenAltText} size={25} />
                <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
                    Notification settings
                </Text>
            </Link>}
            {isStudent &&
                <Button accessibility={{ role: 'button', label: 'report missing event in chat' }} handleAction={hasInternet ? missingEvent : null} flex={0} flexDirection={'row'} margin={5} alignItems={'center'} justifyContent={'flex-start'}>
                    <Icon type={icons.warningActive} color={colors.screenAltText} size={25} />
                    <Text color={colors.screenText} paddingLeft={5} paddingRight={5}>
                        Report missing event
                    </Text>
                </Button>
            }
        </Container>
    )
}