import React from 'react'
import { SettingsHelper, ChatHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general'
import { Container } from 'primitives'
import { useDispatch } from 'react-redux'
import { SettingsActions } from 'redux/lib/settings'
import { BackBar } from 'components/bars'
import { SettingsToggle } from './'
import { colorThemes } from 'lib/general/enums'
import { Platform } from 'lib/primitives'

export const AccessibilityView = props => {
    const accessibility = SettingsHelper.getAccessibilitySettings()
    const dispatch = useDispatch()
    const isChatVisible = ChatHelper.getIsChatVisible()
    const isWeb = Platform.isWeb()

    return (
        <ScreenContainer alignItems={'center'}>
            <BackBar />
            <Container
                flex={1}
                flexGrow={1}
                minHeight={0}
                width={'100%'}
                alignItems={'center'}
                position={'relative'}
                webOverflow={'auto'}
                nativeOverflow={'hidden'}
                viewType={'ScrollView'}
                isChat={isChatVisible}
            >
                <Container
                    flex={0}
                    padding={10}
                    paddingTop={0}
                    width={'100%'}
                    maxWidth={600}
                >
                    <SettingsToggle
                        accessibility={{ role: 'button', label: 'toggle Beacon high-contrast theme: ' + accessibility.isHighContrast ? 'enabled' : 'disabled' }}
                        isActive={accessibility.isHighContrast}
                        toggleFunc={() => !accessibility.isHighContrast ? dispatch(SettingsActions.setColorTheme(colorThemes.highContrast)) : dispatch(SettingsActions.setColorTheme(colorThemes.basic))}
                        text={'High-Contrast Colour Theme'}
                        description={`Use Beacon's high-contrast colour theme.`}
                    />
                    <SettingsToggle
                        accessibility={{ role: 'button', label: 'toggle swipe navigation: ' + accessibility.disableSwipeEvents ? 'enabled' : 'disabled' }}
                        isActive={accessibility.disableSwipeEvents}
                        toggleFunc={() => dispatch(SettingsActions.setDisableSwipeEvents(!accessibility.disableSwipeEvents))}
                        text={'Disable swipe events'}
                        description={'Disable swipe navigation within the chat and menus.'}
                    />
                    <SettingsToggle
                        accessibility={{ role: 'button', label: 'toggle motion animations: ' + accessibility.disableAnimations ? 'enabled' : 'disabled' }}
                        isActive={accessibility.disableAnimations}
                        toggleFunc={() => dispatch(SettingsActions.setDisableAnimations(!accessibility.disableAnimations))}
                        text={'Disable motion animations'}
                        description={'Disable start-up, chat and menu motion animations.'}
                    />
                    {!isWeb && <SettingsToggle
                        accessibility={{ role: 'button', label: 'toggle larger dashboard tiles: ' + accessibility.largerDashboardTiles ? 'enabled' : 'disabled' }}
                        isActive={accessibility.largerDashboardTiles}
                        toggleFunc={() => dispatch(SettingsActions.setEnableLargerTiles(!accessibility.largerDashboardTiles))}
                        text={'Larger dashboard tiles'}
                        description={'Display larger dashboard tiles. This helps with larger display and text sizes.'}
                    />}
                    {/* <SettingsToggle
                        accessibility={{ role: 'button', label: 'toggle in-app notifications: ' + accessibility.disableInAppNotifications ? 'enabled' : 'disabled' }}
                        isActive={accessibility.disableInAppNotifications}
                        toggleFunc={() => dispatch(SettingsActions.setDisableInAppNotifications(!accessibility.disableInAppNotifications))}
                        text={'Disable in-app notifications'}
                        description={'Disable non-essential notifications e.g in-app push notifications, achievement notifications and next lecture notifications.'}
                    /> TODO re-add notifications */}
                </Container>
            </Container>
        </ScreenContainer>
    )
}