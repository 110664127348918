import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const LocalIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'local event icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'local event icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M31.1,8c0-3.5-2.8-6.3-6.4-6.3S18.5,4.5,18.5,8c0,1.1,0.3,2.1,0.8,3l0,0l0,0c0.1,0.1,0.1,0.2,0.2,0.2l5.4,9.1
                l5.4-9c0.1-0.1,0.1-0.2,0.2-0.3l0,0l0,0C30.8,10.1,31.1,9.1,31.1,8z M24.8,10.6c-1.5,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6
                c1.5,0,2.6,1.2,2.6,2.6S26.2,10.6,24.8,10.6z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M31.7,10.5c-0.1,0.3-0.3,0.6-0.4,0.9c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1l-0.6,1
                c0.5,1.4,0.8,2.8,0.9,4.3h-3.4l-0.6,1h4c-0.1,2.3-0.8,4.5-1.9,6.4H24c0.4-1,0.7-2.1,0.9-3.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0
                c-0.3,0-0.6-0.1-0.8-0.4c-0.2,1.2-0.6,2.4-1.1,3.6h-5.1v-6.4h4.4l-0.6-1h-3.9v-6.4c0,0,0.2,0,0.2,0c-0.3-0.9-0.5-1.8-0.5-2.7
                c0-0.9,0.2-1.7,0.4-2.4V3.7c0.2,0.2,0.4,0.4,0.6,0.6C19,3.4,19.8,2.6,20.6,2c-1-0.2-2-0.3-3.1-0.3C8.8,1.7,1.7,8.8,1.7,17.5
                s7.1,15.8,15.8,15.8s15.8-7.1,15.8-15.8C33.3,15,32.7,12.6,31.7,10.5z M15.2,3.9c-1.8,1.7-3.2,3.6-4.1,5.8h-5
                C8.2,6.7,11.4,4.5,15.2,3.9z M5.5,10.7h5.1c-0.7,2-1.2,4.1-1.2,6.4H3.7C3.8,14.8,4.4,12.6,5.5,10.7z M3.7,18.1h5.7
                c0.1,2.2,0.5,4.4,1.3,6.4H5.6C4.5,22.6,3.8,20.4,3.7,18.1z M6.3,25.5h4.9c1,2.1,2.3,4,4,5.6C11.5,30.5,8.4,28.4,6.3,25.5z
                M16.9,31.2c-1.9-1.6-3.5-3.6-4.6-5.7h4.6V31.2z M16.9,24.5h-5c-0.9-2-1.4-4.2-1.5-6.4h6.5V24.5z M16.9,17.1h-6.5
                c0-2.3,0.5-4.4,1.3-6.4h5.2V17.1z M16.9,9.6h-4.7c1.1-2.2,2.6-4.2,4.7-5.9V9.6z M17.9,31.2v-5.7h4.6C21.4,27.7,19.9,29.6,17.9,31.2z
                M19.4,31.2c1.7-1.6,3.1-3.5,4.1-5.6h5.2C26.6,28.5,23.3,30.6,19.4,31.2z"/>
        </Svg>
    )
}