import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const RoomLocationIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'room location icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'room location icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeMiterLimit:10}} d="M22.7,6.4c0-2.8-2.3-5.2-5.2-5.2s-5.2,2.3-5.2,5.2c0,0.8,0.2,1.7,0.6,2.4l0,0l0,0C13,9,13.1,9,13.1,9.1l4.4,7.4
                l4.3-7.3c0-0.1,0.1-0.2,0.1-0.2l0,0l0,0C22.5,8.1,22.7,7.3,22.7,6.4z M17.5,8.5c-1.2,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1
                s2.1,0.9,2.1,2.1S18.7,8.5,17.5,8.5z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M22.5,10.9l-1.1,1.8l5.5,3.2v9.7l-2.1-1.3v-5.7L20,15.8v5.7l-2-1.1v-2.1l-0.5,0.8L17,18.2v2.1l-8.8,5.1v-9.7
                l5.5-3.2l-1.1-1.8L6,14.5v13.3l11.5,6.6L29,27.8V14.5L22.5,10.9z M21.1,17.7l2.6,1.5v4.5l-2.6-1.5V17.7z M17.5,31.9l-9.3-5.4
                l9.3-5.4l9.3,5.4L17.5,31.9z"/>
        </Svg>
    )
}