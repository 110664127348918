import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SuggestionsActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'suggestions icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'suggestions icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M29.1,23.2c2.5-2.3,3.9-5.4,3.9-8.5C33,7.6,26,1.8,17.5,1.8C9,1.8,2,7.6,2,14.7C2,21.8,9,27.6,17.5,27.6
                c1.7,0,3.3-0.2,4.9-0.6c0.4-0.1,1.1,0.8,3.1,5.5c0.1,0.3,0.5,0.6,0.8,0.6c0,0,0.1,0,0.1,0c0.3,0,0.6-0.2,0.8-0.5
                c2.8-4.3,2.3-7.2,2-8.8C29.2,23.6,29.1,23.4,29.1,23.2z M18.6,22.9c-0.3,0.3-0.7,0.4-1.1,0.4c-0.4,0-0.8-0.1-1.1-0.4
                c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.2-0.8,0.5-1.1s0.7-0.5,1.1-0.5s0.8,0.2,1.1,0.5s0.5,0.7,0.5,1.1C19.1,22.3,18.9,22.7,18.6,22.9z
                M22.5,13.3c-0.2,0.4-0.5,0.8-0.9,1.2c-0.3,0.3-0.9,0.9-1.8,1.6c-0.2,0.2-0.4,0.4-0.6,0.6C19.1,16.8,19,17,19,17.1
                c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.4-0.2,0.7c-0.1,0.7-0.5,1.1-1.2,1.1c-0.4,0-0.7-0.1-0.9-0.3c-0.3-0.2-0.4-0.6-0.4-1
                c0-0.6,0.1-1.1,0.3-1.5s0.4-0.8,0.7-1.1c0.3-0.3,0.7-0.7,1.2-1.2c0.4-0.4,0.8-0.7,1-0.9s0.4-0.4,0.5-0.7s0.2-0.5,0.2-0.8
                c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.6-0.6c-0.8,0-1.3,0.2-1.7,0.6c-0.4,0.4-0.7,0.9-0.9,1.7c-0.2,0.8-0.7,1.2-1.3,1.2
                c-0.4,0-0.7-0.1-1-0.4c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.7,0.2-1.3,0.6-2c0.4-0.7,1-1.2,1.8-1.7c0.8-0.4,1.8-0.7,2.8-0.7
                c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,0.9,1.8,1.5s0.6,1.3,0.6,2.1C22.8,12.3,22.7,12.8,22.5,13.3z"/>
        </Svg>
    )
}