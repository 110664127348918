import React, { useState, useEffect } from 'react'
import { ScreenContainer, StyledButton, ReduxFetch, LoadingSpinner } from 'components/general'
import { Container, Text } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { SettingsHelper, UserHelper, FetchHelper, ChatHelper } from 'redux/helpers'
import { BackBar, OfflineBar } from 'components/bars'
import { SettingsToggle } from './'
import { useHistory, routes } from 'navigation'
import { FetchRequest, UpdateHelper } from 'lib/general'
import { Namespaces } from 'redux/namespaces'
import { Platform } from 'lib/primitives'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const InterestsView = props => {
    const isStudent = UserHelper.getIsStudent()
    const history = useHistory()
    const getInterestCategories = FetchRequest.useGetInterestCategories()
    const fetchInterests = FetchHelper.useFetch([Namespaces.SETTINGS], "interests", getInterestCategories)

    if (!isStudent) history.push(routes.settings.default)

    return (
        <ScreenContainer alignItems={'center'}>
            <BackBar />
            <OfflineBar />
            <Container
                flex={1}
                flexGrow={1}
                width={'100%'}
                minHeight={0}
            >
                <ReduxFetch
                    debug={false}
                    namespace={Namespaces.SETTINGS}
                    fetchKey={"interests"}
                    fetchFunc={fetchInterests}
                    cacheDuration={1}
                    component={InterestsDisplay}
                    displayWhileLoading={true}
                />
            </Container>
        </ScreenContainer>
    )
}

const InterestsDisplay = ({ data, fetchFunc }) => {
    const colors = SettingsHelper.getColors()
    const isWeb = Platform.isWeb()
    const hasInternet = NetworkInfo.useHasInternet()
    const isChatVisible = ChatHelper.getIsChatVisible()
    const groups = Array.from(new Set(data.data.map(item => item.groupName)))
    const toggleInterestCategory = FetchRequest.useToggleInterestCategory()
    

    const updateInterestCategory = async (id) => {
        return await toggleInterestCategory(id)
    }
    const { updateItems, awaitingUpdate, awaitingRefresh } = UpdateHelper(data.data, 'i3Id', fetchFunc)

    const handleAction = () => {
        if (hasInternet) {
            fetchFunc()
        }
    }

    return (
        <React.Fragment>
            {data.isLoading && Object.values(awaitingRefresh).length == 0 && <LoadingSpinner size={40} color={colors.screenAltText} />}
            <Container
                flex={1}
                flexGrow={1}
                alignItems={'center'}
                position={'relative'}
                opacity={!hasInternet ? 0.5 : null}
                webOverflow={'auto'}
                nativeOverflow={'hidden'}
                viewType={'ScrollView'}
                onRefresh={handleAction}
                isChat={isChatVisible}
            >
                <Container
                    flex={0}
                    padding={10}
                    paddingTop={0}
                    paddingBottom={30}
                    width={'100%'}
                    maxWidth={600}
                >
                    <Container
                        flex={0}
                        padding={10}
                        borderBottom={`2px solid ${colors.screenAltBg}`}
                        borderBottomWidth={2}
                        borderColor={colors.screenAltBg}
                    >
                        <Text color={colors.screenAltText}>
                            Choose which categories below interest you. This helps Beacon to pick content you would like to see.
                    </Text>
                    </Container>
                    {groups.map(group => <CategoryGroup
                        title={group}
                        data={data.data.filter(item => item.groupName == group)}
                        colors={colors}
                        hasInternet={hasInternet}
                        updateItems={updateItems}
                        updateInterestCategory={updateInterestCategory}
                        awaitingUpdate={awaitingUpdate}
                        awaitingRefresh={awaitingRefresh}
                        key={group}
                    />)}
                    {isWeb ? <StyledButton
                        title={'Refresh'}
                        accessibility={{ role: 'button', label: 'refresh the page' }}
                        handleAction={handleAction}
                        bgColor={colors.actionBg}
                        textColor={colors.actionText}
                    /> : null}
                </Container>
            </Container>
        </React.Fragment>
    )
}

const CategoryGroup = ({ title, data, colors, hasInternet, updateItems, updateInterestCategory, awaitingUpdate, awaitingRefresh }) => {
    return (
        <React.Fragment>
            <Container
                flex={0}
                paddingTop={10}
                paddingBottom={10}
                textAlign={'left'}
                borderBottom={`2px solid ${colors.screenAltBg}`}
                borderBottomWidth={2}
                borderColor={colors.screenAltBg}
            >
                <Text
                    color={colors.screenText}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                    fontSize={fonts.size.large}
                    paddingLeft={10}
                >
                    {title}
                </Text>
            </Container>
            <Container flex={0} paddingLeft={10} paddingRight={10}>
                {data.map(item => <CategoryItem
                    data={item}
                    hasInternet={hasInternet}
                    updateItems={updateItems}
                    updateInterestCategory={updateInterestCategory}
                    isLoading={awaitingUpdate[item.i3Id] != null || awaitingRefresh[item.i3Id] != null}
                    key={item.i3Id}
                />)}
            </Container>
        </React.Fragment>
    )
}

const CategoryItem = ({ data, hasInternet, updateItems, updateInterestCategory, isLoading }) => {
    const [loading, setLoading] = useState(isLoading)
    let active = data && data.studentInterestCategories && data.studentInterestCategories[0] && data.studentInterestCategories[0].interestScore == 1 ? true : false

    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    if (!data || data.hidden) return null
    return (
        <SettingsToggle
            accessibility={{ role: 'button', label: 'toggle ' + data.name + ' interest' + active ? 'enabled' : 'disabled' }}
            isActive={active}
            isLoading={loading}
            toggleFunc={() => {
                if (hasInternet) {
                    setLoading(true)
                    updateItems(data.i3Id, async () => await updateInterestCategory(data.i3Id))
                }
            }}
            text={data.name}
            key={data.name}
        />
    )
}