import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const LocationActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'location icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'location icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M28.25,12.42c0-5.94-4.81-10.75-10.75-10.75S6.75,6.49,6.75,12.42c0,1.84,0.46,3.56,1.27,5.08h0l0.01,0.02
                c0.08,0.14,0.16,0.27,0.25,0.41l9.22,15.41l9.14-15.28c0.11-0.18,0.23-0.36,0.33-0.55l0,0h0C27.78,15.99,28.25,14.26,28.25,12.42z
                M17.5,16.9c-2.47,0-4.48-2-4.48-4.48c0-2.47,2-4.48,4.48-4.48s4.48,2,4.48,4.48C21.98,14.9,19.97,16.9,17.5,16.9z"/>
        </Svg>
    )
}