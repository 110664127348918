import React, { useEffect } from 'react'
import { Container, Button, Text, Image } from 'primitives'
import { SettingsHelper, ChatHelper, UserHelper } from 'redux/helpers'
import { fonts } from 'lib/config/ui-config'
import { ImageSrc } from 'assets/images'
import Screen from 'lib/screen/screen'
import { Platform } from 'lib/primitives'

export const ChatBar = props => {
    //Global state
    const colors = SettingsHelper.getColors()
    const isWeb = Platform.isWeb()
    const screenXS = Screen.isScreenXS()
    const isStudent = UserHelper.getIsStudent()
    const isChatVisible = ChatHelper.getIsChatVisible()
    //Hooks
    const setChatVisible = ChatHelper.useSetChatMenuVisibility()

    useEffect(() => {
        if (screenXS && isChatVisible) {
            setChatVisible(false)
        }
    }, [screenXS, isChatVisible])

    if (!isWeb || isChatVisible || screenXS || isStudent === null) return null

    return (
        <Container
            flex={0}
            backgroundColor={colors.chatUserDefault}
            height={40}
            marginTop={-40}
            zIndex={50}
            marginLeft={'auto'}
            marginRight={20}
            borderRadius={`10px 10px 0px 0px`}
        >
            <Button accessibility={{ role: 'button', label: 'open Beacon chat' }} padding={0} flexDirection={'row'} handleAction={setChatVisible}>
                <Container width={30} height={30} margin={5} borderRadius={15} overflow={'hidden'}>
                    <Image src={ImageSrc.beacon} alt={'Beacon mascot'} width={'100%'} height={'100%'} />
                </Container>
                <Text
                    color={colors.menuIcon}
                    padding={10}
                    paddingLeft={0}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                    fontSize={fonts.size.large}
                >
                    Chat to Beacon
                </Text>
            </Button>
        </Container>
    )
}