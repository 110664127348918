import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SuccessActiveIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'success icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'success icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:props.color}} d="M17.5,1.68c-8.73,0-15.82,7.1-15.82,15.82c0,8.72,7.1,15.82,15.82,15.82c8.72,0,15.82-7.1,15.82-15.82
                C33.32,8.77,26.22,1.68,17.5,1.68z M25.52,14.8l-8.24,8.24c-0.39,0.39-0.9,0.59-1.41,0.59s-1.02-0.2-1.41-0.59l-4.98-4.98
                c-0.78-0.78-0.78-2.05,0-2.83c0.78-0.78,2.05-0.78,2.83,0l3.57,3.57l6.82-6.82c0.78-0.78,2.05-0.78,2.83,0
                C26.31,12.75,26.31,14.02,25.52,14.8z"/>
        </Svg>
    )
}