import React from 'react'
import { Animated, Container } from 'primitives'
import { Icon, icons } from 'assets/MenuIcons'

export const LoadingSpinner = ({ size, color, ...props }) => {
    return (
        <Container flex={0} width={'100%'} padding={props.padding != null ? props.padding : 20} alignItems={'center'}>
            <Animated
                flex={0}
                height={size}
                width={size}
                animations={LoadingAnimation}
                current={'animated'}
                initial={'init'}
                reset={true}
            >
                <Icon type={icons.subMenu} color={color} size={size} />
            </Animated>
        </Container>
    )
}

const LoadingAnimation = {
    init: {
        rotate: '0deg',
        transform: `rotate(0deg)`
    },
    animated: async (next, cancel) => {
        let rotation = '180deg'
        while (true) {
            await next({
                rotate: rotation,
                transform: `rotate(${rotation})`
            })
        }
    }
}