import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RuntimeActions } from 'redux/lib/runtime/RuntimeActions'

// Web only listeners
export const PlatformListener = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        const windowListener = window.addEventListener('resize', () => {
            // Prevents resize if input is active on mobile
            if (!(document.activeElement instanceof HTMLInputElement)) {
                dispatch(RuntimeActions.setWindowHeight(window.innerHeight))
                dispatch(RuntimeActions.setWindowWidth(window.innerWidth))
            }
        })

        return () => {
            if (windowListener) {
                windowListener.remove()
            }
        }
    }, [])

    return null
}

export const KeyboardListener = () => {
    return null
}

export const BackButtonListener = () => {
    return null
}

