import React from 'react'
import { Svg, Line, Polyline } from 'primitives'
import { Platform } from 'lib/primitives'

export const SendMessageIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'send message icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'send message icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="10.47" y1="18.04" x2="14.42" y2="28.3"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="19.58" y1="23.17" x2="14.42" y2="28.3"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="20.53" y1="23.7" x2="18.03" y2="22.3"/>
            <Polyline scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} points="20.53,23.7 27.76,27.78 31.59,4.84 2.57,13.6 10.47,18.04   "/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="30.63" y1="5.89" x2="17.08" y2="21.82"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="30.63" y1="5.89" x2="10.47" y2="18.04"/>
            <Line scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round'}} x1="17.08" y1="21.82" x2="14.42" y2="28.3"/>
        </Svg>
    )
}