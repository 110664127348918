import { Namespaces } from 'redux/namespaces'

export const SettingsTypes = {
    RESET_SETTINGS: `${Namespaces.SETTINGS}_RESET_SETTINGS`,
    SET_COLOR_THEME: `${Namespaces.SETTINGS}_SET_COLOR_THEME`,
    SET_BACKGROUND_COLOR: `${Namespaces.SETTINGS}_SET_BACKGROUND_COLOR`,
    SET_DISABLE_SWIPE_EVENTS: `${Namespaces.SETTINGS}_SET_DISABLE_SWIPE_EVENTS`,
    SET_DISABLE_ANIMATIONS: `${Namespaces.SETTINGS}_SET_DISABLE_ANIMATIONS`,
    SET_DISABLE_IN_APP_NOTIFICATIONS: `${Namespaces.SETTINGS}_SET_DISABLE_IN_APP_NOTIFICATIONS`,
    SET_ENABLE_LARGER_TILES: `${Namespaces.SETTINGS}_SET_ENABLE_LARGER_TILES`,
    SET_QNA_RESULTS: `${Namespaces.SETTINGS}_SET_QNA_RESULTS`,
}