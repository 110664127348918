import React from 'react'
import { Svg, Rect, LinearGradient, Stop, Defs } from 'primitives'
import { Platform } from 'lib/primitives'

export const GradientIcon = props => {
    const isNative = Platform.isNative()
    return (
        <Svg style={{ height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Defs>
                <LinearGradient id="Gradient" x1="0" y1="0" x2="0" y2="1">
                    <Stop offset="0" stopColor={props.color} stopOpacity="1" />
                    <Stop offset={props.endpoint} stopColor={props.color} stopOpacity="0" />
                </LinearGradient>
            </Defs>
            <Rect fill="url(#Gradient)" width={props.size} height={props.size} x="0" y="0" />
        </Svg>
    )
}