import React from 'react';
import { useSelector } from 'react-redux'
import { Navigation } from 'navigation/Navigation'
import { UpgradeRequired } from 'screens'

export const AppContainer = props => {
  const versionUpToDate = useSelector(state => state.runtime.versionUpToDate)

  if (!versionUpToDate) {
    return <UpgradeRequired />
  }

  return <Navigation />
}