import React from 'react';
import './App.css';
import { AppCommon } from 'AppCommon'
import { platform, version } from '../package.json'
import WebfontLoader from '@dr-kobros/react-webfont-loader'

const fontConfig = {
  google: {
    families: ['Montserrat:500,600,700', 'Open+Sans:400,600,700']
  }
}

function App() {
  return (
    <WebfontLoader config={fontConfig}>
      <AppCommon platform={{ platform, version }} />
    </WebfontLoader>)
}

export default App;
