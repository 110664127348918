import React from 'react'
import { Svg, Circle } from 'primitives'
import { Platform } from 'lib/primitives'

export const ChatMenuIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'chat menu icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'chat menu icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="24.9" cy="9.76" r="4.85"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="24.9" cy="25.08" r="4.85"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.2" cy="9.84" r="4.85"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="10.2" cy="25.16" r="4.85"/>
        </Svg>
    )
}