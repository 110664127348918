import React from 'react'
import ReactMarkdown from 'react-markdown'
import { SettingsHelper } from 'redux/helpers'
import { Container, Text, Link, Image } from 'primitives'
import { fonts } from 'lib/config/ui-config'
import { Icon, icons } from 'assets/MenuIcons'

export const CustomText = ({ text, textColor, textAlign = 'left', title = false }) => {
    const colors = SettingsHelper.getColors()

    const RootRenderer = ({ children, ...props }) => {
        return (
            <Container flex={0}>
                {children}
            </Container>
        )
    }

    const TextRenderer = ({ children, ...props }) => {
        return (
            <Text
                fontFamily={title ? fonts.web.heading : fonts.web.default}
                fontSize={title ? fonts.size.large : fonts.size.default}
                fontWeight={fonts.weight.semiBold}
                fontSize={title ? fonts.size.large : fonts.size.default}
                lineHeight={title ? fonts.size.large : fonts.size.default * 1.5}
                margin={0}
                padding={0}
                color={textColor}
                textAlign={textAlign}
                overflowWrap={'break-word'}
                wordWrap={'break-word'}
                wordBreak={'break-word'}
                hyphens={'auto'}
            >
                {children}
            </Text>
        )
    }

    const StrongTextRenderer = ({ children, ...props }) => {
        return (
            <Text
                fontFamily={title ? fonts.web.heading : fonts.web.default}
                fontSize={title ? fonts.size.large : fonts.size.default}
                fontWeight={fonts.weight.bold}
                fontSize={title ? fonts.size.large : fonts.size.default}
                lineHeight={title ? fonts.size.large : fonts.size.default * 1.5}
                margin={0}
                padding={0}
                color={textColor}
                textAlign={textAlign}
                overflowWrap={'break-word'}
                wordWrap={'break-word'}
                wordBreak={'break-word'}
                hyphens={'auto'}
            >
                {children[0].props.children}
            </Text>
        )
    }

    const LinkRenderer = ({ children, href, ...props }) => {
        return (
            <Link
                display={'inline'}
                to={href}
                isExternal={true}
            >
                <Text
                    fontFamily={title ? fonts.web.heading : fonts.web.default}
                    fontSize={title ? fonts.size.large : fonts.size.default}
                    fontWeight={fonts.weight.bold}
                    fontSize={title ? fonts.size.large : fonts.size.default}
                    lineHeight={title ? fonts.size.large : fonts.size.default * 1.5}
                    margin={0}
                    padding={0}
                    color={colors.chatBotTextAccent}
                    textAlign={textAlign}
                    overflowWrap={'break-word'}
                    wordWrap={'break-word'}
                    wordBreak={'break-word'}
                >
                    {children[0].props.children}
                    <Icon type={icons.externalLink} color={colors.screenAltText} size={fonts.size.default} />
                </Text>
            </Link >
        )
    }

    const ParagraphRenderer = ({ children, ...props }) => {
        return (
            <Container
                display={'block'}
                marginTop={5}
                marginBottom={5}
            >
                {children}
            </Container>
        )
    }

    const ImageRenderer = ({ src, alt, ...props }) => {
        return (
            <Image
                src={src}
                alt={alt}
                resizeMode={'center'}
                height={200}
                marginLeft={'auto'}
                marginRight={'auto'}
                width={'100%'}
            />
        )
    }

    return (
        <ReactMarkdown
            source={text}
            renderers={{
                root: RootRenderer,
                text: TextRenderer,
                strong: StrongTextRenderer,
                link: LinkRenderer,
                paragraph: ParagraphRenderer,
                image: ImageRenderer,
            }}
        />
    )
}