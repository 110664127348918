import React from 'react'
import { Svg, Circle, Polygon } from 'primitives'
import { Platform } from 'lib/primitives'

export const StudentsUnionIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: `students' union icon`
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': `students' union icon`
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="17.5" cy="17.5" r="14.82"/>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeMiterLimit:10}} cx="20.12" cy="8.85" r="2.58"/>
            <Polygon scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:1.5,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterLimit:10}} points="18.29,13.6 12.16,8.13 15.06,15.53 13,28.09 19.68,16.95 27.46,13.7 "/>
        </Svg>
    )
}