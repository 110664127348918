import React from 'react'
import { Svg, Circle, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const MoodNeutralIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'neutral mood icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'neutral mood icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Circle scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeMiterLimit:10}} cx="17.52" cy="17.5" r="14.81"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="11.76" cy="13.94" r="2.56"/>
            <Circle scale={props.size/35} style={{fill:props.color}} cx="23.27" cy="13.94" r="2.56"/>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeMiterLimit:10}} d="M25.03,22.45c0,0-3.37,2.11-7.52,2.11S10,22.45,10,22.45"/>
        </Svg>
    )
}