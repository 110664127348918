import React from 'react'
import { Svg, Path } from 'primitives'
import { Platform } from 'lib/primitives'

export const SuggestionsIcon = props => {
    const isNative = Platform.isNative()

    const accessibility = isNative ? {
        accessible: true,
        accessibilityRole: 'image',
        accessibilityLabel: 'suggestions icon'
    } : {
        'aria-required': true,
        role: 'img',
        'aria-label': 'suggestions icon'
    }

    return (
        <Svg {...accessibility} style={{height: props.size, width: props.size }} viewBox={`0 0 ${isNative ? props.size + ' ' + props.size : '35 35'}`}>
            <Path scale={props.size/35} style={{fill:'none',stroke:props.color,strokeWidth:2,strokeLinecap:'round',strokeLinejoin:'round',strokeMiterlimit:10}} d="M31.98,14.68c0,3.05-1.4,5.83-3.7,7.93c-0.83,0.76,1.88,3.76-1.86,9.44c-2.39-5.72-3.12-6.43-4.32-6.1
                c-1.44,0.4-2.99,0.61-4.59,0.61c-8,0-14.48-5.32-14.48-11.88S9.5,2.8,17.5,2.8S31.98,8.12,31.98,14.68z"/>
            <Path scale={props.size/35} style={{fill:props.color}} d="M12.43,11.94c0-0.66,0.21-1.33,0.63-2c0.42-0.68,1.04-1.24,1.85-1.68c0.81-0.44,1.76-0.67,2.84-0.67
                c1.01,0,1.89,0.19,2.66,0.56c0.77,0.37,1.36,0.88,1.78,1.51s0.63,1.33,0.63,2.08c0,0.59-0.12,1.11-0.36,1.55s-0.52,0.83-0.85,1.15
                c-0.33,0.32-0.92,0.86-1.77,1.63c-0.24,0.21-0.42,0.4-0.57,0.57c-0.14,0.16-0.25,0.31-0.32,0.45c-0.07,0.14-0.12,0.27-0.16,0.41
                c-0.04,0.14-0.1,0.37-0.17,0.71c-0.13,0.72-0.54,1.08-1.24,1.08c-0.36,0-0.66-0.12-0.91-0.35c-0.25-0.24-0.37-0.59-0.37-1.05
                c0-0.58,0.09-1.09,0.27-1.51s0.42-0.8,0.72-1.12s0.7-0.71,1.21-1.15c0.44-0.39,0.76-0.68,0.96-0.88c0.2-0.2,0.36-0.42,0.5-0.66
                c0.14-0.24,0.2-0.51,0.2-0.79c0-0.55-0.21-1.02-0.62-1.4s-0.94-0.57-1.6-0.57c-0.76,0-1.32,0.19-1.68,0.58
                c-0.36,0.38-0.67,0.95-0.92,1.7c-0.24,0.78-0.68,1.18-1.34,1.18c-0.39,0-0.72-0.14-0.98-0.41S12.43,12.26,12.43,11.94z
                M17.51,23.34c-0.42,0-0.79-0.14-1.11-0.41c-0.32-0.27-0.47-0.66-0.47-1.15c0-0.44,0.15-0.8,0.46-1.1s0.68-0.45,1.12-0.45
                c0.44,0,0.8,0.15,1.1,0.45s0.45,0.67,0.45,1.1c0,0.49-0.16,0.87-0.47,1.14C18.28,23.2,17.92,23.34,17.51,23.34z"/>
        </Svg>
    )
}