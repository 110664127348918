import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TokenHelper } from 'lib/auth/TokenHelper'
import { UserHelper, PlatformHelper, NotificationHelper, ChatHelper, RuntimeHelper } from 'redux/helpers'
import { AppState } from 'lib/network/AppState'
import { FetchRequest } from 'lib/general/fetchRequest'
import { RuntimeActions, UserActions } from './redux/actions'
import { Platform, SplashScreen } from 'lib/primitives'
import { FirebaseMessaging, NotificationsUtils } from 'lib/notifications'
import { QueueHelper, JobNames } from 'redux/helpers'
import { PlatformListener } from 'redux/listeners'
import { Namespaces } from 'redux/namespaces'


/*

PURPOSE OF THIS CLASS:
Run initial checks when the app is openned or comes to foreground (Use this for async checks that should not prevent the app from starting)
Example: check if token is still valid, check if new version available..

IMPORTANT:
This class is not here to perform actions that can be initialised in the redux store
Example: define current version, current platform, set initial states

*/

export const AppInit = props => {

  // Current state
  // const hasInternetStatus = NetworkInfo.useHasInternet()
  const hasInternetStatus = useSelector(state => state[Namespaces.RUNTIME].online)

  const authToken = UserHelper.getAuthToken() // Required on native to know when the token gets updated (for instance: on log in)

  const version = PlatformHelper.getVersion()
  const [appState, setAppState] = useState(AppState.currentState)
  const getAuthToken = UserHelper.useGetAuthToken()
  const getUser = UserHelper.useGetUser()
  const isNative = Platform.isNative()

  // Define hooks
  const dispatch = useDispatch()
  const handleTokenInvalid = UserHelper.useHandleTokenExpired()
  const onNotificationPressed = NotificationHelper.useOnNotificationPressed()
  const onNotificationForeground = NotificationHelper.useOnNotificationForeground()
  const setupDirectline = ChatHelper.useSetupDirectline()
  const disconnectDirectline = ChatHelper.useDisconnectDirectline()
  const processQueue = QueueHelper.useProcessQueue()
  const checkIfOnline = RuntimeHelper.useCheckIfOnline()

  // Fetch functions
  const isTokenValid = TokenHelper.useIsTokenValid()
  const getUserInfo = FetchRequest.useGetUserInfo()
  const getStudentInfo = FetchRequest.useGetStudentInfo()

  // Services Singletons
  let notificationsUtils = null // Push notifications
  let firebaseMessaging = null  // Firebase
  let directline = null

  // Initial checks (when the app is openned or moved from backgroud to foreground)
  const runInitialChecks = async () => {
    const token = await getAuthToken()
    console.log(`********************************`)
    console.log(`Running initial checks`)

    // ----------------------------------
    // --          IF INTERNET         --
    // ----------------------------------

    const hasInternet = await checkIfOnline()
    console.log(`- Checking Internet again (NetworkInfo.hasInternetAsync): ${hasInternet}`)

    if (hasInternet) {

      processQueue()

      // Check if a new version is available
      if (isNative) {
        console.log(`- Current version: ${version}`)
        console.log("Checking if a new version is available...")
        FetchRequest.checkVersion(version)
          .then(res => {
            if (res && !res.error) {
              if (res.upgradeNeeded === true) {
                console.log("Version: upgrade required ❌")
              } else {
                console.log("Version: no upgrade needed ✅")
              }
              dispatch(RuntimeActions.setVersionChecked(res.upgradeNeeded, res.res, res.text))
            } else {
              console.log("Version: could not check ❌")
            }
          })
          .catch(e => console.error(e))
      }

      // Await token verification (there's no point initialising the user if the token is not valid anymore)
      let tokenIsValid = false
      console.log("- Auth token found: " + (token ? 'true' : 'false'))
      if (token) {
        console.log('Checking if the auth token is still valid..')
        try {
          tokenIsValid = await isTokenValid()
          if (tokenIsValid) {
            console.log("Auth token: valid ✅")
          } else {
            console.log("Auth token: invalid ❌")
            handleTokenInvalid()
          }
        }
        catch (e) {
          console.error(e)
        }
      }

      // ----------------------------------
      // --        IF TOKEN VALID        --
      // ----------------------------------
      if (tokenIsValid) {
        const user = await getUser()

        // TODO: Load data (timetable, news and events, interest categories...)

        // Initialise Services
        console.log(`Initialising services:`)

        // Get user info
        getUserInfo()
          .then(res => {
            if (!res.error) {
              dispatch(UserActions.setUserInfo(res))
              if (res.isStudent) {
                // Get student info
                getStudentInfo()
                  .then(res => {
                    if (!res.error) dispatch(UserActions.setStudentInfo(res))
                  })
                  .catch(e => console.error(e))
              }
            } else {
              dispatch(UserActions.cannotLoadProfile())
            }
          })
          .catch(e => {
            dispatch(UserActions.cannotLoadProfile())
            console.error(e)
          })

        // Push notifications
        if (isNative) {
          console.log(`- Push notifications`)
          notificationsUtils = new NotificationsUtils(user, onNotificationPressed, onNotificationForeground)
          firebaseMessaging = new FirebaseMessaging(user)
        }

        // Directline
        setupDirectline(user)

      }

      // ----------------------------------
      // --  IF TOKEN INVALID / MISSING  --
      // ----------------------------------
      else {
        // Remove firebase push notification callback
        if (isNative && firebaseMessaging) {
          console.log('Unregistering from push notifications')
          firebaseMessaging.unregister()
        }
        disconnectDirectline()
      }

    }

    // ----------------------------------
    // --        IF NO INTERNET        --
    // ----------------------------------
    else {
      // // If no Internet, try again in one minute (attempting to fix issue on beacon-test stuck in offline mode)
      // setTimeout(() => {
      //   runInitialChecks()
      // }, 1000 * 60);

    }

  }

  useEffect(() => {
    // Component mounts
    console.log("")
    console.log("--------------- " + new Date().toLocaleTimeString() + " ---------------")
    console.log("Beacon started with the following state:")
    console.log("- Internet: " + hasInternetStatus)
    console.log("- App state: " + appState)

    // Hide splashscreen and then run initial checks (we don't want to keep the splashscreen for too long)
    AppState.addEventListener("change", _handleAppStateChange)
    SplashScreen.hide()
    runInitialChecks()

    // Component will unmount
    return () => {
      AppState.removeEventListener("change", _handleAppStateChange)
    };
  // }, [hasInternetStatus]) // re-run the check if the users gets Internet or logs in / out
  }, [hasInternetStatus, authToken]) // re-run the check if the users gets Internet or logs in / out


  // Run the checks when the app comes into the foreground
  const _handleAppStateChange = nextAppState => {
    if (nextAppState === "active") {
      runInitialChecks()
    }
    setAppState(nextAppState)
  }

  return (
    <PlatformListener dispatch={dispatch} />
  )
}