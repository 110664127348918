
import { useSelector, useDispatch } from 'react-redux'
import { getCachedToken, getToken, getCachedUserInfo, getCachedUser } from 'lib/auth/adalConfig'
import { azureAD } from 'lib/auth/azureAD'
import { UserActions, SettingsActions } from 'redux/actions'
import { authContext } from 'lib/auth/adalConfig'
import { Platform } from 'lib/primitives'
import { NotificationHelper } from 'redux/helpers'
import { features } from 'lib/general/enums'
import { routes } from 'navigation'
import { FetchRequest } from 'lib/general/fetchRequest'

// User is a bit special, it is not fully stored in redux. 
// On react native: it is
// On web: react-adal is used and the user/jwt token is stored in an iframe

export class UserHelper {

    static isLoggedIn = () => {
        const isNative = Platform.isNative()
        if (isNative) {
            const user = useSelector(state => state.user)
            return user && user.loggedIn === true && user.info != null
        } else {
            return getCachedToken() != null
        }
    }

    static getUser = () => {
        const isNative = Platform.isNative()
        if (isNative) {
            const user = useSelector(state => state.user.info)
            return user
        } else {
            return getCachedUserInfo()
        }
    }

    static useGetUser = () => {
        const isNative = Platform.isNative()
        const user = useSelector(state => state.user.info)
        const getAuthToken = this.useGetAuthToken()
        return async () => {
            if (isNative) {
                return user
            } else {
                let user = null
                // this return the same user object as on mobile
                try {
                    let token = await getAuthToken()
                    const adalUser = await getCachedUser()
                    if (adalUser && token) {
                        let username = adalUser.profile.upn.split('@')[0]
                        user = {
                            id: username,
                            username,
                            email: adalUser.profile.upn,
                            name: adalUser.profile.name,
                            token,
                            deviceId: null,
                            deviceOS: "web",
                        }
                    }
                } catch (error) {
                    console.log("❌ Could not get user info")
                    console.error(error)
                }
                if (!user) {
                    console.error("The user is null")
                }
                return user
            }
        }
    }

    static getUserInfo = () => {
        const userInfo = useSelector(state => state.user.userInfo)
        return userInfo
    }

    static getStudentInfo = () => {
        const studentInfo = useSelector(state => state.user.studentInfo)
        return studentInfo
    }

    static useGetAuthToken = () => {
        const userInfo = useSelector(state => state.user.userInfo)
        const isNative = Platform.isNative()
        const user = useSelector(state => state.user)
        const handleTokenExpired = this.useHandleTokenExpired()
        const dispatch = useDispatch()
        return async () => {
            let token = null
            try {
                // 1. NATIVE
                if (isNative) {
                    if (user && user.loggedIn === true && user.info != null) {
                        token = user.info.token
                    }
                }
                // 2. WEB 
                else {
                    console.log("Getting cached token..")
                    token = await getCachedToken()
                    // If the token was empty, try renewing it
                    if (!token) {
                        console.log("Refresh and getting token..")
                        token = await getToken()
                    }

                    // Fixing issue on web for users sharing their device:
                    // Reproducing steps:
                    // - Log in with user A, load a couple screens, close Beacon Web
                    // - Access other MS services with user A in the same browser
                    // - Wait a few hours (until your auth token becomes invalid in the adal iframe)
                    // - Log in to MS Services using user B
                    // - Go to Beacon, the SSO flow will kick in and sign you in as user B
                    // - Because you have a valid auth token, the app will not sign you out and you will see the cached data from user A until it gets refreshed
                    // Devs: 
                    // To test this locally and avoid the wait, edit the useHandleLogOut method to NOT dispatch UserActions.logOut(), 
                    // Sign out and sign back in with a different account
                    let decodedToken = this.getUserDataFromToken(token)
                    if (userInfo && userInfo.username && decodedToken && decodedToken.username) {
                        if (userInfo.username != decodedToken.username) {
                            console.log("👤 The token and redux user do not match. Clearing all user data..")
                            // On web, this will clear out the user data but not log out the user
                            dispatch(UserActions.logOut())
                            // Load the user info again
                            // We're not using the FetchRequest hook version here on purpose to avoid an infinite loop
                            FetchRequest.getUserInfo(token)
                                .then(res => {
                                    if (!res.error) {
                                        dispatch(UserActions.setUserInfo(res))
                                    }
                                })
                        }
                    }
                }
            } catch (e) {
                console.log("👤 Error in useGetAuthToken")
                console.log(e)
            }
            if (!token) {
                console.log('👤 The token is null or expired')
                handleTokenExpired()
            }
            return token
        }
    }


    static getUserDataFromToken = (token) => {
        try {
            const [, body] = token.split('.')
            const decoded = JSON.parse(Buffer.from(body, 'base64').toString())
            const email = decoded.email ||
                decoded.mail ||
                decoded.username ||
                decoded.unique_name ||
                decoded.preferred_username ||
                ''
            const [username] = email.split('@')
            return {
                username,
                email,
                name: decoded.name || ''
            }
        } catch (e) {
            console.error("Could not decode token")
            console.log(token)
            return null
        }
    }

    static getAuthToken = () => {
        const isNative = Platform.isNative()
        let token = null
        if (isNative) {
            const user = useSelector(state => state.user)
            if (user && user.loggedIn === true && user.info != null) {
                token = user.info.token
            }
        } else {
            console.log("Getting cached token..")
            token = getCachedToken()
        }
        return token
    }

    static getIsStudent = () => {
        const userInfo = useSelector(state => state.user.userInfo)
        if (userInfo != null && userInfo.isStudent != null) return userInfo.isStudent
        return null
    }

    static isAttendanceEnabled = () => {
        const userInfo = useSelector(state => state.user.userInfo)
        if (userInfo != null && userInfo.features != null) {
            return userInfo.features.includes(features.attendance)
        }
        return false
    }

    static isFeatureEnabled = (feature) => {
        const userInfo = useSelector(state => state.user.userInfo)
        if (userInfo != null && userInfo.features != null) {
            return userInfo.features.includes(feature)
        }
        return false
    }

    static useIsFeatureEnabled = () => {
        const userInfo = useSelector(state => state.user.userInfo)
        return feature => {
            if (userInfo != null && userInfo.features != null) {
                return userInfo.features.includes(feature)
            }
            return false
        }
    }

    // Hooks to Log in / out
    // The auth implementation is slightly different on mobile and web
    // - Mobile: the JWT token is stored within redux
    // - Web: An iframe is added to the page to refresh the token automatically

    static useHandleLogIn = () => {
        const dispatch = useDispatch()
        const isNative = Platform.isNative()
        return () => {
            if (isNative) {
                dispatch(UserActions.logInAttempt())
                azureAD.promptLoginPage()
                    .then(authState => {
                        dispatch(UserActions.logInSuccess(authState))
                    })
                    .catch(error => {
                        console.log("⚠️ Could not authenticate")
                        console.log(error);
                        dispatch(UserActions.logInFailure(error))
                    })
            } else {
                authContext.login()
            }
        }
    }

    static useHandleLogOut = () => {
        const dispatch = useDispatch()
        const isNative = Platform.isNative()
        const deleteAllNotifications = NotificationHelper.useDeleteAllNotifications()
        return () => {
            if (isNative) {
                deleteAllNotifications()
                dispatch(SettingsActions.resetSettings()) // This crashes the logout on web for some reason, so don't add it below
                dispatch(UserActions.logOut())
            } else {
                dispatch(UserActions.logOut())
                authContext.logOut()
            }
        }
    }

    static useHandleTokenExpired = () => {
        const dispatch = useDispatch()
        const isNative = Platform.isNative()
        return () => {
            if (isNative) {
                dispatch(UserActions.tokenInvalid())
            } else {
                authContext.clearCache()
                if (window.location.pathname != routes.login) {
                    window.location.replace("/"); // using pure JS here as react-router-dom might not yet be defined
                }
            }
        }
    }

    static useSetOnboarding = () => {
        const dispatch = useDispatch()
        return (visible) => {
            dispatch(UserActions.setShowOnboarding(visible))
        }
    }

}
