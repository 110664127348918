import React from 'react'
import { SettingsHelper, ChatHelper } from 'redux/helpers'
import { ScreenContainer } from 'components/general'
import { Container } from 'primitives'
import { useDispatch } from 'react-redux'
import { SettingsActions } from 'redux/lib/settings'
import { BackBar } from 'components/bars'
import { SettingsToggle } from './'
import { colorThemes } from 'lib/general/enums'

export const ColourThemeView = props => {
    const currentTheme = SettingsHelper.getCurrentTheme()
    const themes = Object.keys(colorThemes)
    const dispatch = useDispatch()
    const isChatVisible = ChatHelper.getIsChatVisible()

    const themeName = (theme) => {
        switch (theme) {
            case colorThemes.basic: return 'Default - Light'
            case colorThemes.dark: return 'Default - Dark'
            case colorThemes.highContrast: return 'High-Contrast'
            default: return null
        }
    }

    return (
        <ScreenContainer alignItems={'center'}>
            <BackBar />
            <Container
                flex={1}
                flexGrow={1}
                minHeight={0}
                width={'100%'}
                alignItems={'center'}
                position={'relative'}
                webOverflow={'auto'}
                nativeOverflow={'hidden'}
                viewType={'ScrollView'}
                isChat={isChatVisible}
            >
                <Container
                    flex={0}
                    padding={10}
                    paddingTop={0}
                    width={'100%'}
                    maxWidth={600}
                >
                    {themes.map(theme => {
                        return (
                            <SettingsToggle
                                accessibility={{ role: 'button', label: 'toggle ' + themeName(colorThemes[theme]) + ' theme: ' + colorThemes[theme] === currentTheme ? 'enabled' : 'disabled' }}
                                isActive={colorThemes[theme] === currentTheme}
                                isDisabled={colorThemes[theme] === currentTheme}
                                toggleFunc={() => !(colorThemes[theme] === currentTheme) ? dispatch(SettingsActions.setColorTheme(colorThemes[theme])) : null}
                                text={themeName(colorThemes[theme])}
                                key={theme}
                            />
                        )
                    })}
                </Container>
            </Container>
        </ScreenContainer>
    )
}