import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { SettingsHelper, ChatHelper } from 'redux/helpers'
import { Container, Text } from 'primitives'
import { Platform } from 'lib/primitives'
import { TimelineWrapper, TimetableOptions } from 'components/timetable'
import { StyledButton } from 'components/general'
import { fonts } from 'lib/config/ui-config'
import { NetworkInfo } from 'lib/network/NetworkInfo'

export const TimelineView = ({ data, lastFetchedAt, lastDateWithData, fetchFunc, ...props }) => {
    //Global state
    const colors = SettingsHelper.getColors()
    const isWeb = Platform.isWeb()
    const hasInternet = NetworkInfo.useHasInternet()
    const isChatVisible = ChatHelper.getIsChatVisible()
    const appHeight = useSelector(state => state.runtime.height)
    //Refs
    const scrollRef = useRef(null)
    //Local state
    const [isEmpty, setIsEmpty] = useState(false)
    const [viewHeight, setViewHeight] = useState(appHeight)
    const [lastDayHeight, setLastDayHeight] = useState(0)
    const [currentDayOffset, setCurrentDayOffset] = useState(0)
    //Other
    const weekDate = data && data[0] && data[0].data ? moment(data[0].date) : null

    useEffect(() => {
        let isEmpty = true
        try {
            if (data && data.map(item => item.data).flat().length > 0) {
                isEmpty = false
            }
        } catch (e) { }
        setIsEmpty(isEmpty)
    }, [data])

    useEffect(() => {
        if (scrollRef.current) scrollRef.current.scrollTo({ y: currentDayOffset, animated: true })
    }, [currentDayOffset])

    const onLayout = ({ event, isCurrent, isLast, isView }) => {
        const { y, height } = event.nativeEvent.layout
        if (isView && viewHeight === appHeight) setViewHeight(height - 30)
        if (isLast && lastDayHeight === 0) setLastDayHeight(height + 23)
        if (isCurrent && currentDayOffset === 0) setCurrentDayOffset(y + 10)
    }

    const TimetableDayTitle = ({ day, isCurrent }) => {
        return (
            <Container
                flex={0}
                position={'relative'} zIndex={5}
                width={isWeb ? '100%' : null}
                margin={5}
                padding={5}
                backgroundColor={colors.screenBg}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Container flex={1} height={3} marginRight={10} borderRadius={2} backgroundColor={isCurrent ? colors.screenAltBg : null} />
                <Text
                    color={colors.screenAltText}
                    nativeFontFamily={fonts.native.subHeading}
                    webFontFamily={fonts.web.heading}
                    fontWeight={fonts.weight.semiBold}
                >
                    {moment(day).format('dddd, DD MMMM')}
                </Text>
                <Container flex={1} height={3} marginLeft={10} borderRadius={2} backgroundColor={isCurrent ? colors.screenAltBg : null} />
            </Container>
        )
    }

    return (
        <Container
            flex={1}
            flexGrow={1}
            alignItems={'center'}
            position={'relative'}
            webOverflow={'auto'}
            nativeOverflow={'hidden'}
            viewType={'ScrollView'}
            onLayout={event => onLayout({ event, isView: true })}
            onRefresh={hasInternet ? fetchFunc : null}
            useRef={scrollRef}
            isChat={isChatVisible}
        >
            <TimetableOptions
                fetchFunc={fetchFunc}
                lastFetchedAt={lastFetchedAt}
                weekDate={weekDate}
            />
            <Container
                flex={0}
                position={'relative'}
                zIndex={5}
                alignItems={'center'}
                width={'100%'}
                maxWidth={600}
                padding={5}
                paddingTop={0}
                marginBottom={20}
            >
                <Container flex={0} width={11} height={11} borderRadius={6} margin={6} backgroundColor={colors.screenAltBg} />
                {isEmpty ?
                    <Container position={'relative'} zIndex={5} flex={0} backgroundColor={colors.screenBg}>
                        <Text color={colors.screenAltText} >No scheduled events.</Text>
                    </Container>
                    : data.map(weekDay => {
                        const isCurrent = weekDay.date.isSame(moment(), 'day')
                        const isLast = weekDay.date.isSame(lastDateWithData)
                        if (weekDay.data.length < 1 && !isCurrent) return null
                        return (
                            <Container
                                key={weekDay.date}
                                position={'relative'}
                                zIndex={5}
                                padding={5}
                                flex={0}
                                width={'100%'}
                                maxWidth={600}
                                alignItems={'center'}
                                onLayout={event => onLayout({ event, isCurrent, isLast })}
                            >
                                <TimetableDayTitle day={weekDay.date} isCurrent={isCurrent} />
                                {weekDay.data.length < 1 ?
                                    <Container flex={0} backgroundColor={colors.screenBg}>
                                        <Text color={colors.screenAltText} >No scheduled events.</Text>
                                    </Container>
                                    : weekDay.data.map(item => <TimelineWrapper data={item} key={item.eventStartDate} fetchFunc={fetchFunc} />)}
                            </Container>
                        )
                    })}
                <Container flex={0} width={11} height={11} borderRadius={6} margin={6} backgroundColor={colors.screenAltBg} />
                {isWeb || isEmpty ? null : <Container flex={0} height={Math.max(viewHeight - lastDayHeight - 25, 0)} />}
                <Container
                    position={'absolute'}
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    justifyContent={'center'}
                >
                    <Container
                        flex={1}
                        width={5}
                        borderRadius={3}
                        marginTop={16}
                        marginBottom={16}
                        marginLeft={'auto'}
                        marginRight={'auto'}
                        backgroundColor={colors.screenAltBg}
                    />
                    {isWeb || isEmpty ? null : <Container flex={0} height={Math.max(viewHeight - lastDayHeight - 25, 0)} />}
                </Container>
            </Container>
            { hasInternet ? <StyledButton title={'Refresh Timetable'} accessibility={{ role: 'button', label: 'refresh the page' }} handleAction={fetchFunc} bgColor={colors.actionBg} textColor={colors.actionText} /> : null}
        </Container>
    )
}