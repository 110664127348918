import React from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from 'redux/store'
import { LoadingData } from 'components/general'
import { AppInit } from './AppInit'
import { AppContainer } from './AppContainer'
import { useInterval } from 'lib/general/useInterval'
import { RuntimeHelper } from 'redux/helpers'


export const AppCommon = props => {
  moment.updateLocale('en-gb', { week: { dow: 1 } })
  // Initiliase Redux Store
  const { store, persistor } = configureStore(props)

  return (
    <Provider store={store} >
      <PersistGate loading={<LoadingData />} persistor={persistor}>
        <AppInit />
        <OnlineChecker />
        <AppContainer />
      </PersistGate>
    </Provider>
  )
}

const OnlineChecker = () => {
  const checkIfOnline = RuntimeHelper.useCheckIfOnline()

  useInterval(() => {
    checkIfOnline()
  }, 7000)

  return null
}
