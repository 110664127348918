import React from 'react'
import { SettingsHelper } from 'redux/helpers'
import { Container, Text, Button } from 'primitives'
import { Icon, icons } from 'assets/MenuIcons'
import { useHistory } from 'navigation'
import { Platform } from 'lib/primitives'

export const BackBar = props => {
    const colors = SettingsHelper.getColors()
    const history = useHistory()
    const isWeb = Platform.isWeb()

    return (
        <Container
            flex={0}
            width={'100%'}
            alignItems={'center'}
            borderBottom={`2px solid ${colors.screenAltBg}`}
            borderBottomWidth={2}
            borderColor={colors.screenAltBg}
        >
            <Button
                accessibility={{ role: 'button', label: 'go back to previous page' }}
                handleAction={() => history.goBack()}
                flex={0}
                flexDirection={'row'}
                padding={10}
                alignItems={'center'}
                justifyContent={'flex-start'}
                width={'100%'}
                maxWidth={600}
            >
                <Container flex={0} transform={isWeb ? 'rotate(180deg)' : [{ rotate: '180deg' }]}>
                    <Icon type={icons.rightArrow} color={colors.screenAltText} size={15} />
                </Container>
                <Text color={colors.screenAltText} paddingLeft={5} paddingRight={5}>
                    Back
                </Text>
            </Button>
        </Container>
    )
}