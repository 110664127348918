export const webviewConfig = {
    pcAvailability: {
        injectedJavascript:
            // Remove the onlick action on tr (it breaks the link within the webview on Android)
            `var trs = document.body.querySelectorAll("tr");for(var i=0;i<trs.length;i++){trs[i].removeAttribute("onclick");};`
            // Styling:
            // `document.querySelector('tbody').style.backgroundColor = 'blue';` 
    },
    covid19: {
        injectedJavascript: `try {
            document.getElementsByClassName('main-header')[0].style.display = 'none';
            document.getElementsByTagName('footer')[0].style.display = 'none';
            } catch (e) {}`
    }    
}
